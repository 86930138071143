@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-interpret-detail {
  .base-heading::before {
    display: none;
  }
}

.i-interpret-detail__image {
  width: 88px;

  .img {
    border-radius: 50%;
    border: 4px solid colors.$white;
    box-shadow: shadows.$small-shadow;
  }
}

.i-interpret-detail__tags {
  border: 1px solid colors.$primary-100;

  @include media-breakpoint-only(sm) {
    align-items: flex-start;
  }
}

.i-interpret-detail__description {
  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.i-interpret-detail__content.entry-content {
  ol,
  ul {
    margin-top: 0;
  }
}

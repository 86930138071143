$primary-100: #edecf4;
$primary-300: #d7cef0;
$primary-500: #7868a3;
$primary-600: #6248aa;
$primary-600-o-80: #6248aacc;
$primary: #312960;

$secondary: #e93d30;
$secondary-500: #ff5c50;
$secondary-100: #ffdcdc;

$tertiary: #484752;
$tertiary-600: #73717e;
$tertiary-400: #b1afbd;
$tertiary-300: #d5d4da;

$gradient: linear-gradient(to right, #312960, #6248aa);
$gradient-2: linear-gradient(266.66deg, #9852b0 27.69%, #8f73e1 61.95%);

$background: #f9f9f9;
$gray: #a9a9b2;
$gray-200: #ebebeb;

$white: #fff;

$black: #081828;
$black-400: #1d1d1b;

$danger: #e21c3d;
$danger-200: #f9dede;

$success: #48a463;
$success-200: #e7f4e4;

$warning: #fdb81e;
$warning-200: #fff1d2;

$facebook: #3b5998;
$apple: #111;
$google-yellow: #fbbb00;
$google-blue: #518ef8;
$google-green: #28b446;
$google-red: #f14336;

$label-1: #f43e80;
$label-2: #f9af79;
$label-3: #c7e9f4;
$label-4: #ffdc61;
$label-5: #cfe9a4;

$availability-status-1: #ff2616;
$availability-status-2: #ff7629;
$availability-status-3: #0dc5ed;
$availability-status-4: #57bc3d;

$status-1: $availability-status-1;
$status-2: $label-4;
$status-3: $label-3;
$status-4: $label-5;

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.f-variant__input {
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
  }
}

.f-variant__mediums {
  pointer-events: none;

  .img {
    margin: 0 -10px;
    transform: translate(0);
  }
}

.f-variant__color {
  width: 6px;
  height: 6px;
  background-color: var(--color);
}

.f-variant {
  --border-color: #{colors.$primary-300};
  --color: #{colors.$primary};
  --background: transparent;
  --height: 88px;
  --font-weight: 400;
  --line-height: 24px;

  min-height: var(--height);
  min-width: var(--height);
  box-shadow: inset 0 0 0 1px var(--border-color);
  background-color: var(--background);
  color: var(--color);
  transition: 0.2s ease;
  padding-inline: 12px;
  transition-property: box-shadow, background-color, color;

  &::after {
    content: "";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background:
      url("../images/svg/check.svg") center center no-repeat,
      colors.$primary-600;
    background-size: 14px;
    top: 50%;
    left: 2px;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  &:hover {
    --background-color: #{colors.$white};
    --font-weight: 700;

    box-shadow:
      shadows.$product-card-hover-shadow,
      inset 0 0 0 1px var(--border-color);
  }

  &:has(.f-variant__input:checked, .f-variant__input.--link.--checked) {
    --font-weight: 700;

    box-shadow: inset 0 0 0 2px var(--border-color);

    &:not(.--disabled) {
      --border-color: #{colors.$primary-600};
    }

    &::after {
      opacity: 1;
    }
  }

  &.--disabled {
    --border-color: #{colors.$tertiary-400};
    --color: #{colors.$tertiary-400};

    pointer-events: none;

    .f-variant__mediums {
      filter: grayscale(1);
    }
  }
}

@use "settings/s-colors" as colors;

.i-search-dropdown {
  @include media-breakpoint-up(lg) {
    border-top: 1px solid colors.$primary-300;
  }

  @include media-breakpoint-down(md) {
    max-height: none;
  }
}

.i-search-dropdown__link {
  text-align: right;

  @include media-breakpoint-between(lg, xl) {
    font-size: 14px;
  }
}

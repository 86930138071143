@use "settings/s-colors" as colors;
@use "settings/s-general" as general;

.i-product {
  &.--wishlist {
    border-radius: 16px;

    &::before {
      top: 8px;
      left: 98px;
    }

    .i-product__header {
      align-self: end;
    }

    .i-product__subtitle {
      display: block;
    }

    .i-product__body {
      display: grid;
      grid-template-columns: 176px 1fr 225px;
      grid-template-areas:
        "image header buttons"
        "image content buttons";
    }

    .i-product__content {
      align-self: start;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "mediums price"
        "availability price";
      gap: 8px;
      margin-right: 16px;
    }

    .i-product__image {
      align-self: start;
    }

    .i-discount {
      top: auto;
      bottom: 0;
    }

    .i-product__buttons {
      flex-direction: column;
      justify-content: center;
    }

    .i-product__price {
      align-self: start;
    }

    .i-add-wishlist-button__button {
      width: 100%;
    }

    .i-product__cart-add {
      --min-height: 48px;
      --padding-x: 8px;
      --padding-y: 8px;

      flex-grow: 0;
    }

    @include media-breakpoint-up(md) {
      width: 100%;
    }

    @include media-breakpoint-between(md, lg) {
      .i-product__buttons {
        flex-direction: row;

        > * {
          flex-grow: 1;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      .i-product__body {
        grid-template-columns: 176px 1fr;
        grid-template-areas:
          "image header"
          "image content"
          "buttons buttons";
      }
    }

    @include media-breakpoint-down(md) {
      &::before {
        top: 8px;
        left: 56px;
      }

      .i-product__body {
        grid-template-columns: 80px 1fr;
        grid-template-areas:
          "image image"
          "header header"
          "content content"
          "buttons buttons";
      }

      .i-product__content {
        grid-template-columns: 64px 1fr;
        grid-template-areas:
          "mediums mediums"
          "availability price";
        gap: 12px 8px;
      }
    }
  }
}

@use "settings/s-colors.scss" as colors;
@use "settings/s-shadows.scss" as shadows;

.g-mediums {
  gap: 24px;
  z-index: 10;

  &:hover {
    z-index: 15;
  }

  .i-medium,
  .g-mediums__show-more {
    &::before {
      content: "+";
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      left: -18px;
      font-size: 18px;
      line-height: 12px;
      color: colors.$tertiary;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .i-medium {
    &:first-child {
      &::before {
        display: none;
      }
    }
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-nav {
  --transition-timing: 0;

  @include media-breakpoint-up(lg) {
    margin-left: auto;
    border-bottom: 1px solid colors.$primary-300;
  }

  @include media-breakpoint-down(lg) {
    padding-block: 80px;
    width: 100vw;
    opacity: 0;
    transition: transform var(--transition-timing) ease;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    pointer-events: auto;

    &.--open {
      opacity: 1;
    }

    &.--transition {
      --transition-timing: 0.3s;
    }

    &:has(.i-menu.--has-submenu.--open) .m-nav__promo-button {
      display: none;
    }
  }
}

.m-nav__list {
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  @include media-breakpoint-down(lg) {
    align-self: stretch;
  }
}

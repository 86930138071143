@use "settings/s-colors" as colors;

.product-gallery__slider {
  border: 1px solid colors.$primary-100;
  max-width: 568px;

  @include media-breakpoint-up(md) {
    margin-left: 96px;
  }
}

.product-gallery__thumbnails {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: auto;
    width: 88px;
    max-height: 100%;
  }

  @include media-breakpoint-down(md) {
    order: 2;
  }
}

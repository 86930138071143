.s-hero-signpost {
  margin-top: 40px;

  @include media-breakpoint-down(lg) {
    margin-top: 24px;
  }
}

.s-hero-signpost__body {
  &:has(.i-product-offer) {
    grid-template-columns: 328px 1fr;
    grid-template-areas: "offer products";

    @include media-breakpoint-down(lg) {
      grid-template-columns: 272px 1fr;
      column-gap: 16px;
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }
}

.s-hero-signpost__products {
  grid-area: products;
}

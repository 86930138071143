@use "settings/s-colors" as colors;
@use "settings/s-fonts" as fonts;

.btn__icon {
  .icon {
    --size: var(--svg-size);
  }

  svg {
    transition: transform 0.3s ease;
    transition-property: transform, opacity;
  }
}

.btn {
  --border-radius: 8px;
  --font-size: 16px;
  --font-weight: 700;
  --font-color: #{colors.$white};
  --font-family: #{fonts.$secondary};
  --padding-y: 16px;
  --padding-x: 24px;
  --min-height: 56px;
  --svg-size: 20px;

  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  user-select: none;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: var(--font-color);
  }

  &:focus,
  &:active {
    outline: 0;
    color: var(--font-color);
    text-decoration: none;
  }

  path {
    fill: var(--font-color);
  }

  &.--icon-before {
    .btn__icon {
      order: -1;
    }
  }

  &.--icon-only {
    &:not(.--full-width) {
      width: var(--min-height);
    }

    height: var(--min-height);
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    .btn__title {
      display: none;
    }
  }

  &.--full-width-mobile {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &.--rounded {
    --border-radius: calc(var(--min-height) / 2);
  }

  &.--indicator {
    &::after {
      content: attr(data-indicator);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      background-color: colors.$secondary-500;
      font-size: 10px;
      line-height: 16px;
      font-weight: 700;
      text-align: center;
      color: colors.$white;
      position: absolute;
      top: 0;
      right: -3px;
      border-radius: 50%;
    }
  }
}

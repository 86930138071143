@use "settings/s-colors" as colors;

.f-date__icon {
  display: var(--icon-display);
  padding: 8px;
  position: absolute;
  pointer-events: none;
  padding-right: 16px;
  right: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.f-date__input {
  color: var(--font-color);
  border-radius: var(--radius);
  cursor: var(--cursor);

  &::-webkit-inner-spin-button,
  &::-webkit-clear-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
  }
}

.f-date {
  --inset-shadow-color: #{colors.$tertiary-400};
  --radius: 24px;
  --icon-display: block;
  --cursor: pointer;
  --font-color: #{colors.$primary};
  --shadow: 0px 0px 0px 0px transparent;

  &:hover {
    --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);

    .f-base {
      --inset-shadow-color: #{colors.$primary-600};
    }
  }

  &:not(.--native) {
    .f-date__input {
      padding-right: 48px;
    }
  }

  &.--active {
    .f-base {
      --inset-shadow-color: #{colors.$primary-600};
    }
  }

  &.--native {
    --icon-display: none;
    --cursor: auto;
  }

  &.--invalid {
    .f-base {
      --inset-shadow-color: #{colors.$danger};
    }
  }
}

.f-date__wrap {
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  transition: box-shadow 0.3s ease;
}

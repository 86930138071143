.w-top-products {
  .g-buttons {
    @include media-breakpoint-up(md) {
      --spacing: 32px;
    }
  }

  .w-top-products__header {
    --spacing: 16px;

    padding-block: 8px;
  }
}

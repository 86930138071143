.i-sort-header {
  --arrow-left-display: visible;
  --arrow-right-display: visible;

  &.--asc {
    --arrow-left-display: hidden;
  }

  &.--desc {
    --arrow-right-display: hidden;
  }

  .s-sort-left {
    visibility: var(--arrow-left-display);
  }

  .s-sort-right {
    visibility: var(--arrow-right-display);
  }
}

@use "settings/s-colors" as colors;

.i-faq__body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.i-faq__body-inner {
  overflow: hidden;
}

.i-faq__header {
  cursor: pointer;
}

.i-faq__heading {
  line-height: 24px;
  transition: color 0.3s ease;
}

.i-faq__toggler {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.i-faq {
  --border-radius: 16px;
  --shadow: 0px 0px 0px 0px transparent;
  --bg-color: transparent;

  border: 1px solid colors.$primary-300;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  background-color: var(--bg-color);
  transition:
    box-shadow 0.3s ease,
    background-color 0.3s ease;

  &:hover {
    --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
    --bg-color: #{colors.$white};

    .i-faq__heading {
      color: colors.$primary-600;
    }

    .i-faq__toggler {
      background-color: colors.$primary-100;
    }
  }

  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }

  &.--open {
    --bg-color: transparent;

    .i-faq__toggler svg {
      transform: scale(-1);
    }

    .i-faq__body {
      grid-template-rows: 1fr;
    }
  }
}

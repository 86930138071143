@use "settings/s-colors.scss" as colors;

.link {
  &.--black {
    --color: #{colors.$black};
  }

  &.--white {
    --color: #{colors.$white};
  }

  &.--primary {
    --color: #{colors.$primary};
  }

  &.--secondary {
    --color: #{colors.$secondary};
  }

  &.--primary-600 {
    --color: #{colors.$primary-600};
  }

  &.--tertiary {
    --color: #{colors.$tertiary};
  }

  &.--tertiary-to-primary {
    --color: #{colors.$tertiary};
    --color-hover: #{colors.$primary};
  }

  &.--tertiary-to-primary-600 {
    --color: #{colors.$tertiary};
    --color-hover: #{colors.$primary-600};
  }

  &.--primary-600-to-primary {
    --color: #{colors.$primary-600};
    --color-hover: #{colors.$primary};
  }

  &.--primary-to-secondary-500 {
    --color: #{colors.$primary};
    --color-hover: #{colors.$secondary-500};
  }

  &.--primary-600-to-secondary-500 {
    --color: #{colors.$primary-600};
    --color-hover: #{colors.$secondary-500};
  }

  &.--colored:not(.--underline-initial) {
    color: var(--color);
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: var(--color-hover, var(--color));
      text-decoration-color: var(--color-hover, var(--color));
    }
  }

  &.--colored.--underline-initial {
    text-decoration-color: inherit;
    color: var(--color);

    &:hover,
    &:focus {
      text-decoration-color: transparent;
      color: var(--color-hover, var(--color));
    }
  }
}

@use "settings/s-colors" as colors;

.i-transport-indicator {
  flex: 0 1 224px;

  @include media-breakpoint-down(xl) {
    font-size: 14px;
  }

  &.--progress {
    .i-transport-indicator__title {
      &.--default-title {
        display: none;
      }

      &.--progress-title {
        display: inline;
      }
    }
  }

  &.--fulfilled {
    .i-transport-indicator__icon {
      --color: #{colors.$secondary-500};
    }

    .i-transport-indicator__title {
      &.--fulfilled-title {
        display: inline;
      }

      &.--progress-title,
      &.--default-title {
        display: none;
      }
    }
  }
}

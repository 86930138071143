@use "settings/s-colors" as colors;

.u-scrollbar {
  .os-scrollbar {
    --os-handle-bg: #{colors.$primary-600};
    --os-handle-bg-hover: #{colors.$primary-600};
    --os-handle-bg-active: #{colors.$primary-600};
    --os-handle-border-radius: 8px;
    --os-size: 16px;
    --os-track-bg: #{colors.$primary-100};
    --os-track-bg-hover: #{colors.$primary-100};
    --os-track-bg-active: #{colors.$primary-100};
    --os-padding-perpendicular: 4px;
    --os-padding-axis: 4px;

    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

.u-scrollbar-native {
  --sb-track-color: #{colors.$background};
  --sb-thumb-color: #{colors.$primary-500};
  --sb-size: 8px;

  &::-webkit-scrollbar {
    width: var(--sb-size);
  }

  &::-webkit-scrollbar:horizontal {
    height: var(--sb-size);
  }

  &::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 8px;
    border: 1px solid #fff;
  }
}

@supports not selector(::-webkit-scrollbar) {
  .u-scrollbar-native {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

@use "settings/s-colors" as colors;

.product-thumbnails {
  width: 100%;
  max-height: 100%;

  &.swiper {
    position: static;
  }

  .swiper-slide {
    cursor: pointer;

    @include media-breakpoint-down(md) {
      width: 88px;
    }
  }

  .swiper-slide-thumb-active {
    .i-thumbnail {
      border-color: colors.$primary-600;
    }
  }

  .swiper-buttons {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    pointer-events: none;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    @include media-breakpoint-up(md) {
      height: 100%;
      transform: translateX(-50%);
      top: 0;
      left: 50%;
      flex-direction: column;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .swiper-button {
    pointer-events: auto;
    transition: opacity 0.3s ease;

    @include media-breakpoint-up(md) {
      rotate: 90deg;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }

    &.--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .swiper-button-prev {
    @include media-breakpoint-down(md) {
      margin-left: -8px;
    }

    @include media-breakpoint-up(md) {
      margin-top: -8px;
    }
  }

  .swiper-button-next {
    @include media-breakpoint-up(md) {
      margin-bottom: -8px;
    }

    @include media-breakpoint-down(md) {
      margin-right: -8px;
    }
  }
}

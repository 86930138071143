@use "settings/s-colors" as colors;

.f-range-slider__label.--from,
.f-range-slider__label.--to,
.f-range-slider__label.--from-to {
  bottom: 14px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
}

.f-range-slider {
  --height: 4px;
  --thumb-size: 16px;
  --thumb-offset: 8px; //only length is allowed
  --color: #{colors.$primary-600};
  --background: #{colors.$primary-300};
  --gradient-from: calc(var(--background-from) - var(--thumb-offset));
  --gradient-to: calc(var(--background-to) + var(--thumb-offset));

  &.--from-to {
    .f-range-slider__label.--from,
    .f-range-slider__label.--to {
      display: none;
    }

    .f-range-slider__label.--from-to {
      display: block;
    }
  }
}

.f-range-slider__control {
  .f-base {
    min-height: 40px;
    padding-block: 10px;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}

.f-range-slider__ranges {
  height: var(--height);
}

.f-range-slider__inputs {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.f-range-slider__range {
  appearance: none;
  inset: 0 var(--thumb-offset);
  position: absolute;
  background: linear-gradient(
    90deg,
    transparent 0%,
    transparent var(--gradient-from),
    var(--color) var(--gradient-from),
    var(--color) var(--gradient-to),
    transparent var(--gradient-to),
    transparent 100%
  );
  pointer-events: none;

  &::before {
    content: "";
    position: absolute;
    inset: 0 calc(var(--thumb-offset) * -1);
    background-color: var(--background);
    z-index: -1;
    border-radius: var(--height);
  }

  &::-webkit-slider-thumb,
  &::-moz-range-thumb {
    appearance: none;
    pointer-events: all;
    width: var(--thumb-size);
    height: var(--thumb-size);
    background-color: colors.$primary-600;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transform: translateX(calc(var(--thumb-offset) * var(--side)));
    transition: 0.2s ease background-color;
    z-index: 2;
  }

  &.--from {
    --side: -1;
  }

  &.--to {
    --side: 1;
  }
}

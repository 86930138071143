@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.f-color__input {
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
  }
}

.f-color {
  --size: 32px;
  --radius: 50%;

  width: var(--size);
  height: var(--size);
  border-radius: var(--radius);
  background: transparent;
  padding: 4px;
  transition: 0.2s ease;
  transition-property: box-shadow, background-color;

  &::after {
    content: "";
    display: block;
    border-radius: var(--radius);
    background: var(--background-color);
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 0 1px colors.$white;
  }

  &:hover {
    box-shadow: shadows.$product-card-hover-shadow;
    background: colors.$white;

    &::after {
      box-shadow: inset 0 0 0 0 colors.$white;
    }
  }

  &:has(input:checked),
  &.--active {
    background: colors.$primary-600;

    &::after {
      box-shadow: inset 0 0 0 2px colors.$white;
    }
  }

  &.--disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  &.--link {
    &.--checked {
      background: colors.$primary-600;

      &::after {
        box-shadow: inset 0 0 0 2px colors.$white;
      }
    }
  }
}

.i-filtration-visual {
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &:not(:has(.i-selected-tag)) {
    display: none;
  }
}

.i-filtration-visual__reset-btn.btn {
  padding-block: 6px;
  margin: 0 auto;
}

@use "settings/s-colors" as colors;

.btn.i-selected-tag {
  --border-radius: 23px;
  --font-weight: 500;

  background-color: colors.$primary-100;
  text-transform: none;

  @media (hover: hover) {
    &:hover {
      box-shadow: none;
    }
  }
}

.m-ppl {
  @include media-breakpoint-down(sm) {
    &.c-modal.--size-xl {
      --width: min(100%, 1140px);
    }

    .c-modal__dialog {
      height: calc(100% - 32px);
      margin-block: 16px;
    }

    .c-modal__content {
      height: 100%;
    }

    .c-modal__inner {
      height: 100%;
    }

    #ppl-parcelshop-map {
      height: calc(100% - 32px);
    }
  }
}

@use "settings/s-colors.scss" as colors;

.i-tag {
  --font-color: #{colors.$primary-600};
  --background-color: #{colors.$white};
  --font-size: 14px;
  --radius: 24px;

  font-size: var(--font-size);
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: var(--radius);
  border: 1px solid var(--background-color);
  gap: 2px;

  &.--outline {
    border-color: colors.$primary-300;
  }

  &.--primary {
    --font-color: #{colors.$white};
    --background-color: #{colors.$primary-600};
  }

  &.--blank {
    --background-color: transparent;

    padding: 0;
    min-height: auto;
  }

  &.--icon-before {
    .i-tag__icon {
      order: -1;
    }
  }

  &.--icon-only {
    width: var(--min-height);
    height: var(--min-height);
    min-height: auto;
    padding: 0;

    .i-tag__title {
      display: none;
    }
  }

  &.--input {
    transition-property: border-color, background-color;
    will-change: border-color, background-color;
  }
}

label.i-tag,
a.i-tag,
.i-tag.--hoverable {
  text-decoration: none;
  transition:
    border-color 0.3s ease,
    background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    border-color: colors.$primary;
  }

  &.--primary {
    &:hover {
      --font-color: #{colors.$white};
      --background-color: #{colors.$primary};

      color: var(--font-color);
    }
  }
}

a.i-tag.--blank {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;

  &:hover {
    border-color: transparent;
    text-decoration-color: var(--font-color);
  }
}

.i-tag__input {
  left: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
}

.i-tag:has(.i-tag__input:checked) {
  --font-color: var(--c-white);

  border-color: colors.$primary;
}

@use "settings/s-colors" as colors;

.i-social {
  &.--lg {
    .btn {
      --border-radius: 16px;
      --min-height: 64px;
    }
  }

  &.--sm {
    .btn {
      --border-radius: 50%;
      --min-height: 24px;
    }
  }
}

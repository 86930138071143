.i-countdown__value {
  width: 48px;
  height: 48px;
  border-radius: 6px;
}

.i-countdown__colon {
  margin-top: 12px;
}

.i-countdown__step {
  &.--hidden {
    display: none;

    + .i-countdown__colon {
      display: none;
    }
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-product-offer__header {
  &::after,
  &::before {
    content: "";
    display: block;
    background: url("../images/svg/decor-after.svg") center no-repeat;
    width: 21px;
    height: 9px;
  }
}

.i-product-offer {
  border: 1px solid colors.$primary-300;
  transition: 0.2s ease;
  transition-property: background-color, opacity, transform;

  &.--hidden {
    pointer-events: none;
    opacity: 0;
    transform: translateY(-48px);
  }

  @container (max-width: 312px) {
    .i-product-offer__body {
      padding: 8px;
    }
  }
}

@use "settings/s-colors" as colors;

.product-detail__form {
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "gallery heading"
    "gallery content";

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "heading"
      "gallery"
      "content";
  }
}

.product-detail__header,
.product-detail__variants {
  border-bottom: 1px solid colors.$primary-300;
}

.product-detail__footer {
  grid-template-columns: 56px auto 1fr;
  grid-template-areas:
    "discount discount price"
    "wishlist counter cart"
    "lowest lowest lowest";

  @container (max-width: 488px) {
    gap: 16px;
    grid-template-areas:
      "discount discount discount"
      "price price price"
      "wishlist counter cart"
      "lowest lowest lowest";
  }

  @container (max-width: 430px) {
    gap: 8px;
    grid-template-areas:
      "discount discount discount"
      "price price price"
      "wishlist counter counter"
      "cart cart cart"
      "lowest lowest lowest";
  }
}

.product-detail__gallery {
  grid-area: gallery;
}

.product-detail__heading {
  grid-area: heading;
  margin-bottom: -16px;
}

.product-detail__content {
  grid-area: content;
}

.product-detail__availability {
  min-height: 56px;
}

.product-detail__discount {
  grid-area: discount;
}

.product-detail__price {
  grid-area: price;
}

.product-detail__wishlist {
  grid-area: wishlist;
}

.product-detail__counter {
  grid-area: counter;
}

.product-detail__cart-add {
  grid-area: cart;
}

.product-detail__lowest-price {
  grid-area: lowest;
}

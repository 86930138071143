.g-buttons {
  --spacing: 0px;

  margin-top: var(--spacing);

  &:has(.btn) {
    --spacing: 24px;

    @include media-breakpoint-up(md) {
      --spacing: 48px;
    }
  }

  &.--spacing-none {
    --spacing: 0;
  }
}

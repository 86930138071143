@use "settings/s-colors" as colors;

.f-text__icon {
  position: absolute;
  pointer-events: none;

  &.--before {
    left: 24px;
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  &.--after {
    right: 24px;
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
}

.f-text__input {
  border-radius: var(--radius);
  color: var(--font-color);
}

.f-text {
  --inset-shadow-color: #{colors.$tertiary-400};
  --font-color: #{colors.$primary};
  --shadow: 0px 0px 0px 0px transparent;
  --radius: 24px;

  &:hover {
    --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);

    .f-base {
      --inset-shadow-color: #{colors.$primary-600};
    }
  }

  &.--active {
    .f-base {
      --inset-shadow-color: #{colors.$primary-600};
    }
  }

  &.--invalid {
    .f-base {
      --inset-shadow-color: #{colors.$danger};
    }
  }

  &.--icon-before {
    .f-text__input {
      padding-left: 56px;
    }

    .f-label:not(.--active) {
      left: 56px;
    }
  }

  &.--icon-after {
    .f-text__input {
      padding-right: 56px;
    }
  }

  &.--disabled {
    opacity: 0.5;
  }

  @include media-breakpoint-down(sm) {
    .f-text__icon {
      &.--before {
        left: 16px;
      }

      &.--after {
        right: 16px;
      }
    }

    &.--icon-before {
      .f-text__input {
        padding-left: 48px;
      }

      .f-label:not(.--active) {
        left: 48px;
      }
    }

    &.--icon-after {
      .f-text__input {
        padding-right: 48px;
      }
    }
  }
}

.f-text__wrap {
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  transition: box-shadow 0.3s ease;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 24px;

  right: 16px;
  width: var(--size);
  height: var(--size);

  &.--active {
    --on-opacity: 1;
    --off-opacity: 0;
  }
}

.f-text__toggle-icon {
  grid-column-start: 1;
  grid-row-start: 1;
  width: var(--size);
  height: var(--size);

  &.--on {
    opacity: var(--on-opacity);
  }

  &.--off {
    opacity: var(--off-opacity);
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.f-tag-checkbox__input {
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
  }
}

.f-tag-checkbox {
  --border-color: #{colors.$primary-300};
  --color: #{colors.$primary};
  --background: transparent;
  --height: 48px;
  --border-radius: 8px;
  --font-size: 16px;
  --font-weight: 400;
  --line-height: 24px;
  --padding-x: 8px;

  min-height: var(--height);
  min-width: var(--height);
  padding-inline: var(--padding-x);
  box-shadow: inset 0 0 0 1px var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--color);
  transition: 0.2s ease;
  transition-property: box-shadow, background-color, color;

  &::after {
    content: "";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background:
      url("../images/svg/check.svg") center center no-repeat,
      colors.$primary-600;
    background-size: 14px;
    top: 2px;
    left: 2px;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.1s ease;
  }

  &.--disabled {
    --border-color: #{colors.$tertiary-400};
    --color: #{colors.$tertiary-400};

    pointer-events: none;
  }

  &:hover {
    --background-color: #{colors.$white};
    --font-weight: 700;

    box-shadow:
      shadows.$product-card-hover-shadow,
      inset 0 0 0 1px var(--border-color);
  }

  &:has(.f-tag-checkbox__input:checked),
  &.--active {
    --border-color: #{colors.$primary-600};
    --font-weight: 700;

    box-shadow: inset 0 0 0 2px var(--border-color);

    &::after {
      opacity: 1;
    }
  }

  &.--link {
    &.--checked {
      --border-color: #{colors.$primary-600};
      --font-weight: 700;

      box-shadow: inset 0 0 0 2px var(--border-color);

      &::after {
        opacity: 1;
      }
    }
  }
}

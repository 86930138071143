.g-category__cell {
  &.--hidden {
    display: none;
  }
}

.g-category {
  @include media-breakpoint-down(sm) {
    --bs-gutter-x: 16px;
    --bs-gutter-y: 16px;
  }
}

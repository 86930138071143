@use "settings/s-shadows" as shadows;

.u-shadow-small {
  box-shadow: shadows.$small-shadow;
}

.u-shadow-medium {
  box-shadow: shadows.$medium-shadow;
}

.u-hover\:shadow-medium:hover {
  box-shadow: shadows.$medium-shadow;
}

.u-hover\:shadow-small:hover {
  box-shadow: shadows.$small-shadow;
}

.m-header-middle__contacts {
  @include media-breakpoint-down(xxl) {
    font-size: 18px;
  }

  @include media-breakpoint-down(xl) {
    font-size: 12px;
  }
}

.m-header-middle {
  @include media-breakpoint-down(lg) {
    border-radius: 0 0 24px 24px;
    transform: translateY(0);
    transition: transform 0.3s ease;
    z-index: 5;
    pointer-events: auto;
  }

  > .container {
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .c-language-select {
    display: none;
  }

  .i-wishlist-button {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .f-base {
    @include media-breakpoint-down(lg) {
      min-height: 48px;
    }
  }
}

.m-header-middle__actions {
  @include media-breakpoint-down(lg) {
    flex-grow: 1;
    gap: 8px;
  }
}

@use "settings/s-colors" as colors;

.g-contacts-pillars {
  @include media-breakpoint-up(xl) {
    padding-inline: 16px;
  }
}

.g-contacts-pillars__cell {
  --max-width: 100%;
  --margin: auto;

  max-width: var(--max-width);

  &:nth-child(1) {
    .i-contact-pillar {
      @include media-breakpoint-up(xl) {
        margin-left: var(--margin);
        border-radius: 24px 0 0 24px;
      }
    }
  }

  &:nth-child(3) {
    .i-contact-pillar {
      @include media-breakpoint-up(xl) {
        margin-right: var(--margin);
        border-radius: 0 24px 24px 0;
      }
    }
  }

  @include media-breakpoint-down(xl) {
    --max-width: 668px;
    --margin: 0;

    margin-inline: auto;

    &:nth-child(1),
    &:nth-child(3) {
      .i-contact-pillar {
        border-radius: 24px;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    &:nth-child(1),
    &:nth-child(3) {
      --max-width: 407px;

      width: 100%;
    }

    &:nth-child(2) {
      --max-width: 447px;

      width: 100%;
    }
  }
}

@use "settings/s-colors" as colors;

.g-signpost {
  .g-signpost__cell {
    @include media-breakpoint-down(md) {
      max-width: 448px;
      margin-inline: auto;
    }
  }
}

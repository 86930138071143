@use "settings/s-colors" as colors;

.product-parameters__body {
  border: 1px solid colors.$primary-300;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
}

.product-parameters__row {
  @container (min-width: 320px) {
    grid-template-columns: 1fr 1fr;
  }
}

.product-parameters__value {
  &.--open {
    .product-parameters__completed-value {
      display: inline;
    }

    .product-parameters__short {
      display: none;
    }

    .product-parameters__ellipsis {
      display: none;
    }

    .product-parameters__show-more {
      svg {
        transform: rotateX(-180deg);
      }
    }
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-interpret {
  @include media-breakpoint-down(sm) {
    width: min(100%, 488px);
    margin-inline: auto;
  }
}

.i-interpret__image-link {
  transition: transform 0.3s linear;
  transform-style: preserve-3d;

  .img {
    border-radius: 50%;
    border: 4px solid colors.$white;
    box-shadow: shadows.$small-shadow;
  }
}

.i-interpret__image-back-overlay {
  backface-visibility: hidden;
  background-color: rgba(colors.$primary-600, 0.8);
  color: colors.$white;
  transform: rotateY(180deg);
  column-gap: 4px;
}

.i-interpret__image-wrapper {
  width: min(204px, 100%);

  @include media-breakpoint-down(md) {
    width: min(128px, 100%);
  }

  & ~ .base-heading {
    transition: color 0.3s ease;
    transition-property: color, transform;
  }

  &:hover {
    .i-interpret__image-link {
      transform: rotateY(180deg);
    }

    & ~ .base-heading {
      color: colors.$primary-600;
      transform: translateY(-8px);
    }
  }
}

.i-interpret__more-btn.btn {
  --font-size: 16px;
}

.i-interpret__products-btn {
  min-width: 160px;
}

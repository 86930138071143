@use "settings/s-colors" as colors;
@use "settings/s-general" as general;
@use "settings/s-fonts" as fonts;
@import "./src/components/items/discount-item/discount-item";
@import "./src/components/items/price-item/price-item";
@import "./src/components/items/tag-item/tag-item--sizes";
@import "./src/components/items/availability-item/availability-item";

.i-product__image {
  grid-area: image;
  border-radius: 16px;
}

.i-product__header,
.i-product__variants {
  grid-column: span 2;
}

.i-product__header {
  grid-area: header;
}

.i-product__link.link {
  --font-size: 16px;
  --line-height: 24px;

  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: #{fonts.$primary};
  text-decoration: none;

  &:hover {
    color: colors.$primary-600;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 2;
  }
}

.i-product__content {
  grid-area: content;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "mediums mediums"
    "availability price";
}

.i-product__mediums {
  pointer-events: none;
  grid-area: mediums;

  > .i-medium {
    pointer-events: auto;
  }

  .g-mediums__show-more {
    pointer-events: auto;
  }

  &:hover {
    .g-mediums {
      pointer-events: auto;
    }
  }
}

.i-product__price {
  grid-area: price;
}

.i-product__availability {
  grid-area: availability;
}

.i-product__buttons {
  grid-area: buttons;
}

.i-product__image-thumbnail {
  width: 120px;
  height: 120px;
}

.i-product__cart-add,
.i-product__wishlist {
  z-index: 10;
}

.i-product__cart-add {
  flex: 1;
}

.i-product__tags {
  width: calc(100% - 56px);
  pointer-events: none;
}

.i-product {
  width: min(100%, 328px);
  border-radius: 16px;
  border: 1px solid colors.$primary-300;
  transition: background-color 0.2s ease;
  transition-property: box-shadow, background-color;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &:hover {
    z-index: 15;
  }

  &:has(.i-product__link:hover) {
    background: colors.$white;

    .i-product__image-link {
      img {
        transform: scale(1.03);
      }
    }
  }

  &.--hidden-buttons {
    .i-product__buttons {
      display: none;
    }
  }

  @container (max-width: 224px) {
    .g-mediums.--toggle {
      top: auto;
      left: auto;
      right: 50%;
      bottom: -1px;
      transform: translateX(50%);
    }

    .i-product__body {
      padding: 8px;
      border-radius: 16px;
    }

    .i-product__link.link {
      --font-size: 14px;
      --line-height: 16px;
    }

    .i-product__content {
      grid-template-columns: 1fr;
      grid-template-areas:
        "mediums"
        "availability"
        "price";
    }

    .i-product__buttons {
      gap: 8px;

      .btn__title {
        display: none;
      }
    }

    .i-product__price {
      @include price-item-size-sm;
    }

    .i-product__discount {
      @include discount-item-size-sm;

      bottom: 0;
      top: auto;
    }

    .i-product__tag.i-tag {
      @include tag-item-size-xs;
    }

    .i-product__availability {
      @include availability-item-size-xs;
    }
  }
}

@use "settings/s-colors" as colors;

.d-small-circles {
  left: 0;
  column-gap: 3px;

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::after {
    width: 16px;
    height: 16px;
    background-color: colors.$primary-600;
    border-radius: 16px;
  }

  &::before {
    width: 8px;
    height: 16px;
    background-color: colors.$secondary-500;
    border-radius: 0 16px 16px 0;
  }
}

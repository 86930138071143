.i-overview {
  &.--default {
    flex-wrap: wrap;
    align-items: center;

    .i-overview__title,
    .i-overview__subtitle {
      font-size: 12px;
    }

    .i-overview__label {
      width: 100%;
      flex-grow: 1;
    }

    .i-overview__description {
      min-width: 204px;
      max-width: calc(100% - 56px - 72px);
    }
  }

  &.--compact {
    justify-content: space-between;

    .i-overview__title,
    .i-overview__subtitle {
      font-size: 14px;
    }
  }
}

.i-overview__image {
  --image-size: 56px;

  flex: 0 0 var(--image-size);
  max-width: var(--image-size);
  height: var(--image-size);
}

.i-overview__price {
  margin-left: auto;
}

@use "settings/s-colors.scss" as colors;

.i-user-panel__link.link.--primary-600 {
  background-color: transparent;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &:hover,
  &:focus {
    background-color: colors.$primary-100;
  }

  &.--active {
    color: colors.$white;
    background-color: colors.$primary-600;

    &:hover,
    &:focus {
      color: colors.$white;
      background-color: colors.$primary-600;
    }

    .icon {
      --color: #{colors.$white};
    }
  }
}

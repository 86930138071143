@use "settings/s-colors" as colors;

.i-cart {
  --image-size: 80px;
  --grid-columns: var(--image-size) 2fr 1fr 150px 40px 0.5fr 46px; // corresponding to .i-discount, .button
  --grid-areas: "image content availability counter discount price action";

  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  align-items: center;
  min-height: var(--image-size);
  background-color: colors.$white;
  border: 1px solid colors.$primary-100;

  .c-counter {
    --width: 128px;

    min-height: 40px;
    height: 40px;
  }

  &.--voucher {
    --grid-columns: auto 4fr 1fr 46px;
    --grid-areas: "image content price action";
  }

  @include media-breakpoint-down(xl) {
    --image-size: 64px;

    grid-template-columns: var(--image-size) 1fr 1fr 46px;
    grid-template-areas:
      "image content content content"
      "availability availability discount discount"
      "counter counter price action";

    &.--voucher {
      grid-template-columns: auto 1fr 46px;
      grid-template-areas:
        "image content content"
        "price price action";
    }
  }
}

.i-cart__image {
  grid-area: image;
  width: var(--image-size);
  height: var(--image-size);
}

.i-cart__content {
  grid-area: content;

  .i-sale-voucher {
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    padding-left: 10px;
  }

  @include media-breakpoint-down(xl) {
    align-self: start;
    margin-top: 8px;
    max-width: 100%;
  }
}

.i-cart__availability {
  grid-area: availability;
}

.i-cart__counter {
  grid-area: counter;
}

.i-cart__price {
  grid-area: price;
}

.i-cart__discount {
  grid-area: discount;
  justify-self: end;
}

.i-cart__action {
  grid-area: action;
  justify-self: end;
}

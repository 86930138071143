@use "settings/s-colors" as colors;

.i-medium {
  --height: 32px;

  height: var(--height);
  min-width: var(--height);
  border-radius: 22px;
  box-shadow: 0 0 0 1px #{colors.$tertiary-400};

  &.--compact {
    --height: 20px;
  }
}

.i-medium__image {
  margin-left: 3px;
  margin-right: -4px;
}

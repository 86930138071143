@use "settings/s-colors.scss" as colors;

@mixin tab-control-size-xs {
  --font-size: 12px;
  --line-height: 16px;
  --padding: 4px 8px;
}

@mixin tab-control-size-sm {
  --font-size: 14px;
  --line-height: 16px;
  --padding: 8px;
}

@mixin tab-control-size-md {
  --font-size: 16px;
  --line-height: 24px;
  --padding: 8px 16px;
}

.i-tab-control {
  min-width: 90px;
  border: 1px solid colors.$primary-300;
  transition: 0.2s ease;
  transition-property: background-color, border-color, color;
  padding: var(--padding);
  font-size: var(--font-size);
  line-height: var(--line-height);

  &:hover {
    background-color: colors.$primary-300;
  }

  &.--selected {
    background-color: colors.$primary-600;
    border-color: colors.$primary-600;
    color: colors.$white;
  }

  &.--size-xs {
    @include tab-control-size-xs;
  }

  &.--size-sm {
    @include tab-control-size-sm;

    @include media-breakpoint-down(md) {
      @include tab-control-size-xs;
    }
  }

  &.--size-md {
    @include tab-control-size-md;

    @include media-breakpoint-down(lg) {
      @include tab-control-size-sm;

      min-width: 48px;
    }
  }
}

@use "settings/s-colors" as colors;

.f-coupon-add {
  background: colors.$white;
  border-radius: 24px;
  border: 1px solid colors.$primary-500;
}

.f-coupon-add__inputs {
  max-width: 500px;
}

.f-coupon-add__input .f-text__input {
  @include media-breakpoint-up(sm) {
    padding-right: 48px;
  }
}

.f-coupon-add__apply {
  @include media-breakpoint-up(sm) {
    margin-left: -24px;
  }
}

.btn {
  &.--sm-base-text {
    --font-size: 16px;
    --padding-y: 10px;
    --padding-x: 16px;
    --min-height: 48px;
  }

  &.--sm {
    --font-size: 14px;
    --padding-y: 12px;
    --padding-x: 24px;
    --min-height: 48px;
  }

  &.--xs {
    --font-size: 12px;
    --padding-y: 12px;
    --padding-x: 24px;
    --min-height: 40px;
    --svg-size: 16px;
  }

  &.--xxs {
    --font-size: 14px;
    --padding-y: 2px;
    --padding-x: 8px;
    --min-height: 32px;
    --svg-size: 24px;
  }

  &.--xxxs {
    --font-size: 12px;
    --padding-y: 0px;
    --padding-x: 2px;
    --min-height: 24px;
    --svg-size: 24px;
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-general" as general;

.i-progress {
  --progress-color: #{colors.$gradient};

  &.--complete {
    .i-progress__icon path {
      fill: colors.$secondary;
    }
  }
}

.i-progress__header {
  @include media-breakpoint-down(lg) {
    width: min(100%, 256px);
    align-self: center;
  }
}

.i-progress__input {
  width: 100%;
  appearance: none;
  border: none;
  display: block;
  border-radius: 4px;
  height: 8px;
  overflow: hidden;
  background-color: colors.$primary-300;

  &::-webkit-progress-bar {
    background: transparent;
  }

  &::-webkit-progress-value {
    background-image: var(--progress-color);
  }

  &::-moz-progress-bar {
    background-image: var(--progress-color);
  }
}

.i-progress__icon-decoration {
  margin-right: -8px;
}

.u-fz-xxs {
  --size: 10px;
  --line-height: 16px;

  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-xs {
  --size: 12px;

  font-size: var(--size);
}

.u-fz-sm {
  --size: 14px;
  --line-height: 16px;

  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-md {
  --size: 16px;

  font-size: var(--size);
}

.u-fz-lg {
  --size: 18px;
  --line-height: 24px;

  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-xl {
  --size: 20px;

  font-size: var(--size);
}

.u-fz-xxl {
  --size: 22px;

  font-size: var(--size);
}

@use "settings/s-colors" as colors;

$colors: (
  label-1: colors.$label-1,
  label-2: colors.$label-2,
  label-3: colors.$label-3,
  label-4: colors.$label-4,
  label-5: colors.$label-5,
);

.i-tag {
  @each $name, $color in $colors {
    &.--color-#{$name} {
      --background-color: #{$color};

      color: colors.$tertiary;
    }
  }
}

a.i-tag,
.i-tag.--hoverable {
  &.--color-primary-100 {
    --background-color: #{colors.$primary-100};

    &:hover {
      --background-color: #{colors.$primary-300};

      border-color: colors.$primary-300;
    }
  }
}

@use "settings/s-colors" as colors;

.i-cart-button__button.btn {
  --background: #{colors.$primary};
}

.i-cart-button {
  @include media-breakpoint-down(lg) {
    position: static;
  }

  .container {
    @include media-breakpoint-up(lg) {
      padding: 16px;
    }
  }

  &:hover {
    .i-cart-button__button {
      .icon {
        --color: #{colors.$secondary-500};
      }
    }
  }

  &.--open {
    .i-cart-button__panel {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

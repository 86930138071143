.i-promo__button {
  inset: auto 16px 16px;

  &::after {
    position: absolute;
    display: block;
    bottom: -16px;
    left: -16px;
    width: 100vw;
    height: 100vh;
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-general" as general;
@use "settings/s-shadows" as shadows;

.f-search__close-button.btn {
  top: 5px;
  right: 5px;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
}

.f-search__dropdown {
  top: 100%;
  transition: 0.2s ease;

  @include media-breakpoint-up(lg) {
    max-height: min(722px, calc(100vh - 176px));
    box-shadow: shadows.$medium-shadow;
    border: 1px solid colors.$primary-600;
    border-top: none;
    border-radius: 0 0 24px 24px;
    transform: translateY(-4px);
  }

  @include media-breakpoint-down(lg) {
    padding-bottom: 24px;
  }

  .container {
    padding: 0;
  }
}

.f-search__search-field {
  z-index: 15;
}

.f-search__search-typing {
  display: var(--search-typing-display);
  left: 56px;
  top: 50%;
  transform: translateY(-50%);
}

.f-search__body {
  @include media-breakpoint-down(lg) {
    position: static;
  }
}

.f-search {
  --search-typing-display: block;

  grid-area: search;

  @include media-breakpoint-down(lg) {
    width: 100%;
    order: 10;
  }

  .f-text__wrap {
    border: 1px solid colors.$primary-600;
  }

  .f-base {
    box-shadow: none;
  }

  &.--disable-typing {
    --search-typing-display: none;
  }

  &:has(.i-search-dropdown).--open {
    .f-search__dropdown {
      transform: translateY(0);
      pointer-events: auto;
      display: flex;
      opacity: 1;

      @include media-breakpoint-down(lg) {
        width: 100%;
        top: 0;
        left: 0;
        height: calc(100dvh + 80px);
        padding-top: 162px;
        border-radius: 0;
        box-shadow: none;
        transition: opacity 0.3s ease;
        background: none;
      }
    }

    .f-search__close-button {
      @include media-breakpoint-down(lg) {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .f-text__wrap {
      background-color: colors.$white;
      box-shadow: none;

      @include media-breakpoint-up(lg) {
        border-radius: 24px 24px 0 0;
        border-bottom-color: transparent;
      }
    }
  }
}

@use "settings/s-colors.scss" as colors;

.i-support__perex {
  word-wrap: break-word;
}

.i-support__img {
  --max-height: 64px;

  width: var(--max-height);
  height: var(--max-height);

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 21px;
    height: 10px;
    background-image: url("../images/svg/decor-after.svg");
    background-repeat: no-repeat;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    width: var(--max-height);
    height: var(--max-height);
    border-radius: 9999px;
    border: 2px solid colors.$primary-600;
    z-index: 1;
  }

  &.--placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colors.$primary-100;
    box-shadow: 0 2px 16px 0 rgb(213 212 218 / 30%);

    &::before {
      display: none;
    }
  }

  &.--sm {
    --max-height: 48px;
  }
}

.i-support__text {
  gap: 2px;
}

.i-support {
  &.--sm {
    &::before {
      content: "";
      position: absolute;
      bottom: -16px;
      left: 0;
      transform: translateY(100%);
      width: 100%;
      height: 1px;
      background-color: colors.$primary-300;
    }

    .i-support__title {
      font-size: 16px;
      line-height: 24px;
    }

    .i-support__perex {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-general" as general;
@use "settings/s-fonts" as fonts;
@import "./src/components/items/discount-item/discount-item";
@import "./src/components/items/price-item/price-item";

.i-product-inline__link.link {
  --font-size: 16px;
  --line-height: 24px;
  --lines: 1;

  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: #{fonts.$primary};
  text-decoration: none;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 2;
  }
}

.i-product-inline__order {
  width: 32px;
  height: 32px;
  border: 1px solid colors.$primary-100;
  background-color: colors.$white;
  border-radius: 50%;
}

.i-product-inline__image {
  grid-area: image;
}

.i-product-inline__header {
  grid-area: header;
  margin-bottom: 1px;
}

.i-product-inline__mediums {
  pointer-events: none;
  grid-area: mediums;

  > .i-medium {
    pointer-events: auto;
  }

  .g-mediums__show-more {
    pointer-events: auto;
  }

  &:hover {
    .g-mediums {
      pointer-events: auto;
    }
  }
}

.i-product-inline__availability {
  grid-area: availability;
}

.i-product-inline__discount {
  grid-area: discount;
}

.i-product-inline__price {
  grid-area: price;
}

.i-product-inline__cart-add {
  z-index: 10;
  grid-area: button;
  justify-self: flex-end;
}

.i-product-inline__content {
  grid-template-columns: 56px 1fr 64px minmax(96px, auto);
  grid-template-areas:
    "image header discount price"
    "image mediums discount price";
}

.i-product-inline {
  border: 1px solid colors.$primary-100;
  transition: background-color 0.2s ease;
  padding-block: 10.5px;

  &:has(.i-product-inline__link:hover) {
    background: colors.$white;

    .i-product__image-link {
      img {
        transform: scale(1.03);
      }
    }
  }

  &.--top {
    padding-left: 24px;

    .i-product-inline__content {
      grid-template-columns: 56px 1fr 1fr 64px minmax(96px, auto) auto;
      grid-template-areas:
        "image header  header       discount price button"
        "image mediums availability discount price button";
    }
  }

  @container (max-width: 480px) {
    .i-product-inline__content {
      grid-template-columns: 56px 1fr auto 32px;
      row-gap: 8px;
      grid-template-areas:
        "image header header discount"
        "mediums mediums price price";
    }

    .i-product-inline__order {
      top: 28px;
    }

    .i-product-inline__link.link {
      --lines: 3;
      --font-size: 14px;
      --line-height: 16px;
    }

    .i-product-inline__price.i-price {
      @include price-item-size-sm;
    }

    .i-product-inline__discount.i-discount {
      margin: 0;

      @include discount-item-size-sm;
    }

    &.--top {
      .i-product-inline__content {
        grid-template-columns: 56px 1fr auto 32px;
        grid-template-areas:
          "image header header discount"
          "mediums mediums price price"
          "availability availability button button";
      }
    }
  }
}

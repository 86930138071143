@use "settings/s-colors" as colors;

.f-editorial-calendar-filter__inputs-wrapper {
  @include media-breakpoint-between(md, xl) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(xxl) {
    gap: 24px;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    gap: 16px;
  }
}

.f-editorial-calendar-filter {
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  column-gap: 80px;

  @include media-breakpoint-down(xl) {
    grid-template-columns: 1fr;
  }

  @include media-breakpoint-down(md) {
    gap: 16px;
    padding: 16px;
  }
}

.f-editorial-calendar-filter__filter-visual {
  border: 1px solid colors.$primary-100;
  grid-column: 1/-1;

  @include media-breakpoint-down(lg) {
    padding: 16px;
  }
}

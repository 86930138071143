@use "settings/s-colors" as colors;

.c-svg {
  opacity: 0;
  transition: opacity 0.3s ease;

  &.entered {
    opacity: 1;
  }

  &.--colored {
    path {
      fill: var(--color);
      transition: fill 0.3s ease;
    }
  }

  &.--primary {
    --color: #{colors.$primary};
  }

  &.--white {
    --color: #{colors.$white};
  }

  &.--text {
    --color: #{colors.$black};
  }
}

@use "settings/s-colors" as colors;

.i-top-category {
  min-height: 264px;
  padding: 60px 8px 8px;

  @media (hover: hover) {
    &:hover {
      img {
        transform: scale(1.03);
      }
    }
  }
}

.i-top-category-background {
  border-radius: 16px;
  background-color: rgba(colors.$tertiary, 0.8);
  mix-blend-mode: multiply;
}

.i-top-category__body {
  > .base-heading {
    font-size: 28px;

    &::before {
      content: none;
    }
  }
}

@use "settings/s-general" as general;
@use "settings/s-colors" as colors;

$padding-x: 16px;
$padding-y: 12px;

$background: colors.$white;
$color-placeholder: colors.$primary-500;

$text-area-min-height: 176px;

.f-base {
  --inset-shadow-color: #{colors.$tertiary-400};

  display: block;
  width: 100%;
  background-color: $background;
  background-clip: padding-box;
  padding: $padding-y $padding-x;
  padding-left: 24px;
  appearance: none;
  border: none;
  min-height: 48px;
  font-weight: 500;
  box-shadow: inset 0 0 0 1px var(--inset-shadow-color);
  transition: box-shadow 0.3s ease;

  &:focus {
    border-color: none;
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: $color-placeholder;
  }

  @include media-breakpoint-down(sm) {
    min-height: 40px;
    padding-block: 10px;
  }
}

textarea.f-base {
  min-height: $text-area-min-height;
  padding-top: 16px;
  padding-bottom: 12px;
  resize: none;
}

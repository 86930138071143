@use "settings/s-colors" as colors;

.f-newsletter {
  background: colors.$gradient;
  border-radius: 24px;
  border: 1px solid colors.$primary-500;
  padding-left: 240px;

  .f-text {
    --border-color: #{colors.$tertiary-300};

    &:hover {
      --shadow: none;
    }

    .f-text__input {
      --p-offset: 176px;

      padding-right: var(--p-offset);

      @include media-breakpoint-down(xxl) {
        --p-offset: 16px;
      }

      @include media-breakpoint-only(md) {
        --p-offset: 176px;
      }

      @include media-breakpoint-down(sm) {
        min-height: 48px;
        padding-block: 12px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 24px;
  }
}

.f-newsletter__grid {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  @include media-breakpoint-down(lg) {
    gap: 24px;
  }
}

.f-newsletter__header {
  gap: 16px;

  @include media-breakpoint-down(sm) {
    &.--left {
      text-align: center;
    }
  }
}

.f-newsletter__inputs-col {
  @include media-breakpoint-down(xxl) {
    gap: 16px;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.f-newsletter__inputs {
  @include media-breakpoint-down(xxl) {
    flex-direction: column;
  }

  @include media-breakpoint-only(md) {
    flex-direction: row;
  }
}

.f-newsletter__submit {
  --m-offset: calc(-1 * 176px);

  margin-left: var(--m-offset);

  .btn {
    --font-size: 16px;

    gap: 4px;
    min-width: 160px;
    line-height: 24px;
  }

  @include media-breakpoint-down(xxl) {
    --m-offset: 0;

    position: static;
  }

  @include media-breakpoint-only(md) {
    --m-offset: calc(-1 * 176px);

    position: relative;
  }
}

.f-newsletter__terms {
  .f-checkbox__title {
    color: colors.$primary-300;
    font-weight: 400;

    a {
      color: colors.$primary-300;
    }
  }
}

.f-newsletter__img {
  max-width: 269px;
  height: auto;
  left: 0;
  top: 50%;
  transform: translate(-25%, -50%);

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

@use "settings/s-colors" as colors;

.f-error {
  --display: none;

  width: auto;
  top: calc(100% - 8px);
  left: 16px;
  color: colors.$danger;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0 4px 30px 2px rgb(49 41 96 / 30%);
  display: var(--display);
  z-index: 3;
  gap: 4px;

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 10px;
    transform: translateY(-100%);
    width: 8px;
    height: 7px;
    background-color: colors.$white;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    pointer-events: none;
    z-index: 3;
  }

  &.--show {
    --display: flex;
  }
}

.f-error__title {
  line-height: 16px;
}

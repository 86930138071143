@use "settings/s-colors" as colors;

@mixin availability-item-size-xs {
  --dot-size: 6px;
  --title-gap: 4px;
  --font-size: 12px;
  --font-size-title: 12px;
  --description-border: 0 none;
  --description-background-color: #{colors.$primary-100};
  --line-height: 16px;
  --shadow-blur: 2px;
  --shadow-spread: 1px;
  --p-description: 2px 6px;
}

@mixin availability-item-size-sm {
  --dot-size: 8px;
  --title-gap: 4px;
  --font-size: 14px;
  --font-size-title: 14px;
  --description-border: 0 none;
  --description-background-color: #{colors.$primary-100};
  --line-height: 16px;
  --shadow-blur: 2px;
  --shadow-spread: 1px;
  --p-description: 2px 8px;
}

@mixin availability-item-size-lg {
  --dot-size: 16px;
  --title-gap: 8px;
  --font-size: 16px;
  --font-size-title: 18px;
  --description-border: 2px solid var(--background-color);
  --description-background-color: #{colors.$white};
  --line-height: 16px;
  --shadow-blur: 8px;
  --shadow-spread: 2px;
  --p-description: 6px 12px 6px 10px;

  .i-availability__title {
    font-weight: 700;
    margin-left: 4px;
    color: var(--background-color);
  }
}

$colors: (
  available: colors.$availability-status-4,
  unavailable: colors.$availability-status-1,
  preorder: colors.$availability-status-3,
  ended: colors.$tertiary,
  enroute: colors.$availability-status-2,
);

.i-availability__description {
  padding: var(--p-description);
  border: var(--description-border);
  background-color: var(--description-background-color);
  color: colors.$primary;
}

.i-availability__title {
  column-gap: var(--title-gap);
  font-size: var(--font-size-title);

  &::before {
    content: "";
    flex-shrink: 0;
    display: inline-block;
    width: var(--dot-size);
    height: var(--dot-size);
    margin: 2px;
    border-radius: 50%;
    background-color: var(--background-color);
    box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
  }
}

.i-availability {
  @each $name, $color in $colors {
    &.--#{$name} {
      --background-color: #{$color};
      --shadow-color: #{rgba($color, 0.5)};
      --border-color: #{$color};
    }
  }

  &.--custom-color {
    --background-color: var(--custom-color);
    --shadow-color: #{rgba(var(--custom-color), 0.5)};
    --border-color: var(--custom-color);
  }

  &.--size-xs {
    @include availability-item-size-xs;

    .icon {
      display: none;
    }

    .i-availability__description {
      font-size: var(--font-size-title);
    }
  }

  &.--size-sm {
    @include availability-item-size-sm;

    .icon {
      display: none;
    }
  }

  &.--size-lg {
    @include availability-item-size-lg;
  }

  &.--mini {
    .i-availability__quantity,
    .i-availability__description {
      display: none;
    }
  }
}

@use "settings/s-colors" as colors;

.i-document {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  color: colors.$primary-100;
}

.i-document__title {
  color: colors.$primary;
}

.i-document__details {
  color: colors.$primary;
}

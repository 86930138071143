@use "settings/s-colors" as colors;

.m-header-bottom {
  position: relative;

  @include media-breakpoint-up(lg) {
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: 4px 8px 24px var(--shadow-color);
    transform: translateY(0);
    transition: transform 0.2s ease;
    z-index: -1;
  }

  @include media-breakpoint-down(lg) {
    background: transparent;
    pointer-events: none;
    height: 0;
    max-height: 100%;
    overflow: auto;
    opacity: 0;
    overscroll-behavior: none;
  }
}

.m-header-bottom__contacts {
  position: fixed;
  bottom: 0;
  border-radius: 24px 24px 0 0;
  transition: transform 0.2s ease;
  transform: translateY(100%);
  z-index: 15;
  pointer-events: auto;
}

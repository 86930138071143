@use "settings/s-colors.scss" as colors;

$transition: cubic-bezier(0.68, -0.55, 0.27, 1.55);

.c-scroll-up {
  --size: 48px;
  --offset-right: 40px;
  --offset-bottom: 40px;
  --radius: 8px;
  --bg-white: 255 255 255;
  --background: rgb(var(--bg-white) / 50%);
  --background-hover: rgb(var(--bg-white) / 100%);
  --icon-size: 24px;
  --icon-color: #{colors.$primary-600};
  --shadow: 0px 2px 16px 0px rgb(213 212 218 / 30%);

  width: var(--size);
  height: var(--size);
  bottom: var(--offset-bottom);
  right: var(--offset-right);
  border-radius: var(--radius);
  border: 1px solid #{colors.$primary-600};
  background-color: var(--background);
  box-shadow: var(--shadow);
  z-index: 50;
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease,
    opacity 0.3s $transition,
    transform 0.3s $transition;
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(0.8);
  cursor: pointer;

  @include media-breakpoint-down(md) {
    --offset-right: 26px;
    --offset-bottom: 96px;
  }

  &:hover {
    --background: var(--background-hover);
    --shadow: 0px 4px 16px 1px rgb(98 72 170 / 20%);
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-radius: 8px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-property: opacity, box-shadow;
    box-shadow: 0 0 10px 40px colors.$white;
  }

  &:active::after {
    box-shadow: 0 0 0 0 colors.$white;
    position: absolute;
    opacity: 1;
    transition: 0s;
  }

  svg {
    width: var(--icon-size);
    height: var(--icon-size);
  }

  &.--active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0) scale(1);
  }
}

body {
  &:has(#supportBox-iframe) {
    .c-scroll-up {
      --offset-bottom: 24px;
      --offset-right: 92px;
    }
  }
}

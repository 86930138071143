@use "settings/s-colors" as colors;

.s-content {
  --padding-y: 48px;

  margin-block: var(--padding-y);
  border-top: 1px solid colors.$primary-300;

  .base-header {
    --spacing: 24px;

    gap: 16px;
  }

  .base-header__description {
    font-weight: 400;
  }

  .entry-content {
    h6 {
      margin-top: 0;
    }
  }

  &:has(+ .product-reviews) {
    border-bottom: 1px solid colors.$primary-300;
  }
}

@use "settings/s-colors.scss" as colors;

$directions: (top, right, bottom, left);

@each $direction in $directions {
  .u-border-#{$direction} {
    border-#{$direction}: 1px solid colors.$primary;
  }
}

.u-border-gray {
  border-color: colors.$primary-300;
}

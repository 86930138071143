@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-contact-card {
  background-color: colors.$white;
  border-radius: 24px;
  box-shadow: shadows.$small-shadow;
  padding: 40px;
}

.i-contact-card__decoration-position {
  .d-small-circles {
    left: unset;
    right: 0;
  }
}

.i-contact-card__content {
  color: colors.$tertiary;
  line-height: 30px;
}

.i-contact-card__image {
  border-radius: 16px;
}

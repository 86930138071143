@use "settings/s-colors" as colors;

.i-cart-step {
  --decorator-size: 40px;
  --flow-marker-size: 1px;

  flex: 0 1 100%;
  align-items: center;
  position: relative;
  color: colors.$primary;
  text-align: center;
  font-size: 18px;

  &::before {
    content: attr(data-step);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: var(--decorator-size);
    min-height: var(--decorator-size);
    background-color: colors.$primary;
    color: colors.$white;
    border-radius: 20px;
    box-shadow: 0 2 16px rgba(colors.$tertiary-300, 0.3);
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(var(--decorator-size) / 2 - var(--flow-marker-size) / 2);
    left: 0;
    right: 0;
    height: var(--flow-marker-size);
    z-index: -1;
    background-color: colors.$primary-300;
  }

  &.--disabled {
    color: colors.$primary-500;

    &::before {
      background-color: colors.$white;
      color: colors.$primary-500;
    }
  }

  &.--active {
    &::before {
      background-color: colors.$primary-300;
      color: colors.$primary-600;
    }
  }

  &.--completed {
    &::before {
      background-image: url("../images/svg/check.svg");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: center;
      text-indent: -9999px;
    }
  }

  &:first-child {
    &::after {
      left: 50%;
    }
  }

  &:last-child {
    &::after {
      right: 50%;
    }
  }

  @include media-breakpoint-between(sm, lg) {
    --decorator-size: 32px;

    font-size: 16px;

    &.--completed {
      &::before {
        background-size: 12px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    --decorator-size: 40px;

    font-size: 14px;
  }
}

a .i-cart-step__title {
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease; // corresponding with e-link.scss
}

a.i-cart-step {
  text-decoration: none;

  &:hover {
    .i-cart-step__title {
      text-decoration: underline;
    }
  }
}

@use "settings/s-colors" as colors;

.i-method {
  --min-height: 32px;
  --min-width: 48px;
  --padding-y: 2px;
  --padding-x: 4px;

  border: 1px solid colors.$tertiary-300;
  border-radius: 4px;
  background-color: colors.$white;
  height: var(--min-height);
  width: var(--min-width);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.f-filtration__wrapper {
  position: sticky;
  top: 24px;
  transition: top 0.3s ease;

  @include media-breakpoint-down(lg) {
    position: relative;
  }
}

.f-filtration__header {
  padding: 8px 12px;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: colors.$primary-300;
    left: 0;
    bottom: -16px;
    transform: translateY(-100%);
  }
}

.f-filtration__header-heading {
  margin: 0;

  .base-heading {
    @include media-breakpoint-only(xl) {
      --size: 20px;
    }
  }
}

.f-filtration__close-filter.btn {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.f-filtration__content {
  @include media-breakpoint-down(lg) {
    height: 100%;
    overflow: auto;
  }
}

.f-filtration__reset-btn {
  display: none;
}

.f-filtration {
  padding: 16px;
  max-height: 100%;
  height: auto;
  display: block;
  border: 1px solid colors.$primary-300;
  border-radius: 24px;

  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    background: colors.$white;
    width: min(320px, 100%);
    height: 100%;
    border-radius: 24px 0 0 24px;
    padding-right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }

  &.--open {
    transform: translateX(0);
    box-shadow: shadows.$medium-shadow;
  }

  &:has(.i-selected-tag) .f-filtration__reset-btn {
    display: inline-flex;
  }
}

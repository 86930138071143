.s-socials__wrap {
  gap: 64px;
  max-width: 922px;
  width: 100%;
  margin-inline: auto;

  .base-header {
    margin-inline: unset;
  }

  @include media-breakpoint-down(xl) {
    gap: 32px;
  }
}

.s-products-list {
  .base-header {
    --spacing: 0;
  }

  .base-heading {
    &::before {
      display: none;
    }
  }

  .g-buttons {
    .btn {
      min-width: 160px;
    }

    @include media-breakpoint-up(md) {
      --spacing: 40px;
    }
  }
}

.s-products-list__header {
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: flex-start;

    .f-single-select-inline {
      margin-left: auto;
    }
  }
}

.s-products-list__load-more {
  &.--hidden {
    display: none;
  }
}

@use "settings/s-colors" as colors;

.i-notification {
  min-height: 40px;
  background-color: colors.$secondary-500;
  color: colors.$white;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  @include media-breakpoint-down(lg) {
    &:has(~ .m-header.--menu-open, ~ .m-header.--user-open, ~ .m-header.--cart-open) {
      display: none;
    }
  }
}

.i-notification__container {
  width: min(100% - 32px, 832px);

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    gap: 8px;
  }
}

.i-notification__icon {
  @include media-breakpoint-down(sm) {
    margin-inline: auto;
  }
}

.i-notification__close {
  @include media-breakpoint-down(sm) {
    order: -1;
  }
}

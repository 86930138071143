@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;

  width: max-content;
  min-width: 52px;

  &:hover {
    --dropdown-opacity: 1;
    --dropdown-pointer-events: auto;
  }
}

.c-language-select__dropdown {
  background-color: colors.$white;
  transition: opacity 0.3s ease;
  top: 100%;
  left: 0;
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  box-shadow: shadows.$shadow;
  z-index: 500;
}

.c-language-select__active {
  min-height: 24px;
  gap: 4px;
}

.c-language-select__dropdown-link {
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: colors.$primary-100;
  }
}

.c-language-select__flag {
  border-radius: 2px;
  margin-right: 4px;

  &.--active {
    background-color: #{colors.$white};
    border: 1px solid #{colors.$white};
  }

  &.--select {
    background-color: #{colors.$primary};
    border: 1px solid #{colors.$primary};
  }
}

@use "settings/s-colors" as colors;

.base-heading {
  --decoration-width: 7px;
  --decoration-height: 16px;

  @include media-breakpoint-down(sm) {
    --decoration-width: 5px;
    --decoration-height: 12px;
  }

  &.--decoration {
    &::before {
      content: "";
      display: inline-block;
      width: var(--decoration-width);
      height: var(--decoration-height);
      border-radius: 0 var(--decoration-height) var(--decoration-height) 0;
      background-color: colors.$secondary-500;
      vertical-align: middle;
    }
  }

  &.--decoration-after {
    display: flex;
    gap: 8px;
  }

  &.--decoration-line {
    .base-heading__decoration-after {
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: colors.$primary-300;
      }
    }
  }

  &.u-h3 {
    --decoration-width: 5px;
    --decoration-height: 12px;

    @include media-breakpoint-down(sm) {
      --decoration-width: 4px;
      --decoration-height: 9px;
    }
  }
}

@use "settings/s-colors" as colors;

.i-interpret-category__img {
  width: 56px;

  .img {
    border-radius: 50%;
    z-index: 1;
  }
}

.i-interpret-category {
  --background-color: transparent;
  --color: #{colors.$tertiary};
  --transform: scale(1);

  outline: none;
  border: none;
  background-color: var(--background-color);
  color: var(--color);
  padding-block: 0;
  min-height: 56px;
  transition: color 0.3s ease;
  transition-property: color, background-color;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border: 1px solid colors.$primary-300;
    border-radius: 40px;
    z-index: 2;
  }

  @media (hover: hover) {
    &:hover {
      --background-color: #{colors.$white};
      --color: #{colors.$primary-600};
      --transform: scale(1.3);

      color: var(--color);
    }
  }

  &:has(.i-interpret-category__img) {
    padding-left: 0;
  }

  &.--checkbox {
    &::before {
      transition: border 0.3s ease;
    }
  }

  &:has(.i-interpret-category__input:checked),
  &.--active {
    --background-color: transparent;
    --color: #{colors.$primary-600};

    &::before {
      border: 1px solid colors.$primary-600;
    }
  }

  &.--link {
    text-decoration: none;

    &:focus {
      color: var(--color);
    }
  }

  &.--lg-hidden {
    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}

.i-interpret-category__icon {
  width: 40px;
  height: 40px;

  .icon,
  .img {
    transform: var(--transform);
    transition: transform 0.3s ease;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-image: url("../images/svg/spot.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

input[type="number"] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
}

fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}

progress {
  vertical-align: baseline;
}

* {
  min-width: 0;
}

/**
 * Clickable labels
 */

label[for] {
  cursor: pointer;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

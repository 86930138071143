@use "settings/s-colors" as colors;

.g-review {
  row-gap: 16px;
  padding: 40px;
  border-radius: 24px;
  border: 1px solid colors.$primary-300;

  @include media-breakpoint-up(lg) {
    row-gap: 24px;
  }

  @include media-breakpoint-up(xl) {
    row-gap: 40px;
  }

  .g-review__cell {
    @include media-breakpoint-up(xxl) {
      padding: 8px 16px;
    }
  }
}

.g-review__cell-separator {
  column-gap: 16px;
}

.g-review__cell-separator-line {
  height: 1px;
  width: 100%;
  background-color: colors.$primary-300;
  margin: 0;
}

@use "settings/s-colors" as colors;

.i-signpost {
  .img {
    border-radius: 24px;
  }

  .i-signpost__wrapper {
    width: calc(100% - 32px);
    display: flex;
    align-self: center;
    min-height: 171px;
    bottom: 16px;
  }

  .i-signpost__body-background {
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 16px;
    background-color: rgba(colors.$tertiary, 0.91);
    mix-blend-mode: multiply;
    z-index: 1;
  }

  .i-signpost__body {
    width: 100%;
    z-index: 4;
  }

  .base-heading {
    min-height: 71px;
  }

  @media (hover: hover) {
    &:hover {
      img {
        transform: scale(1.03);
      }
    }
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-similar-interprets__title {
  overflow-wrap: break-word;
  word-break: break-all;
  padding-block: 4px;
  transition: color 0.3s ease;
}

.i-similar-interprets {
  --min-height: 56px;
  --radius: 40px;

  user-select: none;
  text-decoration: none;
  border: 1px solid colors.$primary-300;
  border-radius: var(--radius);
  min-height: var(--min-height);
  min-width: var(--min-height);
  padding-right: 12px;
  transition: box-shadow 0.3s ease;

  @media (hover: hover) {
    &:hover {
      box-shadow: shadows.$button-primary-hover-shadow;

      .i-similar-interprets__title {
        color: colors.$primary-600;
      }
    }
  }
}

.i-similar-interprets__img {
  width: var(--min-height);
  height: var(--min-height);
}

.btn-copy-to-clipboard__tooltip {
  top: 100%;
  right: 0;
  width: 100%;
  width: max-content;
  opacity: var(--tootlip-opacity);
  transition: opacity 0.3s ease;
}

.btn-copy-to-clipboard {
  --tootlip-opacity: 0;

  &.--copied {
    --tootlip-opacity: 1;
  }
}

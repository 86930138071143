@use "settings/s-colors.scss" as colors;

.u-bg-primary {
  background-color: colors.$primary;
}

.u-bg-primary-100 {
  background-color: colors.$primary-100;
}

.u-bg-primary-300 {
  background-color: colors.$primary-300;
}

.u-bg-primary-600-o-80 {
  background-color: colors.$primary-600-o-80;
}

.u-bg-gray {
  background-color: colors.$gray;
}

.u-bg-white {
  background-color: colors.$white;
}

.u-bg-background {
  background-color: colors.$background;
}

.u-bg-secondary-500 {
  background-color: colors.$secondary-500;
}

.u-bg-gradient {
  background: colors.$gradient;
}

@use "settings/s-colors.scss" as colors;

.product-navbar {
  background-color: colors.$background;
  z-index: 11; // over i-product:hover

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: colors.$primary-300;
  }

  @include media-breakpoint-down(lg) {
    position: relative;
  }

  @include media-breakpoint-up(lg) {
    top: var(--top);
    transition: transform 0.2s ease;
  }
}

.product-navbar__item {
  --underline-opacity: 0;
  --color-hover: #{colors.$secondary-500};
  --min-height: 72px;

  min-height: var(--min-height);

  @media (hover: hover) {
    &:hover {
      --underline-opacity: 1;

      .product-navbar__link {
        color: var(--color-hover);
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 4px;
    background-color: var(--color-hover);
    opacity: var(--underline-opacity);
    z-index: 1;
    transition: opacity 0.3s ease;
  }

  &.--active {
    --underline-opacity: 1;

    .product-navbar__link {
      color: var(--color-hover);
    }
  }

  @include media-breakpoint-down(md) {
    --min-height: 56px;
  }
}

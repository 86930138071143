.c-order-search__btn {
  min-width: 160px;

  @include media-breakpoint-up(sm) {
    margin-left: -24px;
  }
}

.c-order-search__input {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: min(450px, 100%);
  }
}

@use "settings/s-colors" as colors;

.i-breadcrumb__divider {
  min-width: 24px;
  align-items: center;

  svg {
    width: 6px;
    height: 9px;
  }
}

.i-breadcrumb {
  color: colors.$primary-500;

  &.--homepage {
    .i-breadcrumb__link {
      gap: 0;
    }

    .i-breadcrumb__divider {
      display: none;
    }
  }
}

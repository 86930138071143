.i-variant-row__title {
  width: var(--title-width);
}

.i-variant-row__group-wrapper {
  min-height: 48px;
}

.i-variant-row {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  @container (max-width: 488px) {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }

  &.--lg {
    --title-width: 128px;
  }

  &.--sm {
    --title-width: 80px;
  }
}

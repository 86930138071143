@import "./src/components/items/discount-item/discount-item";
@import "./src/components/items/tag-item/tag-item--sizes";

.i-product {
  &.--offer {
    border: none;

    .i-product__body {
      display: grid;
      grid-template-columns: 144px 1fr;
      grid-template-areas:
        "image header"
        "content content";
      padding: 8px;
      gap: 8px;
      height: auto;
    }

    .i-product__content {
      align-items: center;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "mediums price"
        "availability price";
      gap: 8px;
    }

    .i-product__discount.i-discount {
      top: auto;
      bottom: 0;

      @include discount-item-size-sm;
    }

    .i-product__buttons {
      display: none;
    }

    .i-product__header {
      align-self: center;
    }

    .i-product__title {
      .link {
        --lines: 6;
        --font-size: 14px;
        --line-height: 16px;
      }
    }

    .i-product__tags {
      width: calc(100% - 24px);
    }

    .i-product__tag.i-tag {
      @include tag-item-size-xs;
    }

    .i-product__price {
      align-self: end;

      @include price-item-size-sm;
    }
  }
}

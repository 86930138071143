@use "settings/s-colors" as colors;

.base-section {
  --decor-max-height: 560px;
  --decor-radius: 40px;

  .base-section__banner {
    max-width: 1686px;
    width: 100%;
    height: 100%;
    max-height: var(--decor-max-height);
    background: linear-gradient(79.93deg, colors.$primary 0%, colors.$primary-600 98.96%);
    border-radius: var(--decor-radius);
    pointer-events: none;
    z-index: -2;
  }

  .base-section__banner-inner {
    .base-section__banner-equalizer svg,
    &::after {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      max-width: 1686px;
      width: 100%;
      max-height: var(--decor-max-height);
      mix-blend-mode: multiply;
      border-radius: var(--decor-radius);
      pointer-events: none;
      z-index: -1;
    }

    &::after {
      content: "";
      top: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center top;
    }

    .base-section__banner-equalizer svg {
      bottom: 0;
    }
  }

  .base-section__banner-img {
    width: 461px;
    height: 461px;
    right: -160px;
    bottom: -160px;
  }

  &.--big,
  &.--big-reverse,
  &.--small,
  &.--small-reverse {
    .base-header {
      .base-heading {
        color: colors.$white;
      }

      .base-header__description {
        color: colors.$primary-100;
      }
    }
  }

  &.--big,
  &.--big-reverse {
    min-height: var(--decor-max-height);

    .base-section__banner-inner::after {
      background-image: url("../images/svg/banner_front_big.svg");
    }
  }

  &.--small,
  &.--small-reverse {
    --decor-max-height: 360px;

    min-height: var(--decor-max-height);

    .base-section__banner-inner::after {
      background-image: url("../images/svg/banner_front_small.svg");
    }
  }

  &.--big-reverse,
  &.--small-reverse {
    .base-section__banner-img {
      left: -160px;
      right: auto;
    }
  }

  &.--equalizer {
    .base-section__banner-inner::after {
      display: none;
    }
  }

  @include media-breakpoint-down(xl) {
    .base-section__banner-img {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    --decor-radius: 24px;

    .base-section__banner,
    .base-section__banner-inner::after {
      border-radius: var(--decor-radius) var(--decor-radius) 0 0;
    }
  }
}

*, :before, :after {
  box-sizing: border-box;
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: .5rem 0;
}

h6, h5, h4, h3, h2, h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 75em) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 75em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 75em) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 75em) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: .875em;
}

mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 75em) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (min-width: 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (min-width: 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  .container {
    max-width: 71.25rem;
  }
}

@media (min-width: 93.75em) {
  .container {
    max-width: 91.25rem;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 36rem;
  --bs-breakpoint-md: 48rem;
  --bs-breakpoint-lg: 62rem;
  --bs-breakpoint-xl: 75rem;
  --bs-breakpoint-xxl: 93.75rem;
  --bs-breakpoint-xxxl: 120rem;
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.gy-1 {
  --bs-gutter-y: .5rem;
}

.gy-2 {
  --bs-gutter-y: 1rem;
}

.gy-4 {
  --bs-gutter-y: 2rem;
}

@media (min-width: 36em) {
  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .gy-sm-4 {
    --bs-gutter-y: 2rem;
  }
}

@media (min-width: 48em) {
  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }
}

@media (min-width: 62em) {
  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }
}

@media (min-width: 75em) {
  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }
}

@media (min-width: 93.75em) {
  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.opacity-0 {
  opacity: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm);
}

.position-relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.start-0 {
  left: 0;
}

.end-0 {
  right: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

.mx-1 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mt-1 {
  margin-top: .5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.me-1 {
  margin-right: .5rem;
}

.me-3 {
  margin-right: 1.5rem;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-7 {
  margin-bottom: 3.5rem;
}

.mb-10 {
  margin-bottom: 5rem;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: .5rem;
}

.ms-3 {
  margin-left: 1.5rem;
}

.ms-auto {
  margin-left: auto;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.p-6 {
  padding: 3rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 2rem;
}

.pe-2 {
  padding-right: 1rem;
}

.pe-6 {
  padding-right: 3rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.ps-1 {
  padding-left: .5rem;
}

.ps-2 {
  padding-left: 1rem;
}

.ps-3 {
  padding-left: 1.5rem;
}

.gap-0 {
  gap: 0;
}

.gap-05 {
  gap: .25rem;
}

.gap-1 {
  gap: .5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.gap-6 {
  gap: 3rem;
}

.gap-7 {
  gap: 3.5rem;
}

.row-gap-1 {
  row-gap: .5rem;
}

.row-gap-2 {
  row-gap: 1rem;
}

.row-gap-3 {
  row-gap: 1.5rem;
}

.row-gap-4 {
  row-gap: 2rem;
}

.row-gap-6 {
  row-gap: 3rem;
}

.column-gap-1 {
  column-gap: .5rem;
}

.column-gap-2 {
  column-gap: 1rem;
}

.column-gap-3 {
  column-gap: 1.5rem;
}

.column-gap-4 {
  column-gap: 2rem;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-nowrap {
  white-space: nowrap;
}

.user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--bs-border-radius);
}

.visible {
  visibility: visible;
}

@media (min-width: 36em) {
  .flex-sm-row {
    flex-direction: row;
  }

  .justify-content-sm-start {
    justify-content: flex-start;
  }

  .ms-sm-auto {
    margin-left: auto;
  }

  .px-sm-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-sm-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .gap-sm-3 {
    gap: 1.5rem;
  }

  .gap-sm-5 {
    gap: 2.5rem;
  }
}

@media (min-width: 48em) {
  .flex-md-row {
    flex-direction: row;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }

  .p-md-3 {
    padding: 1.5rem;
  }

  .px-md-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .px-md-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .gap-md-4 {
    gap: 2rem;
  }

  .gap-md-8 {
    gap: 4rem;
  }

  .row-gap-md-4 {
    row-gap: 2rem;
  }
}

@media (min-width: 62em) {
  .d-lg-flex {
    display: flex;
  }

  .d-lg-none {
    display: none;
  }

  .flex-lg-row {
    flex-direction: row;
  }

  .flex-lg-column {
    flex-direction: column;
  }

  .mt-lg-0 {
    margin-top: 0;
  }

  .ms-lg-0 {
    margin-left: 0;
  }

  .ms-lg-auto {
    margin-left: auto;
  }

  .p-lg-1 {
    padding: .5rem;
  }

  .p-lg-2 {
    padding: 1rem;
  }

  .p-lg-3 {
    padding: 1.5rem;
  }

  .p-lg-4 {
    padding: 2rem;
  }

  .p-lg-5 {
    padding: 2.5rem;
  }

  .px-lg-2 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .px-lg-3 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .py-lg-3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .gap-lg-2 {
    gap: 1rem;
  }

  .gap-lg-3 {
    gap: 1.5rem;
  }

  .row-gap-lg-0 {
    row-gap: 0;
  }

  .row-gap-lg-2 {
    row-gap: 1rem;
  }

  .column-gap-lg-4 {
    column-gap: 2rem;
  }
}

@media (min-width: 75em) {
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }

  .justify-content-xl-end {
    justify-content: flex-end;
  }

  .mb-xl-0 {
    margin-bottom: 0;
  }

  .px-xl-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .px-xl-4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .py-xl-2 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .gap-xl-3 {
    gap: 1.5rem;
  }

  .gap-xl-5 {
    gap: 2.5rem;
  }
}

@media (min-width: 93.75em) {
  .flex-xxl-row {
    flex-direction: row;
  }

  .justify-content-xxl-start {
    justify-content: flex-start;
  }

  .gap-xxl-7 {
    gap: 3.5rem;
  }
}

.container {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

@media (max-width: 35.999em) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 359px) {
  .container {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.gap-0\.5 {
  gap: .25rem;
}

.fancybox__container {
  --fancybox-accent-color: #6248aa;
}

.fancybox__container .fancybox__thumb {
  background-size: contain;
}

.flatpickr-calendar {
  --theme-color: #6248aa;
  --theme-color-hover: #f1ecf6;
  --border-color: #d7cef0;
  --font-color: #484752;
  --font-color-out: #b1afbd;
}

.flatpickr-calendar.open {
  background: #f9f9f9;
  border-radius: 1.5rem;
  width: 25.5rem;
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.flatpickr-calendar.open:before, .flatpickr-calendar.open:after {
  display: none;
}

.flatpickr-calendar .flatpickr-day {
  color: var(--font-color);
  vertical-align: middle;
  width: 100%;
  max-width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 400;
}

.flatpickr-calendar .flatpickr-day:hover {
  background: var(--theme-color-hover);
  border: .0625rem solid var(--border-color);
}

.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.flatpickr-calendar .flatpickr-day.startRange.today, .flatpickr-calendar .flatpickr-day.startRange.selected.today, .flatpickr-calendar .flatpickr-day.endRange.today, .flatpickr-calendar .flatpickr-day.endRange.selected.today, .flatpickr-calendar .flatpickr-day.startRange.today:hover, .flatpickr-calendar .flatpickr-day.startRange.selected.today:hover, .flatpickr-calendar .flatpickr-day.endRange.today:hover, .flatpickr-calendar .flatpickr-day.endRange.selected.today:hover {
  border-color: var(--theme-color);
  color: var(--font-color);
  background: none;
}

.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay.selected.today, .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay.selected.today, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay.selected.today, .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay.selected.today, .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay.selected.today:hover, .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay.selected.today:hover, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay.selected.today:hover, .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay.selected.today:hover {
  color: var(--font-color-out);
}

.flatpickr-calendar .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-day.startRange.selected, .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay, .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay, .flatpickr-calendar .flatpickr-day.startRange:hover, .flatpickr-calendar .flatpickr-day.startRange.selected:hover, .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay:hover, .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay:hover {
  box-shadow: .3125rem 0 var(--theme-color);
}

.flatpickr-calendar .flatpickr-day.endRange, .flatpickr-calendar .flatpickr-day.endRange.selected, .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay, .flatpickr-calendar .flatpickr-day.endRange:hover, .flatpickr-calendar .flatpickr-day.endRange.selected:hover, .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay:hover, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay:hover {
  box-shadow: -.3125rem 0 var(--theme-color);
}

.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay, .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay, .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay:not(.flatpickr-disabled), .flatpickr-calendar .flatpickr-day.startRange.selected, .flatpickr-calendar .flatpickr-day.inRange.nextMonthDay, .flatpickr-calendar .flatpickr-day.inRange.prevMonthDay, .flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:not(.flatpickr-disabled), .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay:not(.flatpickr-disabled), .flatpickr-calendar .flatpickr-day.endRange.selected, .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay:hover, .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay:hover, .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay:not(.flatpickr-disabled):hover, .flatpickr-calendar .flatpickr-day.startRange.selected:hover, .flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:hover, .flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:hover, .flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:not(.flatpickr-disabled):hover, .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay:hover, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay:hover, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay:not(.flatpickr-disabled):hover, .flatpickr-calendar .flatpickr-day.endRange.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--border-color);
}

.flatpickr-calendar .flatpickr-day.inRange {
  box-shadow: -.3125rem 0 var(--theme-color), .3125rem 0 var(--theme-color);
}

.flatpickr-calendar .flatpickr-day.inRange.today {
  border-color: var(--theme-color);
  color: var(--font-color);
  background: none;
}

.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay.today, .flatpickr-calendar .flatpickr-day.inRange.prevMonthDay.today {
  border-color: var(--theme-color);
  color: var(--font-color-out);
  background: none;
}

.flatpickr-calendar .flatpickr-day.inRange:hover {
  box-shadow: -.3125rem 0 var(--theme-color), .3125rem 0 var(--theme-color);
}

.flatpickr-calendar .flatpickr-day.startRange, .flatpickr-calendar .flatpickr-day.startRange.selected, .flatpickr-calendar .flatpickr-day.inRange, .flatpickr-calendar .flatpickr-day.endRange, .flatpickr-calendar .flatpickr-day.endRange.selected, .flatpickr-calendar .flatpickr-day.startRange:hover, .flatpickr-calendar .flatpickr-day.startRange.selected:hover, .flatpickr-calendar .flatpickr-day.inRange:hover, .flatpickr-calendar .flatpickr-day.endRange:hover, .flatpickr-calendar .flatpickr-day.endRange.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
  color: #fff;
}

.flatpickr-calendar .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)):hover, .flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)):hover, .flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) .flatpickr-day.selected:hover {
  box-shadow: -.625rem 0 var(--theme-color);
}

.flatpickr-calendar .flatpickr-day.today {
  border: .125rem solid var(--theme-color);
}

.flatpickr-calendar .flatpickr-day.today:hover {
  border-color: var(--theme-color);
  color: var(--font-color);
  background: none;
}

.flatpickr-calendar .flatpickr-day.nextMonthDay, .flatpickr-calendar .flatpickr-day.prevMonthDay:not(.flatpickr-disabled) {
  color: var(--font-color-out);
}

.flatpickr-calendar .flatpickr-day.nextMonthDay:hover, .flatpickr-calendar .flatpickr-day.prevMonthDay:not(.flatpickr-disabled):hover {
  background: var(--theme-color-hover);
  border-color: var(--border-color);
}

.flatpickr-calendar .flatpickr-day.nextMonthDay.selected, .flatpickr-calendar .flatpickr-day.prevMonthDay.selected:not(.flatpickr-disabled) {
  color: var(--border-color);
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.flatpickr-calendar .flatpickr-day.nextMonthDay.today, .flatpickr-calendar .flatpickr-day.prevMonthDay.today:not(.flatpickr-disabled) {
  color: var(--font-color-out);
  border-color: var(--theme-color);
  background: none;
}

.flatpickr-calendar .flatpickr-day.nextMonthDay.today:hover, .flatpickr-calendar .flatpickr-day.prevMonthDay.today:not(.flatpickr-disabled):hover {
  border-color: var(--theme-color);
  color: var(--font-color-out);
  background: none;
}

.flatpickr-calendar .flatpickr-day.flatpickr-disabled, .flatpickr-calendar .flatpickr-day.prevMonthDay.flatpickr-disabled, .flatpickr-calendar .flatpickr-day.flatpickr-disabled:hover, .flatpickr-calendar .flatpickr-day.prevMonthDay.flatpickr-disabled:hover {
  color: var(--font-color-out);
}

.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay.selected, .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay.selected, .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay.selected, .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay.selected {
  color: var(--border-color);
}

.flatpickr-calendar .flatpickr-day.startRange.endRange.selected {
  box-shadow: none;
}

.flatpickr-calendar .flatpickr-months {
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 1.5rem;
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month, .flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  border: .0625rem solid var(--border-color);
  background: #f9f9f9;
  border-radius: .5rem;
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  transition: background .3s, box-shadow .3s;
  position: relative;
  box-shadow: 0 .25rem 1rem .0625rem #0000;
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover, .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover {
  background: #fff;
  box-shadow: 0 .25rem 1rem .0625rem #6248aa33;
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:before, .flatpickr-calendar .flatpickr-months .flatpickr-next-month:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month svg, .flatpickr-calendar .flatpickr-months .flatpickr-next-month svg {
  display: none;
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:before {
  background-image: url("calendar-left.e253e8c6.svg");
}

.flatpickr-calendar .flatpickr-months .flatpickr-next-month:before {
  background-image: url("calendar-right.3e28d8cc.svg");
}

.flatpickr-calendar .flatpickr-months .flatpickr-month {
  width: 100%;
  height: 3rem;
  display: flex;
}

.flatpickr-calendar .flatpickr-months .flatpickr-current-month {
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding-top: 0;
  display: flex;
  position: relative;
  left: 0;
}

.flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months, .flatpickr-calendar .flatpickr-months .numInput.cur-year {
  color: var(--theme-color);
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-decoration: none;
}

.flatpickr-calendar .flatpickr-months .numInput.cur-year {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.flatpickr-calendar .flatpickr-months .numInputWrapper {
  margin-left: -1rem;
}

.flatpickr-calendar .flatpickr-months .numInputWrapper span {
  opacity: 1;
  background: none;
  border: none;
  width: 1rem;
  height: 1rem;
  padding: 0;
  line-height: normal;
}

.flatpickr-calendar .flatpickr-months .numInputWrapper span:after {
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 1rem;
  height: 1rem;
}

.flatpickr-calendar .flatpickr-months .numInputWrapper .arrowUp {
  top: -.25rem;
  right: -.25rem;
}

.flatpickr-calendar .flatpickr-months .numInputWrapper .arrowUp:after {
  background-image: url("arrow-up-num-input.0bc2b638.svg");
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.flatpickr-calendar .flatpickr-months .numInputWrapper .arrowDown {
  top: .75rem;
  right: -.25rem;
}

.flatpickr-calendar .flatpickr-months .numInputWrapper .arrowDown:after {
  background-image: url("arrow-down-num-input.a6bf1149.svg");
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.flatpickr-calendar .flatpickr-months .numInputWrapper:hover {
  background: none;
}

.flatpickr-calendar .flatpickr-months .numInputWrapper:hover .numInput.cur-year {
  text-decoration-color: #0000;
}

.flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months {
  background-image: url("calendar-down.34dd7e24.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  padding-right: 1.5rem;
}

.flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months:hover {
  background: unset;
  background-image: url("calendar-down.34dd7e24.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  text-decoration-color: #0000;
}

.flatpickr-calendar .flatpickr-days {
  width: 100%;
}

.flatpickr-calendar .dayContainer {
  justify-content: center;
  gap: .5rem;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  margin-top: .5rem;
  margin-bottom: -3rem;
}

.flatpickr-calendar .flatpickr-weekdays {
  background: var(--theme-color-hover);
  border-radius: .5rem;
  justify-content: center;
  height: 3.5rem;
  padding: .5rem;
}

.flatpickr-calendar .flatpickr-weekdaycontainer {
  justify-content: center;
  gap: .5rem;
}

.flatpickr-calendar span.flatpickr-weekday {
  color: var(--theme-color);
  width: 100%;
  max-width: 2.5rem;
  font-size: 1rem;
  font-weight: 700;
}

.flatpickr-innerContainer {
  padding: 0 2rem 2rem;
}

.ss-content {
  pointer-events: none;
}

.ss-content.ss-open-above, .ss-content.ss-open-below {
  pointer-events: auto;
}

.swiper {
  --swiper-pagination-color: #312960;
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide.--hidden {
  display: none;
}

.swiper-button-disabled.btn {
  opacity: 0;
  pointer-events: none;
}

body {
  -webkit-font-smoothing: antialiased;
  color: #484752;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #f9f9f9;
  flex-direction: column;
  min-height: 100vh;
  font-family: Inter, Adjusted Verdana, sans-serif;
  line-height: 1.5;
  display: flex;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale !important;
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }
}

body #product-parameters, body #product-content, body #product-reviews {
  scroll-margin-top: var(--scroll-margin-top);
}

@media (max-width: 61.999em) {
  body * {
    --scroll-margin-top: 5rem;
  }

  body.--search-open {
    overflow: hidden;
  }

  body.--search-open .i-notification, body.--search-open .m-header-top {
    opacity: 0;
    pointer-events: none;
  }
}

body:has(.c-modal.--open) {
  overflow: hidden;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
  display: block;
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  text-wrap: balance;
  margin-bottom: 0;
  font-family: Blinker, Adjusted Verdana, sans-serif;
}

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  height: 100%;
}

iframe {
  max-width: 100%;
  display: block;
}

img {
  opacity: 1;
  max-width: 100%;
  height: auto;
  transition: opacity .5s;
  display: block;
}

img[data-src], img[data-srcset] {
  min-width: .0625rem;
  min-height: .0625rem;
  display: block;
}

img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: #312960;
  text-decoration: underline;
  transition: text-decoration-color .3s;
}

a:hover, a:focus {
  color: #312960;
  text-decoration-color: #0000;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  flex-grow: 1;
  display: block;
  overflow-x: hidden;
}

main:has(.product-navbar) {
  overflow-x: initial;
}

main:has(.f-filtration.--open) {
  overflow-x: initial;
}

p {
  text-wrap: pretty;
}

:root {
  --section-spacing: 5rem;
}

@media (max-width: 35.999em) {
  :root {
    --section-spacing: 2.5rem;
  }
}

@font-face {
  font-family: Adjusted Verdana;
  size-adjust: 80%;
  src: local(verdana);
}

@font-face {
  font-family: Adjusted Georgia;
  size-adjust: 78%;
  src: local(georgia);
}

@font-face {
  font-family: Blinker;
  src: url("Blinker-Bold.ab054980.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Blinker;
  src: url("Blinker-SemiBold.f0b2a559.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Inter;
  src: url("Inter-VariableFont_opsz,wght.9b0b2305.woff2") format("woff2");
  font-optical-sizing: auto;
  font-weight: 400 500 700;
  font-style: normal;
  font-display: fallback;
}

strong, b {
  font-weight: 700;
}

h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

input[type="number"] {
  -moz-appearance: textfield;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
}

progress {
  vertical-align: baseline;
}

* {
  min-width: 0;
}

label[for] {
  cursor: pointer;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

.entry-content .alignnone {
  margin: .3125rem 1.25rem 1.25rem 0;
}

.entry-content .alignright {
  float: right;
  margin: 0 0 1.25rem 1.875rem;
}

.entry-content .alignright img {
  margin-block: 0;
}

.entry-content .alignleft {
  float: left;
  margin: 0 1.875rem 1.25rem 0;
}

.entry-content .alignleft img {
  margin-block: 0;
}

.entry-content .aligncenter, .entry-content div.aligncenter {
  margin: 3rem auto;
  display: block;
}

.entry-content .aligncenter img, .entry-content div.aligncenter img {
  margin-block: 0;
  margin-inline: auto;
}

@media (max-width: 35.999em) {
  .entry-content .alignleft, .entry-content .alignright {
    float: none;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.screen-reader-text {
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  width: .0625rem;
  height: .0625rem;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: .1875rem;
  width: auto;
  height: auto;
  padding: .9375rem 1.4375rem .875rem;
  font-size: .875rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: .3125rem;
  left: .3125rem;
  box-shadow: 0 0 .125rem .125rem #0009;
  clip: auto !important;
}

.u-bg-primary {
  background-color: #312960;
}

.u-bg-primary-100 {
  background-color: #edecf4;
}

.u-bg-primary-300 {
  background-color: #d7cef0;
}

.u-bg-primary-600-o-80 {
  background-color: #6248aacc;
}

.u-bg-gray {
  background-color: #a9a9b2;
}

.u-bg-white {
  background-color: #fff;
}

.u-bg-background {
  background-color: #f9f9f9;
}

.u-bg-secondary-500 {
  background-color: #ff5c50;
}

.u-bg-gradient {
  background: linear-gradient(to right, #312960, #6248aa);
}

.u-border-top {
  border-top: .0625rem solid #312960;
}

.u-border-right {
  border-right: .0625rem solid #312960;
}

.u-border-bottom {
  border-bottom: .0625rem solid #312960;
}

.u-border-left {
  border-left: .0625rem solid #312960;
}

.u-border-gray {
  border-color: #d7cef0;
}

.u-clamp {
  --lines: 2;
  -webkit-line-clamp: var(--lines);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-primary {
  color: #312960;
}

.u-color-primary-100 {
  color: #edecf4;
}

.u-color-primary-300 {
  color: #d7cef0;
}

.u-color-primary-500 {
  color: #7868a3;
}

.u-color-primary-600 {
  color: #6248aa;
}

.u-color-secondary {
  color: #e93d30;
}

.u-color-white {
  color: #fff;
}

.u-color-tertiary-600 {
  color: #73717e;
}

.u-color-tertiary {
  color: #484752;
}

.u-container-inline {
  container-type: inline-size;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-effect-fade > .u-effect-fade__target {
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 62em) {
  .u-effect-fade:hover > .u-effect-fade__target {
    opacity: 1;
    pointer-events: auto;
    transition: all .3s;
  }
}

.u-empty\:none:empty {
  display: none;
}

.u-fz-xxs {
  --size: .625rem;
  --line-height: 1rem;
  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-xs {
  --size: .75rem;
  font-size: var(--size);
}

.u-fz-sm {
  --size: .875rem;
  --line-height: 1rem;
  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-md {
  --size: 1rem;
  font-size: var(--size);
}

.u-fz-lg {
  --size: 1.125rem;
  --line-height: 1.5rem;
  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-xl {
  --size: 1.25rem;
  font-size: var(--size);
}

.u-fz-xxl {
  --size: 1.375rem;
  font-size: var(--size);
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-font-primary {
  font-family: Inter, Adjusted Verdana, sans-serif;
}

.u-font-secondary {
  font-family: Blinker, Adjusted Verdana, sans-serif;
}

.u-h1 {
  --size: 2.8125rem;
}

@media (max-width: 47.999em) {
  .u-h1 {
    --size: 2rem;
  }
}

.u-h2 {
  --size: 2.3125rem;
}

@media (max-width: 47.999em) {
  .u-h2 {
    --size: 1.75rem;
  }
}

.u-h3 {
  --size: 1.75rem;
}

@media (max-width: 47.999em) {
  .u-h3 {
    --size: 1.625rem;
  }
}

.u-h4 {
  --size: 1.5rem;
}

@media (max-width: 47.999em) {
  .u-h4 {
    --size: 1.25rem;
  }
}

.u-h5 {
  --size: 1.375rem;
}

@media (max-width: 47.999em) {
  .u-h5 {
    --size: 1.125rem;
  }
}

.u-h6 {
  --size: 1.125rem;
}

@media (max-width: 47.999em) {
  .u-h6 {
    --size: 1rem;
  }
}

.u-h1, .u-h2, .u-h3, .u-h4, .u-h5, .u-h6 {
  font-size: var(--size);
}

.u-img-hover {
  backface-visibility: hidden;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.u-img-hover img {
  will-change: transform, opacity;
  transition-property: opacity, transform;
}

.u-img-hover:hover img {
  transform: scale(1.03);
}

.--loading {
  pointer-events: none;
  position: relative;
}

.--loading:after, .--loading:before {
  content: "";
  display: block;
  position: absolute;
}

.--loading:before {
  z-index: 90;
  opacity: .7;
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  animation: 2s ease-out infinite opacity-pulse;
  bottom: 0;
  left: 0;
}

.--loading:after {
  z-index: 9001;
  border: .25rem solid #ff5c50;
  border-bottom-color: #0000;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: -.9375rem;
  margin-left: -.9375rem;
  animation: 1s cubic-bezier(.68, -.55, .27, 1.55) infinite rotation;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes opacity-pulse {
  0% {
    opacity: .5;
  }

  50% {
    opacity: .7;
  }

  100% {
    opacity: .5;
  }
}

.u-scrollbar .os-scrollbar {
  --os-handle-bg: #6248aa;
  --os-handle-bg-hover: #6248aa;
  --os-handle-bg-active: #6248aa;
  --os-handle-border-radius: .5rem;
  --os-size: 1rem;
  --os-track-bg: #edecf4;
  --os-track-bg-hover: #edecf4;
  --os-track-bg-active: #edecf4;
  --os-padding-perpendicular: .25rem;
  --os-padding-axis: .25rem;
  cursor: grab;
}

.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}

.u-scrollbar-native {
  --sb-track-color: #f9f9f9;
  --sb-thumb-color: #7868a3;
  --sb-size: .5rem;
}

.u-scrollbar-native::-webkit-scrollbar {
  width: var(--sb-size);
}

.u-scrollbar-native::-webkit-scrollbar:horizontal {
  height: var(--sb-size);
}

.u-scrollbar-native::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: .5rem;
}

.u-scrollbar-native::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border: .0625rem solid #fff;
  border-radius: .5rem;
}

@supports not selector(::-webkit-scrollbar) {
  .u-scrollbar-native {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-50-p {
  top: 50%;
}

.u-top-0 {
  top: 0;
}

.u-right-0 {
  right: 0;
}

.u-bottom-0 {
  bottom: 0;
}

.u-inset-0 {
  inset: 0;
}

.u-left-50-p {
  left: 50%;
}

.u-left-0 {
  left: 0;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%)translateY(-50%);
}

.u-rounded-full {
  border-radius: 624.938rem;
}

.u-radius-1 {
  border-radius: .5rem;
}

.u-radius-2 {
  border-radius: 1rem;
}

.u-radius-3 {
  border-radius: 1.5rem;
}

.u-radius-4 {
  border-radius: 2rem;
}

.u-radius-5 {
  border-radius: 2.5rem;
}

.u-radius-4px {
  border-radius: .25rem;
}

.u-shadow-small {
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.u-shadow-medium, .u-hover\:shadow-medium:hover {
  box-shadow: 0 .25rem 1.875rem .125rem #3132604d;
}

.u-hover\:shadow-small:hover {
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.u-z-index-0 {
  z-index: 0;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.u-z-index-101 {
  z-index: 101;
}

.a-user-panel {
  border: .0625rem solid #d7cef0;
}

.base-header {
  --spacing: 1.5rem;
  margin-inline: auto;
  margin-bottom: var(--spacing);
  max-width: 58rem;
}

.base-header.--left {
  text-align: left;
  align-items: flex-start;
  margin-inline: 0;
}

.base-header.--spacing-none {
  --spacing: 0;
}

.base-header.--spacing-lg {
  --spacing: 3.5rem;
}

@media (max-width: 35.999em) {
  .base-header.--spacing-lg {
    --spacing: 2.5rem;
  }
}

@media (min-width: 48em) {
  .base-header {
    --spacing: 3rem;
  }
}

.base-header__description p {
  margin-bottom: 0;
}

.base-heading {
  --decoration-width: .4375rem;
  --decoration-height: 1rem;
}

@media (max-width: 35.999em) {
  .base-heading {
    --decoration-width: .3125rem;
    --decoration-height: .75rem;
  }
}

.base-heading.--decoration:before {
  content: "";
  width: var(--decoration-width);
  height: var(--decoration-height);
  border-radius: 0 var(--decoration-height) var(--decoration-height) 0;
  vertical-align: middle;
  background-color: #ff5c50;
  display: inline-block;
}

.base-heading.--decoration-after {
  gap: .5rem;
  display: flex;
}

.base-heading.--decoration-line .base-heading__decoration-after:after {
  content: "";
  background-color: #d7cef0;
  width: 100%;
  height: .0625rem;
}

.base-heading.u-h3 {
  --decoration-width: .3125rem;
  --decoration-height: .75rem;
}

@media (max-width: 35.999em) {
  .base-heading.u-h3 {
    --decoration-width: .25rem;
    --decoration-height: .5625rem;
  }
}

.base-section {
  --decor-max-height: 35rem;
  --decor-radius: 2.5rem;
}

.base-section .base-section__banner {
  max-height: var(--decor-max-height);
  border-radius: var(--decor-radius);
  pointer-events: none;
  z-index: -2;
  background: linear-gradient(79.93deg, #312960 0%, #6248aa 98.96%);
  width: 100%;
  max-width: 105.375rem;
  height: 100%;
}

.base-section .base-section__banner-inner .base-section__banner-equalizer svg, .base-section .base-section__banner-inner:after {
  max-height: var(--decor-max-height);
  mix-blend-mode: multiply;
  border-radius: var(--decor-radius);
  pointer-events: none;
  z-index: -1;
  width: 100%;
  max-width: 105.375rem;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.base-section .base-section__banner-inner:after {
  content: "";
  background-position: top;
  background-repeat: no-repeat;
  height: 100%;
  top: 0;
}

.base-section .base-section__banner-inner .base-section__banner-equalizer svg {
  bottom: 0;
}

.base-section .base-section__banner-img {
  width: 28.8125rem;
  height: 28.8125rem;
  bottom: -10rem;
  right: -10rem;
}

.base-section.--big .base-header .base-heading, .base-section.--big-reverse .base-header .base-heading, .base-section.--small .base-header .base-heading, .base-section.--small-reverse .base-header .base-heading {
  color: #fff;
}

.base-section.--big .base-header .base-header__description, .base-section.--big-reverse .base-header .base-header__description, .base-section.--small .base-header .base-header__description, .base-section.--small-reverse .base-header .base-header__description {
  color: #edecf4;
}

.base-section.--big, .base-section.--big-reverse {
  min-height: var(--decor-max-height);
}

.base-section.--big .base-section__banner-inner:after, .base-section.--big-reverse .base-section__banner-inner:after {
  background-image: url("banner_front_big.fa3086b6.svg");
}

.base-section.--small, .base-section.--small-reverse {
  --decor-max-height: 22.5rem;
  min-height: var(--decor-max-height);
}

.base-section.--small .base-section__banner-inner:after, .base-section.--small-reverse .base-section__banner-inner:after {
  background-image: url("banner_front_small.68ecd78c.svg");
}

.base-section.--big-reverse .base-section__banner-img, .base-section.--small-reverse .base-section__banner-img {
  left: -10rem;
  right: auto;
}

.base-section.--equalizer .base-section__banner-inner:after {
  display: none;
}

@media (max-width: 74.999em) {
  .base-section .base-section__banner-img {
    display: none;
  }
}

@media (max-width: 35.999em) {
  .base-section {
    --decor-radius: 1.5rem;
  }

  .base-section .base-section__banner, .base-section .base-section__banner-inner:after {
    border-radius: var(--decor-radius) var(--decor-radius) 0 0;
  }
}

.base-section__banner-equalizer {
  pointer-events: none;
  overflow: hidden;
}

.base-section__banner-equalizer path {
  transform-box: fill-box;
  will-change: transform;
}

.base-section__banner-equalizer path:nth-child(5n) {
  animation: 4s cubic-bezier(.54, 2.36, .41, -.33) -1s infinite alternate-reverse equalizer;
}

.base-section__banner-equalizer path:nth-child(5n+1) {
  animation: 3s cubic-bezier(.89, 1.33, .64, .78) -2s infinite reverse equalizer;
}

.base-section__banner-equalizer path:nth-child(5n+2) {
  animation: 2s cubic-bezier(.76, 1.32, .37, -.32) infinite alternate equalizer;
}

.base-section__banner-equalizer path:nth-child(5n+3) {
  animation: 5s cubic-bezier(.535, .33, .69, 1.275) infinite equalizer;
}

.base-section__banner-equalizer path:nth-child(5n+4) {
  animation: 4s cubic-bezier(.61, -.05, .51, 1.41) -3s infinite reverse equalizer;
}

@keyframes equalizer {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(45%);
  }

  40% {
    transform: translateY(23%);
  }

  60% {
    transform: translateY(64%);
  }

  80% {
    transform: translateY(32%);
  }

  100% {
    transform: translateY(0);
  }
}

.base-section {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
}

.base-section.--top-0 {
  padding-top: 0;
}

.base-section.--bottom-0 {
  padding-bottom: 0;
}

.base-section.--none {
  --spacing: 0;
}

main:has(.stylebook-grid) {
  overflow-x: visible;
}

.btn {
  --direction-offset: .5rem;
}

.btn.--size-xs {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }

  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--sm-base-text {
  --font-size: 1rem;
  --padding-y: .625rem;
  --padding-x: 1rem;
  --min-height: 3rem;
}

.btn.--sm {
  --font-size: .875rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 3rem;
}

.btn.--xs {
  --font-size: .75rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 2.5rem;
  --svg-size: 1rem;
}

.btn.--xxs {
  --font-size: .875rem;
  --padding-y: .125rem;
  --padding-x: .5rem;
  --min-height: 2rem;
  --svg-size: 1.5rem;
}

.btn.--xxxs {
  --font-size: .75rem;
  --padding-y: 0;
  --padding-x: .125rem;
  --min-height: 1.5rem;
  --svg-size: 1.5rem;
}

.btn.--primary {
  --background: #6248aa;
  background-color: var(--background);
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--primary:hover {
    --background: #312960;
    box-shadow: 0 .25rem 1rem .0625rem #6248aa33;
  }
}

.btn.--secondary {
  --background: #ff5c50;
  background-color: var(--background);
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--secondary:hover {
    --background: #e93d30;
    box-shadow: 0 .25rem 1rem .0625rem #ff5c5099;
  }
}

.btn.--secondary-to-primary {
  --background: #ff5c50;
  background-color: var(--background);
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--secondary-to-primary:hover {
    --background: #6248aa;
  }
}

.btn.--transparent {
  --background: transparent;
  background-color: var(--background);
  transition: background-color .3s;
}

.btn.--outline-secondary {
  --font-color: #ff5c50;
  --inner-shadow: inset 0 0 0 .0625rem #ff5c50;
  --background: #fff;
  background-color: var(--background);
  box-shadow: 0 .25rem 1rem .0625rem transparent, var(--inner-shadow);
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--outline-secondary:hover {
    box-shadow: 0 .25rem 1rem .0625rem #6248aa33, var(--inner-shadow);
  }
}

.btn.--outline {
  --font-color: #6248aa;
  --inner-shadow: inset 0 0 0 .0625rem #d7cef0;
  --background: #f9f9f9;
  background-color: var(--background);
  box-shadow: 0 .25rem 1rem .0625rem transparent, var(--inner-shadow);
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--outline:hover {
    --background: #fff;
    box-shadow: 0 .25rem 1rem .0625rem #6248aa33, var(--inner-shadow);
  }
}

.btn.--outline-transparent {
  --font-color: #6248aa;
  --inner-shadow: inset 0 0 0 .0625rem #d7cef0;
  --background: #f9f9f980;
  background-color: var(--background);
  box-shadow: 0 .25rem 1rem .0625rem transparent, var(--inner-shadow);
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--outline-transparent:hover {
    --background: #fff;
    box-shadow: 0 .25rem 1rem .0625rem #6248aa33, var(--inner-shadow);
  }
}

.btn.--outline-transparent-primary-300 {
  --font-color: #d7cef0;
  --border-color: #d7cef0;
  --background: transparent;
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  transition: background-color .3s, box-shadow .3s;
}

.btn.--ghost {
  --background: transparent;
  --font-color: #312960;
  background-color: var(--background);
  transition: background-color .3s;
}

@media (hover: hover) {
  .btn.--ghost:hover {
    --background: #edecf4;
  }
}

.btn.--facebook {
  --background: #3b5998;
  --radius: .5rem;
  --background-hover: #3b5998cc;
  background-color: var(--background);
  transition: background-color .3s;
}

.btn.--facebook.--icon-only {
  flex-shrink: 1;
  width: 100%;
}

@media (hover: hover) {
  .btn.--facebook:hover {
    background-color: var(--background-hover);
  }
}

.btn.--google {
  --background: #fff;
  --radius: .5rem;
  --background-hover: #0000000d;
  background-color: var(--background);
  border: .0625rem solid #b1afbd;
  transition: background-color .3s;
}

.btn.--google path {
  fill: revert-layer;
}

.btn.--google.--icon-only {
  flex-shrink: 1;
  width: 100%;
}

@media (hover: hover) {
  .btn.--google:hover {
    background-color: var(--background-hover);
  }
}

.btn.--apple {
  --background: #111;
  --radius: .5rem;
  --background-hover: #111c;
  background-color: var(--background);
  transition: background-color .3s;
}

.btn.--apple.--icon-only {
  flex-shrink: 1;
  width: 100%;
}

@media (hover: hover) {
  .btn.--apple:hover {
    background-color: var(--background-hover);
  }
}

.btn.--link {
  --border-radius: 0;
  --font-size: .875rem;
  --font-weight: 400;
  --font-color: #6248aa;
  --background: transparent;
  --underline-color: #6248aa;
  --min-height: 1.5rem;
  background-color: var(--background);
  color: var(--font-color);
  text-transform: none;
  padding: 0;
  transition: color .3s;
}

.btn.--link .btn__title {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--underline-color);
  text-decoration-color: var(--underline-color);
  transition: text-decoration-color .3s;
}

.btn.--link path {
  will-change: fill;
  transition: fill .3s;
}

@media (hover: hover) {
  .btn.--link:hover {
    --font-color: #312960;
    --underline-color: transparent;
  }
}

.btn.--disabled {
  opacity: .4;
  pointer-events: none;
}

.btn.--disabled.--outline, .btn.--disabled.--outline-transparent {
  --font-color: #b1afbd;
  --inner-shadow: inset 0 0 0 .0625rem #b1afbd;
}

.btn.--swiper {
  --font-color: #6248aa;
  --inner-shadow: inset 0 0 0 .0625rem #d7cef0;
  --background: #ffffff80;
  background-color: var(--background);
  box-shadow: 0 .25rem 1rem .0625rem transparent, var(--inner-shadow);
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--swiper:hover {
    --background: #fff;
    box-shadow: 0 .25rem 1rem .0625rem #6248aa33, var(--inner-shadow);
  }
}

.btn.--no-style {
  --font-color: #081828;
  --background: transparent;
  background-color: var(--background);
  color: var(--font-color);
  min-height: 0;
  padding: 0;
  font-size: .875rem;
}

.btn.--no-style .btn__body {
  min-height: auto;
  padding: 0;
}

.btn.--no-style path {
  fill: var(--font-color);
}

@media (hover: hover) {
  .btn.--no-style:hover {
    color: var(--font-color);
  }
}

.btn-copy-to-clipboard__tooltip {
  opacity: var(--tootlip-opacity);
  width: max-content;
  transition: opacity .3s;
  top: 100%;
  right: 0;
}

.btn-copy-to-clipboard {
  --tootlip-opacity: 0;
}

.btn-copy-to-clipboard.--copied {
  --tootlip-opacity: 1;
}

.btn__icon .icon {
  --size: var(--svg-size);
}

.btn__icon svg {
  transition: transform .3s, opacity .3s;
}

.btn {
  --border-radius: .5rem;
  --font-size: 1rem;
  --font-weight: 700;
  --font-color: #fff;
  --font-family: Blinker, Adjusted Verdana, sans-serif;
  --padding-y: 1rem;
  --padding-x: 1.5rem;
  --min-height: 3.5rem;
  --svg-size: 1.25rem;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  text-transform: uppercase;
  border: none;
  text-decoration: none;
}

.btn:hover {
  color: var(--font-color);
  text-decoration: none;
}

.btn:focus, .btn:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

.btn path {
  fill: var(--font-color);
}

.btn.--icon-before .btn__icon {
  order: -1;
}

.btn.--icon-only {
  height: var(--min-height);
  justify-content: center;
  align-items: center;
  min-height: auto;
  padding: 0;
  display: flex;
}

.btn.--icon-only:not(.--full-width) {
  width: var(--min-height);
}

.btn.--icon-only .btn__title {
  display: none;
}

@media (max-width: 35.999em) {
  .btn.--full-width-mobile {
    width: 100%;
  }
}

.btn.--rounded {
  --border-radius: calc(var(--min-height) / 2);
}

.btn.--indicator:after {
  content: attr(data-indicator);
  text-align: center;
  color: #fff;
  background-color: #ff5c50;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
  font-size: .625rem;
  font-weight: 700;
  line-height: 1rem;
  display: flex;
  position: absolute;
  top: 0;
  right: -.1875rem;
}

.c-compositions-box {
  border: .0625rem solid #edecf4;
}

.c-compositions-box.entry-content ol {
  column-count: 2;
  column-gap: 2rem;
  margin-top: 0;
}

.c-compositions-box.entry-content ol > li {
  padding-left: 1.5rem;
}

.c-compositions-box.entry-content ol > li:before {
  color: #6248aa;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: -1.875rem;
  font-size: 1rem;
}

.c-compositions-box.entry-content h6, .c-compositions-box.entry-content li:last-child {
  margin-bottom: 0;
}

.content-w-aside__content .base-section .container {
  padding-inline: 0;
}

.content-w-aside__content .base-section:first-child {
  padding-top: 0;
}

.content-w-aside__content .base-section:last-child {
  padding-bottom: 0;
}

@media (min-width: 75em) {
  .content-w-aside__content {
    grid-column: 4 / span 8;
  }

  .content-w-aside__aside {
    will-change: min-height;
    grid-column: span 4;
  }

  .content-w-aside__aside.--sticky {
    height: max-content;
  }

  .content-w-aside__aside.--sticky .content-w-aside__aside-inner {
    will-change: position, transform;
    transform: translate3d(0, 0, 0);
  }
}

.content-w-aside {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
}

.content-w-aside.--top-0 {
  padding-top: 0;
}

.content-w-aside.--top-3 {
  padding-top: 1.5rem;
}

.content-w-aside.--top-6 {
  padding-top: 3rem;
}

.content-w-aside.--bottom-0 {
  padding-bottom: 0;
}

.content-w-aside.--none {
  --padding-y: 0;
}

.content-w-aside.--reverse .content-w-aside__aside, .content-w-aside.--reverse-lg .content-w-aside__aside {
  order: -1;
  grid-column: span 3;
}

.content-w-aside.--reverse .content-w-aside__content, .content-w-aside.--reverse-lg .content-w-aside__content {
  grid-column: 4 / span 9;
}

@media (max-width: 61.999em) {
  .content-w-aside.--reverse-lg .content-w-aside__aside {
    order: 0;
  }

  .content-w-aside.--default-lg .content-w-aside__content {
    order: -1;
  }
}

@media (min-width: 75em) {
  .content-w-aside.--columns-4 .content-w-aside__aside {
    grid-column: span 4;
  }

  .content-w-aside.--columns-4 .content-w-aside__content {
    grid-column: 1 / span 8;
  }
}

.content-w-aside:not(:has(.content-w-aside__aside)) .content-w-aside__content {
  grid-column: 2 / span 10;
}

.content-w-aside__row {
  --gap: 2rem;
  gap: var(--gap);
}

@media (max-width: 74.999em) {
  .content-w-aside__row {
    --gap: 5rem;
  }
}

@media (max-width: 35.999em) {
  .content-w-aside__row {
    --gap: 2.5rem;
  }
}

@media (min-width: 75em) {
  .content-w-aside__row {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    display: grid;
  }
}

@media (max-width: 74.999em) {
  .content-w-aside__row {
    flex-direction: column;
    display: flex;
  }
}

.c-counter {
  --width: 9.5rem;
  --color: #6248aa;
  --border-color: #d7cef0;
  border: .0625rem solid var(--border-color);
  min-width: var(--width);
  color: var(--color);
  -webkit-user-select: none;
  user-select: none;
  min-height: 3.5rem;
  transition: background-color .3s;
}

.c-counter.--disabled {
  --color: #b1afbd;
  --border-color: #edecf4;
  pointer-events: none;
}

.c-counter__value-visual {
  gap: .25rem;
}

.c-counter__button {
  z-index: 2;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.c-counter__button path {
  fill: var(--color);
}

.c-counter__button > svg {
  z-index: 2;
}

.c-counter__button:before {
  content: "";
  opacity: 0;
  background-color: #d7cef0;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.c-counter__button:hover:before {
  opacity: 1;
}

.c-counter__notice {
  pointer-events: none;
  z-index: 10;
  border-radius: .25rem;
  padding: .25rem .5rem;
  top: calc(100% + .25rem);
  box-shadow: 0 .25rem 1.875rem .125rem #3132604d;
}

.c-counter__notice:before {
  content: "";
  background-color: inherit;
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  width: 1rem;
  height: .5rem;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.d-small-circles {
  column-gap: .1875rem;
  left: 0;
}

.d-small-circles:before, .d-small-circles:after {
  content: "";
  display: block;
}

.d-small-circles:after {
  background-color: #6248aa;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
}

.d-small-circles:before {
  background-color: #ff5c50;
  border-radius: 0 1rem 1rem 0;
  width: .5rem;
  height: 1rem;
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  margin-right: var(--margin-right);
  box-shadow: inset 0 0 0 var(--border-width) var(--color);
  border-radius: var(--radius);
  background-color: #fff;
  transition: box-shadow .3s, background-color .3s;
}

.f-checkbox__visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-checkbox__visual:after {
  content: "";
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  background-color: #fff;
  transition: opacity .3s, transform .3s;
  display: none;
  position: absolute;
  transform: scale(.2);
}

.f-checkbox__body {
  flex-basis: calc(100% - (var(--size)  + var(--margin-right)));
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50%);
}

.f-checkbox__input:checked ~ .f-checkbox__visual {
  --border-width: .0625rem;
  background-color: var(--color);
}

.f-checkbox__input:checked ~ .f-checkbox__visual:after, .f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title a {
  color: var(--color);
  font-weight: 700;
}

.f-checkbox {
  --color: #6248aa;
  --radius: .375rem;
  --size: 1.5rem;
  --margin-right: 1rem;
  --border-width: .0625rem;
  --min-height: 3rem;
  min-height: var(--min-height);
  cursor: pointer;
}

.f-checkbox:hover {
  --color: #6248aa;
  --border-width: .125rem;
}

.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}

.f-checkbox.--disabled {
  --color: #d7cef0;
  pointer-events: none;
}

.f-checkbox.--disabled .f-checkbox__input:not(:checked) ~ .f-checkbox__visual {
  background-color: #f9f9f9;
}

.f-checkbox.--disabled .f-checkbox__title {
  color: var(--color);
}

.f-checkbox .f-error__title {
  font-size: 1rem;
}

.f-checkbox-select__header {
  min-height: var(--height);
  border: .0625rem solid var(--border-color);
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--header-background);
  box-shadow: var(--shadow);
  border-radius: 1.5rem;
  padding: .375rem 1rem .375rem 1.5rem;
  transition: box-shadow .3s, border .3s, border-radius .3s;
}

.f-checkbox-select__header:hover {
  --border-color: #6248aa;
  --shadow: 0 .25rem .625rem .125rem #7868a31a;
}

.f-checkbox-select__clear {
  display: none;
}

.f-checkbox-select__clear.--active {
  display: inline-flex;
}

.f-checkbox-select__toggler {
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transition: background-color .3s;
}

.f-checkbox-select__toggler svg {
  width: 1.5rem;
  height: 1.5rem;
  transition: scale .3s;
}

.f-checkbox-select__title {
  --end: 0;
  flex: 1;
  height: 1rem;
  top: 0;
  left: 0;
  overflow: hidden;
}

.f-checkbox-select__title > span {
  color: #312960;
  white-space: nowrap;
  transition: left 2s linear;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.f-checkbox-select__title:after {
  content: "";
  pointer-events: none;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box;
  z-index: 1;
  width: 1rem;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.f-checkbox-select__title:hover > span {
  left: var(--end);
}

.f-checkbox-select__body {
  box-shadow: 0 0 0 .0625rem var(--border-color);
  transform-origin: top;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  will-change: opacity, transform;
  background-color: #fff;
  width: calc(100% - .125rem);
  margin-top: -.0625rem;
  transition: opacity .3s, transform .3s;
  left: .0625rem;
  transform: scaleY(.25);
}

.f-checkbox-select__body .os-scrollbar-track {
  height: calc(100% - .5rem);
}

.f-checkbox-select {
  --header-background: #fff;
  --border-color: #b1afbd;
  --shadow: 0 0 0 0 transparent;
  --height: 3rem;
  min-width: 0;
}

.f-checkbox-select .f-checkbox {
  --min-height: 2.5rem;
}

.f-checkbox-select.--active {
  --border-color: #6248aa;
}

.f-checkbox-select.--active .f-checkbox-select__header {
  border-radius: 1.5rem 1.5rem 0 0;
}

.f-checkbox-select.--active .f-checkbox-select__body {
  opacity: 1;
  pointer-events: auto;
  border-radius: 0 0 1.5rem 1.5rem;
  transform: scaleY(1);
}

.f-checkbox-select.--active .f-checkbox-select__toggler svg {
  scale: -1;
}

@media (max-width: 35.999em) {
  .f-checkbox-select {
    --height: 2.5rem;
  }
}

.f-checkbox-select__body-inner {
  max-height: 11.4375rem;
}

.f-checkbox-select__body-inner .f-checkbox:last-child {
  margin-bottom: 1rem;
}

.f-checkbox-select__body-inner .f-checkbox__title {
  color: #312960;
  font-weight: 500;
}

.f-color__input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.f-color__input:after {
  content: "";
  position: absolute;
  inset: 0;
}

.f-color {
  --size: 2rem;
  --radius: 50%;
  width: var(--size);
  height: var(--size);
  border-radius: var(--radius);
  background: none;
  padding: .25rem;
  transition: box-shadow .2s, background-color .2s;
}

.f-color:after {
  content: "";
  border-radius: var(--radius);
  background: var(--background-color);
  width: 100%;
  height: 100%;
  display: block;
  box-shadow: inset 0 0 0 .0625rem #fff;
}

.f-color:hover {
  background: #fff;
  box-shadow: 0 .25rem .625rem .125rem #7868a31a;
}

.f-color:hover:after {
  box-shadow: inset 0 0 #fff;
}

.f-color.--active {
  background: #6248aa;
}

.f-color:has(input:checked) {
  background: #6248aa;
}

.f-color.--active:after {
  box-shadow: inset 0 0 0 .125rem #fff;
}

.f-color:has(input:checked):after {
  box-shadow: inset 0 0 0 .125rem #fff;
}

.f-color.--disabled {
  pointer-events: none;
  opacity: .8;
}

.f-color.--link.--checked {
  background: #6248aa;
}

.f-color.--link.--checked:after {
  box-shadow: inset 0 0 0 .125rem #fff;
}

.f-date__icon {
  display: var(--icon-display);
  pointer-events: none;
  padding: .5rem 1rem .5rem .5rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-date__input {
  color: var(--font-color);
  border-radius: var(--radius);
  cursor: var(--cursor);
}

.f-date__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date__input::-webkit-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date__input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date {
  --inset-shadow-color: #b1afbd;
  --radius: 1.5rem;
  --icon-display: block;
  --cursor: pointer;
  --font-color: #312960;
  --shadow: 0 0 0 0 transparent;
}

.f-date:hover {
  --shadow: 0 .25rem .625rem .125rem #7868a31a;
}

.f-date:hover .f-base {
  --inset-shadow-color: #6248aa;
}

.f-date:not(.--native) .f-date__input {
  padding-right: 3rem;
}

.f-date.--active .f-base {
  --inset-shadow-color: #6248aa;
}

.f-date.--native {
  --icon-display: none;
  --cursor: auto;
}

.f-date.--invalid .f-base {
  --inset-shadow-color: #e21c3d;
}

.f-date__wrap {
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  transition: box-shadow .3s;
}

.f-error {
  --display: none;
  color: #e21c3d;
  display: var(--display);
  z-index: 3;
  border-radius: .5rem;
  gap: .25rem;
  width: auto;
  padding: .25rem;
  top: calc(100% - .5rem);
  left: 1rem;
  box-shadow: 0 .25rem 1.875rem .125rem #3129604d;
}

.f-error:before {
  content: "";
  -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  pointer-events: none;
  z-index: 3;
  background-color: #fff;
  width: .5rem;
  height: .4375rem;
  position: absolute;
  top: .0625rem;
  left: .625rem;
  transform: translateY(-100%);
}

.f-error.--show {
  --display: flex;
}

.f-error__title {
  line-height: 1rem;
}

.f-base {
  --inset-shadow-color: #b1afbd;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: inset 0 0 0 .0625rem var(--inset-shadow-color);
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  width: 100%;
  min-height: 3rem;
  padding: .75rem 1rem .75rem 1.5rem;
  font-weight: 500;
  transition: box-shadow .3s;
  display: block;
}

.f-base:focus {
  border-color: none;
  outline: none;
}

.f-base::placeholder {
  opacity: 1;
  color: #7868a3;
}

@media (max-width: 35.999em) {
  .f-base {
    min-height: 2.5rem;
    padding-block: .625rem;
  }
}

textarea.f-base {
  resize: none;
  min-height: 11rem;
  padding-top: 1rem;
  padding-bottom: .75rem;
}

.i-selected-file {
  cursor: pointer;
  gap: .25rem;
  min-height: 1.5rem;
}

.i-selected-file__title {
  color: #6248aa;
  text-decoration: underline;
}

.i-selected-file__remove {
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
}

.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file.--empty .f-file__files, .f-file__input {
  display: none;
}

.f-ico .f-base {
  -webkit-appearance: textfield;
  appearance: textfield;
}

.f-ico .f-base::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.f-ico .f-base::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.f-label {
  --top: 50%;
  --translate-y: -50%;
  --color: #7868a3;
  top: var(--top);
  transform: translateY(var(--translate-y));
  color: var(--color);
  pointer-events: none;
  z-index: 2;
  background-color: #fff;
  margin-right: .0625rem;
  padding-inline: .25rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1rem;
  transition: transform .3s, font-size .3s, top .3s, left .3s, color .3s;
  position: absolute;
  left: 1.25rem;
}

.f-label.--required:after {
  content: "*";
  color: #7868a3;
  padding-left: .25rem;
}

.f-label.--active {
  --color: #6248aa;
  --top: 0;
  font-size: .75rem;
}

.f-multicheckbox-search__search .f-base {
  min-height: 2.5rem;
}

.f-multicheckbox-search__body {
  padding-left: .125rem;
}

.f-multicheckbox-search__body .os-scrollbar-track {
  height: 100%;
}

.f-multicheckbox-search__body-inner {
  max-height: 12rem;
}

.f-multicheckbox-search__body-inner > div {
  padding-block: .25rem;
}

.f-multicheckbox-search__body-inner .f-checkbox__title {
  color: #312960;
  font-weight: 500;
}

.f-multicheckbox-search .f-checkbox {
  --min-height: 2.5rem;
  margin-right: 1rem;
}

.f-multicheckbox-search__load-more.--hidden {
  display: none;
}

.f-radio-box__visual {
  --size: 1.5rem;
  --border-width: .0625rem;
  --color: #6248aa;
  --radius: calc(var(--size) / 2);
  --options-col-display: block;
  width: var(--size);
  height: var(--size);
  box-shadow: 0 0 0 var(--border-width) var(--color);
  border-radius: var(--radius);
  transition: box-shadow .3s, background-color .3s;
}

.f-radio-box__visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-radio-box__visual:after {
  content: "";
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  background-color: #fff;
  transition: opacity .3s, transform .3s;
  display: none;
  position: absolute;
  transform: scale(.2);
}

.f-radio-box__image-col {
  flex: 0 0 5rem;
}

.f-radio-box__options-col {
  display: var(--options-col-display);
}

@media (min-width: 48em) {
  .f-radio-box__options-col > .link {
    margin-left: 1.5rem;
  }
}

.f-radio-box__reload-link.link {
  display: none;
}

.f-radio-box.--disabled .f-radio-box__visual {
  --color: #d7cef0;
}

.f-radio-box.--disabled .f-radio-box__image-col {
  opacity: .6;
}

.f-radio-box.--disabled, .f-radio-box.--disabled .i-price__main, .f-radio-box.--disabled .i-price__before, .f-radio-box.--disabled .i-price__base {
  color: #d7cef0;
}

.f-radio-box:has(.f-radio-box__reload-link) {
  --options-col-display: none;
}

.f-radio-box:has(.f-radio-box__input:checked) {
  --options-col-display: flex;
}

.f-radio-box__body {
  --inset-shadow-color: transparent;
  box-shadow: inset 0 0 0 .0625rem var(--inset-shadow-color);
  transition: background-color .3s;
}

.f-radio-box__body:hover {
  background-color: #ebebeb;
}

.f-radio-box__body:hover .f-radio-box__visual {
  --border-width: .125rem;
}

@media (max-width: 61.999em) {
  .f-radio-box__body {
    flex-wrap: wrap;
  }
}

.f-radio-box__content > * {
  flex: 1;
}

@media (max-width: 61.999em) {
  .f-radio-box__content {
    padding-left: 2.5rem;
  }

  .f-radio-box__title {
    width: 100%;
  }
}

.f-radio-box__input {
  display: none;
}

.f-radio-box__input:checked ~ .f-radio-box__body {
  --inset-shadow-color: #d7cef0;
  background-color: #fff;
}

.f-radio-box__input:checked ~ .f-radio-box__body .f-radio-box__visual {
  background-color: #312960;
}

.f-radio-box__input:checked ~ .f-radio-box__body .f-radio-box__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-radio-box__input:checked ~ .f-radio-box__body .f-radio-box__reload-link.link {
  display: inline-flex;
}

.f-radio-box__input:disabled:checked ~ .f-radio-box__body .f-radio-box__visual {
  background-color: #d7cef0;
}

.f-radio-box__price-col {
  flex: 1 0 auto;
}

@media (min-width: 62em) {
  .f-radio-box__price-col {
    flex-basis: 6.25rem;
    max-width: 6.25rem;
  }
}

.f-range-slider__label.--from, .f-range-slider__label.--to, .f-range-slider__label.--from-to {
  white-space: nowrap;
  font-size: .625rem;
  font-weight: 700;
  line-height: .875rem;
  bottom: .875rem;
}

.f-range-slider {
  --height: .25rem;
  --thumb-size: 1rem;
  --thumb-offset: .5rem;
  --color: #6248aa;
  --background: #d7cef0;
  --gradient-from: calc(var(--background-from)  - var(--thumb-offset));
  --gradient-to: calc(var(--background-to)  + var(--thumb-offset));
}

.f-range-slider.--from-to .f-range-slider__label.--from, .f-range-slider.--from-to .f-range-slider__label.--to {
  display: none;
}

.f-range-slider.--from-to .f-range-slider__label.--from-to {
  display: block;
}

.f-range-slider__control .f-base {
  -webkit-appearance: textfield;
  appearance: textfield;
  min-height: 2.5rem;
  padding-block: .625rem;
}

.f-range-slider__control .f-base::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.f-range-slider__control .f-base::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.f-range-slider__ranges {
  height: var(--height);
}

@media (max-width: 35.999em) {
  .f-range-slider__inputs {
    flex-direction: column;
  }
}

.f-range-slider__range {
  -webkit-appearance: none;
  appearance: none;
  inset: 0 var(--thumb-offset);
  background: linear-gradient(90deg, transparent 0%, transparent var(--gradient-from), var(--color) var(--gradient-from), var(--color) var(--gradient-to), transparent var(--gradient-to), transparent 100%);
  pointer-events: none;
  position: absolute;
}

.f-range-slider__range:before {
  content: "";
  inset: 0 calc(var(--thumb-offset) * -1);
  background-color: var(--background);
  z-index: -1;
  border-radius: var(--height);
  position: absolute;
}

.f-range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: all;
  width: var(--thumb-size);
  height: var(--thumb-size);
  cursor: pointer;
  transform: translateX(calc(var(--thumb-offset) * var(--side)));
  z-index: 2;
  background-color: #6248aa;
  border-radius: 50%;
  transition: background-color .2s;
  position: relative;
}

.f-range-slider__range::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  pointer-events: all;
  width: var(--thumb-size);
  height: var(--thumb-size);
  cursor: pointer;
  transform: translateX(calc(var(--thumb-offset) * var(--side)));
  z-index: 2;
  background-color: #6248aa;
  border-radius: 50%;
  transition: background-color .2s;
  position: relative;
}

.f-range-slider__range.--from {
  --side: -1;
}

.f-range-slider__range.--to {
  --side: 1;
}

.f-single-select__select.slim-select.ss-main {
  padding-inline: var(--padding-inline);
  box-shadow: var(--shadow);
  padding-left: 1.5rem;
  transition: box-shadow .3s, border .3s, border-radius .3s;
}

.f-single-select__select.slim-select.ss-main:after {
  content: "";
  pointer-events: none;
  background-image: url("arrow-down.90032685.svg");
  background-repeat: no-repeat;
  grid-area: select;
  place-self: center end;
  width: 1.5rem;
  height: 1.5rem;
  transition: scale .3s;
  display: block;
  scale: 1;
}

.f-single-select__select.slim-select.ss-main.ss-disabled {
  --ss-font-color: #d7cef0;
}

.f-single-select__select.slim-select.ss-main .ss-values {
  gap: .25rem;
}

.f-single-select__select.slim-select.ss-main .ss-values .ss-value {
  min-height: 1.5rem;
}

.f-single-select__select.slim-select.ss-main .ss-values .ss-value .ss-value-text {
  padding-right: .25rem;
}

.f-single-select__select.slim-select.ss-main .ss-values .ss-value .ss-value-delete {
  border-left: none;
  padding-left: .25rem;
}

.f-single-select__select.slim-select .ss-list .ss-option {
  padding-inline: 1rem;
  padding-left: 1.5rem;
}

.f-single-select__select.slim-select .ss-arrow {
  display: none;
}

.f-single-select.--multiple .ss-main .ss-values {
  margin-block: .75rem;
}

.f-single-select, .f-single-select__select.slim-select {
  --radius: 1.5rem;
  --height: 3rem;
  --padding-inline: 1rem;
  --padding-block: .625rem;
  --border-color: #b1afbd;
  --shadow: 0 0 0 0 transparent;
  --ss-primary-color: #312960;
  --ss-bg-color: #fff;
  --ss-font-color: #312960;
  --ss-font-placeholder-color: #7868a3;
  --ss-disabled-color: #f9f9f9;
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 18.75rem;
  --ss-spacing-l: .4375rem;
  --ss-spacing-m: .75rem;
  --ss-spacing-s: 0;
  --ss-animation-timing: .2s;
  --ss-border-radius: var(--radius);
  font-size: .875rem;
  font-weight: 500;
  line-height: 1rem;
}

.f-single-select:hover, .f-single-select__select.slim-select:hover {
  --border-color: #6248aa;
  --shadow: 0 .25rem .625rem .125rem #7868a31a;
}

.f-single-select.--native .f-single-select__wrap, .f-single-select__select.slim-select.--native .f-single-select__wrap {
  grid-template-areas: "select";
  align-items: center;
  display: grid;
}

.f-single-select.--native .f-single-select__wrap:after, .f-single-select__select.slim-select.--native .f-single-select__wrap:after {
  content: "";
  pointer-events: none;
  background-image: url("arrow-down.90032685.svg");
  background-repeat: no-repeat;
  grid-area: select;
  justify-self: end;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  display: block;
}

.f-single-select.--native .f-single-select__select, .f-single-select__select.slim-select.--native .f-single-select__select {
  color: #312960;
  border-radius: var(--radius);
  min-height: var(--height);
  border: .0625rem solid var(--border-color);
  padding-block: var(--padding-block);
  padding-inline: var(--padding-inline);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: var(--shadow);
  background-color: #fff;
  outline: none;
  grid-area: select;
  width: 100%;
  margin: 0;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
  font-weight: 500;
  transition: box-shadow .3s, border .3s;
}

.f-single-select .ss-search input, .f-single-select__select.slim-select .ss-search input {
  padding-inline: 1.5rem;
}

.f-single-select .ss-search input:focus, .f-single-select__select.slim-select .ss-search input:focus {
  box-shadow: none;
}

.f-single-select.ss-open-below, .f-single-select__select.slim-select.ss-open-below {
  --border-color: #6248aa;
}

.f-single-select.ss-open-below:after, .f-single-select__select.slim-select.ss-open-below:after {
  scale: -1;
}

.f-single-select.--invalid, .f-single-select__select.slim-select.--invalid, .f-single-select.--invalid .ss-main, .f-single-select__select.slim-select.--invalid .ss-main {
  --border-color: #e21c3d;
}

@media (max-width: 35.999em) {
  .f-single-select, .f-single-select__select.slim-select {
    --height: 2.5rem;
  }
}

.f-single-select-inline {
  gap: .0625rem;
}

.f-single-select-inline__select {
  --ss-primary-color: #6248aa;
  --ss-bg-color: #fff;
  --ss-font-color: #6248aa;
  --ss-border-color: transparent;
  --ss-success-color: #48a463;
  --ss-error-color: #e21c3d;
  --ss-main-height: 1.5rem;
  --ss-content-height: auto;
  --ss-spacing-l: 0;
  --ss-spacing-m: .25rem;
  --ss-spacing-s: .125rem;
  --ss-animation-timing: .3s;
  --ss-border-radius: .5rem;
  --text-decoration-color: #6248aa;
  --selected-text-color: #6248aa;
  --option-text-color: #6248aa;
  --option-text-decoration-color: #6248aa;
  border-radius: .5rem;
  font-size: 1rem;
}

.f-single-select-inline__select:hover {
  --selected-text-color: #312960;
  --text-decoration-color: transparent;
}

.f-single-select-inline__select .ss-single, .f-single-select-inline__select .ss-option {
  color: var(--selected-text-color);
  text-decoration: underline;
  -webkit-text-decoration-color: var(--text-decoration-color);
  text-decoration-color: var(--text-decoration-color);
  will-change: text-decoration-color, color;
  transition: text-decoration-color .3s, color .3s;
}

.f-single-select-inline__select.ss-content {
  border-top: 0;
  min-width: 8.875rem;
  font-size: .875rem;
  line-height: 1rem;
  box-shadow: 0 .25rem 1.875rem .125rem #3129604d;
  transform: translateY(1.5rem) !important;
}

.f-single-select-inline__select.ss-content .ss-list {
  pointer-events: none;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.f-single-select-inline__select.ss-content .ss-list .ss-option {
  -webkit-text-decoration-color: var(--option-text-decoration-color);
  text-decoration-color: var(--option-text-decoration-color);
}

.f-single-select-inline__select.ss-content .ss-list .ss-option:hover {
  --option-text-decoration-color: transparent;
  color: #312960;
  background-color: #0000;
}

.f-single-select-inline__select.ss-open-above, .f-single-select-inline__select.ss-open-below {
  --ss-bg-color: #fff;
}

.f-single-select-inline__select.ss-open-above.ss-content .ss-list, .f-single-select-inline__select.ss-open-below.ss-content .ss-list {
  pointer-events: auto;
}

.f-single-select-inline__select.ss-open-above.ss-content {
  transform: translateY(-1.375rem) !important;
}

.f-single-select-inline__select.ss-main {
  background-color: #0000;
  border-color: #0000;
  width: auto;
  font-weight: 400;
}

.f-single-select-inline__select.ss-main:after {
  content: "";
  pointer-events: none;
  background-image: url("chevron-down-ss-inline.5b7d10a1.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75rem .625rem;
  grid-area: select;
  place-self: center flex-end;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: .375rem;
  transition: scale .3s;
  display: block;
  scale: 1;
}

.f-single-select-inline__select.ss-main:focus {
  box-shadow: none;
}

.f-single-select-inline__select.ss-main.ss-open-above, .f-single-select-inline__select.ss-main.ss-open-below {
  border-color: var(--ss-border-color);
  border-bottom-color: #0000;
}

.f-single-select-inline__select.ss-main .ss-arrow {
  display: none;
}

.f-switch {
  --width: 3rem;
  --height: 1.5rem;
  --offset: .125rem;
  --background: #fff;
  --background-checked: #fff;
  --transition-timing: .2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height)  - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #6248aa;
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width)  - var(--handle-size)  - var(--offset));
}

.f-switch.--disabled {
  --background: #a9a9b2;
  --background-checked: #a9a9b2;
  --handle-background: #ebebeb;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;
  box-shadow: 0 0 0 .0625rem #d7cef0;
}

.f-switch__visual:after {
  content: "";
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  will-change: transform;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;
}

.f-switch__input:checked ~ .f-switch__visual {
  --background: var(--background-checked);
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-tag-checkbox__input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.f-tag-checkbox__input:after {
  content: "";
  position: absolute;
  inset: 0;
}

.f-tag-checkbox {
  --border-color: #d7cef0;
  --color: #312960;
  --background: transparent;
  --height: 3rem;
  --border-radius: .5rem;
  --font-size: 1rem;
  --font-weight: 400;
  --line-height: 1.5rem;
  --padding-x: .5rem;
  min-height: var(--height);
  min-width: var(--height);
  padding-inline: var(--padding-x);
  box-shadow: inset 0 0 0 .0625rem var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--color);
  transition: box-shadow .2s, background-color .2s, color .2s;
}

.f-tag-checkbox:after {
  content: "";
  opacity: 0;
  background-color: #6248aa;
  background-image: url("check.3455d650.svg"), none;
  background-position: center, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: .875rem;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  transition: opacity .1s;
  display: flex;
  position: absolute;
  top: .125rem;
  left: .125rem;
  transform: translate(-50%, -50%);
}

.f-tag-checkbox.--disabled {
  --border-color: #b1afbd;
  --color: #b1afbd;
  pointer-events: none;
}

.f-tag-checkbox:hover {
  --background-color: #fff;
  --font-weight: 700;
  box-shadow: 0 .25rem .625rem .125rem #7868a31a, inset 0 0 0 .0625rem var(--border-color);
}

.f-tag-checkbox.--active {
  --border-color: #6248aa;
  --font-weight: 700;
  box-shadow: inset 0 0 0 .125rem var(--border-color);
}

.f-tag-checkbox:has(.f-tag-checkbox__input:checked) {
  --border-color: #6248aa;
  --font-weight: 700;
  box-shadow: inset 0 0 0 .125rem var(--border-color);
}

.f-tag-checkbox.--active:after {
  opacity: 1;
}

.f-tag-checkbox:has(.f-tag-checkbox__input:checked):after {
  opacity: 1;
}

.f-tag-checkbox.--link.--checked {
  --border-color: #6248aa;
  --font-weight: 700;
  box-shadow: inset 0 0 0 .125rem var(--border-color);
}

.f-tag-checkbox.--link.--checked:after {
  opacity: 1;
}

.f-text__icon {
  pointer-events: none;
  position: absolute;
}

.f-text__icon.--before {
  top: 50%;
  left: 1.5rem;
  transform: translateX(0)translateY(-50%);
}

.f-text__icon.--after {
  top: 50%;
  right: 1.5rem;
  transform: translateX(0)translateY(-50%);
}

.f-text__input {
  border-radius: var(--radius);
  color: var(--font-color);
}

.f-text {
  --inset-shadow-color: #b1afbd;
  --font-color: #312960;
  --shadow: 0 0 0 0 transparent;
  --radius: 1.5rem;
}

.f-text:hover {
  --shadow: 0 .25rem .625rem .125rem #7868a31a;
}

.f-text:hover .f-base, .f-text.--active .f-base {
  --inset-shadow-color: #6248aa;
}

.f-text.--invalid .f-base {
  --inset-shadow-color: #e21c3d;
}

.f-text.--icon-before .f-text__input {
  padding-left: 3.5rem;
}

.f-text.--icon-before .f-label:not(.--active) {
  left: 3.5rem;
}

.f-text.--icon-after .f-text__input {
  padding-right: 3.5rem;
}

.f-text.--disabled {
  opacity: .5;
}

@media (max-width: 35.999em) {
  .f-text .f-text__icon.--before {
    left: 1rem;
  }

  .f-text .f-text__icon.--after {
    right: 1rem;
  }

  .f-text.--icon-before .f-text__input {
    padding-left: 3rem;
  }

  .f-text.--icon-before .f-label:not(.--active) {
    left: 3rem;
  }

  .f-text.--icon-after .f-text__input {
    padding-right: 3rem;
  }
}

.f-text__wrap {
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  transition: box-shadow .3s;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  right: 1rem;
}

.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  width: var(--size);
  height: var(--size);
  grid-row-start: 1;
  grid-column-start: 1;
}

.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}

.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea {
  --inset-shadow-color: #b1afbd;
  --font-color: #312960;
  --shadow: 0 0 0 0 transparent;
  --radius: 1.5rem;
}

.f-textarea:hover {
  --shadow: 0 .25rem .625rem .125rem #7868a31a;
}

.f-textarea:hover .f-base {
  --inset-shadow-color: #6248aa;
}

.f-textarea .f-label {
  --top: 1.5rem;
}

.f-textarea.--active .f-base {
  --inset-shadow-color: #6248aa;
}

.f-textarea.--active .f-label {
  --top: 0;
}

.f-textarea.--invalid .f-base {
  --inset-shadow-color: #e21c3d;
}

.f-textarea.--disabled {
  --font-color: #d7cef0;
  cursor: not-allowed;
}

.f-textarea.--disabled .f-base {
  background-color: #f9f9f9;
}

.f-textarea.--disabled .f-base, .f-textarea.--disabled.--active .f-base {
  --inset-shadow-color: #b1afbd;
}

.f-textarea__wrap {
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  transition: box-shadow .3s;
}

.f-textarea__input {
  border-radius: var(--radius);
  color: var(--font-color);
}

.f-variant__input {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.f-variant__input:after {
  content: "";
  position: absolute;
  inset: 0;
}

.f-variant__mediums {
  pointer-events: none;
}

.f-variant__mediums .img {
  margin: 0 -.625rem;
  transform: translate(0);
}

.f-variant__color {
  background-color: var(--color);
  width: .375rem;
  height: .375rem;
}

.f-variant {
  --border-color: #d7cef0;
  --color: #312960;
  --background: transparent;
  --height: 5.5rem;
  --font-weight: 400;
  --line-height: 1.5rem;
  min-height: var(--height);
  min-width: var(--height);
  box-shadow: inset 0 0 0 .0625rem var(--border-color);
  background-color: var(--background);
  color: var(--color);
  padding-inline: .75rem;
  transition: box-shadow .2s, background-color .2s, color .2s;
}

.f-variant:after {
  content: "";
  opacity: 0;
  background-color: #6248aa;
  background-image: url("check.3455d650.svg"), none;
  background-position: center, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: .875rem;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  transition: opacity .1s;
  display: flex;
  position: absolute;
  top: 50%;
  left: .125rem;
  transform: translate(-50%, -50%);
}

.f-variant:hover {
  --background-color: #fff;
  --font-weight: 700;
  box-shadow: 0 .25rem .625rem .125rem #7868a31a, inset 0 0 0 .0625rem var(--border-color);
}

.f-variant:has(.f-variant__input:checked, .f-variant__input.--link.--checked) {
  --font-weight: 700;
  box-shadow: inset 0 0 0 .125rem var(--border-color);
}

.f-variant:has(.f-variant__input:checked, .f-variant__input.--link.--checked):not(.--disabled) {
  --border-color: #6248aa;
}

.f-variant:has(.f-variant__input:checked, .f-variant__input.--link.--checked):after {
  opacity: 1;
}

.f-variant.--disabled {
  --border-color: #b1afbd;
  --color: #b1afbd;
  pointer-events: none;
}

.f-variant.--disabled .f-variant__mediums {
  filter: grayscale();
}

.f-contact {
  --padding: 1.5rem;
  --max-width: 100%;
  max-width: var(--max-width);
  padding: var(--padding);
  background: #fff;
  border-radius: 1.5rem;
  box-shadow: 0 .25rem 1.875rem .125rem #3132604d;
}

.f-contact .g-terms__text-only {
  color: #312960;
}

.f-contact .g-terms__text-only > a {
  color: #ff5c50;
}

@media (min-width: 48em) {
  .f-contact {
    --padding: 2.5rem;
  }
}

@media (max-width: 35.999em) {
  .f-contact {
    --max-width: 30.5rem;
    margin-inline: auto;
  }
}

@media (min-width: 48em) {
  .f-contact__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.f-contact__submit .btn {
  gap: .25rem;
  min-width: 10rem;
}

@media (min-width: 36em) {
  .f-contact__submit {
    margin-left: auto;
  }
}

@media (max-width: 35.999em) {
  .f-contact__submit {
    width: 100%;
  }
}

.f-coupon-add {
  background: #fff;
  border: .0625rem solid #7868a3;
  border-radius: 1.5rem;
}

.f-coupon-add__inputs {
  max-width: 31.25rem;
}

@media (min-width: 36em) {
  .f-coupon-add__input .f-text__input {
    padding-right: 3rem;
  }

  .f-coupon-add__apply {
    margin-left: -1.5rem;
  }
}

@media (min-width: 48em) and (max-width: 74.999em) {
  .f-editorial-calendar-filter__inputs-wrapper {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

@media (max-width: 93.749em) {
  .f-editorial-calendar-filter__inputs-wrapper {
    gap: 1.5rem;
  }
}

@media (max-width: 47.999em) {
  .f-editorial-calendar-filter__inputs-wrapper {
    flex-direction: column;
    gap: 1rem;
  }
}

.f-editorial-calendar-filter {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr auto;
  column-gap: 5rem;
}

@media (max-width: 74.999em) {
  .f-editorial-calendar-filter {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 47.999em) {
  .f-editorial-calendar-filter {
    gap: 1rem;
    padding: 1rem;
  }
}

.f-editorial-calendar-filter__filter-visual {
  border: .0625rem solid #edecf4;
  grid-column: 1 / -1;
}

@media (max-width: 61.999em) {
  .f-editorial-calendar-filter__filter-visual {
    padding: 1rem;
  }
}

.f-filtration__wrapper {
  transition: top .3s;
  position: sticky;
  top: 1.5rem;
}

@media (max-width: 61.999em) {
  .f-filtration__wrapper {
    position: relative;
  }
}

.f-filtration__header {
  padding: .5rem .75rem;
}

.f-filtration__header:before {
  content: "";
  background-color: #d7cef0;
  width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: -1rem;
  left: 0;
  transform: translateY(-100%);
}

.f-filtration__header-heading {
  margin: 0;
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .f-filtration__header-heading .base-heading {
    --size: 1.25rem;
  }
}

@media (min-width: 62em) {
  .f-filtration__close-filter.btn {
    display: none;
  }
}

@media (max-width: 61.999em) {
  .f-filtration__content {
    height: 100%;
    overflow: auto;
  }
}

.f-filtration__reset-btn {
  display: none;
}

.f-filtration {
  border: .0625rem solid #d7cef0;
  border-radius: 1.5rem;
  height: auto;
  max-height: 100%;
  padding: 1rem;
  display: block;
}

@media (max-width: 61.999em) {
  .f-filtration {
    z-index: 200;
    background: #fff;
    border-radius: 1.5rem 0 0 1.5rem;
    flex-direction: column;
    width: min(20rem, 100%);
    height: 100%;
    padding-right: 0;
    transition: transform .3s;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
  }
}

.f-filtration.--open {
  transform: translateX(0);
  box-shadow: 0 .25rem 1.875rem .125rem #3132604d;
}

.f-filtration:has(.i-selected-tag) .f-filtration__reset-btn {
  display: inline-flex;
}

.f-forgotten-password {
  border: .0625rem solid #d5d4da;
  border-radius: 1rem;
  box-shadow: 0 .25rem 1.875rem .125rem #3129604d;
}

@media (max-width: 61.999em) {
  .f-forgotten-password {
    justify-self: center;
  }
}

.f-interprets-filter {
  background-color: #fff;
  border-radius: 1.5rem;
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.f-interprets-filter .f-text {
  --p-offset: 11rem;
  width: calc(50% + 1rem);
}

.f-interprets-filter .f-text .f-text__input {
  padding-right: var(--p-offset);
}

@media (max-width: 35.999em) {
  .f-interprets-filter .f-text .f-text__input {
    min-height: 3rem;
    padding-block: .75rem;
  }
}

@media (max-width: 74.999em) {
  .f-interprets-filter .f-text {
    width: 100%;
  }
}

@media (max-width: 47.999em) {
  .f-interprets-filter .f-text {
    --p-offset: 1rem;
  }
}

.f-interprets-filter__submit {
  --m-offset: calc(-1 * 10rem);
  margin-left: var(--m-offset);
}

.f-interprets-filter__submit .btn {
  --font-size: 1rem;
  border: .0625rem solid #6248aa;
  min-width: 10rem;
  line-height: 1.5rem;
}

@media (max-width: 47.999em) {
  .f-interprets-filter__submit {
    --m-offset: 0;
    position: static;
  }
}

@media (max-width: 35.999em) {
  .f-interprets-filter__submit {
    width: 100%;
  }
}

.f-interprets-filter__keypad-btn.btn {
  --font-size: 1.125rem;
  --min-height: 3rem;
  width: var(--min-height);
  padding: 0;
  line-height: 1.5rem;
}

.f-interprets-filter__keypad-btn.btn:first-child {
  width: 4rem;
}

.f-newsletter {
  background: linear-gradient(to right, #312960, #6248aa);
  border: .0625rem solid #7868a3;
  border-radius: 1.5rem;
  padding-left: 15rem;
}

.f-newsletter .f-text {
  --border-color: #d5d4da;
}

.f-newsletter .f-text:hover {
  --shadow: none;
}

.f-newsletter .f-text .f-text__input {
  --p-offset: 11rem;
  padding-right: var(--p-offset);
}

@media (max-width: 93.749em) {
  .f-newsletter .f-text .f-text__input {
    --p-offset: 1rem;
  }
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .f-newsletter .f-text .f-text__input {
    --p-offset: 11rem;
  }
}

@media (max-width: 35.999em) {
  .f-newsletter .f-text .f-text__input {
    min-height: 3rem;
    padding-block: .75rem;
  }
}

@media (max-width: 61.999em) {
  .f-newsletter {
    padding: 1.5rem;
  }
}

@media (min-width: 62em) {
  .f-newsletter__grid {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    display: grid;
  }
}

@media (max-width: 61.999em) {
  .f-newsletter__grid {
    gap: 1.5rem;
  }
}

.f-newsletter__header {
  gap: 1rem;
}

@media (max-width: 35.999em) {
  .f-newsletter__header.--left {
    text-align: center;
  }
}

@media (max-width: 93.749em) {
  .f-newsletter__inputs-col {
    gap: 1rem;
  }
}

@media (max-width: 35.999em) {
  .f-newsletter__inputs-col {
    flex-direction: column;
  }
}

@media (max-width: 93.749em) {
  .f-newsletter__inputs {
    flex-direction: column;
  }
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .f-newsletter__inputs {
    flex-direction: row;
  }
}

.f-newsletter__submit {
  --m-offset: calc(-1 * 11rem);
  margin-left: var(--m-offset);
}

.f-newsletter__submit .btn {
  --font-size: 1rem;
  gap: .25rem;
  min-width: 10rem;
  line-height: 1.5rem;
}

@media (max-width: 93.749em) {
  .f-newsletter__submit {
    --m-offset: 0;
    position: static;
  }
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .f-newsletter__submit {
    --m-offset: calc(-1 * 11rem);
    position: relative;
  }
}

.f-newsletter__terms .f-checkbox__title {
  color: #d7cef0;
  font-weight: 400;
}

.f-newsletter__terms .f-checkbox__title a {
  color: #d7cef0;
}

.f-newsletter__img {
  max-width: 16.8125rem;
  height: auto;
  top: 50%;
  left: 0;
  transform: translate(-25%, -50%);
}

@media (max-width: 61.999em) {
  .f-newsletter__img {
    display: none;
  }
}

.f-product-review {
  border: .0625rem solid #b1afbd;
}

.f-product-review__image {
  width: 7rem;
}

.f-refund-method {
  border: .0625rem solid #edecf4;
}

.f-refund-method__disclaimer {
  border: .0625rem solid #d7cef0;
}

.f-registration {
  border: .0625rem solid #d5d4da;
  border-radius: 1rem;
  width: 100%;
  max-width: 28rem;
  box-shadow: 0 .25rem 1.875rem .125rem #3129604d;
}

@media (max-width: 61.999em) {
  .f-registration {
    justify-self: center;
  }
}

.f-registration__existing-user {
  padding-top: .75rem;
}

.f-reset-password {
  border: .0625rem solid #d5d4da;
  border-radius: 1rem;
  box-shadow: 0 .25rem 1.875rem .125rem #3129604d;
}

.f-search__close-button.btn {
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  top: .3125rem;
  right: .3125rem;
}

.f-search__dropdown {
  transition: all .2s;
  top: 100%;
}

@media (min-width: 62em) {
  .f-search__dropdown {
    max-height: min(45.125rem, calc(var(--vh, 1vh) * 100)  - 11rem);
    border: .0625rem solid #6248aa;
    border-top: none;
    border-radius: 0 0 1.5rem 1.5rem;
    max-height: min(45.125rem, 100vh - 11rem);
    transform: translateY(-.25rem);
    box-shadow: 0 .25rem 1.875rem .125rem #3132604d;
  }
}

@media (max-width: 61.999em) {
  .f-search__dropdown {
    padding-bottom: 1.5rem;
  }
}

.f-search__dropdown .container {
  padding: 0;
}

.f-search__search-field {
  z-index: 15;
}

.f-search__search-typing {
  display: var(--search-typing-display);
  top: 50%;
  left: 3.5rem;
  transform: translateY(-50%);
}

@media (max-width: 61.999em) {
  .f-search__body {
    position: static;
  }
}

.f-search {
  --search-typing-display: block;
  grid-area: search;
}

@media (max-width: 61.999em) {
  .f-search {
    order: 10;
    width: 100%;
  }
}

.f-search .f-text__wrap {
  border: .0625rem solid #6248aa;
}

.f-search .f-base {
  box-shadow: none;
}

.f-search.--disable-typing {
  --search-typing-display: none;
}

.f-search:has(.i-search-dropdown).--open .f-search__dropdown {
  pointer-events: auto;
  opacity: 1;
  display: flex;
  transform: translateY(0);
}

@media (max-width: 61.999em) {
  .f-search:has(.i-search-dropdown).--open .f-search__dropdown {
    box-shadow: none;
    background: none;
    border-radius: 0;
    width: 100%;
    height: calc(100dvh + 5rem);
    padding-top: 10.125rem;
    transition: opacity .3s;
    top: 0;
    left: 0;
  }

  .f-search:has(.i-search-dropdown).--open .f-search__close-button {
    opacity: 1;
    pointer-events: auto;
  }
}

.f-search:has(.i-search-dropdown).--open .f-text__wrap {
  box-shadow: none;
  background-color: #fff;
}

@media (min-width: 62em) {
  .f-search:has(.i-search-dropdown).--open .f-text__wrap {
    border-bottom-color: #0000;
    border-radius: 1.5rem 1.5rem 0 0;
  }
}

.f-user-password {
  width: min(100%, 28rem);
}

@media (max-width: 74.999em) {
  .f-user-password {
    width: 100%;
  }
}

.g-alerts:not(:has(.i-alert)) {
  display: none;
}

.g-breadcrumbs__list.--top-0 {
  padding-top: 0;
}

.g-breadcrumbs__list.--none {
  padding-block: 0;
}

.g-buttons {
  --spacing: 0;
  margin-top: var(--spacing);
}

.g-buttons:has(.btn) {
  --spacing: 1.5rem;
}

@media (min-width: 48em) {
  .g-buttons:has(.btn) {
    --spacing: 3rem;
  }
}

.g-buttons.--spacing-none {
  --spacing: 0;
}

.cart-items-group__header {
  grid-template-columns: 5rem 2fr 1fr 9.375rem 2.5rem .5fr 2.875rem;
  grid-template-areas: "content content availability counter discount price action";
  display: grid;
}

@media (max-width: 74.999em) {
  .cart-items-group__header {
    display: none;
  }
}

.cart-items-group__heading.--product {
  grid-area: content;
}

.cart-items-group__heading.--availability {
  grid-area: availability;
}

.cart-items-group__heading.--amount {
  grid-area: counter;
}

.cart-items-group__heading.--sale {
  grid-area: discount;
}

.cart-items-group__heading.--price {
  grid-area: price;
}

.g-category__cell.--hidden {
  display: none;
}

@media (max-width: 35.999em) {
  .g-category {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
}

@media (max-width: 61.999em) {
  .g-contacts-cards {
    max-width: 30rem;
    margin-inline: auto;
  }
}

@media (min-width: 75em) {
  .g-contacts-pillars {
    padding-inline: 1rem;
  }
}

.g-contacts-pillars__cell {
  --max-width: 100%;
  --margin: auto;
  max-width: var(--max-width);
}

@media (min-width: 75em) {
  .g-contacts-pillars__cell:first-child .i-contact-pillar {
    margin-left: var(--margin);
    border-radius: 1.5rem 0 0 1.5rem;
  }

  .g-contacts-pillars__cell:nth-child(3) .i-contact-pillar {
    margin-right: var(--margin);
    border-radius: 0 1.5rem 1.5rem 0;
  }
}

@media (max-width: 74.999em) {
  .g-contacts-pillars__cell {
    --max-width: 41.75rem;
    --margin: 0;
    margin-inline: auto;
  }

  .g-contacts-pillars__cell:first-child .i-contact-pillar, .g-contacts-pillars__cell:nth-child(3) .i-contact-pillar {
    border-radius: 1.5rem;
  }
}

@media (min-width: 93.75em) {
  .g-contacts-pillars__cell:first-child, .g-contacts-pillars__cell:nth-child(3) {
    --max-width: 25.4375rem;
    width: 100%;
  }

  .g-contacts-pillars__cell:nth-child(2) {
    --max-width: 27.9375rem;
    width: 100%;
  }
}

@media (min-width: 62em) {
  .g-information {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (min-width: 48em) {
  .g-interprets-category {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 75em) {
  .g-interprets-category {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 93.75em) {
  .g-interprets-category {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-interprets-category__heading, .g-interprets__load-more-wrapper {
  grid-column: 1 / -1;
}

@media (min-width: 359.001px) {
  .g-interprets {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 62em) {
  .g-interprets {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 75em) {
  .g-interprets {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 93.75em) {
  .g-interprets {
    grid-template-columns: repeat(6, 1fr);
  }
}

@container (width >= 264px) {
  .g-mediums:not(.--toggle):not(:has( > :nth-child(5))) .g-mediums__show-more {
    display: none;
  }

  .g-mediums:not(.--toggle):has( > :nth-child(5)) > .i-medium {
    display: none;
  }

  .g-mediums:not(.--toggle):has( > :nth-child(5)) > .i-medium:nth-child(-n+2) {
    display: flex;
  }

  .g-mediums:not(.--toggle):has( > :nth-child(5)) .g-mediums.--toggle > :first-child {
    display: none;
  }

  .g-mediums:not(.--toggle):has( > :nth-child(5)) .g-mediums.--toggle > :nth-child(2):before {
    content: "";
  }
}

@container (width >= 172px) and (width <= 263px) {
  .g-mediums:not(.--toggle) > .i-medium {
    display: none;
  }

  .g-mediums:not(.--toggle) > .i-medium:nth-child(-n+2) {
    display: flex;
  }

  .g-mediums:not(.--toggle) > .i-medium:nth-child(2):not(:nth-last-child(2)), .g-mediums:not(.--toggle) .g-mediums__show-more:nth-child(3) {
    display: none;
  }
}

@container (width <= 171px) {
  .g-mediums:not(.--toggle) > .i-medium {
    display: none;
  }

  .g-mediums:not(.--toggle) > .i-medium:first-child {
    display: flex;
  }
}

.g-mediums:not(.--toggle).--overflows > .i-medium:first-child {
  max-width: calc(100% - 4rem);
}

.g-mediums:not(.--toggle).--overflows > .i-medium:not(:first-child) {
  display: none;
}

.g-mediums:not(.--toggle).--overflows .g-mediums__show-more, .g-mediums:not(.--toggle).--overflows .g-mediums.--toggle > .i-medium {
  display: flex;
}

.g-mediums:not(.--toggle).--overflows .g-mediums.--toggle > .i-medium:before {
  content: "+";
}

.g-mediums.--toggle {
  pointer-events: none;
  opacity: 0;
  background: #fff;
  border-radius: 1rem;
  flex-direction: column;
  width: max-content;
  padding: .625rem;
  transition: opacity .1s;
  transform: translate(-.0625rem, -.0625rem);
  box-shadow: 0 .25rem 1rem .0625rem #6248aa33;
}

@media (max-width: 35.999em) {
  .g-mediums.--toggle {
    left: 50%;
    transform: translateX(-50%);
  }
}

.g-mediums.--toggle .i-medium:before {
  top: -1.125rem;
  left: 50%;
  transform: translateX(-50%);
}

.g-mediums .g-mediums__show-more:hover .g-mediums.--toggle {
  opacity: 1;
  pointer-events: auto;
}

.g-mediums {
  z-index: 10;
  gap: 1.5rem;
}

.g-mediums:hover {
  z-index: 15;
}

.g-mediums .i-medium:before, .g-mediums .g-mediums__show-more:before {
  content: "+";
  color: #484752;
  width: .75rem;
  height: .75rem;
  font-size: 1.125rem;
  line-height: .75rem;
  display: block;
  position: absolute;
  top: 50%;
  left: -1.125rem;
  transform: translateY(-50%);
}

.g-mediums .i-medium:first-child:before {
  display: none;
}

@media (max-width: 35.999em) {
  .g-personal-data__row {
    --bs-gutter-y: 1.5rem;
  }
}

.g-persons {
  display: grid;
}

@media (min-width: 48em) {
  .g-persons {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 75em) {
  .g-persons {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-posts-grid {
  gap: 4.5rem 2rem;
  display: grid;
}

@media (min-width: 62em) {
  .g-posts-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-posts-grid__cell:first-of-type {
  grid-column: 1 / -1;
}

.g-posts {
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem 2rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .g-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.999em) {
  .g-posts {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--compact {
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 47.999em) {
  .g-posts.--compact {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--inline {
  flex-direction: column;
  display: flex;
}

.g-product-overviews {
  padding-bottom: .0625rem;
}

.g-products__title {
  grid-column: 1 / -1;
  margin-bottom: -.5rem;
}

@media (min-width: 48em) {
  .g-products {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 62em) {
  .g-products {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 47.999em) {
  .g-products .i-product {
    margin-inline: auto;
  }
}

@media (min-width: 75em) {
  .g-products.--lg {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-products-slider__button {
  --offset: calc(50% - .25rem);
  pointer-events: auto;
}

.g-products-slider__button.btn.--outline {
  --bg-white: 255 255 255;
  --background: rgb(var(--bg-white) / 50%);
  --background-hover: rgb(var(--bg-white) / 100%);
}

@media (hover: hover) {
  .g-products-slider__button.btn.--outline:hover {
    --background: var(--background-hover);
  }
}

@media (max-width: 359px) {
  .g-products-slider__button.btn.--outline {
    --min-height: 2rem;
    --offset: calc(50% - .75rem);
  }
}

.g-products-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-products-slider__button.--next {
  transform: translateX(var(--offset));
}

.g-products-slider__button .icon.--lg {
  --size: 1.25rem;
}

.g-products-slider.--loading:before {
  border-radius: 2rem;
}

.g-products-slider.swiper {
  overflow: initial;
  align-items: center;
  display: flex;
}

.g-products-slider .swiper-wrapper {
  align-items: center;
  display: flex;
}

.g-products-slider .swiper-slide {
  backface-visibility: hidden;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  flex-shrink: 0;
  transition: opacity .3s;
  transform: translate3d(0, 0, 0);
}

.g-products-slider .swiper-slide-visible {
  opacity: 1;
  pointer-events: auto;
}

.g-products-slider__buttons {
  pointer-events: none;
}

.g-products-slider__buttons .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.g-products-slider__load-more-img.img.--cover {
  background-color: #6248aa;
  border-radius: .5rem;
  width: auto;
  height: auto;
  inset: 1rem;
  overflow: hidden;
}

.g-products-slider__load-more-img.img.--cover img {
  opacity: .3;
  transition: opacity .3s, scale .3s;
}

.g-products-slider__load-more.swiper-slide {
  border: .0625rem solid #d7cef0;
  border-radius: 1rem;
  height: auto;
  transition: box-shadow .3s, background-color .3s;
}

.g-products-slider__load-more.swiper-slide:hover {
  background-color: #fff;
}

.g-products-slider__load-more.swiper-slide:hover .g-products-slider__load-more-img img {
  opacity: .2;
  scale: 1.03;
}

.g-review {
  border: .0625rem solid #d7cef0;
  border-radius: 1.5rem;
  row-gap: 1rem;
  padding: 2.5rem;
}

@media (min-width: 62em) {
  .g-review {
    row-gap: 1.5rem;
  }
}

@media (min-width: 75em) {
  .g-review {
    row-gap: 2.5rem;
  }
}

@media (min-width: 93.75em) {
  .g-review .g-review__cell {
    padding: .5rem 1rem;
  }
}

.g-review__cell-separator {
  column-gap: 1rem;
}

.g-review__cell-separator-line {
  background-color: #d7cef0;
  width: 100%;
  height: .0625rem;
  margin: 0;
}

@media (min-width: 48em) {
  .g-sales {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.999em) {
  .g-signpost .g-signpost__cell {
    max-width: 28rem;
    margin-inline: auto;
  }
}

@media (min-width: 36em) {
  .g-similar-interprets {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .g-similar-interprets {
    grid-template-columns: auto;
  }
}

.g-socials.--sm {
  gap: .5rem;
}

.g-terms__checkbox {
  border-radius: .5rem;
}

.g-terms__checkbox .f-checkbox__title {
  font-weight: 400;
}

.g-terms__text-only {
  line-height: 1rem;
}

.g-terms__text-only a {
  color: #6248aa;
  font-weight: 600;
  display: inline-block;
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}

@media (max-width: 35.999em) {
  .g-testimonials__button {
    --offset: .5rem;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-testimonials .swiper-slide {
  backface-visibility: hidden;
  cursor: pointer;
  flex-shrink: 0;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate3d(0, 0, 0);
}

.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .g-testimonials {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
  }
}

@media (min-width: 93.75em) {
  .g-testimonials {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
  }
}

.g-testimonials__last {
  height: 3.1875rem;
}

.g-top-products {
  grid-template: repeat(5, 1fr) / repeat(3, auto);
  grid-auto-flow: column;
}

@media (max-width: 74.999em) {
  .g-top-products {
    gap: 1rem;
  }
}

@media (max-width: 61.999em) {
  .g-top-products {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: row;
  }
}

.g-top-products__item:before {
  content: attr(data-top-products-order);
  color: #fff;
  z-index: 1;
  pointer-events: none;
  background: url("star-filled.878338d7.svg") center / 2rem no-repeat;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-weight: 700;
  display: flex;
  position: absolute;
}

@media (max-width: 35.999em) {
  .g-usps {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
}

.icon {
  --size: 1.25rem;
  color: #081828;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon.--colored {
  color: var(--color);
}

.icon.--colored path {
  fill: var(--color);
}

.icon.--primary {
  --color: #312960;
}

.icon.--primary-600 {
  --color: #6248aa;
}

.icon.--secondary-500 {
  --color: #ff5c50;
}

.icon.--white {
  --color: #fff;
}

.icon.--black {
  --color: #081828;
}

.icon.--danger {
  --color: #e21c3d;
}

.icon.--5-xl {
  --size: 5rem;
}

.icon.--xxxxl {
  --size: 3.5rem;
}

.icon.--xxxl {
  --size: 3rem;
}

.icon.--xxl {
  --size: 2.5rem;
}

.icon.--xl {
  --size: 2rem;
}

.icon.--lg {
  --size: 1.5rem;
}

.icon.--md {
  --size: 1.125rem;
}

.icon.--sm {
  --size: 1rem;
}

.icon.--xs {
  --size: .75rem;
}

.icon.--xxs {
  --size: .625rem;
}

.img.--ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.img.--ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.img.--ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.img.--ratio-1-1 {
  aspect-ratio: 1;
}

.img.--ratio-3-4 {
  aspect-ratio: 3 / 4;
}

.img.--contain {
  width: 100%;
  height: 100%;
}

.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img.--contain picture, .img.--cover {
  width: 100%;
  height: 100%;
}

.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img.--cover picture {
  width: 100%;
  height: 100%;
}

.img.--placeholder {
  --opacity: .7;
}

.img.--placeholder:has(img.loaded) {
  --opacity: 0;
}

.img.--placeholder:before {
  content: "";
  z-index: -1;
  opacity: var(--opacity);
  background-color: #edecf4;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-add-wishlist-button {
  --svg-empty: block;
  --svg-full: none;
}

.i-add-wishlist-button.--wishlisted {
  --svg-empty: none;
  --svg-full: block;
}

.i-add-wishlist-button .s-heart__empty {
  display: var(--svg-empty);
  transition: fill .3s;
}

.i-add-wishlist-button .s-heart__full {
  display: var(--svg-full);
}

.i-add-wishlist-button:hover .s-heart__empty {
  fill: #ff5c50;
}

.i-alert {
  --background: #fff;
  --border-color: #ebebeb;
  --color: #081828;
  --opacity: 0;
  --translate: -2rem;
  --icon-background: #fff;
  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  border-color: var(--border-color);
  transform: translateY(var(--translate));
  pointer-events: none;
  will-change: opacity, transform;
  border-style: solid;
  border-width: .0625rem;
  border-radius: .25rem;
  transition: opacity .3s, transform .3s;
}

.i-alert.--show {
  --opacity: 1;
  --translate: 0;
  pointer-events: auto;
}

.i-alert.--success {
  --border-color: #48a463;
  --background: #e7f4e4;
  --color: #48a463;
  --icon-background: #48a463;
}

.i-alert.--danger {
  --border-color: #e21c3d;
  --background: #f9dede;
  --color: #e21c3d;
  --icon-background: #e21c3d;
}

.i-alert.--warning {
  --border-color: #fdb81e;
  --background: #fff1d2;
  --color: #fdb81e;
  --icon-background: #fdb81e;
}

.i-alert.--primary {
  --border-color: #312960;
  --background: #312960;
  --color: #fff;
  --icon-background: #312960;
}

.i-alert__icon, .i-alert__close {
  background-color: var(--icon-background);
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author {
  display: flex;
}

.i-author.--compact {
  align-items: center;
}

.i-author.--compact .i-author__description {
  display: none;
}

@media (max-width: 35.999em) {
  .i-author {
    flex-direction: column;
  }
}

.i-author__image {
  --size: 4rem;
  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}

.i-banner {
  background: linear-gradient(to right, #312960, #6248aa);
  border-radius: 1.5rem;
  padding-left: 15rem;
}

@media (max-width: 61.999em) {
  .i-banner {
    padding: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .i-banner {
    text-align: center;
  }

  .i-banner .btn {
    --min-height: 3rem;
    --padding-y: .75rem;
  }
}

.i-banner__header {
  max-width: 110ch;
}

@media (max-width: 61.999em) {
  .i-banner__header {
    max-width: 100%;
  }
}

.i-banner__description {
  line-height: 1.5rem;
}

.i-banner__img {
  max-width: 16.8125rem;
  height: auto;
  transform: translate(-25%, -50%);
}

@media (max-width: 61.999em) {
  .i-banner__img {
    display: none;
  }
}

@media (max-width: 35.999em) {
  .i-banner__buttons {
    width: 100%;
  }
}

.i-breadcrumb__divider {
  align-items: center;
  min-width: 1.5rem;
}

.i-breadcrumb__divider svg {
  width: .375rem;
  height: .5625rem;
}

.i-breadcrumb {
  color: #7868a3;
}

.i-breadcrumb.--homepage .i-breadcrumb__link {
  gap: 0;
}

.i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}

.i-cart-button__button.btn {
  --background: #312960;
}

@media (max-width: 61.999em) {
  .i-cart-button {
    position: static;
  }
}

@media (min-width: 62em) {
  .i-cart-button .container {
    padding: 1rem;
  }
}

.i-cart-button:hover .i-cart-button__button .icon {
  --color: #ff5c50;
}

.i-cart-button.--open .i-cart-button__panel {
  opacity: 1;
  pointer-events: auto;
}

.i-cart-dropdown {
  max-height: min(calc(var(--vh, 1vh) * 100)  - 10rem, 44.5rem);
  width: min(100vw - 1rem, 35.5rem);
  max-height: min(100vh - 10rem, 44.5rem);
}

@media (max-width: 61.999em) {
  .i-cart-dropdown {
    height: calc(calc(var(--vh, 1vh) * 100)  + 4.125rem);
    box-shadow: none;
    z-index: auto;
    background: none;
    border-radius: 0;
    width: 100%;
    height: calc(100vh + 4.125rem);
    max-height: none;
    padding-top: 10.125rem;
    top: 0;
    left: 0;
  }
}

.i-cart-dropdown:not(:has(.i-product-overview)) .i-cart-dropdown__cart-button {
  display: none;
}

@media (max-width: 61.999em) {
  .i-cart-dropdown__title {
    pointer-events: none;
    color: #fff;
    align-items: center;
    width: auto;
    height: 5rem;
    position: absolute;
    top: 4.125rem;
  }

  .i-cart-dropdown__title .base-heading__decoration-after:after {
    display: none;
  }
}

.i-cart-dropdown__cart-list-wrapper {
  overflow: auto;
}

.i-cart {
  --image-size: 5rem;
  --grid-columns: var(--image-size) 2fr 1fr 9.375rem 2.5rem .5fr 2.875rem;
  --grid-areas: "image content availability counter discount price action";
  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  min-height: var(--image-size);
  background-color: #fff;
  border: .0625rem solid #edecf4;
  align-items: center;
  display: grid;
}

.i-cart .c-counter {
  --width: 8rem;
  height: 2.5rem;
  min-height: 2.5rem;
}

.i-cart.--voucher {
  --grid-columns: auto 4fr 1fr 2.875rem;
  --grid-areas: "image content price action";
}

@media (max-width: 74.999em) {
  .i-cart {
    --image-size: 4rem;
    grid-template-columns: var(--image-size) 1fr 1fr 2.875rem;
    grid-template-areas: "image content content content"
                         "availability availability discount discount"
                         "counter counter price action";
  }

  .i-cart.--voucher {
    grid-template-columns: auto 1fr 2.875rem;
    grid-template-areas: "image content content"
                         "price price action";
  }
}

.i-cart__image {
  width: var(--image-size);
  height: var(--image-size);
  grid-area: image;
}

.i-cart__content {
  grid-area: content;
}

.i-cart__content .i-sale-voucher {
  flex-flow: wrap;
  justify-content: start;
  padding-left: .625rem;
}

@media (max-width: 74.999em) {
  .i-cart__content {
    align-self: start;
    max-width: 100%;
    margin-top: .5rem;
  }
}

.i-cart__availability {
  grid-area: availability;
}

.i-cart__counter {
  grid-area: counter;
}

.i-cart__price {
  grid-area: price;
}

.i-cart__discount {
  grid-area: discount;
  justify-self: end;
}

.i-cart__action {
  grid-area: action;
  justify-self: end;
}

.i-cart-list__wrapper {
  max-height: 15.625rem;
}

.i-cart-list__products:not(:has(.i-product-overview)) {
  display: none;
}

.i-cart-step {
  --decorator-size: 2.5rem;
  --flow-marker-size: .0625rem;
  color: #312960;
  text-align: center;
  flex: 0 100%;
  align-items: center;
  font-size: 1.125rem;
  position: relative;
}

.i-cart-step:before {
  content: attr(data-step);
  min-width: var(--decorator-size);
  min-height: var(--decorator-size);
  color: #fff;
  background-color: #312960;
  border-radius: 1.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 1rem #d5d4da4d;
}

.i-cart-step:after {
  content: "";
  top: calc(var(--decorator-size) / 2 - var(--flow-marker-size) / 2);
  height: var(--flow-marker-size);
  z-index: -1;
  background-color: #d7cef0;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
}

.i-cart-step.--disabled {
  color: #7868a3;
}

.i-cart-step.--disabled:before {
  color: #7868a3;
  background-color: #fff;
}

.i-cart-step.--active:before {
  color: #6248aa;
  background-color: #d7cef0;
}

.i-cart-step.--completed:before {
  text-indent: -624.938rem;
  background-image: url("check.3455d650.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
}

.i-cart-step:first-child:after {
  left: 50%;
}

.i-cart-step:last-child:after {
  right: 50%;
}

@media (min-width: 36em) and (max-width: 61.999em) {
  .i-cart-step {
    --decorator-size: 2rem;
    font-size: 1rem;
  }

  .i-cart-step.--completed:before {
    background-size: .75rem;
  }
}

@media (max-width: 35.999em) {
  .i-cart-step {
    --decorator-size: 2.5rem;
    font-size: .875rem;
  }
}

a .i-cart-step__title {
  text-decoration-color: #0000;
  transition: text-decoration-color .3s;
}

a.i-cart-step {
  text-decoration: none;
}

a.i-cart-step:hover .i-cart-step__title {
  text-decoration: underline;
}

.i-category__image-link {
  transform-style: preserve-3d;
  max-width: 8.75rem;
  max-height: 8.75rem;
  transition: transform .3s linear;
}

@media (min-width: 48em) {
  .i-category__image-link {
    max-width: 10rem;
    max-height: 10rem;
  }
}

.i-category__image-link .img {
  border: .25rem solid #fff;
  border-radius: 50%;
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.i-category__image-back-overlay {
  backface-visibility: hidden;
  color: #fff;
  background-color: #6248aacc;
  column-gap: .25rem;
  transform: rotateY(180deg);
}

.i-category__image-wrapper {
  width: min(var(--image-size), 100%);
  height: min(var(--image-size), 100%);
}

.i-category__image-wrapper:hover .i-category__image-link {
  transform: rotateY(180deg);
}

.i-category__image-overlay {
  color: #6248aa;
  background-color: #fff;
  min-width: 3.5rem;
  height: 3.5rem;
  padding-inline: 1rem;
  right: -1.5rem;
}

.i-category__decoration {
  column-gap: .1875rem;
  left: 0;
}

.i-category__decoration:before, .i-category__decoration:after {
  content: "";
  display: block;
}

.i-category__decoration:after {
  background-color: #6248aa;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
}

.i-category__decoration:before {
  background-color: #ff5c50;
  border-radius: 0 1rem 1rem 0;
  width: .5rem;
  height: 1rem;
}

.i-category {
  --image-size: 8.75rem;
}

.i-category.--last .i-category__decoration {
  left: auto;
  right: 0;
  transform: scale(-1);
}

@media (min-width: 48em) {
  .i-category {
    --image-size: 10rem;
  }
}

.i-code {
  border: .0625rem dashed #ff5c50;
  border-radius: .5rem;
  line-height: 1rem;
  overflow-x: hidden;
}

.i-contact-card {
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 2.5rem;
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.i-contact-card__decoration-position .d-small-circles {
  left: unset;
  right: 0;
}

.i-contact-card__content {
  color: #484752;
  line-height: 1.875rem;
}

.i-contact-card__image {
  border-radius: 1rem;
}

.i-contact-pillar {
  --img-size: 5.5rem;
  --shadow: 0 .125rem 1rem 0 #d5d4da4d;
  --min-height: 24.125rem;
  --padding-y: 2.5rem;
  --padding-x: 5rem;
  min-height: var(--min-height);
  box-shadow: var(--shadow);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  background-color: #fff;
  border: .0625rem solid #edecf4;
  border-radius: 1.5rem;
}

.i-contact-pillar.--colored {
  --img-size: 7.5rem;
  --shadow: 0 .25rem 1.875rem .125rem #3132604d;
  --min-height: 29rem;
  background: linear-gradient(to right, #312960, #6248aa);
  border: none;
}

@media (max-width: 93.749em) {
  .i-contact-pillar {
    --padding-y: 2rem;
    --padding-x: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .i-contact-pillar {
    --padding-y: 1.5rem;
    --padding-x: 1rem;
  }
}

.i-contact-pillar__img {
  width: var(--img-size);
  height: var(--img-size);
  background-color: #fff;
  border: .0625rem solid #d7cef0;
  border-radius: 1rem;
  margin-inline: auto;
}

.i-contact-pillar__btn .btn {
  --padding-x: 1rem;
  gap: .25rem;
  min-width: 10rem;
}

.i-contact-pillar__contact .link__icon {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
}

.i-contact-pillar__contact .link__icon:after {
  content: "";
  background-color: #ff5c50;
  border-radius: 3.5rem;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.i-contact-pillar__contact .link__icon .icon {
  z-index: 1;
}

.i-contact-pillar__contact .link__icon svg {
  margin-left: .03125rem;
}

.i-content-toggle__content {
  display: var(--content-display);
}

.i-content-toggle {
  --content-display: none;
}

.i-content-toggle.--open {
  --content-display: block;
}

.i-countdown__value {
  border-radius: .375rem;
  width: 3rem;
  height: 3rem;
}

.i-countdown__colon {
  margin-top: .75rem;
}

.i-countdown__step.--hidden, .i-countdown__step.--hidden + .i-countdown__colon {
  display: none;
}

.i-document {
  color: #edecf4;
  border-style: solid;
  border-width: .0625rem;
  border-radius: .25rem;
}

.i-document__title, .i-document__details {
  color: #312960;
}

.i-faq__body {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  grid-template-rows: 0fr;
  transition: grid-template-rows .5s;
  display: grid;
}

.i-faq__body-inner {
  overflow: hidden;
}

.i-faq__header {
  cursor: pointer;
}

.i-faq__heading {
  line-height: 1.5rem;
  transition: color .3s;
}

.i-faq__toggler {
  background-color: #0000;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  transition: background-color .3s;
}

.i-faq {
  --border-radius: 1rem;
  --shadow: 0 0 0 0 transparent;
  --bg-color: transparent;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  background-color: var(--bg-color);
  border: .0625rem solid #d7cef0;
  transition: box-shadow .3s, background-color .3s;
}

.i-faq:hover {
  --shadow: 0 .25rem .625rem .125rem #7868a31a;
  --bg-color: #fff;
}

.i-faq:hover .i-faq__heading {
  color: #6248aa;
}

.i-faq:hover .i-faq__toggler {
  background-color: #edecf4;
}

.i-faq svg {
  will-change: transform;
  transition: transform .3s;
}

.i-faq.--open {
  --bg-color: transparent;
}

.i-faq.--open .i-faq__toggler svg {
  transform: scale(-1);
}

.i-faq.--open .i-faq__body {
  grid-template-rows: 1fr;
}

.i-filtration__header {
  cursor: pointer;
  background-color: #0000;
  border-radius: 1.5rem;
  min-height: 2.5rem;
  transition: background-color .3s;
}

@media (hover: hover) {
  .i-filtration__header:hover {
    background-color: #edecf4;
  }
}

.i-filtration__title {
  margin-left: -1.125rem;
  transition: margin-left .3s;
}

.i-filtration__body {
  height: var(--height);
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity;
}

.i-filtration__dropdown {
  scale: var(--dropdown-scale);
  transition: scale .3s;
}

.i-filtration__decor {
  opacity: var(--opacity);
  transition: opacity .3s;
}

.i-filtration__range-year .f-base {
  -webkit-appearance: textfield;
  appearance: textfield;
  min-height: 2.5rem;
  padding-block: .625rem;
}

.i-filtration__range-year .f-base::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.i-filtration__range-year .f-base::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .i-filtration__range-inputs {
    flex-direction: column;
  }
}

@media (max-width: 35.999em) {
  .i-filtration__range-inputs {
    flex-direction: column;
  }
}

.i-filtration {
  --dropdown-scale: 1;
  --opacity: 0;
  --height: 0;
  --pointer-events: none;
  overflow: hidden;
}

.i-filtration .f-range-slider {
  padding-top: 2rem;
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .i-filtration .f-range-slider .f-range-slider__inputs {
    flex-direction: column;
  }
}

.i-filtration.--open {
  --dropdown-scale: -1;
  --opacity: 1;
  --height: auto;
  --pointer-events: all;
  overflow: auto;
}

.i-filtration.--open .i-filtration__title {
  margin-left: 0;
}

.i-filtration.--open .i-filtration__body {
  transition: opacity .3s;
}

@media (max-width: 47.999em) {
  .i-filtration-visual {
    flex-direction: column;
  }
}

.i-filtration-visual:not(:has(.i-selected-tag)) {
  display: none;
}

.i-filtration-visual__reset-btn.btn {
  margin: 0 auto;
  padding-block: .375rem;
}

@media (min-width: 36em) {
  .i-information__label {
    flex-shrink: 0;
    width: min(100%, 7rem);
  }
}

.i-information__item {
  --background-color: #f9f9f9;
  border-radius: .5rem;
  row-gap: .5rem;
}

@media (max-width: 35.999em) {
  .i-information__item {
    flex-direction: column;
  }
}

.i-information__item:nth-child(2n) {
  background-color: var(--background-color);
}

.i-information__content {
  max-width: 80ch;
}

.i-information.--background-inverted .i-information__item {
  --background-color: #fff;
}

.i-interpret-category__img {
  width: 3.5rem;
}

.i-interpret-category__img .img {
  z-index: 1;
  border-radius: 50%;
}

.i-interpret-category {
  --background-color: transparent;
  --color: #484752;
  --transform: scale(1);
  background-color: var(--background-color);
  color: var(--color);
  border: none;
  outline: none;
  min-height: 3.5rem;
  padding-block: 0;
  transition: color .3s, background-color .3s;
}

.i-interpret-category:before {
  content: "";
  z-index: 2;
  border: .0625rem solid #d7cef0;
  border-radius: 2.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

@media (hover: hover) {
  .i-interpret-category:hover {
    --background-color: #fff;
    --color: #6248aa;
    --transform: scale(1.3);
    color: var(--color);
  }
}

.i-interpret-category:has(.i-interpret-category__img) {
  padding-left: 0;
}

.i-interpret-category.--checkbox:before {
  transition: border .3s;
}

.i-interpret-category.--active {
  --background-color: transparent;
  --color: #6248aa;
}

.i-interpret-category:has(.i-interpret-category__input:checked) {
  --background-color: transparent;
  --color: #6248aa;
}

.i-interpret-category.--active:before {
  border: .0625rem solid #6248aa;
}

.i-interpret-category:has(.i-interpret-category__input:checked):before {
  border: .0625rem solid #6248aa;
}

.i-interpret-category.--link {
  text-decoration: none;
}

.i-interpret-category.--link:focus {
  color: var(--color);
}

@media (max-width: 61.999em) {
  .i-interpret-category.--lg-hidden {
    display: none;
  }
}

.i-interpret-category__icon {
  width: 2.5rem;
  height: 2.5rem;
}

.i-interpret-category__icon .icon, .i-interpret-category__icon .img {
  transform: var(--transform);
  transition: transform .3s;
}

.i-interpret-category__icon:after {
  content: "";
  background-image: url("spot.fd43364b.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.i-interpret-detail .base-heading:before {
  display: none;
}

.i-interpret-detail__image {
  width: 5.5rem;
}

.i-interpret-detail__image .img {
  border: .25rem solid #fff;
  border-radius: 50%;
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.i-interpret-detail__tags {
  border: .0625rem solid #edecf4;
}

@media (min-width: 36em) and (max-width: 47.999em) {
  .i-interpret-detail__tags {
    align-items: flex-start;
  }
}

@media (max-width: 35.999em) {
  .i-interpret-detail__description {
    text-align: center;
  }
}

.i-interpret-detail__content.entry-content ol, .i-interpret-detail__content.entry-content ul {
  margin-top: 0;
}

@media (max-width: 35.999em) {
  .i-interpret {
    width: min(100%, 30.5rem);
    margin-inline: auto;
  }
}

.i-interpret__image-link {
  transform-style: preserve-3d;
  transition: transform .3s linear;
}

.i-interpret__image-link .img {
  border: .25rem solid #fff;
  border-radius: 50%;
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.i-interpret__image-back-overlay {
  backface-visibility: hidden;
  color: #fff;
  background-color: #6248aacc;
  column-gap: .25rem;
  transform: rotateY(180deg);
}

.i-interpret__image-wrapper {
  width: min(12.75rem, 100%);
}

@media (max-width: 47.999em) {
  .i-interpret__image-wrapper {
    width: min(8rem, 100%);
  }
}

.i-interpret__image-wrapper ~ .base-heading {
  transition: color .3s, transform .3s;
}

.i-interpret__image-wrapper:hover .i-interpret__image-link {
  transform: rotateY(180deg);
}

.i-interpret__image-wrapper:hover ~ .base-heading {
  color: #6248aa;
  transform: translateY(-.5rem);
}

.i-interpret__more-btn.btn {
  --font-size: 1rem;
}

.i-interpret__products-btn {
  min-width: 10rem;
}

.i-intro-banner__inner {
  --max-width: 32.75rem;
  max-width: var(--max-width);
  margin-left: var(--m-left);
}

@media (max-width: 35.999em) {
  .i-intro-banner__inner {
    --max-width: 100%;
  }
}

.i-intro-banner {
  --min-height: 15.875rem;
  min-height: var(--min-height);
  color: var(--font-color);
  text-align: var(--text-align);
}

.i-intro-banner.--light-text {
  --font-color: #fff;
  --text-background: #3c4043b3;
  --blend-mode: multiply;
}

.i-intro-banner.--dark-text {
  --font-color: #312960;
  --text-background: #f9f9f9b3;
  --blend-mode: normal;
}

.i-intro-banner.--left {
  --text-align: left;
}

@media (max-width: 35.999em) {
  .i-intro-banner.--left {
    --text-align: center;
  }
}

.i-intro-banner.--right {
  --text-align: right;
  --m-left: auto;
}

@media (max-width: 35.999em) {
  .i-intro-banner.--right {
    --text-align: center;
    --m-left: 0;
  }
}

.i-intro-banner.--text-background .i-intro-banner__inner:has(.i-intro-banner__inner-background) {
  padding: 1rem;
}

.i-intro-banner.--text-background .i-intro-banner__inner-background {
  background-color: var(--text-background);
  mix-blend-mode: var(--blend-mode);
  z-index: -1;
  width: 100%;
  height: 100%;
  transform: translate(-1rem, -1rem);
}

@media (max-width: 35.999em) {
  .i-intro-banner {
    --min-height: auto;
    padding: 1.5rem;
  }
}

.i-intro-banner__description {
  line-height: 1.5rem;
}

.i-intro-banner__buttons {
  margin-left: var(--m-left);
}

.i-intro-banner__image {
  z-index: -2;
}

.i-media.--video {
  cursor: pointer;
}

.i-medium {
  --height: 2rem;
  height: var(--height);
  min-width: var(--height);
  border-radius: 1.375rem;
  box-shadow: 0 0 0 .0625rem #b1afbd;
}

.i-medium.--compact {
  --height: 1.25rem;
}

.i-medium__image {
  margin-left: .1875rem;
  margin-right: -.25rem;
}

@media (max-width: 61.999em) {
  .i-menu__submenu-button {
    margin-left: auto;
    padding: 1rem;
  }
}

.i-menu__submenu-button:before {
  content: "";
  position: absolute;
  inset: 0;
}

@media (min-width: 62em) {
  .i-menu__submenu-button:before {
    display: none;
  }
}

@media (max-width: 61.999em) {
  .i-menu__submenu-button .img {
    transform: rotate(-90deg);
  }
}

.i-menu__link {
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  gap: .25rem;
  padding: 1.375rem 1.5rem 1.625rem;
  text-decoration: none;
  display: flex;
  position: relative;
}

@media (min-width: 62em) {
  .i-menu__link {
    height: 100%;
  }
}

@media (min-width: 62em) and (max-width: 93.749em) {
  .i-menu__link {
    padding: 1rem .75rem 1.125rem;
    font-size: .875rem;
  }
}

@media (max-width: 61.999em) {
  .i-menu__link {
    min-height: 3.5rem;
    padding: 0 0 0 .5rem;
  }
}

@media (min-width: 62em) {
  .i-menu__link:after {
    content: "";
    transform-origin: bottom;
    background-color: #ff5c50;
    height: .25rem;
    transition: transform .2s;
    display: block;
    position: absolute;
    bottom: -.0625rem;
    left: 0;
    right: 0;
    transform: scaleY(0);
  }
}

@media (max-width: 61.999em) {
  .i-menu__link:before {
    content: "";
    vertical-align: middle;
    background-color: #ff5c50;
    border-radius: 0 50% 50% 0;
    width: .25rem;
    height: .5625rem;
    display: inline-block;
  }
}

.i-menu__link:hover {
  color: #6248aa;
}

.i-menu__link:hover:after {
  transform: scaleY(1);
}

.i-menu__link.--submenu-mobile-only {
  display: none;
}

@media (max-width: 61.999em) {
  .i-menu__link.--submenu-mobile-only {
    min-height: 4.5rem;
    display: flex;
  }
}

.i-menu__link.--submenu-mobile-only .i-menu__submenu-button {
  pointer-events: none;
}

.i-menu__submenu-wrapper {
  width: 100%;
  padding-bottom: 5rem;
  position: absolute;
  left: 0;
}

@media (min-width: 62em) {
  .i-menu__submenu-wrapper {
    opacity: 0;
    pointer-events: none;
    max-height: calc(100vh - var(--menu-bottom-offset));
    max-height: calc(calc(var(--vh, 1vh) * 100)  - var(--menu-bottom-offset));
    background: #f9f9f9;
    border-radius: 0 0 1.5rem 1.5rem;
    padding-bottom: 0;
    transition: opacity .3s;
    top: 100%;
    overflow: auto;
    box-shadow: 0 .375rem .625rem .125rem #7868a333;
  }
}

@media (max-width: 61.999em) {
  .i-menu__submenu-wrapper {
    opacity: 0;
    height: calc(var(--vh, 1vh) * 100);
    pointer-events: none;
    height: 100vh;
    transition: opacity .3s;
    top: 0;
    overflow: hidden;
  }

  @supports (-webkit-touch-callout: none) {
    .i-menu__submenu-wrapper {
      height: -webkit-fill-available;
    }
  }
}

@media (min-width: 62em) {
  .i-menu__submenu {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min-content;
    gap: 1rem 2rem;
    display: grid;
  }
}

@media (max-width: 61.999em) {
  .i-menu__submenu {
    padding: 1rem 0 1.25rem;
  }
}

.i-menu__submenu-header {
  z-index: 10;
  text-transform: uppercase;
  width: calc(100% - 4rem);
  position: sticky;
  top: 0;
}

@media (min-width: 62em) {
  .i-menu.--top-category .i-menu__submenu {
    grid-template-columns: repeat(3, 1fr);
  }

  .i-menu.hover .i-menu__submenu-wrapper {
    opacity: 1;
    pointer-events: auto;
  }
}

@media (max-width: 61.999em) {
  .i-menu.--open .i-menu__submenu-wrapper {
    opacity: 1;
    pointer-events: auto;
    overscroll-behavior: none;
    overflow: auto;
  }
}

.i-menu.--open ~ .i-menu {
  z-index: -1;
}

.i-menu.--active .i-menu__link {
  color: #ff5c50;
}

@media (min-width: 93.75em) {
  .i-menu.--sm .i-menu__link {
    font-size: 1rem;
  }
}

@media (min-width: 62em) and (max-width: 93.749em) {
  .i-menu.--sm .i-menu__link {
    padding: 1rem .5rem 1.125rem;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-menu.--sm .i-menu__link {
    gap: .125rem;
    font-size: .75rem;
  }

  .i-menu.--sm .i-menu__link .i-menu__submenu-button {
    width: 1.125rem;
  }
}

.i-menu__submenu-content {
  grid-template-areas: "lists"
                       "top-category";
}

@media (min-width: 62em) {
  .i-menu__submenu-content {
    grid-template-areas: "lists lists lists top-category";
  }
}

.i-menu__submenu-lists {
  grid-area: lists;
}

.i-menu__submenu-top-category {
  grid-area: top-category;
  min-height: 16.5rem;
  max-height: 30rem;
}

.i-menu__submenu-top-category .i-top-category {
  height: 100%;
}

.i-method {
  --min-height: 2rem;
  --min-width: 3rem;
  --padding-y: .125rem;
  --padding-x: .25rem;
  height: var(--min-height);
  width: var(--min-width);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  background-color: #fff;
  border: .0625rem solid #d5d4da;
  border-radius: .25rem;
}

@media (max-width: 47.999em) {
  .i-tab-control.--size-sm {
    --font-size: .75rem;
    --line-height: 1rem;
    --padding: .25rem .5rem;
  }
}

@media (max-width: 61.999em) {
  .i-tab-control.--size-md {
    --font-size: .875rem;
    --line-height: 1rem;
    --padding: .5rem;
    min-width: 3rem;
  }
}

.i-new-products {
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

@media (max-width: 47.999em) {
  .i-new-products {
    padding-inline: .5rem;
  }
}

.i-new-products__slider-buttons {
  top: 6.625rem;
  left: -1.625rem;
  right: -1.625rem;
}

@media (max-width: 35.999em) {
  .i-new-products__slider-buttons {
    display: none;
  }

  .i-new-products__slider .swiper-wrapper {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    display: grid;
  }
}

.i-new-products__slider .swiper-slide {
  cursor: pointer;
  height: auto;
}

.i-new-products__slider.swiper {
  overflow-clip-margin: .5rem;
  overflow: clip;
}

.i-new-products__slider.swiper:not(.swiper-initialized) .swiper-wrapper {
  gap: 1rem;
}

@media (min-width: 36em) {
  .i-new-products__slider.swiper:not(.swiper-initialized) .swiper-slide {
    flex: 0 0 12rem;
    width: 12rem;
  }
}

@media (max-width: 61.999em) {
  .i-new-products__header {
    justify-content: center;
  }
}

.i-new-products__tab-controls {
  justify-content: center;
}

.i-new-products__button.--hidden {
  display: none;
}

.i-notification {
  color: #fff;
  background-color: #ff5c50;
  min-height: 2.5rem;
}

@media (max-width: 35.999em) {
  .i-notification {
    text-align: center;
  }
}

@media (max-width: 61.999em) {
  .i-notification:has( ~ .m-header.--menu-open,  ~ .m-header.--user-open,  ~ .m-header.--cart-open) {
    display: none;
  }
}

.i-notification__container {
  width: min(100% - 2rem, 52rem);
}

@media (max-width: 35.999em) {
  .i-notification__container {
    flex-direction: column;
    gap: .5rem;
  }

  .i-notification__icon {
    margin-inline: auto;
  }

  .i-notification__close {
    order: -1;
  }
}

.i-order-info__icon {
  height: 1.5rem;
}

.i-order {
  border: .0625rem solid #d7cef0;
}

.i-order-column {
  --images-col: 10rem;
  --status-col: 10rem;
  --id-col: 8.75rem;
  --date-col: 8.75rem;
  --price-col: 1fr;
  grid-template-columns: var(--images-col) var(--status-col) var(--id-col) var(--date-col) var(--price-col) 3rem;
  width: max-content;
  min-width: 100%;
}

@media (min-width: 75em) and (max-width: 93.749em) {
  .i-order-column {
    --date-col: 6.25rem;
  }
}

.i-order__products-count {
  width: 3rem;
  height: 3rem;
}

.i-overview.--default {
  flex-wrap: wrap;
  align-items: center;
}

.i-overview.--default .i-overview__title, .i-overview.--default .i-overview__subtitle {
  font-size: .75rem;
}

.i-overview.--default .i-overview__label {
  flex-grow: 1;
  width: 100%;
}

.i-overview.--default .i-overview__description {
  min-width: 12.75rem;
  max-width: calc(100% - 8rem);
}

.i-overview.--compact {
  justify-content: space-between;
}

.i-overview.--compact .i-overview__title, .i-overview.--compact .i-overview__subtitle {
  font-size: .875rem;
}

.i-overview__image {
  --image-size: 3.5rem;
  flex: 0 0 var(--image-size);
  max-width: var(--image-size);
  height: var(--image-size);
}

.i-overview__price {
  margin-left: auto;
}

@media (min-width: 62em) {
  .i-post.--hero {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    display: grid;
  }
}

@media (max-width: 61.999em) {
  .i-post.--hero {
    max-width: 43rem;
    margin-inline: auto;
  }
}

.i-post.--hero .i-post__tags {
  margin-top: 0;
}

@media (min-width: 62em) {
  .i-post.--hero .i-post__content-col {
    padding-block: 2rem;
  }
}

.i-post.--hero .i-post__content {
  justify-content: center;
}

@media (min-width: 62em) {
  .i-post.--inline {
    grid-template-columns: minmax(10rem, 27.875rem) auto;
    gap: 2rem;
    display: grid;
  }

  .i-post.--inline .i-post__content {
    max-width: 70ch;
  }
}

@media (max-width: 61.999em) {
  .i-post {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-product-inline__link.link {
  --font-size: 1rem;
  --line-height: 1.5rem;
  --lines: 1;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: Inter, Adjusted Verdana, sans-serif;
  text-decoration: none;
}

.i-product-inline__link.link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.i-product-inline__order {
  background-color: #fff;
  border: .0625rem solid #edecf4;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.i-product-inline__image {
  grid-area: image;
}

.i-product-inline__header {
  grid-area: header;
  margin-bottom: .0625rem;
}

.i-product-inline__mediums {
  pointer-events: none;
  grid-area: mediums;
}

.i-product-inline__mediums > .i-medium, .i-product-inline__mediums .g-mediums__show-more, .i-product-inline__mediums:hover .g-mediums {
  pointer-events: auto;
}

.i-product-inline__availability {
  grid-area: availability;
}

.i-product-inline__discount {
  grid-area: discount;
}

.i-product-inline__price {
  grid-area: price;
}

.i-product-inline__cart-add {
  z-index: 10;
  grid-area: button;
  justify-self: flex-end;
}

.i-product-inline__content {
  grid-template-columns: 3.5rem 1fr 4rem minmax(6rem, auto);
  grid-template-areas: "image header discount price"
                       "image mediums discount price";
}

.i-product-inline {
  border: .0625rem solid #edecf4;
  padding-block: .65625rem;
  transition: background-color .2s;
}

.i-product-inline:has(.i-product-inline__link:hover) {
  background: #fff;
}

.i-product-inline:has(.i-product-inline__link:hover) .i-product__image-link img {
  transform: scale(1.03);
}

.i-product-inline.--top {
  padding-left: 1.5rem;
}

.i-product-inline.--top .i-product-inline__content {
  grid-template-columns: 3.5rem 1fr 1fr 4rem minmax(6rem, auto) auto;
  grid-template-areas: "image header header discount price button"
                       "image mediums availability discount price button";
}

@container (width <= 480px) {
  .i-product-inline .i-product-inline__content {
    grid-template-columns: 3.5rem 1fr auto 2rem;
    grid-template-areas: "image header header discount"
                         "mediums mediums price price";
    row-gap: .5rem;
  }

  .i-product-inline .i-product-inline__order {
    top: 1.75rem;
  }

  .i-product-inline .i-product-inline__link.link {
    --lines: 3;
    --font-size: .875rem;
    --line-height: 1rem;
  }

  .i-product-inline .i-product-inline__price.i-price {
    --font-size-main: .875rem;
    --line-height-main: 1rem;
    --font-size-base: .75rem;
    --line-height-base: 1rem;
    --font-size-before: .75rem;
    --line-height-before: .875rem;
  }

  .i-product-inline .i-product-inline__discount.i-discount {
    --size: 2rem;
    --font-size: .75rem;
    margin: 0;
  }

  .i-product-inline.--top .i-product-inline__content {
    grid-template-columns: 3.5rem 1fr auto 2rem;
    grid-template-areas: "image header header discount"
                         "mediums mediums price price"
                         "availability availability button button";
  }
}

.i-product.--horizontal {
  border-radius: 1rem;
  width: 100%;
}

.i-product.--horizontal:before {
  top: .5rem;
  left: 6.125rem;
}

.i-product.--horizontal .i-product__body {
  grid-template-columns: 7.5rem 1fr 2.5rem;
  grid-template-areas: "image header header"
                       "image content buttons";
  gap: 1rem .5rem;
  padding: .5rem;
  display: grid;
}

.i-product.--horizontal .i-product__content {
  grid-template-columns: 1fr auto;
  grid-template-areas: "mediums price"
                       "availability price";
  align-items: center;
  gap: .5rem;
  margin-right: .5rem;
}

.i-product.--horizontal .i-product__image {
  align-self: start;
}

.i-product.--horizontal .i-product__buttons {
  justify-content: flex-end;
  align-items: flex-end;
}

.i-product.--horizontal .i-product__price {
  align-self: end;
}

.i-product.--horizontal .i-product__cart-add {
  --min-height: 2.5rem;
  --padding-x: .5rem;
  --padding-y: .5rem;
}

.i-product.--horizontal .i-product__cart-add .btn__title, .i-product.--horizontal .i-product__wishlist, .i-product.--horizontal .i-product__discount, .i-product.--horizontal .i-product__tags {
  display: none;
}

@container (width <= 400px) {
  .i-product.--horizontal:before {
    top: .5rem;
    left: 3.5rem;
  }

  .i-product.--horizontal .i-product__body {
    grid-template-columns: 5rem 1fr 2.5rem;
  }

  .i-product.--horizontal .i-product__content {
    grid-template-columns: 4rem 1fr;
    grid-template-areas: "mediums mediums"
                         "price price";
    gap: .75rem .5rem;
    min-height: 5rem;
  }

  .i-product.--horizontal .i-product__availability {
    --font-size-title: .75rem;
    --font-size: .75rem;
    max-width: 7rem;
    position: absolute;
    bottom: .5rem;
    left: .5rem;
  }
}

.i-product.--horizontal.--hidden-buttons .i-product__body {
  grid-template-areas: "image header header"
                       "image content content";
}

.i-product.--horizontal.--hidden-buttons .i-product__price {
  padding-right: 0;
}

@media (max-width: 35.999em) {
  .i-tag.--sm {
    --min-height: 1.5rem;
    --min-width: 2.5rem;
    --font-size: .75rem;
  }
}

@media (max-width: 35.999em) {
  .i-tag.--md {
    --min-height: 1.5rem;
    --min-width: 2.5rem;
    --font-size: .75rem;
  }
}

.i-product.--offer {
  border: none;
}

.i-product.--offer .i-product__body {
  grid-template-columns: 9rem 1fr;
  grid-template-areas: "image header"
                       "content content";
  gap: .5rem;
  height: auto;
  padding: .5rem;
  display: grid;
}

.i-product.--offer .i-product__content {
  grid-template-columns: 1fr auto;
  grid-template-areas: "mediums price"
                       "availability price";
  align-items: center;
  gap: .5rem;
}

.i-product.--offer .i-product__discount.i-discount {
  --size: 2rem;
  --font-size: .75rem;
  top: auto;
  bottom: 0;
}

.i-product.--offer .i-product__buttons {
  display: none;
}

.i-product.--offer .i-product__header {
  align-self: center;
}

.i-product.--offer .i-product__title .link {
  --lines: 6;
  --font-size: .875rem;
  --line-height: 1rem;
}

.i-product.--offer .i-product__tags {
  width: calc(100% - 1.5rem);
}

.i-product.--offer .i-product__tag.i-tag {
  --padding-x: .25rem;
  --padding-y: 0;
  --min-height: 1rem;
  --font-size: .75rem;
  --line-height: 1rem;
}

.i-product.--offer .i-product__price {
  --font-size-main: .875rem;
  --line-height-main: 1rem;
  --font-size-base: .75rem;
  --line-height-base: 1rem;
  --font-size-before: .75rem;
  --line-height-before: .875rem;
  align-self: end;
}

@media (min-width: 36em) {
  .i-product.--vertical .i-product__header {
    flex-grow: 1;
  }

  .i-product.--vertical .i-product__content {
    flex-grow: 0;
  }
}

.i-product.--wishlist {
  border-radius: 1rem;
}

.i-product.--wishlist:before {
  top: .5rem;
  left: 6.125rem;
}

.i-product.--wishlist .i-product__header {
  align-self: end;
}

.i-product.--wishlist .i-product__subtitle {
  display: block;
}

.i-product.--wishlist .i-product__body {
  grid-template-columns: 11rem 1fr 14.0625rem;
  grid-template-areas: "image header buttons"
                       "image content buttons";
  display: grid;
}

.i-product.--wishlist .i-product__content {
  grid-template-columns: 1fr auto;
  grid-template-areas: "mediums price"
                       "availability price";
  align-self: start;
  gap: .5rem;
  margin-right: 1rem;
}

.i-product.--wishlist .i-product__image {
  align-self: start;
}

.i-product.--wishlist .i-discount {
  top: auto;
  bottom: 0;
}

.i-product.--wishlist .i-product__buttons {
  flex-direction: column;
  justify-content: center;
}

.i-product.--wishlist .i-product__price {
  align-self: start;
}

.i-product.--wishlist .i-add-wishlist-button__button {
  width: 100%;
}

.i-product.--wishlist .i-product__cart-add {
  --min-height: 3rem;
  --padding-x: .5rem;
  --padding-y: .5rem;
  flex-grow: 0;
}

@media (min-width: 48em) {
  .i-product.--wishlist {
    width: 100%;
  }
}

@media (min-width: 48em) and (max-width: 61.999em) {
  .i-product.--wishlist .i-product__buttons {
    flex-direction: row;
  }

  .i-product.--wishlist .i-product__buttons > * {
    flex-grow: 1;
  }
}

@media (max-width: 61.999em) {
  .i-product.--wishlist .i-product__body {
    grid-template-columns: 11rem 1fr;
    grid-template-areas: "image header"
                         "image content"
                         "buttons buttons";
  }
}

@media (max-width: 47.999em) {
  .i-product.--wishlist:before {
    top: .5rem;
    left: 3.5rem;
  }

  .i-product.--wishlist .i-product__body {
    grid-template-columns: 5rem 1fr;
    grid-template-areas: "image image"
                         "header header"
                         "content content"
                         "buttons buttons";
  }

  .i-product.--wishlist .i-product__content {
    grid-template-columns: 4rem 1fr;
    grid-template-areas: "mediums mediums"
                         "availability price";
    gap: .75rem .5rem;
  }
}

.i-discount {
  aspect-ratio: 1;
  text-align: center;
  min-width: var(--size);
  min-height: var(--size);
  font-size: var(--font-size);
  background-color: #f43e80;
  border-radius: 50%;
}

.i-discount.--size-sm {
  --size: 2rem;
  --font-size: .75rem;
}

.i-discount.--size-md {
  --size: 2.5rem;
  --font-size: .875rem;
}

.i-discount.--size-lg {
  --size: 4rem;
  --font-size: 1.125rem;
}

.i-price {
  text-align: right;
  flex: none;
  column-gap: .125rem;
}

.i-price .i-price__main {
  font-size: var(--font-size-main);
  line-height: var(--line-height-main);
}

.i-price .i-price__base {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}

.i-price .i-price__before {
  font-size: var(--font-size-before);
  line-height: var(--line-height-before);
}

.i-price.--size-lg {
  --font-size-main: 1.125rem;
  --line-height-main: 1.5rem;
  --font-size-base: .75rem;
  --line-height-base: .875rem;
  --font-size-before: .875rem;
  --line-height-before: 1rem;
}

.i-price.--size-sm {
  --font-size-main: .875rem;
  --line-height-main: 1rem;
  --font-size-base: .75rem;
  --line-height-base: 1rem;
  --font-size-before: .75rem;
  --line-height-before: .875rem;
}

.i-price__title {
  text-align: left;
  flex: 1;
}

.i-price__title:after {
  content: ":";
}

@media (max-width: 35.999em) {
  .i-tag.--sm {
    --min-height: 1.5rem;
    --min-width: 2.5rem;
    --font-size: .75rem;
  }
}

@media (max-width: 35.999em) {
  .i-tag.--md {
    --min-height: 1.5rem;
    --min-width: 2.5rem;
    --font-size: .75rem;
  }
}

.i-availability__description {
  padding: var(--p-description);
  border: var(--description-border);
  background-color: var(--description-background-color);
  color: #312960;
}

.i-availability__title {
  column-gap: var(--title-gap);
  font-size: var(--font-size-title);
}

.i-availability__title:before {
  content: "";
  width: var(--dot-size);
  height: var(--dot-size);
  background-color: var(--background-color);
  box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
  border-radius: 50%;
  flex-shrink: 0;
  margin: .125rem;
  display: inline-block;
}

.i-availability.--available {
  --background-color: #57bc3d;
  --shadow-color: #57bc3d80;
  --border-color: #57bc3d;
}

.i-availability.--unavailable {
  --background-color: #ff2616;
  --shadow-color: #ff261680;
  --border-color: #ff2616;
}

.i-availability.--preorder {
  --background-color: #0dc5ed;
  --shadow-color: #0dc5ed80;
  --border-color: #0dc5ed;
}

.i-availability.--ended {
  --background-color: #484752;
  --shadow-color: #48475280;
  --border-color: #484752;
}

.i-availability.--enroute {
  --background-color: #ff7629;
  --shadow-color: #ff762980;
  --border-color: #ff7629;
}

.i-availability.--custom-color {
  --background-color: var(--custom-color);
  --shadow-color: rgba(var(--custom-color), .5);
  --border-color: var(--custom-color);
}

.i-availability.--size-xs {
  --dot-size: .375rem;
  --title-gap: .25rem;
  --font-size: .75rem;
  --font-size-title: .75rem;
  --description-border: 0 none;
  --description-background-color: #edecf4;
  --line-height: 1rem;
  --shadow-blur: .125rem;
  --shadow-spread: .0625rem;
  --p-description: .125rem .375rem;
}

.i-availability.--size-xs .icon {
  display: none;
}

.i-availability.--size-xs .i-availability__description {
  font-size: var(--font-size-title);
}

.i-availability.--size-sm {
  --dot-size: .5rem;
  --title-gap: .25rem;
  --font-size: .875rem;
  --font-size-title: .875rem;
  --description-border: 0 none;
  --description-background-color: #edecf4;
  --line-height: 1rem;
  --shadow-blur: .125rem;
  --shadow-spread: .0625rem;
  --p-description: .125rem .5rem;
}

.i-availability.--size-sm .icon {
  display: none;
}

.i-availability.--size-lg {
  --dot-size: 1rem;
  --title-gap: .5rem;
  --font-size: 1rem;
  --font-size-title: 1.125rem;
  --description-border: .125rem solid var(--background-color);
  --description-background-color: #fff;
  --line-height: 1rem;
  --shadow-blur: .5rem;
  --shadow-spread: .125rem;
  --p-description: .375rem .75rem .375rem .625rem;
}

.i-availability.--size-lg .i-availability__title {
  color: var(--background-color);
  margin-left: .25rem;
  font-weight: 700;
}

.i-availability.--mini .i-availability__quantity, .i-availability.--mini .i-availability__description {
  display: none;
}

.i-product__image {
  border-radius: 1rem;
  grid-area: image;
}

.i-product__header, .i-product__variants {
  grid-column: span 2;
}

.i-product__header {
  grid-area: header;
}

.i-product__link.link {
  --font-size: 1rem;
  --line-height: 1.5rem;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: Inter, Adjusted Verdana, sans-serif;
  text-decoration: none;
}

.i-product__link.link:hover {
  color: #6248aa;
}

.i-product__link.link:before {
  content: "";
  z-index: 2;
  position: absolute;
  inset: 0;
}

.i-product__content {
  grid-area: content;
  grid-template-columns: 1fr auto;
  grid-template-areas: "mediums mediums"
                       "availability price";
}

.i-product__mediums {
  pointer-events: none;
  grid-area: mediums;
}

.i-product__mediums > .i-medium, .i-product__mediums .g-mediums__show-more, .i-product__mediums:hover .g-mediums {
  pointer-events: auto;
}

.i-product__price {
  grid-area: price;
}

.i-product__availability {
  grid-area: availability;
}

.i-product__buttons {
  grid-area: buttons;
}

.i-product__image-thumbnail {
  width: 7.5rem;
  height: 7.5rem;
}

.i-product__cart-add, .i-product__wishlist {
  z-index: 10;
}

.i-product__cart-add {
  flex: 1;
}

.i-product__tags {
  pointer-events: none;
  width: calc(100% - 3.5rem);
}

.i-product {
  border: .0625rem solid #d7cef0;
  border-radius: 1rem;
  width: min(100%, 20.5rem);
  transition: box-shadow .2s, background-color .2s;
}

@media (max-width: 47.999em) {
  .i-product {
    width: 100%;
  }
}

.i-product:hover {
  z-index: 15;
}

.i-product:has(.i-product__link:hover) {
  background: #fff;
}

.i-product:has(.i-product__link:hover) .i-product__image-link img {
  transform: scale(1.03);
}

.i-product.--hidden-buttons .i-product__buttons {
  display: none;
}

@container (width <= 224px) {
  .i-product .g-mediums.--toggle {
    inset: auto 50% -.0625rem auto;
    transform: translateX(50%);
  }

  .i-product .i-product__body {
    border-radius: 1rem;
    padding: .5rem;
  }

  .i-product .i-product__link.link {
    --font-size: .875rem;
    --line-height: 1rem;
  }

  .i-product .i-product__content {
    grid-template-columns: 1fr;
    grid-template-areas: "mediums"
                         "availability"
                         "price";
  }

  .i-product .i-product__buttons {
    gap: .5rem;
  }

  .i-product .i-product__buttons .btn__title {
    display: none;
  }

  .i-product .i-product__price {
    --font-size-main: .875rem;
    --line-height-main: 1rem;
    --font-size-base: .75rem;
    --line-height-base: 1rem;
    --font-size-before: .75rem;
    --line-height-before: .875rem;
  }

  .i-product .i-product__discount {
    --size: 2rem;
    --font-size: .75rem;
    top: auto;
    bottom: 0;
  }

  .i-product .i-product__tag.i-tag {
    --padding-x: .25rem;
    --padding-y: 0;
    --min-height: 1rem;
    --font-size: .75rem;
    --line-height: 1rem;
  }

  .i-product .i-product__availability {
    --dot-size: .375rem;
    --title-gap: .25rem;
    --font-size: .75rem;
    --font-size-title: .75rem;
    --description-border: 0 none;
    --description-background-color: #edecf4;
    --line-height: 1rem;
    --shadow-blur: .125rem;
    --shadow-spread: .0625rem;
    --p-description: .125rem .375rem;
  }
}

.i-product-offer__header:after, .i-product-offer__header:before {
  content: "";
  background: url("decor-after.18844e5e.svg") center no-repeat;
  width: 1.3125rem;
  height: .5625rem;
  display: block;
}

.i-product-offer {
  border: .0625rem solid #d7cef0;
  transition: background-color .2s, opacity .2s, transform .2s;
}

.i-product-offer.--hidden {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-3rem);
}

@container (width <= 312px) {
  .i-product-offer .i-product-offer__body {
    padding: .5rem;
  }
}

.i-product-order {
  --image-size: 5.5rem;
  --grid-columns: var(--image-size) 18.75rem 3.125rem 6.25rem auto;
  --grid-areas: "image content count price action";
  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  min-height: var(--image-size);
  align-items: center;
  display: grid;
}

.i-product-order .c-counter {
  --width: 8rem;
  height: 2.5rem;
  min-height: 2.5rem;
}

@media (max-width: 93.749em) {
  .i-product-order {
    --image-size: 4rem;
    grid-template-columns: var(--image-size) 3fr 1fr;
    grid-template-areas: "image content count"
                         "price price price"
                         "action action action";
  }
}

@media (max-width: 35.999em) {
  .i-product-order {
    grid-template-areas: "image content content"
                         "count price price"
                         "action action action";
  }
}

.i-product-order__image {
  width: var(--image-size);
  height: var(--image-size);
  grid-area: image;
}

.i-product-order__content {
  grid-area: content;
}

@media (max-width: 74.999em) {
  .i-product-order__content {
    align-self: start;
    max-width: 100%;
    margin-top: .5rem;
  }
}

.i-product-order__count {
  text-align: right;
  grid-area: count;
}

@media (max-width: 35.999em) {
  .i-product-order__count {
    text-align: left;
  }
}

.i-product-order__price {
  grid-area: price;
}

.i-product-order__actions {
  flex-wrap: wrap;
  grid-area: action;
  justify-self: end;
}

.i-product-overview__image {
  width: 3.5rem;
  height: 3.5rem;
}

.i-product-overview__action {
  margin-left: -.5rem;
}

@media (min-width: 48em) {
  .i-product-overview__count {
    flex: 0 0 3.125rem;
  }
}

.i-product-overview .i-sale-voucher {
  --padding-x: .5rem;
  flex-flow: wrap;
  justify-content: start;
}

@media (max-width: 395px) {
  .i-product-review .i-product-review__rating {
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
  }
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-product-review:not(.--compact) .i-product-review__rating {
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
  }
}

.i-progress {
  --progress-color: linear-gradient(to right, #312960, #6248aa);
}

.i-progress.--complete .i-progress__icon path {
  fill: #e93d30;
}

@media (max-width: 61.999em) {
  .i-progress__header {
    align-self: center;
    width: min(100%, 16rem);
  }
}

.i-progress__input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #d7cef0;
  border: none;
  border-radius: .25rem;
  width: 100%;
  height: .5rem;
  display: block;
  overflow: hidden;
}

.i-progress__input::-webkit-progress-bar {
  background: none;
}

.i-progress__input::-webkit-progress-value {
  background-image: var(--progress-color);
}

.i-progress__input::-moz-progress-bar {
  background-image: var(--progress-color);
}

.i-progress__icon-decoration {
  margin-right: -.5rem;
}

.i-promo__button {
  inset: auto 1rem 1rem;
}

.i-promo__button:after {
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  height: 100vh;
  display: block;
  position: absolute;
  bottom: -1rem;
  left: -1rem;
}

@supports (-webkit-touch-callout: none) {
  .i-promo__button:after {
    height: -webkit-fill-available;
  }
}

.i-rating .base-heading:before {
  display: none;
}

.i-rating.--vertical {
  flex-direction: row-reverse;
}

@media (min-width: 62em) {
  .i-rating.--vertical {
    flex-direction: column;
  }
}

.i-rating.--horizontal {
  flex-direction: row-reverse;
  gap: 1rem;
}

.i-rating.--horizontal .base-heading {
  font-size: 1.5rem;
}

.i-reclamation__collapsible-content {
  --body-inner-overflow: hidden;
  overflow: var(--body-inner-overflow);
}

.i-reclamation {
  --transition-duration: .3s;
  --product-tile-height: 7.5rem;
  --border-color: transparent;
  grid-template-rows: var(--product-tile-height) 0fr;
  transition: grid-template-rows var(--transition-duration) ease;
  border: .0625rem solid var(--border-color);
}

.i-reclamation.--expanded {
  --border-color: #edecf4;
  grid-template-rows: var(--product-tile-height) 1fr;
}

.i-reclamation.--after-expanded .i-reclamation__collapsible-content {
  --body-inner-overflow: visible;
}

@media (max-width: 61.999em) {
  .i-reclamation {
    --product-tile-height: 10rem;
  }
}

@media (max-width: 47.999em) {
  .i-reclamation {
    --product-tile-height: 11.25rem;
  }
}

@media (max-width: 35.999em) {
  .i-reclamation {
    --product-tile-height: 13.75rem;
  }
}

.reclamation__product-tile {
  --image-size: 5.5rem;
  --grid-columns: var(--image-size) 3fr 1fr .5fr;
  --grid-areas: "image content counter price";
  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  min-height: var(--image-size);
}

.reclamation__product-tile .c-counter {
  width: 9.375rem;
}

@media (max-width: 74.999em) {
  .reclamation__product-tile {
    --grid-columns: var(--image-size) 3fr 1fr 1fr;
  }
}

@media (max-width: 61.999em) {
  .reclamation__product-tile {
    --image-size: 4rem;
    grid-template-columns: var(--image-size) 1fr 1fr;
    grid-template-areas: "image content content"
                         "image counter price";
  }
}

@media (max-width: 35.999em) {
  .reclamation__product-tile {
    grid-template-columns: var(--image-size) 1fr;
    grid-template-areas: "image content"
                         "image counter"
                         "image price";
  }
}

.i-reclamation__image {
  width: var(--image-size);
  height: var(--image-size);
  grid-area: image;
}

.i-reclamation__content {
  grid-area: content;
}

.i-reclamation__counter {
  grid-area: counter;
}

.i-reclamation__price {
  grid-area: price;
}

.i-review {
  row-gap: .25rem;
}

@media (min-width: 62em) {
  .i-review {
    column-gap: 1.5rem;
  }
}

@media (min-width: 75em) {
  .i-review {
    flex-direction: row;
  }
}

.i-review__icon-logo {
  --w: 6.5rem;
  --h: 3.5rem;
  flex: 0 0 var(--w);
  max-width: var(--w);
  height: var(--h);
}

.i-review__stars {
  column-gap: .25rem;
}

.i-review__stars .icon {
  --size: 1rem;
}

@media (min-width: 48em) {
  .i-review__stars .icon {
    --size: 1.5rem;
    column-gap: .5rem;
  }
}

@media (min-width: 75em) {
  .i-review__rating {
    justify-content: start;
  }
}

.i-review__details .i-review__details-tag {
  color: #312960;
  --min-height: none;
  --padding-x: .625rem;
  max-height: 1.5rem;
  font-weight: 700;
}

@media (min-width: 75em) {
  .i-review__details {
    justify-content: start;
  }
}

.i-sale-voucher {
  --padding-y: 1rem;
  --padding-x: 5rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  overflow: hidden;
}

@media (max-width: 93.749em) {
  .i-sale-voucher {
    --padding-x: 2.5rem;
  }
}

@media (max-width: 61.999em) {
  .i-sale-voucher {
    --padding-x: 1rem;
    flex-direction: column;
    justify-content: center;
  }
}

.i-sale-voucher__title {
  -webkit-user-select: none;
  user-select: none;
  line-height: 1rem;
}

@media (min-width: 62em) {
  .i-search-dropdown {
    border-top: .0625rem solid #d7cef0;
  }
}

@media (max-width: 47.999em) {
  .i-search-dropdown {
    max-height: none;
  }
}

.i-search-dropdown__link {
  text-align: right;
}

@media (min-width: 62em) and (max-width: 74.999em) {
  .i-search-dropdown__link {
    font-size: .875rem;
  }
}

.btn.i-selected-tag {
  --border-radius: 1.4375rem;
  --font-weight: 500;
  text-transform: none;
  background-color: #edecf4;
}

@media (hover: hover) {
  .btn.i-selected-tag:hover {
    box-shadow: none;
  }
}

.i-signpost .img {
  border-radius: 1.5rem;
}

.i-signpost .i-signpost__wrapper {
  align-self: center;
  width: calc(100% - 2rem);
  min-height: 10.6875rem;
  display: flex;
  bottom: 1rem;
}

.i-signpost .i-signpost__body-background {
  mix-blend-mode: multiply;
  z-index: 1;
  background-color: #484752e8;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.i-signpost .i-signpost__body {
  z-index: 4;
  width: 100%;
}

.i-signpost .base-heading {
  min-height: 4.4375rem;
}

@media (hover: hover) {
  .i-signpost:hover img {
    transform: scale(1.03);
  }
}

.i-similar-interprets__title {
  overflow-wrap: break-word;
  word-break: break-all;
  padding-block: .25rem;
  transition: color .3s;
}

.i-similar-interprets {
  --min-height: 3.5rem;
  --radius: 2.5rem;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--radius);
  min-height: var(--min-height);
  min-width: var(--min-height);
  border: .0625rem solid #d7cef0;
  padding-right: .75rem;
  text-decoration: none;
  transition: box-shadow .3s;
}

@media (hover: hover) {
  .i-similar-interprets:hover {
    box-shadow: 0 .25rem 1rem .0625rem #6248aa33;
  }

  .i-similar-interprets:hover .i-similar-interprets__title {
    color: #6248aa;
  }
}

.i-similar-interprets__img {
  width: var(--min-height);
  height: var(--min-height);
}

.i-social.--lg .btn {
  --border-radius: 1rem;
  --min-height: 4rem;
}

.i-social.--sm .btn {
  --border-radius: 50%;
  --min-height: 1.5rem;
}

.i-sort-header {
  --arrow-left-display: visible;
  --arrow-right-display: visible;
}

.i-sort-header.--asc {
  --arrow-left-display: hidden;
}

.i-sort-header.--desc {
  --arrow-right-display: hidden;
}

.i-sort-header .s-sort-left {
  visibility: var(--arrow-left-display);
}

.i-sort-header .s-sort-right {
  visibility: var(--arrow-right-display);
}

.i-status {
  --font-color: colors. $ black;
  color: var(--font-color);
  background-color: #c7e9f4;
  justify-content: center;
  align-items: center;
  min-height: 1.5rem;
  padding: .0625rem .625rem;
  display: inline-flex;
}

.i-status.--success {
  --font-color: #081828;
  background-color: #cfe9a4;
}

.i-status.--error {
  --font-color: #fff;
  background-color: #ff2616;
}

.i-status.--info {
  --font-color: #081828;
  background-color: #c7e9f4;
}

.i-status.--warning {
  --font-color: #081828;
  background-color: #ffdc61;
}

@media (min-width: 62em) {
  .i-submenu__category-items {
    margin-bottom: .25rem;
    padding-left: 2.25rem;
  }
}

.i-submenu__category-item.--hidden {
  display: none;
}

.i-submenu__show-more {
  margin-top: .25rem;
}

.i-submenu__show-more.--hidden {
  display: none;
}

.i-submenu__link {
  background-color: var(--background);
  text-decoration: none;
  transition: color .2s;
}

.i-submenu__link:hover {
  color: #ff5c50;
}

.i-submenu__label {
  background-color: #f43e80;
  padding-block: .125rem;
}

.i-submenu {
  grid-column: span 1;
}

.i-submenu.--color-label-1 {
  --background: #f43e80;
}

.i-submenu.--color-label-2 {
  --background: #f9af79;
}

.i-submenu.--color-label-3 {
  --background: #c7e9f4;
}

.i-submenu.--color-label-4 {
  --background: #ffdc61;
}

.i-submenu.--color-label-5 {
  --background: #cfe9a4;
}

.i-submenu.--active .i-submenu__link {
  color: #e93d30;
}

.i-submenu.--has-items {
  grid-row: span 3;
}

.i-support__perex {
  word-wrap: break-word;
}

.i-support__img {
  --max-height: 4rem;
  width: var(--max-height);
  height: var(--max-height);
}

.i-support__img:before {
  content: "";
  z-index: 1;
  background-image: url("decor-after.18844e5e.svg");
  background-repeat: no-repeat;
  width: 1.3125rem;
  height: .625rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.i-support__img:after {
  content: "";
  width: var(--max-height);
  height: var(--max-height);
  z-index: 1;
  border: .125rem solid #6248aa;
  border-radius: 624.938rem;
  position: absolute;
  inset: 0;
}

.i-support__img.--placeholder {
  background-color: #edecf4;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.i-support__img.--placeholder:before {
  display: none;
}

.i-support__img.--sm {
  --max-height: 3rem;
}

.i-support__text {
  gap: .125rem;
}

.i-support.--sm:before {
  content: "";
  background-color: #d7cef0;
  width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: -1rem;
  left: 0;
  transform: translateY(100%);
}

.i-support.--sm .i-support__title {
  font-size: 1rem;
  line-height: 1.5rem;
}

.i-support.--sm .i-support__perex {
  font-size: .875rem;
  line-height: 1rem;
}

.i-tab-control {
  padding: var(--padding);
  font-size: var(--font-size);
  line-height: var(--line-height);
  border: .0625rem solid #d7cef0;
  min-width: 5.625rem;
  transition: background-color .2s, border-color .2s, color .2s;
}

.i-tab-control:hover {
  background-color: #d7cef0;
}

.i-tab-control.--selected {
  color: #fff;
  background-color: #6248aa;
  border-color: #6248aa;
}

.i-tab-control.--size-xs {
  --font-size: .75rem;
  --line-height: 1rem;
  --padding: .25rem .5rem;
}

.i-tab-control.--size-sm {
  --font-size: .875rem;
  --line-height: 1rem;
  --padding: .5rem;
}

@media (max-width: 47.999em) {
  .i-tab-control.--size-sm {
    --font-size: .75rem;
    --line-height: 1rem;
    --padding: .25rem .5rem;
  }
}

.i-tab-control.--size-md {
  --font-size: 1rem;
  --line-height: 1.5rem;
  --padding: .5rem 1rem;
}

@media (max-width: 61.999em) {
  .i-tab-control.--size-md {
    --font-size: .875rem;
    --line-height: 1rem;
    --padding: .5rem;
    min-width: 3rem;
  }
}

.i-tag.--color-label-1 {
  --background-color: #f43e80;
  color: #484752;
}

.i-tag.--color-label-2 {
  --background-color: #f9af79;
  color: #484752;
}

.i-tag.--color-label-3 {
  --background-color: #c7e9f4;
  color: #484752;
}

.i-tag.--color-label-4 {
  --background-color: #ffdc61;
  color: #484752;
}

.i-tag.--color-label-5 {
  --background-color: #cfe9a4;
  color: #484752;
}

a.i-tag.--color-primary-100, .i-tag.--hoverable.--color-primary-100 {
  --background-color: #edecf4;
}

a.i-tag.--color-primary-100:hover, .i-tag.--hoverable.--color-primary-100:hover {
  --background-color: #d7cef0;
  border-color: #d7cef0;
}

.i-tag {
  --padding-x: 1rem;
  --padding-y: .25rem;
  --min-height: 2rem;
  --font-size: 1rem;
  --line-height: 1.25rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  min-width: var(--min-width);
  font-size: var(--font-size);
  line-height: var(--line-height);
}

.i-tag.--xs {
  --padding-x: .25rem;
  --padding-y: 0;
  --min-height: 1rem;
  --font-size: .75rem;
  --line-height: 1rem;
}

.i-tag.--sm {
  --padding-x: .5rem;
  --padding-y: .25rem;
  --min-height: 1.25rem;
  --font-size: .875rem;
  --line-height: 1rem;
}

@media (max-width: 35.999em) {
  .i-tag.--sm {
    --min-height: 1.5rem;
    --min-width: 2.5rem;
    --font-size: .75rem;
  }
}

.i-tag.--md {
  --padding-x: .5rem;
  --padding-y: 0;
  --min-height: 2rem;
  --min-width: 3rem;
  --font-size: .875rem;
}

@media (max-width: 35.999em) {
  .i-tag.--md {
    --min-height: 1.5rem;
    --min-width: 2.5rem;
    --font-size: .75rem;
  }
}

.i-tag.--lg {
  --padding-x: 1.5rem;
  --padding-y: .25rem;
  --min-height: 2.5rem;
}

.i-tag {
  --font-color: #6248aa;
  --background-color: #fff;
  --font-size: .875rem;
  --radius: 1.5rem;
  font-size: var(--font-size);
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: var(--radius);
  border: .0625rem solid var(--background-color);
  gap: .125rem;
}

.i-tag.--outline {
  border-color: #d7cef0;
}

.i-tag.--primary {
  --font-color: #fff;
  --background-color: #6248aa;
}

.i-tag.--blank {
  --background-color: transparent;
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-before .i-tag__icon {
  order: -1;
}

.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-only .i-tag__title {
  display: none;
}

.i-tag.--input {
  will-change: border-color, background-color;
  transition-property: border-color, background-color;
}

label.i-tag, a.i-tag, .i-tag.--hoverable {
  cursor: pointer;
  text-decoration: none;
  transition: border-color .3s, background-color .3s;
}

label.i-tag:hover, a.i-tag:hover, .i-tag.--hoverable:hover {
  border-color: #312960;
}

label.i-tag.--primary:hover, a.i-tag.--primary:hover, .i-tag.--hoverable.--primary:hover {
  --font-color: #fff;
  --background-color: #312960;
  color: var(--font-color);
}

a.i-tag.--blank {
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: text-decoration-color .3s;
}

a.i-tag.--blank:hover {
  -webkit-text-decoration-color: var(--font-color);
  text-decoration-color: var(--font-color);
  border-color: #0000;
}

.i-tag__input {
  pointer-events: none;
  opacity: 0;
  bottom: 0;
  left: 0;
}

.i-tag:has(.i-tag__input:checked) {
  --font-color: var(--c-white);
  border-color: #312960;
}

.i-testimonial {
  --opacity: .8;
  --blend-mode: darken;
  --grayscale: 1;
  --width: 7.75rem;
  --height: 3.1875rem;
  width: var(--width);
  max-width: var(--width);
  height: var(--height);
  justify-self: center;
}

.i-testimonial .i-testimonial__image {
  opacity: var(--opacity);
  mix-blend-mode: var(--blend-mode);
  filter: grayscale(var(--grayscale));
  will-change: opacity, mix-blend-mode, filter;
  transition: opacity .3s, mix-blend-mode .3s, filter .3s;
}

.i-testimonial:hover, .i-testimonial.--no-hover {
  --opacity: 1;
  --blend-mode: darken;
  --grayscale: 0;
}

.i-testimonial.--sm {
  --height: 3rem;
}

.i-thumbnail {
  border: .0625rem solid #0000;
  transition: border-color .2s;
}

.i-thumbnail img {
  border: .0625rem solid #edecf4;
}

.i-top-category {
  min-height: 16.5rem;
  padding: 3.75rem .5rem .5rem;
}

@media (hover: hover) {
  .i-top-category:hover img {
    transform: scale(1.03);
  }
}

.i-top-category-background {
  mix-blend-mode: multiply;
  background-color: #484752cc;
  border-radius: 1rem;
}

.i-top-category__body > .base-heading {
  font-size: 1.75rem;
}

.i-top-category__body > .base-heading:before {
  content: none;
}

.i-total-price {
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  background-color: #f9f9f9;
  border: .0625rem solid #d7cef0;
  border-radius: 1rem;
}

.i-total-price.--size-lg {
  --padding-y: 3.5rem;
  --padding-x: 1rem;
}

.i-total-price.--size-md {
  --padding-y: 1.5rem;
  --padding-x: 1rem;
}

.i-total-price.--size-sm {
  --padding-y: 1rem;
  --padding-x: .5rem;
}

.i-transport-indicator {
  flex: 0 14rem;
}

@media (max-width: 74.999em) {
  .i-transport-indicator {
    font-size: .875rem;
  }
}

.i-transport-indicator.--progress .i-transport-indicator__title.--default-title {
  display: none;
}

.i-transport-indicator.--progress .i-transport-indicator__title.--progress-title {
  display: inline;
}

.i-transport-indicator.--fulfilled .i-transport-indicator__icon {
  --color: #ff5c50;
}

.i-transport-indicator.--fulfilled .i-transport-indicator__title.--fulfilled-title {
  display: inline;
}

.i-transport-indicator.--fulfilled .i-transport-indicator__title.--progress-title, .i-transport-indicator.--fulfilled .i-transport-indicator__title.--default-title, .i-user-button__button.--full {
  display: none;
}

@media (min-width: 62em) {
  .i-user-button__header.base-heading {
    display: none;
  }
}

@media (max-width: 61.999em) {
  .i-user-button__header.base-heading {
    top: var(--panel-offset);
    opacity: 0;
    pointer-events: none;
    height: 5rem;
  }
}

.i-user-button__panel {
  width: min(100vw - 1rem, 19rem);
}

@media (max-width: 61.999em) {
  .i-user-button__panel {
    height: calc(100vh + var(--panel-offset));
    height: calc(calc(var(--vh, 1vh) * 100)  + var(--panel-offset));
    padding-top: var(--panel-padding-top);
    box-shadow: none;
    background: none;
    border-radius: 0;
    width: 100%;
    transition: opacity .3s;
    top: 0;
    left: 0;
  }
}

.i-user-button {
  --panel-padding-top: 10.125rem;
  --panel-offset: 4.125rem;
}

@media (max-width: 61.999em) {
  .i-user-button {
    position: static;
  }
}

@media (min-width: 62em) {
  .i-user-button .container {
    padding: 1rem;
  }
}

.i-user-button:hover .i-user-button__button .icon {
  --color: #ff5c50;
}

.i-user-button.--filled .i-user-button__button.--full {
  display: flex;
}

.i-user-button.--filled .i-user-button__button.--empty {
  display: none;
}

.i-user-button.--open .i-user-button__header {
  opacity: 1;
}

.i-user-button.--open .i-user-button__panel {
  opacity: 1;
  pointer-events: auto;
}

.i-user-panel__link.link.--primary-600 {
  background-color: #0000;
  transition: background-color .3s, color .3s;
}

.i-user-panel__link.link.--primary-600:hover, .i-user-panel__link.link.--primary-600:focus {
  background-color: #edecf4;
}

.i-user-panel__link.link.--primary-600.--active, .i-user-panel__link.link.--primary-600.--active:hover, .i-user-panel__link.link.--primary-600.--active:focus {
  color: #fff;
  background-color: #6248aa;
}

.i-user-panel__link.link.--primary-600.--active .icon {
  --color: #fff;
}

.i-usp__icon {
  --size: 5rem;
  width: var(--size);
  height: var(--size);
}

.i-variant-row__title {
  width: var(--title-width);
}

.i-variant-row__group-wrapper {
  min-height: 3rem;
}

@media (max-width: 35.999em) {
  .i-variant-row {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }
}

@container (width <= 488px) {
  .i-variant-row {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }
}

.i-variant-row.--lg {
  --title-width: 8rem;
}

.i-variant-row.--sm {
  --title-width: 5rem;
}

.i-wishlist-button__button.--full {
  display: none;
}

.i-wishlist-button:hover .i-wishlist-button__button .icon {
  --color: #ff5c50;
}

.i-wishlist-button.--filled .i-wishlist-button__button.--full {
  display: flex;
}

.i-wishlist-button.--filled .i-wishlist-button__button.--empty {
  display: none;
}

.i-youtube-iframe__wrapper {
  padding-bottom: 60%;
}

.i-youtube-iframe__wrapper iframe {
  width: 100%;
  height: 100%;
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
  width: max-content;
  min-width: 3.25rem;
}

.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
}

.c-language-select__dropdown {
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  z-index: 500;
  background-color: #fff;
  transition: opacity .3s;
  top: 100%;
  left: 0;
  box-shadow: 0 .5rem 1rem .125rem #0f1c7b26;
}

.c-language-select__active {
  gap: .25rem;
  min-height: 1.5rem;
}

.c-language-select__dropdown-link {
  text-decoration: none;
  transition: background-color .3s;
}

.c-language-select__dropdown-link:hover {
  background-color: #edecf4;
}

.c-language-select__flag {
  border-radius: .125rem;
  margin-right: .25rem;
}

.c-language-select__flag.--active {
  background-color: #fff;
  border: .0625rem solid #fff;
}

.c-language-select__flag.--select {
  background-color: #312960;
  border: .0625rem solid #312960;
}

.link {
  --cubic-transition: cubic-bezier(.68, -.55, .27, 1.55);
}

.link.--animation-left svg, .link.--animation-right svg {
  transition-timing-function: var(--cubic-transition);
}

.link.--animation-left:hover svg {
  transform: translateX(-.25rem);
}

.link.--animation-right:hover svg {
  transform: translateX(.25rem);
}

.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  --color: #081828;
}

.link.--white {
  --color: #fff;
}

.link.--primary {
  --color: #312960;
}

.link.--secondary {
  --color: #e93d30;
}

.link.--primary-600 {
  --color: #6248aa;
}

.link.--tertiary {
  --color: #484752;
}

.link.--tertiary-to-primary {
  --color: #484752;
  --color-hover: #312960;
}

.link.--tertiary-to-primary-600 {
  --color: #484752;
  --color-hover: #6248aa;
}

.link.--primary-600-to-primary {
  --color: #6248aa;
  --color-hover: #312960;
}

.link.--primary-to-secondary-500 {
  --color: #312960;
  --color-hover: #ff5c50;
}

.link.--primary-600-to-secondary-500 {
  --color: #6248aa;
  --color-hover: #ff5c50;
}

.link.--colored:not(.--underline-initial) {
  color: var(--color);
  text-decoration-color: #0000;
}

.link.--colored:not(.--underline-initial):hover, .link.--colored:not(.--underline-initial):focus {
  color: var(--color-hover, var(--color));
  -webkit-text-decoration-color: var(--color-hover, var(--color));
  text-decoration-color: var(--color-hover, var(--color));
}

.link.--colored.--underline-initial {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
  color: var(--color);
}

.link.--colored.--underline-initial:hover, .link.--colored.--underline-initial:focus {
  color: var(--color-hover, var(--color));
  text-decoration-color: #0000;
}

.link__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.link {
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  transition: text-decoration-color .3s, color .3s;
}

.link:hover {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--icon-before .link__icon {
  order: -1;
}

.link.--icon-background .link__icon {
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
}

.link.--icon-background .link__icon:after {
  content: "";
  z-index: -1;
  background-color: #ff5c50;
  border-radius: 3.5rem;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link.--icon-background .link__icon svg {
  margin-left: .03125rem;
}

.link.--underline-none {
  text-decoration: none;
}

.main-footer-column:nth-child(4) {
  min-width: 100%;
}

@media (min-width: 48em) {
  .main-footer-column:nth-child(4) {
    min-width: 16.5rem;
  }
}

@media (min-width: 93.75em) {
  .main-footer-column:nth-child(4) {
    min-width: 28rem;
  }
}

.main-footer-column__link {
  overflow-wrap: break-word;
  word-break: break-all;
}

.main-footer-column__delivery .g-methods {
  gap: 1rem;
}

@media (max-width: 35.999em) {
  .main-footer__columns .main-footer-column {
    padding-inline: 1rem;
  }
}

@media (max-width: 359px) {
  .main-footer__columns .main-footer-column {
    padding-inline: .5rem;
  }
}

@media (min-width: 36em) {
  .main-footer__columns {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (min-width: 75em) {
  .main-footer__columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

.main-footer__bottom-columns:before, .main-footer__wrap:before {
  content: "";
  background-color: #d5d4da;
  width: calc(100% - 3.25rem);
  height: .0625rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -1.4375rem);
}

@media (min-width: 75em) {
  .main-footer__bottom-columns:before, .main-footer__wrap:before {
    transform: translate(-50%, -.9375rem);
  }
}

.main-footer__bottom-columns .main-footer-column:nth-child(3) {
  margin-inline: auto;
}

@media (max-width: 74.999em) {
  .main-footer__bottom-columns .main-footer-column:nth-child(3) {
    margin-bottom: .5rem;
  }
}

.main-footer__bottom-columns .main-footer-column__link {
  color: #73717e;
  overflow-wrap: normal;
  word-break: keep-all;
  font-size: .875rem;
  line-height: 1rem;
}

@media (min-width: 75em) {
  .main-footer__bottom-columns {
    flex-direction: row;
  }
}

.main-footer__wrap {
  gap: 4rem;
  padding-top: 3.5rem;
}

.main-footer__wrap:before {
  top: 0;
  transform: translate(-50%, .0625rem);
}

.main-footer__strip {
  background: linear-gradient(to right, #312960, #6248aa);
  width: 100%;
  max-width: 100%;
  height: .5rem;
}

.m-header-bottom {
  position: relative;
}

@media (min-width: 62em) {
  .m-header-bottom {
    box-shadow: .25rem .5rem 1.5rem var(--shadow-color);
    z-index: -1;
    transition: transform .2s;
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(0);
  }
}

@media (max-width: 61.999em) {
  .m-header-bottom {
    pointer-events: none;
    opacity: 0;
    overscroll-behavior: none;
    background: none;
    height: 0;
    max-height: 100%;
    overflow: auto;
  }
}

.m-header-bottom__contacts {
  z-index: 15;
  pointer-events: auto;
  border-radius: 1.5rem 1.5rem 0 0;
  transition: transform .2s;
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
}

@media (max-width: 61.999em) {
  .m-header-logo {
    max-width: 7rem;
  }
}

@media (max-width: 35.999em) {
  .m-header-logo {
    max-width: 6rem;
  }
}

@media (max-width: 93.749em) {
  .m-header-middle__contacts {
    font-size: 1.125rem;
  }
}

@media (max-width: 74.999em) {
  .m-header-middle__contacts {
    font-size: .75rem;
  }
}

@media (max-width: 61.999em) {
  .m-header-middle {
    z-index: 5;
    pointer-events: auto;
    border-radius: 0 0 1.5rem 1.5rem;
    transition: transform .3s;
    transform: translateY(0);
  }

  .m-header-middle > .container {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.m-header-middle .c-language-select {
  display: none;
}

@media (max-width: 61.999em) {
  .m-header-middle .i-wishlist-button {
    display: none;
  }

  .m-header-middle .f-base {
    min-height: 3rem;
  }

  .m-header-middle__actions {
    flex-grow: 1;
    gap: .5rem;
  }

  .m-header-top__contacts {
    justify-content: center;
  }

  .m-header-top:has( ~ .m-header.--menu-open,  ~ .m-header.--user-open,  ~ .m-header.--cart-open) {
    display: none;
  }
}

.m-header-top:has(.c-language-select) .m-header-top__socials {
  border-left: .0625rem solid #fff;
}

.m-header {
  --shadow-color: transparent;
  --header-bottom-offset: 4.5rem;
  --menu-bottom-offset: 15.5rem;
  z-index: 100;
  transition: box-shadow .3s, transform .3s;
  position: sticky;
  top: 0;
  left: 0;
}

@media (min-width: 62em) and (max-width: 93.749em) {
  .m-header {
    --header-bottom-offset: 3.625rem;
  }
}

@media (max-width: 61.999em) {
  .m-header {
    pointer-events: none;
  }

  .m-header:before {
    content: "";
    pointer-events: none;
    z-index: -1;
    opacity: 0;
    background-color: #f9f9f9;
    width: 100%;
    height: 100dvh;
    transition: transform .3s;
    display: block;
    position: absolute;
    left: 0;
    overflow: hidden;
  }

  .m-header.--menu-open .m-header-middle, .m-header.--search-open .m-header-middle, .m-header.--cart-open .m-header-middle, .m-header.--user-open .m-header-middle {
    width: 100%;
    position: fixed;
    top: 0;
    transform: translateY(-4.125rem);
  }

  .m-header.--menu-open .m-header-middle.--layout-simple, .m-header.--search-open .m-header-middle.--layout-simple, .m-header.--cart-open .m-header-middle.--layout-simple, .m-header.--user-open .m-header-middle.--layout-simple {
    transform: translateY(0);
  }

  .m-header.--menu-open .m-header-middle.--layout-simple .i-user-button, .m-header.--search-open .m-header-middle.--layout-simple .i-user-button, .m-header.--cart-open .m-header-middle.--layout-simple .i-user-button, .m-header.--user-open .m-header-middle.--layout-simple .i-user-button {
    --panel-padding-top: 6rem;
    --panel-offset: 0;
  }

  .m-header.--menu-open:before, .m-header.--search-open:before, .m-header.--cart-open:before, .m-header.--user-open:before {
    opacity: 1;
  }

  .m-header.--menu-open .f-search, .m-header.--search-open .f-search, .m-header.--cart-open .f-search, .m-header.--user-open .f-search {
    opacity: 0;
    pointer-events: none;
    order: -1;
  }

  .m-header.--menu-open .m-header-logo, .m-header.--search-open .m-header-logo, .m-header.--cart-open .m-header-logo, .m-header.--user-open .m-header-logo, .m-header.--menu-open .btn-nav-opener, .m-header.--search-open .btn-nav-opener, .m-header.--cart-open .btn-nav-opener, .m-header.--user-open .btn-nav-opener {
    display: none;
  }

  .m-header.--menu-open .btn-nav-closer, .m-header.--search-open .btn-nav-closer, .m-header.--cart-open .btn-nav-closer, .m-header.--user-open .btn-nav-closer {
    display: block;
  }

  .m-header.--user-open .i-user-button__button, .m-header.--user-open .i-cart-button__button, .m-header.--cart-open .i-user-button__button, .m-header.--cart-open .i-cart-button__button, .m-header.--search-open .i-user-button__button, .m-header.--search-open .i-cart-button__button {
    opacity: 0;
    pointer-events: none;
  }

  .m-header.--user-open .btn-nav-closer, .m-header.--cart-open .btn-nav-closer, .m-header.--search-open .btn-nav-closer {
    order: 10;
  }

  .m-header.--search-open .f-search {
    opacity: 1;
    pointer-events: auto;
    flex-grow: 0;
    order: 5;
    width: calc(100% - 4rem);
  }

  .m-header.--search-open .btn-nav-closer {
    order: 10;
  }

  .m-header.--menu-open .m-header-bottom__contacts {
    transform: translateY(0);
  }

  .m-header.--menu-open .c-language-select, .m-header.--menu-open .i-wishlist-button {
    display: flex;
  }

  .m-header.--menu-open .i-cart-button {
    display: none;
  }

  .m-header.--menu-open .m-header-bottom {
    opacity: 1;
    pointer-events: auto;
    height: calc(var(--vh, 1vh) * 100);
    height: 100vh;
  }

  @supports (-webkit-touch-callout: none) {
    .m-header.--menu-open .m-header-bottom {
      height: -webkit-fill-available;
    }
  }

  .m-header.--menu-open:has(.i-menu.--open) .m-header-middle__actions {
    opacity: 0;
    pointer-events: none;
  }

  .m-header.--menu-open:has(.i-menu.--open) .btn-nav-closer {
    order: 10;
  }
}

.m-header.headroom--not-top {
  --shadow-color: #0f1c7b26;
}

.m-header.headroom--not-top.headroom--unpinned .m-header-middle.--layout-simple {
  transform: translateY(0);
}

@media (max-width: 61.999em) {
  .m-header.headroom--not-top.headroom--unpinned .m-header-middle {
    transform: translateY(-4.125rem);
  }
}

@media (min-width: 62em) {
  .m-header.headroom--not-top.headroom--unpinned .m-header-bottom {
    transform: translateY(-100%);
  }
}

.m-header.headroom--not-top.headroom--unpinned .m-header-bottom.--layout-simple {
  transform: translateY(-5rem);
}

@media (min-width: 62em) {
  .m-header.headroom--not-top.headroom--pinned ~ main .product-navbar.--top {
    transform: translateY(var(--offset));
  }

  .m-header {
    margin-bottom: var(--header-bottom-offset);
  }
}

.m-header__body {
  min-height: 4.5rem;
  padding-block: .875rem;
}

.m-nav {
  --transition-timing: 0;
}

@media (min-width: 62em) {
  .m-nav {
    border-bottom: .0625rem solid #d7cef0;
    margin-left: auto;
  }
}

@media (max-width: 61.999em) {
  .m-nav {
    opacity: 0;
    transition: transform var(--transition-timing) ease;
    pointer-events: auto;
    width: 100vw;
    max-height: 100%;
    padding-block: 5rem;
    overflow: hidden auto;
  }

  .m-nav.--open {
    opacity: 1;
  }

  .m-nav.--transition {
    --transition-timing: .3s;
  }

  .m-nav:has(.i-menu.--has-submenu.--open) .m-nav__promo-button {
    display: none;
  }
}

@media (min-width: 62em) {
  .m-nav__list {
    flex-direction: row;
  }
}

@media (max-width: 61.999em) {
  .m-nav__list {
    align-self: stretch;
  }
}

.c-modal__dialog {
  pointer-events: none;
  cursor: cell;
  width: auto;
  margin-block: 2rem;
  margin-inline: auto;
  position: relative;
}

.c-modal__content {
  width: var(--width);
  pointer-events: auto;
  background-clip: padding-box;
  background-color: var(--content-bg-color);
  cursor: auto;
  transition: transform var(--transtion-length) ease;
  border: .0625rem solid #d5d4da;
  border-radius: 1rem;
  outline: 0;
  margin-inline: auto;
  padding: 1rem;
  position: relative;
  transform: translateY(2rem);
  box-shadow: .25rem .25rem 1.5rem #0f26391a;
}

.c-modal__body {
  flex: auto;
  position: relative;
}

.c-modal__footer.--right {
  justify-content: flex-end;
}

.c-modal__footer.--center {
  justify-content: center;
}

.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal {
  --transtion-length: .3s;
  --width: min(100% - 2rem, 55rem);
  --content-bg-color: #fff;
  z-index: 9000;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
  background-color: #00000040;
  outline: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.c-modal.--open .c-modal__content {
  transform: translateY(0);
}

.c-modal.--hidden {
  visibility: hidden;
  pointer-events: none;
}

.c-modal.--size-xs {
  --width: min(100% - 2rem, 23rem);
}

.c-modal.--size-sm {
  --width: min(100% - 2rem, 35.5rem);
}

.c-modal.--size-xl {
  --width: min(100% - 2rem, 71.25rem);
}

.c-modal.--bg-grey {
  --content-bg-color: #f9f9f9;
}

.c-modal.--height-stretch .c-modal__dialog {
  height: calc(100% - 4rem);
}

.c-modal.--height-stretch .c-modal__content, .c-modal.--height-stretch .c-modal__inner {
  height: 100%;
}

.m-add-product-to-cart-message.--success {
  background-color: #e7f4e4;
}

.m-add-product-to-cart-message.--error {
  background-color: #f9dede;
}

.m-adult-confirm {
  -webkit-backdrop-filter: blur(.5rem);
  backdrop-filter: blur(.5rem);
}

.m-interpret .c-modal__content {
  padding-bottom: 1.5rem;
}

.m-phone-order__grid-top:after {
  content: "";
  background-color: #d7cef0;
  width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: -2rem;
  left: 0;
  transform: translateY(50%);
}

@media (min-width: 36em) {
  .m-phone-order__grid-top {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 48em) {
  .m-phone-order__grid-top {
    grid-template-columns: repeat(3, 1fr);
  }

  .m-phone-order__grid-bottom {
    grid-template-columns: repeat(2, 1fr);
  }
}

.m-phone-order__terms {
  gap: .25rem;
}

.m-phone-order__term {
  line-height: 1rem;
}

.m-phone-order__term a {
  color: #6248aa;
  font-weight: 700;
}

.m-phone-order__footer {
  grid-template-columns: 1fr 13.875rem;
}

@media (max-width: 61.999em) {
  .m-phone-order__footer {
    grid-template-columns: auto;
  }

  .m-phone-order__submit {
    max-width: 12.875rem;
  }
}

@media (max-width: 35.999em) {
  .m-phone-order__submit {
    max-width: 100%;
  }

  .m-ppl.c-modal.--size-xl {
    --width: min(100%, 71.25rem);
  }

  .m-ppl .c-modal__dialog {
    height: calc(100% - 2rem);
    margin-block: 1rem;
  }

  .m-ppl .c-modal__content, .m-ppl .c-modal__inner {
    height: 100%;
  }

  .m-ppl #ppl-parcelshop-map {
    height: calc(100% - 2rem);
  }
}

.n-orders__pills .n-orders__pill:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.n-orders__pills .n-orders__pill:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.c-order-search__btn {
  min-width: 10rem;
}

@media (min-width: 36em) {
  .c-order-search__btn {
    margin-left: -1.5rem;
  }
}

.c-order-search__input {
  width: 100%;
}

@media (min-width: 36em) {
  .c-order-search__input {
    width: min(28.125rem, 100%);
  }
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  z-index: 1;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  position: var(--position);
  background-color: #081828;
  transition: opacity .3s;
  inset: 0;
}

.c-overlay.--show {
  --opacity: .75;
  --pointer-events: auto;
}

.c-overlay.--absolute {
  --position: absolute;
  width: 100%;
  height: 100%;
}

.link.c-pagination__link {
  --background: transparent;
  --size: 2.5rem;
  --font-weight: 400;
  font-weight: var(--font-weight);
  background-color: var(--background);
  min-height: var(--size);
  border-radius: .5rem;
  gap: .25rem;
  padding: .5rem 0;
  transition-property: text-decoration-color, color, background-color;
}

.link.c-pagination__link .icon {
  --size: 1.25rem;
}

.link.c-pagination__link.--page-number {
  --font-weight: 500;
  min-width: var(--size);
  padding: .5rem;
  text-decoration: none;
}

.link.c-pagination__link.--page-number:hover {
  --background: #edecf4;
}

.link.c-pagination__link.--active {
  --background: transparent;
  --font-weight: 700;
  pointer-events: none;
  color: #ff5c50;
}

.link.c-pagination__link.--blank {
  pointer-events: none;
}

@media (max-width: 47.999em) {
  .c-pagination__items {
    flex-basis: 100%;
    order: -1;
    justify-content: center;
  }

  .c-pagination {
    justify-content: center;
  }
}

.product-detail__form {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "gallery heading"
                       "gallery content";
}

@media (max-width: 61.999em) {
  .product-detail__form {
    grid-template-columns: 1fr;
    grid-template-areas: "heading"
                         "gallery"
                         "content";
  }
}

.product-detail__header, .product-detail__variants {
  border-bottom: .0625rem solid #d7cef0;
}

.product-detail__footer {
  grid-template-columns: 3.5rem auto 1fr;
  grid-template-areas: "discount discount price"
                       "wishlist counter cart"
                       "lowest lowest lowest";
}

@container (width <= 488px) {
  .product-detail__footer {
    grid-template-areas: "discount discount discount"
                         "price price price"
                         "wishlist counter cart"
                         "lowest lowest lowest";
    gap: 1rem;
  }
}

@container (width <= 430px) {
  .product-detail__footer {
    grid-template-areas: "discount discount discount"
                         "price price price"
                         "wishlist counter counter"
                         "cart cart cart"
                         "lowest lowest lowest";
    gap: .5rem;
  }
}

.product-detail__gallery {
  grid-area: gallery;
}

.product-detail__heading {
  grid-area: heading;
  margin-bottom: -1rem;
}

.product-detail__content {
  grid-area: content;
}

.product-detail__availability {
  min-height: 3.5rem;
}

.product-detail__discount {
  grid-area: discount;
}

.product-detail__price {
  grid-area: price;
}

.product-detail__wishlist {
  grid-area: wishlist;
}

.product-detail__counter {
  grid-area: counter;
}

.product-detail__cart-add {
  grid-area: cart;
}

.product-detail__lowest-price {
  grid-area: lowest;
}

.product-gallery__slider {
  border: .0625rem solid #edecf4;
  max-width: 35.5rem;
}

@media (min-width: 48em) {
  .product-gallery__slider {
    margin-left: 6rem;
  }

  .product-gallery__thumbnails {
    width: 5.5rem;
    height: auto;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

@media (max-width: 47.999em) {
  .product-gallery__thumbnails {
    order: 2;
  }
}

.product-navbar {
  z-index: 11;
  background-color: #f9f9f9;
}

.product-navbar:after {
  content: "";
  background-color: #d7cef0;
  width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 61.999em) {
  .product-navbar {
    position: relative;
  }
}

@media (min-width: 62em) {
  .product-navbar {
    top: var(--top);
    transition: transform .2s;
  }
}

.product-navbar__item {
  --underline-opacity: 0;
  --color-hover: #ff5c50;
  --min-height: 4.5rem;
  min-height: var(--min-height);
}

@media (hover: hover) {
  .product-navbar__item:hover {
    --underline-opacity: 1;
  }

  .product-navbar__item:hover .product-navbar__link {
    color: var(--color-hover);
  }
}

.product-navbar__item:after {
  content: "";
  background-color: var(--color-hover);
  opacity: var(--underline-opacity);
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: .25rem;
  transition: opacity .3s;
  position: absolute;
  bottom: 0;
  left: 0;
}

.product-navbar__item.--active {
  --underline-opacity: 1;
}

.product-navbar__item.--active .product-navbar__link {
  color: var(--color-hover);
}

@media (max-width: 47.999em) {
  .product-navbar__item {
    --min-height: 3.5rem;
  }
}

.product-parameters__body {
  border: .0625rem solid #d7cef0;
}

@media (min-width: 62em) {
  .product-parameters__body {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

@container (width >= 320px) {
  .product-parameters__row {
    grid-template-columns: 1fr 1fr;
  }
}

.product-parameters__value.--open .product-parameters__completed-value {
  display: inline;
}

.product-parameters__value.--open .product-parameters__short, .product-parameters__value.--open .product-parameters__ellipsis {
  display: none;
}

.product-parameters__value.--open .product-parameters__show-more svg {
  transform: rotateX(-180deg);
}

.product-reviews__reviews-group {
  opacity: 0;
  transition: opacity .3s;
}

@media (min-width: 62em) {
  .product-reviews__header {
    grid-template-columns: 22.1875rem 1fr;
    align-items: center;
    column-gap: 2rem;
    display: grid;
  }
}

.product-reviews__bottom {
  grid-template-rows: 0fr;
  margin-top: -5.5rem;
  transition: all .3s;
  display: grid;
}

.product-reviews__load-more {
  transition: margin-top .3s;
}

.product-reviews__load-more:after {
  content: "";
  background-color: #d7cef0;
  width: 100%;
  height: .0625rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.product-reviews__link {
  opacity: var(--link-opacity);
}

.product-reviews__link.--plus {
  --link-opacity: 1;
}

.product-reviews__link.--minus {
  --link-opacity: 0;
  pointer-events: none;
  transition: opacity .3s;
}

.product-reviews__percent {
  border-radius: 1.625rem;
  min-width: 3.1875rem;
  max-height: 1.5rem;
  padding: .25rem .625rem;
}

.product-reviews.--open .product-reviews__bottom {
  grid-template-rows: 1fr;
  margin-top: 0;
}

.product-reviews.--open .product-reviews__reviews-group {
  opacity: 1;
}

.product-reviews.--open .product-reviews__load-more {
  margin-top: -2.5rem;
}

.product-reviews.--open .product-reviews__link.--plus {
  --link-opacity: 0;
  pointer-events: none;
}

.product-reviews.--open .product-reviews__link.--minus {
  --link-opacity: 1;
  pointer-events: all;
}

.product-thumbnails {
  width: 100%;
  max-height: 100%;
}

.product-thumbnails.swiper {
  position: static;
}

.product-thumbnails .swiper-slide {
  cursor: pointer;
}

@media (max-width: 47.999em) {
  .product-thumbnails .swiper-slide {
    width: 5.5rem;
  }
}

.product-thumbnails .swiper-slide-thumb-active .i-thumbnail {
  border-color: #6248aa;
}

.product-thumbnails .swiper-buttons {
  z-index: 2;
  pointer-events: none;
  justify-content: space-between;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (min-width: 48em) {
  .product-thumbnails .swiper-buttons {
    flex-direction: column;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 47.999em) {
  .product-thumbnails .swiper-buttons {
    width: 100%;
  }
}

.product-thumbnails .swiper-button {
  pointer-events: auto;
  transition: opacity .3s;
}

@media (min-width: 48em) {
  .product-thumbnails .swiper-button {
    rotate: 90deg;
  }
}

.product-thumbnails .swiper-button.swiper-button-disabled {
  opacity: 0;
}

.product-thumbnails .swiper-button.--hidden {
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 47.999em) {
  .product-thumbnails .swiper-button-prev {
    margin-left: -.5rem;
  }
}

@media (min-width: 48em) {
  .product-thumbnails .swiper-button-prev {
    margin-top: -.5rem;
  }

  .product-thumbnails .swiper-button-next {
    margin-bottom: -.5rem;
  }
}

@media (max-width: 47.999em) {
  .product-thumbnails .swiper-button-next {
    margin-right: -.5rem;
  }
}

.c-scroll-up {
  --size: 3rem;
  --offset-right: 2.5rem;
  --offset-bottom: 2.5rem;
  --radius: .5rem;
  --bg-white: 255 255 255;
  --background: rgb(var(--bg-white) / 50%);
  --background-hover: rgb(var(--bg-white) / 100%);
  --icon-size: 1.5rem;
  --icon-color: #6248aa;
  --shadow: 0 .125rem 1rem 0 #d5d4da4d;
  width: var(--size);
  height: var(--size);
  bottom: var(--offset-bottom);
  right: var(--offset-right);
  border-radius: var(--radius);
  background-color: var(--background);
  box-shadow: var(--shadow);
  z-index: 50;
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(.8);
  cursor: pointer;
  border: .0625rem solid #6248aa;
  transition: background-color .3s, box-shadow .3s, opacity .3s cubic-bezier(.68, -.55, .27, 1.55), transform .3s cubic-bezier(.68, -.55, .27, 1.55);
}

@media (max-width: 47.999em) {
  .c-scroll-up {
    --offset-right: 1.625rem;
    --offset-bottom: 6rem;
  }
}

.c-scroll-up:hover {
  --background: var(--background-hover);
  --shadow: 0 .25rem 1rem .0625rem #6248aa33;
}

.c-scroll-up:after {
  content: "";
  opacity: 0;
  border-radius: .5rem;
  width: 100%;
  height: 100%;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.c-scroll-up:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.c-scroll-up svg {
  width: var(--icon-size);
  height: var(--icon-size);
}

.c-scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0)scale(1);
}

body:has(#supportBox-iframe) .c-scroll-up {
  --offset-bottom: 1.5rem;
  --offset-right: 5.75rem;
}

.s-breadcrumbs__homepage {
  display: block;
}

.s-cart__price-calculation {
  --grid-template-columns: 1fr;
  --grid-template-areas: "benefits" "aside";
  grid-template-columns: var(--grid-template-columns);
  grid-template-areas: var(--grid-template-areas);
}

@media (min-width: 62em) {
  .s-cart__price-calculation {
    --grid-template-columns: 1fr 1fr;
    --grid-template-areas: "benefits aside";
  }
}

@media (min-width: 75em) {
  .s-cart__price-calculation {
    --grid-template-columns: 2fr 1fr;
  }
}

.s-cart__price-calculation .s-cart__benefits {
  grid-area: benefits;
  align-self: start;
}

.s-cart__price-calculation .s-cart__aside {
  grid-area: aside;
}

.s-cart {
  --empty-display: none;
  --content-display: flex;
}

.s-cart.--empty {
  --empty-display: block;
  --content-display: none;
}

.s-cart__content {
  display: var(--content-display);
}

.s-cart__empty {
  display: var(--empty-display);
}

.s-content {
  --padding-y: 3rem;
  margin-block: var(--padding-y);
  border-top: .0625rem solid #d7cef0;
}

.s-content .base-header {
  --spacing: 1.5rem;
  gap: 1rem;
}

.s-content .base-header__description {
  font-weight: 400;
}

.s-content .entry-content h6 {
  margin-top: 0;
}

.s-content:has( + .product-reviews) {
  border-bottom: .0625rem solid #d7cef0;
}

.s-editorial-calendar {
  margin-top: 1.5rem;
}

@media (min-width: 62em) {
  .s-editorial-calendar__products {
    gap: 5rem;
    margin-top: 5rem;
  }
}

.s-error-404__heading {
  --font-size: 11rem;
  font-size: var(--font-size);
}

@media (max-width: 47.999em) {
  .s-error-404__heading {
    --font-size: 8.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-error-404__heading.--decoration:before {
    display: none;
  }
}

.s-error-404__sub-heading {
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 1.5rem;
}

.s-error-404__perex {
  text-align: center;
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 0;
}

.s-faq-list > .base-section__container > .base-header {
  --spacing: .375rem;
}

.s-faq__group-col.--box {
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 1rem;
}

@media (max-width: 47.999em) {
  .s-goods-reclamation__heading.base-heading {
    display: inline;
  }

  .s-goods-reclamation__heading.base-heading .base-heading__decoration-after {
    vertical-align: middle;
    margin: -.375rem 0 0 .375rem;
  }
}

.s-hero-signpost {
  margin-top: 2.5rem;
}

@media (max-width: 61.999em) {
  .s-hero-signpost {
    margin-top: 1.5rem;
  }
}

.s-hero-signpost__body:has(.i-product-offer) {
  grid-template-columns: 20.5rem 1fr;
  grid-template-areas: "offer products";
}

@media (max-width: 61.999em) {
  .s-hero-signpost__body:has(.i-product-offer) {
    grid-template-columns: 17rem 1fr;
    column-gap: 1rem;
  }
}

@media (max-width: 47.999em) {
  .s-hero-signpost__body {
    flex-direction: column;
    display: flex;
  }
}

.s-hero-signpost__products {
  grid-area: products;
}

.s-login__grid {
  align-items: center;
  width: 100%;
  max-width: 58rem;
  margin-inline: auto;
}

@media (min-width: 62em) {
  .s-login__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.999em) {
  .s-login__text-side {
    padding: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-login__text-side {
    padding: 1rem;
  }
}

.s-login__content.entry-content ul {
  margin-top: 2rem;
}

.s-login__content.entry-content li {
  margin-bottom: 2rem;
}

.s-login__content.entry-content li:last-child {
  margin-bottom: 0;
}

.s-login__form-box {
  border: .0625rem solid #d5d4da;
  border-radius: 1rem;
  width: 100%;
  max-width: 28rem;
  box-shadow: 0 .25rem 1.875rem .125rem #3129604d;
}

@media (min-width: 62em) {
  .s-order-confirmation__info-item {
    grid-column: span 2;
    grid-template-columns: subgrid;
  }
}

@media (max-width: 35.999em) {
  .s-post-detail__meta {
    flex-direction: column;
    align-items: start;
  }

  .s-post-detail__share-links {
    flex-direction: column;
    align-items: normal;
    margin-left: 0;
  }

  .s-post-detail__share {
    flex-direction: column;
  }
}

.s-products-list .base-header {
  --spacing: 0;
}

.s-products-list .base-heading:before {
  display: none;
}

.s-products-list .g-buttons .btn {
  min-width: 10rem;
}

@media (min-width: 48em) {
  .s-products-list .g-buttons {
    --spacing: 2.5rem;
  }
}

@media (max-width: 47.999em) {
  .s-products-list__header {
    flex-direction: column;
    align-items: flex-start;
  }

  .s-products-list__header .f-single-select-inline {
    margin-left: auto;
  }
}

.s-products-list__load-more.--hidden {
  display: none;
}

.s-products-slider {
  overflow: hidden;
}

@media (max-width: 47.999em) {
  .s-products-slider__slider {
    width: min(100%, 20.5rem);
    margin-inline: auto;
  }
}

@media (min-width: 48em) {
  .s-reccommended-products .g-buttons {
    --spacing: 2.5rem;
  }
}

.s-registration__grid {
  align-items: center;
  width: 100%;
  max-width: 58rem;
  margin-inline: auto;
}

@media (min-width: 62em) {
  .s-registration__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.999em) {
  .s-registration__text-side {
    padding: 1.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-registration__text-side {
    padding: 1rem;
  }
}

.s-registration__content.entry-content ul {
  margin-top: 2rem;
}

.s-registration__content.entry-content li {
  margin-bottom: 2rem;
}

.s-registration__content.entry-content li:last-child {
  margin-bottom: 0;
}

@media (max-width: 35.999em) {
  .s-signpost .g-buttons {
    max-width: 26rem;
    margin-inline: auto;
  }
}

.s-socials__wrap {
  gap: 4rem;
  width: 100%;
  max-width: 57.625rem;
  margin-inline: auto;
}

.s-socials__wrap .base-header {
  margin-inline: unset;
}

@media (max-width: 74.999em) {
  .s-socials__wrap {
    gap: 2rem;
  }
}

.s-text-media__grid {
  flex-direction: column;
  display: flex;
}

@media (min-width: 62em) {
  .s-text-media__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.s-text-media__header-in {
  gap: .5rem;
  margin-bottom: 1.5rem;
}

.s-text-media.--reverse .s-text-media__col.--content-col {
  order: -1;
}

.s-top-products__body {
  box-shadow: 0 .125rem 1rem #d5d4da4d;
}

.s-top-products__products.--hidden, .s-top-products__button.--hidden {
  display: none;
}

.s-user-password .base-header {
  --spacing: 3.5rem;
}

@media (max-width: 35.999em) {
  .s-user-password .base-header {
    --spacing: 2.5rem;
  }
}

.c-svg {
  opacity: 0;
  transition: opacity .3s;
}

.c-svg.entered {
  opacity: 1;
}

.c-svg.--colored path {
  fill: var(--color);
  transition: fill .3s;
}

.c-svg.--primary {
  --color: #312960;
}

.c-svg.--white {
  --color: #fff;
}

.c-svg.--text {
  --color: #081828;
}

.w-cart-overview__more-products {
  --body-inner-overflow: hidden;
  overflow: var(--body-inner-overflow);
  grid-template-rows: 0fr;
  transition: grid-template-rows .3s;
  display: grid;
}

.w-cart-overview__more-products-body {
  min-height: 0;
}

.w-cart-overview__products.--opened .w-cart-overview__more-products {
  grid-template-rows: 1fr;
}

.w-cart-overview__products.--opened .w-cart-overview__products-button svg {
  transform: rotateX(180deg);
}

.w-cart-overview__products.--after-open .w-cart-overview__more-products {
  --body-inner-overflow: visible;
}

.w-cart-overview__shipment, .w-cart-overview__payment, .w-cart-overview__country {
  display: none;
}

:is(.w-cart-overview__shipment:has(.i-overview), .w-cart-overview__payment:has(.i-overview), .w-cart-overview__country:has(.i-overview)) {
  display: block;
}

@media (min-width: 48em) {
  .w-similar-interprets .g-buttons {
    --spacing: 2rem;
  }
}

.w-similar-interprets .w-similar-interprets__header {
  --spacing: 1rem;
  padding-block: .5rem;
}

@media (min-width: 48em) {
  .w-top-products .g-buttons {
    --spacing: 2rem;
  }
}

.w-top-products .w-top-products__header {
  --spacing: 1rem;
  padding-block: .5rem;
}

.entry-content > * + * {
  margin-block-start: 1rem;
}

.entry-content strong:empty, .entry-content b:empty, .entry-content p:empty {
  display: none;
}

.entry-content hr {
  background: #ebebeb;
  border: none;
  height: .0625rem;
  margin-block: 3rem;
}

.entry-content .wp-caption {
  text-align: center;
  border: 0;
  max-width: 100%;
  padding: 0;
}

.entry-content .wp-caption-text {
  text-align: center;
  margin-bottom: 0;
  margin-left: 0;
  padding: .25rem .625rem;
  font-size: 1rem;
  transform: none;
}

.entry-content blockquote {
  background-color: #edecf4;
  flex-direction: column;
  gap: .5rem;
  margin-block: 2rem;
  padding: 1rem;
  font-style: italic;
  font-weight: 400;
  display: flex;
  position: relative;
}

.entry-content blockquote p {
  margin-bottom: 0;
  font-style: italic;
}

.entry-content .column {
  width: 100%;
  min-height: .0625rem;
  margin-bottom: 1.5rem;
  padding-left: .9375rem;
  padding-right: .9375rem;
  position: relative;
}

.entry-content .column img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  float: none !important;
}

@media (max-width: 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

.entry-content .columns-1, .entry-content .columns-1 .column {
  display: block;
}

.entry-content .columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 3rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-2 {
    flex-direction: column;
    display: flex;
  }

  .entry-content .columns-2 img {
    order: -1;
  }
}

.entry-content .columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 74.999em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  color: #312960;
  text-transform: uppercase;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.entry-content h1 {
  margin-top: 0;
  position: relative;
}

.entry-content h2 {
  position: relative;
}

.entry-content h6 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > a {
  display: block;
}

.entry-content img {
  --radius: 2.5rem;
  border-radius: var(--radius);
  max-width: 100%;
}

@media (max-width: 35.999em) {
  .entry-content img {
    --radius: 1.25rem;
  }
}

.entry-content ol > li {
  counter-increment: item;
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ol > li:before {
  content: counter(item) ".";
  color: #312960;
  width: 1.75rem;
  height: 1.75rem;
  margin-left: -2.1875rem;
  margin-right: .5rem;
  padding: .0625rem .25rem .0625rem .375rem;
  font-size: 1.125rem;
  font-weight: 700;
  display: inline-flex;
  top: .0625rem;
}

.entry-content ol > li ol > li {
  counter-increment: sub-item;
}

.entry-content ol > li ol > li:before {
  content: counter(sub-item) ".";
}

.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}

.entry-content ol > li ol > li ol > li:before {
  content: counter(sub-sub-item) ".";
}

.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-alpha"] li:before {
  content: counter(item, lower-alpha) ")";
}

.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-alpha"] li:before {
  content: counter(item, upper-alpha) ")";
}

.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-latin"] li:before {
  content: counter(item, lower-latin) ")";
}

.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-latin"] li:before {
  content: counter(item, upper-latin) ")";
}

.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-greek"] li:before {
  content: counter(item, lower-greek) ")";
}

.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-greek"] li:before {
  content: counter(item, upper-greek) ")";
}

.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-roman"] li:before {
  content: counter(item, lower-roman) ")";
}

.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-roman"] li:before {
  content: counter(item, upper-roman) ")";
}

.entry-content ul {
  list-style-type: none;
}

.entry-content ul > li {
  color: #484752;
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ul > li:before {
  content: "";
  background-image: url("list-decor.2344297d.svg");
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 1rem;
  height: 1.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.entry-content ul, .entry-content ol {
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
}

.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: .5rem;
  margin-bottom: 0;
}

.entry-content ul li, .entry-content ol li {
  margin-bottom: .5rem;
}

p {
  margin-bottom: 0;
}

.entry-content .table-responsive {
  border: 1rem solid #fff;
  margin: 1rem 0;
  overflow: auto;
  box-shadow: 0 0 0 .0625rem #ebebeb;
}

.entry-content th, .entry-content td, .entry-content tr {
  border: none !important;
}

.entry-content td, .entry-content th {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.entry-content td:first-child, .entry-content th:first-child {
  border-radius: .5rem 0 0 .5rem;
}

.entry-content td:last-child, .entry-content th:last-child {
  border-radius: 0 .5rem .5rem 0;
}

.entry-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.entry-content table p {
  font-size: 1rem;
  transform: none;
  margin-bottom: 0 !important;
}

.entry-content thead {
  color: #fff;
  background-color: #7868a3;
  font-size: 1rem;
  font-weight: bold;
}

.entry-content tbody {
  color: #1d1d1b;
  background-color: #fff;
  font-size: .875rem;
}

.entry-content tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}

.entry-content tbody td, .entry-content tbody th {
  padding: .5rem;
}

/*# sourceMappingURL=index.css.map */

.i-post.--inline {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: minmax(160px, 446px) auto;
    gap: 32px;

    .i-post__content {
      max-width: 70ch;
    }
  }
}

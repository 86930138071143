.base-section__banner-equalizer {
  overflow: hidden;
  pointer-events: none;

  path {
    transform-box: fill-box;
    will-change: transform;
  }

  path:nth-child(5n) {
    animation: equalizer 4s cubic-bezier(0.54, 2.36, 0.41, -0.33) -1s infinite alternate-reverse;
  }

  path:nth-child(5n + 1) {
    animation: equalizer 3s cubic-bezier(0.89, 1.33, 0.64, 0.78) -2s infinite reverse;
  }

  path:nth-child(5n + 2) {
    animation: equalizer 2s cubic-bezier(0.76, 1.32, 0.37, -0.32) infinite alternate;
  }

  path:nth-child(5n + 3) {
    animation: equalizer 5s cubic-bezier(0.535, 0.33, 0.69, 1.275) infinite;
  }

  path:nth-child(5n + 4) {
    animation: equalizer 4s cubic-bezier(0.61, -0.05, 0.51, 1.41) -3s infinite reverse;
  }
}

@keyframes equalizer {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(45%);
  }

  40% {
    transform: translateY(23%);
  }

  60% {
    transform: translateY(64%);
  }

  80% {
    transform: translateY(32%);
  }

  100% {
    transform: translateY(0);
  }
}

@use "settings/s-colors" as colors;

.g-top-products {
  grid-template: repeat(5, 1fr) / repeat(3, auto);
  grid-auto-flow: column;

  @include media-breakpoint-down(xl) {
    gap: 16px;
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: row;
  }
}

.g-top-products__item {
  &::before {
    content: attr(data-top-products-order);
    position: absolute;
    font-weight: 700;
    width: 32px;
    height: 32px;
    color: colors.$white;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../images/svg/star-filled.svg") no-repeat center;
    background-size: 32px;
    z-index: 1;
    pointer-events: none;
  }
}

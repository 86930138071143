.cart-items-group__header {
  display: grid;
  grid-template-columns: 80px 2fr 1fr 150px 40px 0.5fr 46px; // corresponding to template in cart-item.scss
  grid-template-areas: "content content availability counter discount price action";

  @include media-breakpoint-down(xl) {
    display: none;
  }
}

.cart-items-group__heading {
  &.--product {
    grid-area: content;
  }

  &.--availability {
    grid-area: availability;
  }

  &.--amount {
    grid-area: counter;
  }

  &.--sale {
    grid-area: discount;
  }

  &.--price {
    grid-area: price;
  }
}

@use "settings/s-colors" as colors;

.i-content-toggle__content {
  display: var(--content-display);
}

.i-content-toggle {
  --content-display: none;

  &.--open {
    --content-display: block;
  }
}

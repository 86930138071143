@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.f-contact {
  --padding: 24px;
  --max-width: 100%;

  max-width: var(--max-width);
  padding: var(--padding);
  box-shadow: shadows.$medium-shadow;
  border-radius: 24px;
  background: colors.$white;

  .g-terms__text-only {
    color: colors.$primary;

    > a {
      color: colors.$secondary-500;
    }
  }

  @include media-breakpoint-up(md) {
    --padding: 40px;
  }

  @include media-breakpoint-down(sm) {
    --max-width: 488px;

    margin-inline: auto;
  }
}

.f-contact__grid {
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.f-contact__submit {
  .btn {
    min-width: 160px;
    gap: 4px;
  }

  @include media-breakpoint-up(sm) {
    margin-left: auto;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

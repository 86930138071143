@use "settings/s-colors" as colors;

.f-single-select__select.slim-select {
  &.ss-main {
    padding-inline: var(--padding-inline);
    padding-left: 24px;
    box-shadow: var(--shadow);
    transition:
      box-shadow 0.3s ease,
      border 0.3s ease,
      border-radius 0.3s ease;

    &::after {
      display: block;
      content: "";
      width: 24px;
      height: 24px;
      background-image: url("../images/svg/arrow-down.svg");
      background-repeat: no-repeat;
      justify-self: end;
      align-self: center;
      grid-area: select;
      pointer-events: none;
      scale: 1;
      transition: scale 0.3s ease;
    }

    &.ss-disabled {
      --ss-font-color: #{colors.$primary-300};
    }

    .ss-values {
      gap: 4px;

      .ss-value {
        min-height: 24px;

        .ss-value-text {
          padding-right: 4px;
        }

        .ss-value-delete {
          border-left: none;
          padding-left: 4px;
        }
      }
    }
  }

  .ss-list .ss-option {
    padding-inline: 16px;
    padding-left: 24px;
  }

  .ss-arrow {
    display: none;
  }
}

.f-single-select.--multiple {
  .ss-main {
    .ss-values {
      margin-block: 12px;
    }
  }
}

.f-single-select,
.f-single-select__select.slim-select {
  --radius: 24px;
  --height: 48px;
  --padding-inline: 16px;
  --padding-block: 10px;
  --border-color: #{colors.$tertiary-400};
  --shadow: 0px 0px 0px 0px transparent;
  --ss-primary-color: #{colors.$primary};
  --ss-bg-color: #fff;
  --ss-font-color: #{colors.$primary};
  --ss-font-placeholder-color: #{colors.$primary-500};
  --ss-disabled-color: #{colors.$background};
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 300px;
  --ss-spacing-l: 7px;
  --ss-spacing-m: 12px;
  --ss-spacing-s: 0;
  --ss-animation-timing: 0.2s;
  --ss-border-radius: var(--radius);

  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  &:hover {
    --border-color: #{colors.$primary-600};
    --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
  }

  &.--native {
    .f-single-select__wrap {
      display: grid;
      align-items: center;
      grid-template-areas: "select";

      &::after {
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        background-image: url("../images/svg/arrow-down.svg");
        background-repeat: no-repeat;
        justify-self: end;
        grid-area: select;
        margin-right: 16px;
        pointer-events: none;
      }
    }

    .f-single-select__select {
      color: colors.$primary;
      font-weight: 500;
      border-radius: var(--radius);
      width: 100%;
      min-height: var(--height);
      border: 1px solid var(--border-color);
      background-color: colors.$white;
      padding-block: var(--padding-block);
      padding-inline: var(--padding-inline);
      padding-left: 24px;
      padding-right: 40px;
      cursor: pointer;
      outline: none;
      margin: 0;
      appearance: none;
      grid-area: select;
      box-shadow: var(--shadow);
      transition:
        box-shadow 0.3s ease,
        border 0.3s ease;
    }
  }

  .ss-search {
    input {
      padding-inline: 24px;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.ss-open-below {
    --border-color: #{colors.$primary-600};

    &::after {
      scale: -1;
    }
  }

  &.--invalid {
    --border-color: #{colors.$danger};

    .ss-main {
      --border-color: #{colors.$danger};
    }
  }

  @include media-breakpoint-down(sm) {
    --height: 40px;
  }
}

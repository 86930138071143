.i-product-overview__image {
  width: 56px;
  height: 56px;
}

.i-product-overview__action {
  margin-left: -8px;
}

.i-product-overview__count {
  @include media-breakpoint-up(md) {
    flex: 0 0 50px;
  }
}

.i-product-overview {
  .i-sale-voucher {
    --padding-x: 8px;

    flex-flow: wrap;
    justify-content: start;
  }
}

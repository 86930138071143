.w-cart-overview__more-products {
  --body-inner-overflow: hidden;

  display: grid;
  grid-template-rows: 0fr;
  overflow: var(--body-inner-overflow);
  transition: grid-template-rows 0.3s ease;
}

.w-cart-overview__more-products-body {
  min-height: 0;
}

.w-cart-overview__products {
  &.--opened {
    .w-cart-overview__more-products {
      grid-template-rows: 1fr;
    }

    .w-cart-overview__products-button {
      svg {
        transform: rotateX(180deg);
      }
    }
  }

  &.--after-open {
    .w-cart-overview__more-products {
      --body-inner-overflow: visible;
    }
  }
}

.w-cart-overview__shipment,
.w-cart-overview__payment,
.w-cart-overview__country {
  display: none;

  &:has(.i-overview) {
    display: block;
  }
}

@use "settings/s-colors.scss" as colors;

.m-phone-order__grid-top {
  &::after {
    content: "";
    position: absolute;
    bottom: -32px;
    left: 0;
    transform: translateY(50%);
    width: 100%;
    height: 1px;
    background-color: colors.$primary-300;
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.m-phone-order__grid-bottom {
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.m-phone-order__terms {
  gap: 4px;
}

.m-phone-order__term {
  line-height: 16px;

  a {
    font-weight: 700;
    color: colors.$primary-600;
  }
}

.m-phone-order__footer {
  grid-template-columns: 1fr 222px;

  @include media-breakpoint-down(lg) {
    grid-template-columns: auto;
  }
}

.m-phone-order__submit {
  @include media-breakpoint-down(lg) {
    max-width: 206px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 100%;
  }
}

@use "settings/s-colors" as colors;

.m-header-top__contacts {
  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}

.m-header-top {
  @include media-breakpoint-down(lg) {
    &:has(~ .m-header.--menu-open, ~ .m-header.--user-open, ~ .m-header.--cart-open) {
      display: none;
    }
  }

  &:has(.c-language-select) {
    .m-header-top__socials {
      border-left: 1px solid colors.$white;
    }
  }
}

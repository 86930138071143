.s-goods-reclamation__heading.base-heading {
  @include media-breakpoint-down(md) {
    display: inline;
  }

  .base-heading__decoration-after {
    @include media-breakpoint-down(md) {
      margin: -6px 0 0 6px;
      vertical-align: middle;
    }
  }
}

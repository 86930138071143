@use "settings/s-colors" as colors;

.f-checkbox-select__header {
  padding: 6px 16px;
  padding-left: 24px;
  min-height: var(--height);
  border: 1px solid var(--border-color);
  border-radius: 24px;
  user-select: none;
  background-color: var(--header-background);
  box-shadow: var(--shadow);
  transition:
    box-shadow 0.3s ease,
    border 0.3s ease,
    border-radius 0.3s ease;

  &:hover {
    --border-color: #{colors.$primary-600};
    --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
  }
}

.f-checkbox-select__clear {
  display: none;

  &.--active {
    display: inline-flex;
  }
}

.f-checkbox-select__toggler {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  svg {
    width: 24px;
    height: 24px;
    transition: scale 0.3s ease;
  }
}

.f-checkbox-select__title {
  --end: 0;

  overflow: hidden;
  flex: 1;
  top: 0;
  left: 0;
  height: 16px;

  > span {
    color: colors.$primary;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: left 2s linear;
  }

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 16px;
    height: 100%;
    background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box; /* stylelint-disable-line max-line-length */
    z-index: 1;
  }

  &:hover {
    > span {
      left: var(--end);
    }
  }
}

.f-checkbox-select__body {
  box-shadow: 0 0 0 1px var(--border-color);
  left: 1px;
  transform: scaleY(0.25);
  transform-origin: top;
  width: calc(100% - 2px);
  background-color: colors.$white;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  margin-top: -1px;

  .os-scrollbar-track {
    height: calc(100% - 8px);
  }
}

.f-checkbox-select {
  --header-background: #{colors.$white};
  --border-color: #{colors.$tertiary-400};
  --shadow: 0px 0px 0px 0px transparent;
  --height: 48px;

  min-width: 0;

  .f-checkbox {
    --min-height: 40px;
  }

  &.--active {
    --border-color: #{colors.$primary-600};

    .f-checkbox-select__header {
      border-radius: 24px 24px 0 0;
    }

    .f-checkbox-select__body {
      opacity: 1;
      pointer-events: auto;
      transform: scaleY(1);
      border-radius: 0 0 24px 24px;
    }

    .f-checkbox-select__toggler {
      svg {
        scale: -1;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    --height: 40px;
  }
}

.f-checkbox-select__body-inner {
  max-height: 183px;

  .f-checkbox:last-child {
    margin-bottom: 16px;
  }

  .f-checkbox__title {
    font-weight: 500;
    color: colors.$primary;
  }
}

@use "settings/s-colors.scss" as colors;

.product-reviews__reviews-group {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.product-reviews__header {
  @include media-breakpoint-up(lg) {
    display: grid;
    column-gap: 32px;
    grid-template-columns: 355px 1fr;
    align-items: center;
  }
}

.product-reviews__bottom {
  display: grid;
  grid-template-rows: 0fr;
  margin-top: -88px;
  transition: 0.3s ease;
}

.product-reviews__load-more {
  transition: margin-top 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: colors.$primary-300;
    bottom: 0;
    left: 0;
  }
}

.product-reviews__link {
  opacity: var(--link-opacity);

  &.--plus {
    --link-opacity: 1;
  }

  &.--minus {
    --link-opacity: 0;

    transition: opacity 0.3s ease;
    pointer-events: none;
  }
}

.product-reviews__percent {
  border-radius: 26px;
  padding: 4px 10px;
  min-width: 51px;
  max-height: 24px;
}

.product-reviews {
  &.--open {
    .product-reviews__bottom {
      grid-template-rows: 1fr;
      margin-top: 0;
    }

    .product-reviews__reviews-group {
      opacity: 1;
    }

    .product-reviews__load-more {
      margin-top: -40px;
    }

    .product-reviews__link.--plus {
      --link-opacity: 0;

      pointer-events: none;
    }

    .product-reviews__link.--minus {
      --link-opacity: 1;

      pointer-events: all;
    }
  }
}

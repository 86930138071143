@use "settings/s-colors" as colors;

.s-registration__grid {
  max-width: 928px;
  width: 100%;
  margin-inline: auto;
  align-items: center;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-registration__text-side {
  @include media-breakpoint-down(md) {
    padding: 24px;
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}

.s-registration__content.entry-content {
  ul {
    margin-top: 32px;
  }

  li {
    margin-bottom: 32px;
  }

  li:last-child {
    margin-bottom: 0;
  }
}

@use "settings/s-colors.scss" as colors;

@mixin tag-item-size-xs {
  --padding-x: 4px;
  --padding-y: 0;
  --min-height: 16px;
  --font-size: 12px;
  --line-height: 16px;
}

@mixin tag-item-size-sm {
  --padding-x: 8px;
  --padding-y: 4px;
  --min-height: 20px;
  --font-size: 14px;
  --line-height: 16px;

  @include media-breakpoint-down(sm) {
    --min-height: 24px;
    --min-width: 40px;
    --font-size: 12px;
  }
}

@mixin tag-item-size-md {
  --padding-x: 8px;
  --padding-y: 0px;
  --min-height: 32px;
  --min-width: 48px;
  --font-size: 14px;

  @include media-breakpoint-down(sm) {
    --min-height: 24px;
    --min-width: 40px;
    --font-size: 12px;
  }
}

@mixin tag-item-size-lg {
  --padding-x: 24px;
  --padding-y: 4px;
  --min-height: 40px;
}

.i-tag {
  --padding-x: 16px;
  --padding-y: 4px;
  --min-height: 32px;
  --font-size: 16px;
  --line-height: 20px;

  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  min-width: var(--min-width);
  font-size: var(--font-size);
  line-height: var(--line-height);

  &.--xs {
    @include tag-item-size-xs;
  }

  &.--sm {
    @include tag-item-size-sm;
  }

  &.--md {
    @include tag-item-size-md;
  }

  &.--lg {
    @include tag-item-size-lg;
  }
}

@use "settings/s-colors" as colors;

.f-forgotten-password {
  border-radius: 16px;
  box-shadow: 0 4px 30px 2px rgb(49 41 96 / 30%);
  border: 1px solid colors.$tertiary-300;

  @include media-breakpoint-down(lg) {
    justify-self: center;
  }
}

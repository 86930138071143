@use "settings/s-colors" as colors;

.i-total-price {
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  border: 1px solid colors.$primary-300;
  border-radius: 16px;
  background-color: colors.$background;

  &.--size-lg {
    --padding-y: 56px;
    --padding-x: 16px;
  }

  &.--size-md {
    --padding-y: 24px;
    --padding-x: 16px;
  }

  &.--size-sm {
    --padding-y: 16px;
    --padding-x: 8px;
  }
}

@use "settings/s-colors" as colors;

.g-products-slider__button {
  --offset: calc(50% - 4px);

  pointer-events: auto;

  &.btn.--outline {
    --bg-white: 255 255 255;
    --background: rgb(var(--bg-white) / 50%);
    --background-hover: rgb(var(--bg-white) / 100%);

    @media (hover: hover) {
      &:hover {
        --background: var(--background-hover);
      }
    }

    @media (width <= 359px) {
      --min-height: 32px;
      --offset: calc(50% - 12px);
    }
  }

  &.--prev {
    transform: translateX(calc(-1 * var(--offset)));
  }

  &.--next {
    transform: translateX(var(--offset));
  }

  & .icon.--lg {
    --size: 20px;
  }
}

.g-products-slider {
  &.--loading {
    &::before {
      border-radius: 32px;
    }
  }

  &.swiper {
    display: flex;
    align-items: center;
    overflow: initial;
  }

  & .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    flex-shrink: 0;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }

  .swiper-slide-visible {
    opacity: 1;
    pointer-events: auto;
  }
}

.g-products-slider__buttons {
  pointer-events: none;

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.g-products-slider__load-more-img.img.--cover {
  inset: 16px;
  border-radius: 8px;
  background-color: colors.$primary-600;
  width: auto;
  height: auto;
  overflow: hidden;

  img {
    opacity: 0.3;
    transition: 0.3s ease;
    transition-property: opacity, scale;
  }
}

.g-products-slider__load-more.swiper-slide {
  border-radius: 16px;
  border: 1px solid colors.$primary-300;
  height: auto;
  transition: 0.3s ease;
  transition-property: box-shadow, background-color;

  &:hover {
    background-color: colors.$white;

    .g-products-slider__load-more-img {
      img {
        opacity: 0.2;
        scale: 1.03;
      }
    }
  }
}

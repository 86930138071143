@use "settings/s-colors" as colors;

.link.c-pagination__link {
  --background: transparent;
  --size: 40px;
  --font-weight: 400;

  gap: 4px;
  font-weight: var(--font-weight);
  padding: 8px 0;
  border-radius: 8px;
  background-color: var(--background);
  min-height: var(--size);
  transition-property: text-decoration-color, color, background-color;

  .icon {
    --size: 20px;
  }

  &.--page-number {
    --font-weight: 500;

    padding: 8px;
    text-decoration: none;
    min-width: var(--size);

    &:hover {
      --background: #{colors.$primary-100};
    }
  }

  &.--active {
    --background: transparent;
    --font-weight: 700;

    pointer-events: none;
    color: colors.$secondary-500;
  }

  &.--blank {
    pointer-events: none;
  }
}

.c-pagination__items {
  @include media-breakpoint-down(md) {
    order: -1;
    flex-basis: 100%;
    justify-content: center;
  }
}

.c-pagination {
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}

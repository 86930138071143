@use "settings/s-colors" as colors;

.base-section {
  --spacing: var(--section-spacing);

  padding-block: var(--spacing);

  &.--top-0 {
    padding-top: 0;
  }

  &.--bottom-0 {
    padding-bottom: 0;
  }

  &.--none {
    --spacing: 0;
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-header {
  --shadow-color: transparent;
  --header-bottom-offset: 72px;
  --menu-bottom-offset: 248px;

  top: 0;
  left: 0;
  position: sticky;
  z-index: 100;
  transition: box-shadow 0.3s ease;
  transition-property: box-shadow, transform;

  @include media-breakpoint-between(lg, xxl) {
    --header-bottom-offset: 58px;
  }

  @include media-breakpoint-down(lg) {
    pointer-events: none;
  }

  &::before {
    @include media-breakpoint-down(lg) {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100dvh;
      pointer-events: none;
      background-color: colors.$background;
      left: 0;
      z-index: -1;
      opacity: 0;
      transition: transform 0.3s ease;
      overflow: hidden;
    }
  }

  &.--menu-open,
  &.--search-open,
  &.--cart-open,
  &.--user-open {
    @include media-breakpoint-down(lg) {
      .m-header-middle {
        position: fixed;
        width: 100%;
        top: 0;
        transform: translateY(-66px);

        &.--layout-simple {
          transform: translateY(0);

          .i-user-button {
            --panel-padding-top: 96px;
            --panel-offset: 0;
          }
        }
      }

      &::before {
        opacity: 1;
      }

      .f-search {
        order: -1;
        opacity: 0;
        pointer-events: none;
      }

      .m-header-logo {
        display: none;
      }

      .btn-nav-opener {
        display: none;
      }

      .btn-nav-closer {
        display: block;
      }
    }
  }

  &.--user-open,
  &.--cart-open,
  &.--search-open {
    @include media-breakpoint-down(lg) {
      .i-user-button__button,
      .i-cart-button__button {
        opacity: 0;
        pointer-events: none;
      }

      .btn-nav-closer {
        order: 10;
      }
    }
  }

  &.--search-open {
    @include media-breakpoint-down(lg) {
      .f-search {
        order: 5;
        opacity: 1;
        pointer-events: auto;
        width: calc(100% - 64px);
        flex-grow: 0;
      }

      .btn-nav-closer {
        order: 10;
      }
    }
  }

  &.--menu-open {
    @include media-breakpoint-down(lg) {
      .m-header-bottom__contacts {
        transform: translateY(0);
      }

      .c-language-select {
        display: flex;
      }

      .i-wishlist-button {
        display: flex;
      }

      .i-cart-button {
        display: none;
      }

      .m-header-bottom {
        opacity: 1;
        pointer-events: auto;
        height: 100vh;
      }

      &:has(.i-menu.--open) {
        .m-header-middle__actions {
          opacity: 0;
          pointer-events: none;
        }

        .btn-nav-closer {
          order: 10;
        }
      }
    }
  }

  &.headroom--not-top {
    --shadow-color: #{shadows.$color};

    &.headroom--unpinned {
      .m-header-middle {
        &.--layout-simple {
          transform: translateY(0);
        }

        @include media-breakpoint-down(lg) {
          transform: translateY(-66px);
        }
      }

      .m-header-bottom {
        @include media-breakpoint-up(lg) {
          transform: translateY(-100%);
        }

        &.--layout-simple {
          transform: translateY(-80px);
        }
      }
    }

    &.headroom--pinned {
      ~ main .product-navbar.--top {
        @include media-breakpoint-up(lg) {
          transform: translateY(var(--offset));
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: var(--header-bottom-offset);
  }
}

.m-header__body {
  min-height: 72px;
  padding-block: 14px;
}

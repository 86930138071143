@use "sass:map";
@use "settings/s-colors" as colors;

$colors: (
  "success": (
    "background": #{colors.$status-4},
    "color": #{colors.$black},
  ),
  "error": (
    "background": #{colors.$status-1},
    "color": #{colors.$white},
  ),
  "info": (
    "background": #{colors.$status-3},
    "color": #{colors.$black},
  ),
  "warning": (
    "background": #{colors.$status-2},
    "color": #{colors.$black},
  ),
);

.i-status {
  --font-color: colors.$black;

  padding: 1px 10px;
  background-color: colors.$status-3;
  color: var(--font-color);
  min-height: 24px;
  align-items: center;
  justify-content: center;
  display: inline-flex;

  @each $class, $color in $colors {
    &.--#{$class} {
      --font-color: #{map.get($color, "color")};

      background-color: map.get($color, "background");
    }
  }
}

@use "settings/s-colors" as colors;

.f-multicheckbox-search__search .f-base {
  min-height: 40px;
}

.f-multicheckbox-search__body {
  padding-left: 2px;

  .os-scrollbar-track {
    height: 100%;
  }
}

.f-multicheckbox-search__body-inner {
  max-height: 192px;

  > div {
    padding-block: 4px;
  }

  .f-checkbox__title {
    font-weight: 500;
    color: colors.$primary;
  }
}

.f-multicheckbox-search {
  .f-checkbox {
    --min-height: 40px;

    margin-right: 16px;
  }
}

.f-multicheckbox-search__load-more {
  &.--hidden {
    display: none;
  }
}

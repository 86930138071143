@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-menu__submenu-button {
  @include media-breakpoint-down(lg) {
    margin-left: auto;
    padding: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .img {
    @include media-breakpoint-down(lg) {
      transform: rotate(-90deg);
    }
  }
}

.i-menu__link {
  padding: 22px 24px 26px;
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;

  @include media-breakpoint-up(lg) {
    height: 100%;
  }

  @include media-breakpoint-between(lg, xxl) {
    padding: 16px 12px 18px;
    font-size: 14px;
  }

  @include media-breakpoint-down(lg) {
    padding: 0 0 0 8px;
    min-height: 56px;
  }

  &::after {
    @include media-breakpoint-up(lg) {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      display: block;
      background-color: colors.$secondary-500;
      height: 4px;
      transition: transform 0.2s ease;
      transform-origin: bottom;
      transform: scaleY(0);
    }
  }

  &::before {
    @include media-breakpoint-down(lg) {
      content: "";
      width: 4px;
      height: 9px;
      border-radius: 0 50% 50% 0;
      vertical-align: middle;
      background-color: colors.$secondary-500;
      display: inline-block;
    }
  }

  &:hover {
    color: colors.$primary-600;

    &::after {
      transform: scaleY(1);
    }
  }

  &.--submenu-mobile-only {
    display: none;

    @include media-breakpoint-down(lg) {
      display: flex;
      min-height: 72px;
    }

    .i-menu__submenu-button {
      pointer-events: none;
    }
  }
}

.i-menu__submenu-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  padding-bottom: 80px;

  @include media-breakpoint-up(lg) {
    opacity: 0;
    pointer-events: none;
    top: 100%;
    padding-bottom: 0;
    box-shadow: 0 6px 10px 2px rgba(colors.$primary-500, 0.2);
    border-radius: 0 0 24px 24px;
    background: colors.$background;
    transition: opacity 0.3s ease;
    max-height: calc(100vh - var(--menu-bottom-offset));
    overflow: auto;
  }

  @include media-breakpoint-down(lg) {
    top: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
  }
}

.i-menu__submenu {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min-content;
    gap: 16px 32px;
  }

  @include media-breakpoint-down(lg) {
    padding: 16px 0 20px;
  }
}

.i-menu__submenu-header {
  position: sticky;
  top: 0;
  z-index: 10;
  width: calc(100% - 64px);
  text-transform: uppercase;
}

.i-menu {
  &.--top-category {
    .i-menu__submenu {
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  &.hover {
    .i-menu__submenu-wrapper {
      @include media-breakpoint-up(lg) {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &.--open {
    .i-menu__submenu-wrapper {
      @include media-breakpoint-down(lg) {
        overflow: auto;
        opacity: 1;
        pointer-events: auto;
        overscroll-behavior: none;
      }
    }

    ~ .i-menu {
      z-index: -1;
    }
  }

  &.--active {
    .i-menu__link {
      color: colors.$secondary-500;
    }
  }

  &.--sm {
    .i-menu__link {
      @include media-breakpoint-up(xxl) {
        font-size: 16px;
      }

      @include media-breakpoint-between(lg, xxl) {
        padding: 16px 8px 18px;
      }

      @include media-breakpoint-only(lg) {
        font-size: 12px;
        gap: 2px;

        .i-menu__submenu-button {
          width: 18px;
        }
      }
    }
  }
}

.i-menu__submenu-content {
  grid-template-areas:
    "lists"
    "top-category";

  @include media-breakpoint-up(lg) {
    grid-template-areas: "lists lists lists top-category";
  }
}

.i-menu__submenu-lists {
  grid-area: lists;
}

.i-menu__submenu-top-category {
  grid-area: top-category;
  min-height: 264px;
  max-height: 480px;

  .i-top-category {
    height: 100%;
  }
}

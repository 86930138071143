@use "settings/s-colors.scss" as colors;

.link {
  --cubic-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55);

  &.--animation-left,
  &.--animation-right {
    svg {
      transition-timing-function: var(--cubic-transition);
    }
  }

  &.--animation-left {
    &:hover {
      svg {
        transform: translateX(-4px);
      }
    }
  }

  &.--animation-right {
    &:hover {
      svg {
        transform: translateX(4px);
      }
    }
  }

  &.--animation-zoom {
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-contact-pillar {
  --img-size: 88px;
  --shadow: #{shadows.$small-shadow};
  --min-height: 386px;
  --padding-y: 40px;
  --padding-x: 80px;

  min-height: var(--min-height);
  background-color: colors.$white;
  border: 1px solid colors.$primary-100;
  border-radius: 24px;
  box-shadow: var(--shadow);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);

  &.--colored {
    --img-size: 120px;
    --shadow: #{shadows.$medium-shadow};
    --min-height: 464px;

    background: colors.$gradient;
    border: none;
  }

  @include media-breakpoint-down(xxl) {
    --padding-y: 32px;
    --padding-x: 24px;
  }

  @include media-breakpoint-down(sm) {
    --padding-y: 24px;
    --padding-x: 16px;
  }
}

.i-contact-pillar__img {
  width: var(--img-size);
  height: var(--img-size);
  margin-inline: auto;
  border: 1px solid colors.$primary-300;
  border-radius: 16px;
  background-color: colors.$white;
}

.i-contact-pillar__btn {
  .btn {
    --padding-x: 16px;

    gap: 4px;
    min-width: 160px;
  }
}

.i-contact-pillar__contact {
  .link__icon {
    position: relative;
    width: 24px;
    height: 24px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      border-radius: 56px;
      background-color: colors.$secondary-500;
    }

    .icon {
      z-index: 1;
    }

    svg {
      margin-left: 0.5px;
    }
  }
}

@use "settings/s-colors.scss" as colors;

.main-footer__columns {
  .main-footer-column {
    @include media-breakpoint-down(sm) {
      padding-inline: 16px;
    }

    @media (width <= 359px) {
      padding-inline: 8px;
    }
  }

  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.main-footer__bottom-columns,
.main-footer__wrap {
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, calc(-23px));
    width: calc(100% - 52px);
    height: 1px;
    background-color: colors.$tertiary-300;

    @include media-breakpoint-up(xl) {
      transform: translate(-50%, calc(-15px));
    }
  }
}

.main-footer__bottom-columns {
  .main-footer-column:nth-child(3) {
    margin-inline: auto;

    @include media-breakpoint-down(xl) {
      margin-bottom: 8px;
    }
  }

  .main-footer-column__link {
    color: colors.$tertiary-600;
    font-size: 14px;
    line-height: 16px;
    overflow-wrap: normal;
    word-break: keep-all;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}

.main-footer__wrap {
  gap: 64px;
  padding-top: 56px;

  &::before {
    top: 0;
    transform: translate(-50%, 1px);
  }
}

.main-footer__strip {
  background: colors.$gradient;
  height: 8px;
  width: 100%;
  max-width: 100%;
}

.entry-content {
  > a {
    display: block;
  }

  img {
    --radius: 40px;

    max-width: 100%;
    border-radius: var(--radius);

    @include media-breakpoint-down(sm) {
      --radius: 20px;
    }
  }
}

.i-product {
  &.--vertical {
    .i-product__header {
      @include media-breakpoint-up(sm) {
        flex-grow: 1;
      }
    }

    .i-product__content {
      @include media-breakpoint-up(sm) {
        flex-grow: 0;
      }
    }
  }
}

@mixin price-item-size-sm {
  --font-size-main: 14px;
  --line-height-main: 16px;
  --font-size-base: 12px;
  --line-height-base: 16px;
  --font-size-before: 12px;
  --line-height-before: 14px;
}

@mixin price-item-size-lg {
  --font-size-main: 18px;
  --line-height-main: 24px;
  --font-size-base: 12px;
  --line-height-base: 14px;
  --font-size-before: 14px;
  --line-height-before: 16px;
}

.i-price {
  text-align: right;
  column-gap: 2px;
  flex: 0 0 auto;

  .i-price__main {
    font-size: var(--font-size-main);
    line-height: var(--line-height-main);
  }

  .i-price__base {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
  }

  .i-price__before {
    font-size: var(--font-size-before);
    line-height: var(--line-height-before);
  }

  &.--size-lg {
    @include price-item-size-lg;
  }

  &.--size-sm {
    @include price-item-size-sm;
  }
}

.i-price__title {
  flex: 1;
  text-align: left;

  &::after {
    content: ":";
  }
}

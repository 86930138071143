main {
  overflow-x: hidden;
  display: block;
  flex-grow: 1;

  &:has(.product-navbar) {
    overflow-x: initial;
  }

  &:has(.f-filtration.--open) {
    overflow-x: initial;
  }
}

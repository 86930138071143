@use "settings/s-colors.scss" as colors;

.u-color-primary {
  color: colors.$primary;
}

.u-color-primary-100 {
  color: colors.$primary-100;
}

.u-color-primary-300 {
  color: colors.$primary-300;
}

.u-color-primary-500 {
  color: colors.$primary-500;
}

.u-color-primary-600 {
  color: colors.$primary-600;
}

.u-color-secondary {
  color: colors.$secondary;
}

.u-color-white {
  color: colors.$white;
}

.u-color-tertiary-600 {
  color: colors.$tertiary-600;
}

.u-color-tertiary {
  color: colors.$tertiary;
}

@use "settings/s-fonts.scss" as fonts;
@use "settings/s-colors.scss" as colors;

body {
  font-family: fonts.$primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  line-height: 1.5;
  background-color: colors.$background;
  color: colors.$tertiary;
  min-height: 100vh;

  #product-parameters,
  #product-content,
  #product-reviews {
    scroll-margin-top: var(--scroll-margin-top);
  }

  @include media-breakpoint-down(lg) {
    * {
      --scroll-margin-top: 80px;
    }

    &.--search-open {
      overflow: hidden;

      .i-notification,
      .m-header-top {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &:has(.c-modal.--open) {
    overflow: hidden;
  }
}

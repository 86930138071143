@use "settings/s-colors" as colors;

.i-banner {
  background: colors.$gradient;
  border-radius: 24px;
  padding-left: 240px;

  @include media-breakpoint-down(lg) {
    padding: 24px;
  }

  @include media-breakpoint-down(sm) {
    text-align: center;

    .btn {
      --min-height: 48px;
      --padding-y: 12px;
    }
  }
}

.i-banner__header {
  max-width: 110ch;

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}

.i-banner__description {
  line-height: 24px;
}

.i-banner__img {
  max-width: 269px;
  height: auto;
  transform: translate(-25%, -50%);

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.i-banner__buttons {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

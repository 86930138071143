@use "settings/s-colors.scss" as colors;

$dialogBackgroundColor: rgb(0 0 0 / 25%);
$contentPaddingX: 16px;
$contentPaddingY: 16px;
$contentBoxShadow: 4px 4px 24px #0f26391a;

.c-modal__dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin-block: 2rem;
  margin-inline: auto;
  cursor: cell;
}

.c-modal__content {
  position: relative;
  width: var(--width);
  margin-inline: auto;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: var(--content-bg-color);
  cursor: auto;
  box-shadow: $contentBoxShadow;
  padding: $contentPaddingY $contentPaddingX;
  border-radius: 16px;
  border: 1px solid colors.$tertiary-300;
  transform: translateY(32px);
  transition: transform var(--transtion-length) ease;
}

.c-modal__body {
  position: relative;
  flex: 1 1 auto;
}

.c-modal__footer {
  &.--right {
    justify-content: flex-end;
  }

  &.--center {
    justify-content: center;
  }

  &.--between {
    justify-content: space-between;
  }
}

.c-modal {
  --transtion-length: 300ms;
  --width: min(100% - 32px, 880px);
  --content-bg-color: #{colors.$white};

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  background-color: $dialogBackgroundColor;
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;

  &.--open {
    opacity: 1;
    pointer-events: all;
    visibility: visible;

    .c-modal__content {
      transform: translateY(0);
    }
  }

  &.--hidden {
    visibility: hidden;
    pointer-events: none;
  }

  &.--size-xs {
    --width: min(100% - 32px, 368px);
  }

  &.--size-sm {
    --width: min(100% - 32px, 568px);
  }

  &.--size-xl {
    --width: min(100% - 32px, 1140px);
  }

  &.--bg-grey {
    --content-bg-color: #{colors.$background};
  }

  &.--height-stretch {
    .c-modal__dialog {
      height: calc(100% - 64px);
    }

    .c-modal__content {
      height: 100%;
    }

    .c-modal__inner {
      height: 100%;
    }
  }
}

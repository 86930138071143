@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

/* stylelint-disable no-descending-specificity */
.entry-content {
  .table-responsive {
    margin: general.$spacing * 2 0;
    border: general.$spacing * 2 solid colors.$white;
    box-shadow: 0 0 0 1px colors.$gray-200;
    overflow: auto;
  }

  th,
  td,
  tr {
    border: none !important;
  }

  td,
  th {
    padding: general.$spacing * 2;
    text-align: center;
    position: relative;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }

  table {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 100%;

    p {
      margin-bottom: 0 !important;
      font-size: 16px;
      transform: none;
    }
  }

  thead {
    background-color: colors.$primary-500;
    color: colors.$white;
    font-size: 16px;
    font-weight: bold;
  }

  tbody {
    background-color: colors.$white;
    color: colors.$black-400;
    font-size: 14px;

    tr {
      &:nth-child(2n) {
        background-color: colors.$background;
      }
    }

    td,
    th {
      padding: general.$spacing;
    }
  }
}
/* stylelint-enable no-descending-specificity */

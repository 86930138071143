@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.c-counter {
  --width: 152px;
  --color: #{colors.$primary-600};
  --border-color: #{colors.$primary-300};

  border: 1px solid var(--border-color);
  min-height: 56px;
  min-width: var(--width);
  color: var(--color);
  user-select: none;
  transition: background-color 0.3s ease;

  &.--disabled {
    --color: #{colors.$tertiary-400};
    --border-color: #{colors.$primary-100};

    pointer-events: none;
  }
}

.c-counter__value-visual {
  gap: 4px;
}

.c-counter__button {
  width: 24px;
  height: 24px;
  z-index: 2;
  cursor: pointer;

  path {
    fill: var(--color);
  }

  > svg {
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-color: colors.$primary-300;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

.c-counter__notice {
  pointer-events: none;
  border-radius: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  box-shadow: shadows.$medium-shadow;
  z-index: 10;

  &::before {
    content: "";
    display: block;
    width: 16px;
    height: 8px;
    background-color: inherit;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
}

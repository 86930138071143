.s-editorial-calendar {
  margin-top: 24px;
}

.s-editorial-calendar__products {
  @include media-breakpoint-up(lg) {
    gap: 80px;
    margin-top: 80px;
  }
}

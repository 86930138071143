@use "settings/s-colors" as colors;

$labelColors: (
  label-1: colors.$label-1,
  label-2: colors.$label-2,
  label-3: colors.$label-3,
  label-4: colors.$label-4,
  label-5: colors.$label-5,
);

.i-submenu__category-items {
  @include media-breakpoint-up(lg) {
    margin-bottom: 4px;
    padding-left: 36px;
  }
}

.i-submenu__category-item {
  &.--hidden {
    display: none;
  }
}

.i-submenu__show-more {
  margin-top: 4px;

  &.--hidden {
    display: none;
  }
}

.i-submenu__link {
  text-decoration: none;
  transition: color 0.2s ease;
  background-color: var(--background);

  &:hover {
    color: colors.$secondary-500;
  }
}

.i-submenu__label {
  padding-block: 2px;
  background-color: colors.$label-1;
}

.i-submenu {
  grid-column: span 1;

  @each $label, $color in $labelColors {
    &.--color-#{$label} {
      --background: #{$color};
    }
  }

  &.--active {
    .i-submenu__link {
      color: colors.$secondary;
    }
  }

  &.--has-items {
    grid-row: span 3;
  }
}

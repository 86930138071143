@use "settings/s-colors" as colors;
@use "settings/s-general" as general;

.i-product {
  &.--horizontal {
    width: 100%;
    border-radius: 16px;

    &::before {
      top: 8px;
      left: 98px;
    }

    .i-product__body {
      display: grid;
      grid-template-columns: 120px 1fr 40px;
      grid-template-areas:
        "image header header"
        "image content buttons";
      padding: 8px;
      gap: 16px 8px;
    }

    .i-product__content {
      align-items: center;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        "mediums price"
        "availability price";
      gap: 8px;
      margin-right: 8px;
    }

    .i-product__image {
      align-self: start;
    }

    .i-product__buttons {
      align-items: flex-end;
      justify-content: flex-end;
    }

    .i-product__price {
      align-self: end;
    }

    .i-product__cart-add {
      --min-height: 40px;
      --padding-x: 8px;
      --padding-y: 8px;

      .btn__title {
        display: none;
      }
    }

    .i-product__wishlist,
    .i-product__discount,
    .i-product__tags {
      display: none;
    }

    @container (max-width: 400px) {
      &::before {
        top: 8px;
        left: 56px;
      }

      .i-product__body {
        grid-template-columns: 80px 1fr 40px;
      }

      .i-product__content {
        grid-template-columns: 64px 1fr;
        grid-template-areas:
          "mediums mediums"
          "price price";
        gap: 12px 8px;
        min-height: 80px;
      }

      .i-product__availability {
        --font-size-title: 12px;
        --font-size: 12px;

        position: absolute;
        bottom: 8px;
        left: 8px;
        max-width: 112px;
      }
    }

    &.--hidden-buttons {
      .i-product__body {
        grid-template-areas:
          "image header header"
          "image content content";
      }

      .i-product__price {
        padding-right: 0;
      }
    }
  }
}

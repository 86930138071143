@use "settings/s-colors" as colors;

.i-product-order {
  --image-size: 88px;
  --grid-columns: var(--image-size) 300px 50px 100px auto;
  --grid-areas: "image content count price action";

  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  align-items: center;
  min-height: var(--image-size);

  .c-counter {
    --width: 128px;

    min-height: 40px;
    height: 40px;
  }

  @include media-breakpoint-down(xxl) {
    --image-size: 64px;

    grid-template-columns: var(--image-size) 3fr 1fr;
    grid-template-areas:
      "image content count"
      "price price price"
      "action action action";
  }

  @include media-breakpoint-down(sm) {
    grid-template-areas:
      "image content content"
      "count price price"
      "action action action";
  }
}

.i-product-order__image {
  grid-area: image;
  width: var(--image-size);
  height: var(--image-size);
}

.i-product-order__content {
  grid-area: content;

  @include media-breakpoint-down(xl) {
    align-self: start;
    margin-top: 8px;
    max-width: 100%;
  }
}

.i-product-order__count {
  grid-area: count;
  text-align: right;

  @include media-breakpoint-down(sm) {
    text-align: left;
  }
}

.i-product-order__price {
  grid-area: price;
}

.i-product-order__actions {
  grid-area: action;
  justify-self: end;
  flex-wrap: wrap;
}

.i-testimonial {
  --opacity: 0.8;
  --blend-mode: darken;
  --grayscale: 1;
  --width: 124px;
  --height: 51px;

  width: var(--width);
  max-width: var(--width);
  height: var(--height);
  justify-self: center;

  & .i-testimonial__image {
    opacity: var(--opacity);
    mix-blend-mode: var(--blend-mode);
    filter: grayscale(var(--grayscale));
    transition: 0.3s ease;
    transition-property: opacity, mix-blend-mode, filter;
    will-change: opacity, mix-blend-mode, filter;
  }

  &:hover {
    --opacity: 1;
    --blend-mode: darken;
    --grayscale: 0;
  }

  &.--no-hover {
    --opacity: 1;
    --blend-mode: darken;
    --grayscale: 0;
  }

  &.--sm {
    --height: 48px;
  }
}

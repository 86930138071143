$color: #0f1c7b26;

$size: 0 8px 16px 2px;

$shadow: #{$size $color};

$medium-shadow-color: rgb(49 50 96 / 30%);
$medium-shadow-size: 0 4px 30px 2px;

$small-shadow-color: rgb(213 212 218 / 30%);
$small-shadow-size: 0 2px 16px 0;

$product-card-hover-shadow-color: rgb(120 104 163 / 10%);
$product-card-hover-shadow-size: 0 4px 10px 2px;

$button-primary-hover-shadow-color: rgb(98 72 170 / 20%);
$button-primary-hover-shadow-size: 0 4px 16px 1px;

$button-secondary-hover-shadow-color: rgb(255 92 80 / 60%);
$button-secondary-hover-shadow-size: 0 4px 16px 1px;

$button-outline-shadow-color: transparent;
$button-outline-shadow-size: $button-primary-hover-shadow-size;

$small-shadow: #{$small-shadow-size $small-shadow-color};
$medium-shadow: #{$medium-shadow-size $medium-shadow-color};
$product-card-hover-shadow: #{$product-card-hover-shadow-size $product-card-hover-shadow-color};
$button-primary-hover-shadow: #{$button-primary-hover-shadow-size $button-primary-hover-shadow-color};
$button-secondary-hover-shadow: #{$button-secondary-hover-shadow-size $button-secondary-hover-shadow-color};
$button-outline-shadow: #{$button-outline-shadow-size $button-outline-shadow-color};
$button-outline-hover-shadow: #{$button-primary-hover-shadow};
$button-outline-secondary-hover-shadow: #{$button-secondary-hover-shadow};

@use "settings/s-shadows" as shadows;

.s-top-products__body {
  box-shadow: shadows.$small-shadow;
}

.s-top-products__products {
  &.--hidden {
    display: none;
  }
}

.s-top-products__button {
  &.--hidden {
    display: none;
  }
}

.s-cart__price-calculation {
  --grid-template-columns: 1fr;
  --grid-template-areas: "benefits" "aside";

  grid-template-columns: var(--grid-template-columns);
  grid-template-areas: var(--grid-template-areas);

  @include media-breakpoint-up(lg) {
    --grid-template-columns: 1fr 1fr;
    --grid-template-areas: "benefits aside";
  }

  @include media-breakpoint-up(xl) {
    --grid-template-columns: 2fr 1fr;
  }

  .s-cart__benefits {
    grid-area: benefits;
    align-self: start;
  }

  .s-cart__aside {
    grid-area: aside;
  }
}

.s-cart {
  --empty-display: none;
  --content-display: flex;

  &.--empty {
    --empty-display: block;
    --content-display: none;
  }
}

.s-cart__content {
  display: var(--content-display);
}

.s-cart__empty {
  display: var(--empty-display);
}

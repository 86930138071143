@use "settings/s-colors" as colors;

.i-thumbnail {
  border: 1px solid transparent;
  transition: border-color 0.2s ease;

  img {
    border: 1px solid colors.$primary-100;
  }
}

.g-testimonials {
  @include media-breakpoint-only(lg) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xxl) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-testimonials__last {
  height: 51px;
}

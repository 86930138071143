@use "settings/s-colors" as colors;

.i-rating {
  .base-heading::before {
    display: none;
  }

  &.--vertical {
    flex-direction: row-reverse;

    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }
  }

  &.--horizontal {
    flex-direction: row-reverse;
    gap: 16px;

    .base-heading {
      font-size: 24px;
    }
  }
}

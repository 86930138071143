@use "settings/s-colors" as colors;

.i-wishlist-button__button.--full {
  display: none;
}

.i-wishlist-button {
  &:hover {
    .i-wishlist-button__button {
      .icon {
        --color: #{colors.$secondary-500};
      }
    }
  }

  &.--filled {
    .i-wishlist-button__button {
      &.--full {
        display: flex;
      }

      &.--empty {
        display: none;
      }
    }
  }
}

@use "settings/s-colors.scss" as colors;

.i-review {
  row-gap: 4px;

  @include media-breakpoint-up(lg) {
    column-gap: 24px;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}

.i-review__icon-logo {
  --w: 104px;
  --h: 56px;

  flex: 0 0 var(--w);
  max-width: var(--w);
  height: var(--h);
}

.i-review__stars {
  column-gap: 4px;

  .icon {
    --size: 16px;

    @include media-breakpoint-up(md) {
      --size: 24px;

      column-gap: 8px;
    }
  }
}

.i-review__rating {
  @include media-breakpoint-up(xl) {
    justify-content: start;
  }
}

.i-review__details {
  .i-review__details-tag {
    font-weight: 700;
    color: colors.$primary;
    max-height: 24px;

    --min-height: none;
    --padding-x: 10px;
  }

  @include media-breakpoint-up(xl) {
    justify-content: start;
  }
}

@use "settings/s-colors" as colors;

.i-add-wishlist-button {
  --svg-empty: block;
  --svg-full: none;

  &.--wishlisted {
    --svg-empty: none;
    --svg-full: block;
  }

  .s-heart__empty {
    display: var(--svg-empty);
    transition: fill 0.3s ease;
  }

  .s-heart__full {
    display: var(--svg-full);
  }

  &:hover {
    .s-heart__empty {
      fill: colors.$secondary-500;
    }
  }
}

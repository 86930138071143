.content-w-aside__content {
  .base-section {
    .container {
      padding-inline: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    grid-column: 4 / span 8;
  }
}

.content-w-aside__aside {
  @include media-breakpoint-up(xl) {
    grid-column: span 4;
    will-change: min-height;
  }

  &.--sticky {
    @include media-breakpoint-up(xl) {
      height: max-content;
    }

    .content-w-aside__aside-inner {
      @include media-breakpoint-up(xl) {
        transform: translate3d(0, 0, 0);
        will-change: position, transform;
      }
    }
  }
}

.content-w-aside {
  --spacing: var(--section-spacing);

  padding-block: var(--spacing);

  &.--top-0 {
    padding-top: 0;
  }

  &.--top-3 {
    padding-top: 24px;
  }

  &.--top-6 {
    padding-top: 48px;
  }

  &.--bottom-0 {
    padding-bottom: 0;
  }

  &.--none {
    --padding-y: 0;
  }

  &.--reverse,
  &.--reverse-lg {
    .content-w-aside__aside {
      order: -1;
      grid-column: span 3;
    }

    .content-w-aside__content {
      grid-column: 4 / span 9;
    }
  }

  &.--reverse-lg {
    @include media-breakpoint-down(lg) {
      .content-w-aside__aside {
        order: 0;
      }
    }
  }

  &.--default-lg {
    @include media-breakpoint-down(lg) {
      .content-w-aside__content {
        order: -1;
      }
    }
  }

  &.--columns-4 {
    @include media-breakpoint-up(xl) {
      .content-w-aside__aside {
        grid-column: span 4;
      }

      .content-w-aside__content {
        grid-column: 1 / span 8;
      }
    }
  }

  &:not(:has(.content-w-aside__aside)) {
    .content-w-aside__content {
      grid-column: 2 / span 10;
    }
  }
}

.content-w-aside__row {
  --gap: 32px;

  gap: var(--gap);

  @include media-breakpoint-down(xl) {
    --gap: 80px;
  }

  @include media-breakpoint-down(sm) {
    --gap: 40px;
  }

  @include media-breakpoint-up(xl) {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
  }
}

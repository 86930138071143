@use "settings/s-colors.scss" as colors;

.m-add-product-to-cart-message {
  &.--success {
    background-color: colors.$success-200;
  }

  &.--error {
    background-color: colors.$danger-200;
  }
}

@use "settings/s-colors" as colors;

.base-header {
  --spacing: 24px;

  margin-inline: auto;
  max-width: 928px;
  margin-bottom: var(--spacing);

  &.--left {
    align-items: flex-start;
    text-align: left;
    margin-inline: 0;
  }

  &.--spacing-none {
    --spacing: 0;
  }

  &.--spacing-lg {
    --spacing: 56px;

    @include media-breakpoint-down(sm) {
      --spacing: 40px;
    }
  }

  @include media-breakpoint-up(md) {
    --spacing: 48px;
  }
}

.base-header__description {
  p {
    margin-bottom: 0;
  }
}

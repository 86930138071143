@use "settings/s-colors.scss" as colors;

.i-sale-voucher {
  --padding-y: 16px;
  --padding-x: 80px;

  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  overflow: hidden;

  @include media-breakpoint-down(xxl) {
    --padding-x: 40px;
  }

  @include media-breakpoint-down(lg) {
    --padding-x: 16px;

    flex-direction: column;
    justify-content: center;
  }
}

.i-sale-voucher__title {
  line-height: 16px;
  user-select: none;
}

@use "settings/s-colors.scss" as colors;

.f-label {
  --top: 50%;
  --translate-y: -50%;
  --color: #{colors.$primary-500};

  position: absolute;
  top: var(--top);
  left: 20px;
  margin-right: 1px;
  transform: translateY(var(--translate-y));
  transition:
    transform 0.3s ease,
    font-size 0.3s ease,
    top 0.3s ease,
    left 0.3s ease,
    color 0.3s ease;
  color: var(--color);
  background-color: colors.$white;
  font-size: 14px;
  line-height: 16px;
  pointer-events: none;
  font-weight: 400;
  padding-inline: 4px;
  z-index: 2;

  &.--required {
    &::after {
      content: "*";
      color: colors.$primary-500;
      padding-left: 4px;
    }
  }

  &.--active {
    --color: #{colors.$primary-600};
    --top: 0;

    font-size: 12px;
  }
}

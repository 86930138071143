@use "settings/s-colors" as colors;

.f-interprets-filter {
  background-color: colors.$white;
  border-radius: 24px;
  box-shadow: 0 2px 16px 0 rgb(213 212 218 / 30%);

  .f-text {
    --p-offset: 176px;

    width: calc(50% + 16px);

    .f-text__input {
      padding-right: var(--p-offset);

      @include media-breakpoint-down(sm) {
        min-height: 48px;
        padding-block: 12px;
      }
    }

    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      --p-offset: 16px;
    }
  }
}

.f-interprets-filter__submit {
  --m-offset: calc(-1 * 160px);

  margin-left: var(--m-offset);

  .btn {
    --font-size: 16px;

    border: 1px solid colors.$primary-600;
    min-width: 160px;
    line-height: 24px;
  }

  @include media-breakpoint-down(md) {
    --m-offset: 0;

    position: static;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.f-interprets-filter__keypad-btn.btn {
  --font-size: 18px;
  --min-height: 48px;

  width: var(--min-height);
  padding: 0;
  line-height: 24px;

  &:nth-child(1) {
    width: 64px;
  }
}

@use "settings/s-colors.scss" as colors;

.i-information__label {
  @include media-breakpoint-up(sm) {
    flex-shrink: 0;
    width: min(100%, 112px);
  }
}

.i-information__item {
  --background-color: #{colors.$background};

  row-gap: 8px;
  border-radius: 8px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  &:nth-child(even) {
    background-color: var(--background-color);
  }
}

.i-information__content {
  max-width: 80ch;
}

.i-information {
  &.--background-inverted {
    .i-information__item {
      --background-color: #{colors.$white};
    }
  }
}

.g-interprets-category {
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-interprets-category__heading,
.g-interprets__load-more-wrapper {
  grid-column: 1 / -1;
}

@use "settings/s-colors" as colors;

:root {
  /* Section spacing */
  --section-spacing: 80px;

  @include media-breakpoint-down(sm) {
    --section-spacing: 40px;
  }
}

@font-face {
  font-family: "Adjusted Verdana";
  size-adjust: 80%;
  src: local(verdana);
}

@font-face {
  font-family: "Adjusted Georgia";
  size-adjust: 78%;
  src: local(georgia);
}

@font-face {
  font-family: Blinker;
  src: url("../fonts/Blinker-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Blinker;
  src: url("../fonts/Blinker-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/Inter-VariableFont_opsz,wght.woff2") format("woff2");
  font-optical-sizing: auto;
  font-weight: 400 500 700;
  font-style: normal;
  font-display: fallback;
}

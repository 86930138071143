@use "settings/s-colors" as colors;

.s-login__grid {
  max-width: 928px;
  width: 100%;
  margin-inline: auto;
  align-items: center;

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-login__text-side {
  @include media-breakpoint-down(md) {
    padding: 24px;
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}

.s-login__content.entry-content {
  ul {
    margin-top: 32px;
  }

  li {
    margin-bottom: 32px;
  }

  li:last-child {
    margin-bottom: 0;
  }
}

.s-login__form-box {
  box-shadow: 0 4px 30px 2px rgb(49 41 96 / 30%);
  border: 1px solid colors.$tertiary-300;
  border-radius: 16px;
  max-width: 448px;
  width: 100%;
}

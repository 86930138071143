@use "settings/s-colors" as colors;

.i-filtration__header {
  cursor: pointer;
  min-height: 40px;
  border-radius: 24px;
  background-color: transparent;
  transition: 0.3s ease background-color;

  @media (hover: hover) {
    &:hover {
      background-color: colors.$primary-100;
    }
  }
}

.i-filtration__title {
  margin-left: -18px;
  transition: margin-left 0.3s ease;
}

.i-filtration__body {
  height: var(--height);
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity 0s ease;
}

.i-filtration__dropdown {
  scale: var(--dropdown-scale);
  transition: scale 0.3s ease;
}

.i-filtration__decor {
  opacity: var(--opacity);
  transition: opacity 0.3s ease;
}

.i-filtration__range-year {
  .f-base {
    min-height: 40px;
    padding-block: 10px;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}

.i-filtration__range-inputs {
  @include media-breakpoint-only(xl) {
    flex-direction: column;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.i-filtration {
  --dropdown-scale: 1;
  --opacity: 0;
  --height: 0;
  --pointer-events: none;

  overflow: hidden;

  .f-range-slider {
    padding-top: 32px;

    .f-range-slider__inputs {
      @include media-breakpoint-only(xl) {
        flex-direction: column;
      }
    }
  }

  &.--open {
    --dropdown-scale: -1;
    --opacity: 1;
    --height: auto;
    --pointer-events: all;

    overflow: auto;

    .i-filtration__title {
      margin-left: 0;
    }

    .i-filtration__body {
      transition: opacity 0.3s ease;
    }
  }
}

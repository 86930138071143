@use "settings/s-shadows.scss" as shadows;
@import "./src/components/items/tab-control-item/tab-control-item";

.i-new-products {
  box-shadow: shadows.$small-shadow;

  @include media-breakpoint-down(md) {
    padding-inline: 8px;
  }
}

.i-new-products__slider-buttons {
  left: -26px;
  right: -26px;
  top: 106px;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.i-new-products__slider {
  .swiper-wrapper {
    @include media-breakpoint-down(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }

  .swiper-slide {
    height: auto;
    cursor: pointer;
  }

  &.swiper {
    overflow: clip;
    overflow-clip-margin: 8px;

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        gap: 16px;
      }

      .swiper-slide {
        @include media-breakpoint-up(sm) {
          width: 192px;
          flex: 0 0 192px;
        }
      }
    }
  }
}

.i-new-products__header {
  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}

.i-new-products__tab-controls {
  justify-content: center;
}

.i-new-products__button {
  &.--hidden {
    display: none;
  }
}

.i-product-review {
  .i-product-review__rating {
    @media (width <= 395px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }

  &:not(.--compact) {
    .i-product-review__rating {
      @include media-breakpoint-only(lg) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    }
  }
}

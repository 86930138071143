@use "settings/s-colors" as colors;

.flatpickr-calendar {
  --theme-color: #{colors.$primary-600};
  --theme-color-hover: #f1ecf6;
  --border-color: #{colors.$primary-300};
  --font-color: #{colors.$tertiary};
  --font-color-out: #{colors.$tertiary-400};

  &.open {
    border-radius: 24px;
    box-shadow: 0 2px 16px 0 rgb(213 212 218 / 30%);
    width: 408px;
    background: colors.$background;

    &::before,
    &::after {
      display: none;
    }
  }

  .flatpickr-day {
    color: var(--font-color);
    width: 100%;
    max-width: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;

    &:hover {
      background: var(--theme-color-hover);
      border: 1px solid var(--border-color);
    }
  }

  .flatpickr-day.selected {
    background: var(--theme-color);
    border-color: var(--theme-color);

    &:hover {
      background: var(--theme-color);
      border-color: var(--theme-color);
    }
  }

  .flatpickr-day.startRange,
  .flatpickr-day.endRange {
    &.today,
    &.selected.today {
      background: transparent;
      border-color: var(--theme-color);
      color: var(--font-color);

      &:hover {
        background: transparent;
        border-color: var(--theme-color);
        color: var(--font-color);
      }
    }

    &.prevMonthDay.selected.today,
    &.nextMonthDay.selected.today {
      color: var(--font-color-out);

      &:hover {
        color: var(--font-color-out);
      }
    }
  }

  .flatpickr-day.startRange,
  .flatpickr-day.startRange.selected,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.startRange.prevMonthDay {
    box-shadow: 5px 0 var(--theme-color);

    &:hover {
      box-shadow: 5px 0 var(--theme-color);
    }
  }

  .flatpickr-day.endRange,
  .flatpickr-day.endRange.selected,
  .flatpickr-day.endRange.nextMonthDay,
  .flatpickr-day.endRange.prevMonthDay {
    box-shadow: -5px 0 var(--theme-color);

    &:hover {
      box-shadow: -5px 0 var(--theme-color);
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay:not(.flatpickr-disabled),
  .flatpickr-day.startRange.selected,
  .flatpickr-day.inRange.nextMonthDay,
  .flatpickr-day.inRange.prevMonthDay,
  .flatpickr-day.inRange.prevMonthDay:not(.flatpickr-disabled),
  .flatpickr-day.endRange.nextMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay:not(.flatpickr-disabled),
  .flatpickr-day.endRange.selected {
    background: var(--theme-color);
    border-color: var(--theme-color);
    color: var(--border-color);

    &:hover {
      background: var(--theme-color);
      border-color: var(--theme-color);
      color: var(--border-color);
    }
  }

  .flatpickr-day.inRange {
    box-shadow:
      -5px 0 var(--theme-color),
      5px 0 var(--theme-color);

    &.today {
      background: transparent;
      border-color: var(--theme-color);
      color: var(--font-color);
    }

    &.nextMonthDay.today,
    &.prevMonthDay.today {
      background: transparent;
      border-color: var(--theme-color);
      color: var(--font-color-out);
    }

    &:hover {
      box-shadow:
        -5px 0 var(--theme-color),
        5px 0 var(--theme-color);
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .flatpickr-day.startRange,
  .flatpickr-day.startRange.selected,
  .flatpickr-day.inRange,
  .flatpickr-day.endRange,
  .flatpickr-day.endRange.selected {
    background: var(--theme-color);
    border-color: var(--theme-color);
    color: colors.$white;

    &:hover {
      background: var(--theme-color);
      border-color: var(--theme-color);
      color: colors.$white;
    }
  }

  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) .flatpickr-day.selected {
    box-shadow: -10px 0 var(--theme-color);

    &:hover {
      box-shadow: -10px 0 var(--theme-color);
    }
  }

  .flatpickr-day.today {
    border: 2px solid var(--theme-color);

    &:hover {
      background: transparent;
      border-color: var(--theme-color);
      color: var(--font-color);
    }
  }

  .flatpickr-day.nextMonthDay,
  .flatpickr-day.prevMonthDay:not(.flatpickr-disabled) {
    color: var(--font-color-out);

    &:hover {
      background: var(--theme-color-hover);
      border-color: var(--border-color);
    }
  }

  .flatpickr-day.nextMonthDay.selected,
  .flatpickr-day.prevMonthDay.selected:not(.flatpickr-disabled) {
    color: var(--border-color);
    background: var(--theme-color);
    border-color: var(--theme-color);
  }

  .flatpickr-day.nextMonthDay.today,
  .flatpickr-day.prevMonthDay.today:not(.flatpickr-disabled) {
    color: var(--font-color-out);
    border-color: var(--theme-color);
    background: transparent;

    &:hover {
      background: transparent;
      border-color: var(--theme-color);
      color: var(--font-color-out);
    }
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.prevMonthDay.flatpickr-disabled {
    color: var(--font-color-out);

    &:hover {
      color: var(--font-color-out);
    }
  }

  .flatpickr-day.startRange.nextMonthDay.selected,
  .flatpickr-day.startRange.prevMonthDay.selected,
  .flatpickr-day.endRange.nextMonthDay.selected,
  .flatpickr-day.endRange.prevMonthDay.selected {
    color: var(--border-color);
  }

  .flatpickr-day.startRange.endRange.selected {
    box-shadow: none;
  }

  .flatpickr-months {
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    padding-bottom: 24px;

    .flatpickr-prev-month,
    .flatpickr-next-month {
      position: relative;
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      border: 1px solid var(--border-color);
      border-radius: 8px;
      background: colors.$background;
      box-shadow: 0 4px 16px 1px transparent;
      transition:
        background 0.3s ease,
        box-shadow 0.3s ease;

      &:hover {
        background: colors.$white;
        box-shadow: 0 4px 16px 1px rgb(98 72 170 / 20%);
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-position: center center;
      }

      svg {
        display: none;
      }
    }

    .flatpickr-prev-month::before {
      background-image: url("../images/svg/calendar-left.svg");
    }

    .flatpickr-next-month::before {
      background-image: url("../images/svg/calendar-right.svg");
    }

    .flatpickr-month {
      height: 48px;
      width: 100%;
      display: flex;
    }

    .flatpickr-current-month {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      position: relative;
      padding-top: 0;
      width: 100%;
      height: 48px;
      left: 0;
    }

    .flatpickr-monthDropdown-months,
    .numInput.cur-year {
      color: var(--theme-color);
      font-weight: 600;
      text-decoration: none;
      font-size: 14px;
      line-height: 24px;
      padding: 0;
      text-align: center;
      appearance: none;
    }

    .numInput.cur-year {
      appearance: textfield;
    }

    .numInputWrapper {
      margin-left: -16px;

      span {
        line-height: normal;
        padding: 0;
        width: 16px;
        height: 16px;
        opacity: 1;
        border: none;
        background: transparent;

        &::after {
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: center center;
          border: none;
        }
      }

      .arrowUp {
        top: -4px;
        right: -4px;

        &::after {
          background-image: url("../images/svg/arrow-up-num-input.svg");
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .arrowDown {
        top: 12px;
        right: -4px;

        &::after {
          background-image: url("../images/svg/arrow-down-num-input.svg");
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:hover {
        background: transparent;

        .numInput.cur-year {
          text-decoration-color: transparent;
        }
      }
    }

    .flatpickr-monthDropdown-months {
      background-image: url("../images/svg/calendar-down.svg");
      background-repeat: no-repeat;
      background-position: right center;
      padding-right: 24px;

      &:hover {
        background: unset;
        background-image: url("../images/svg/calendar-down.svg");
        background-repeat: no-repeat;
        background-position: right center;
        text-decoration-color: transparent;
      }
    }
  }

  .flatpickr-days {
    width: 100%;
  }

  .dayContainer {
    margin-top: 8px;
    margin-bottom: -48px;
    gap: 8px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    justify-content: center;
  }

  .flatpickr-weekdays {
    height: 56px;
    background: var(--theme-color-hover);
    border-radius: 8px;
    padding: 8px;
    justify-content: center;
  }

  .flatpickr-weekdaycontainer {
    justify-content: center;
    gap: 8px;
  }

  span.flatpickr-weekday {
    color: var(--theme-color);
    font-size: 16px;
    font-weight: 700;
    max-width: 40px;
    width: 100%;
  }
}

.flatpickr-innerContainer {
  padding: 32px;
  padding-top: 0;
}

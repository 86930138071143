.g-mediums:not(.--toggle) {
  @container (min-width: 264px) {
    &:not(:has(> :nth-child(5))) {
      .g-mediums__show-more {
        display: none;
      }
    }

    &:has(> :nth-child(5)) {
      & > .i-medium {
        display: none;

        &:nth-child(-n + 2) {
          display: flex;
        }
      }

      .g-mediums.--toggle {
        & > :first-child {
          display: none;
        }

        & > :nth-child(2) {
          &::before {
            content: "";
          }
        }
      }
    }
  }

  @container (min-width: 172px) and (max-width: 263px) {
    & > .i-medium {
      display: none;

      &:nth-child(-n + 2) {
        display: flex;
      }

      &:nth-child(2):not(:nth-last-child(2)) {
        display: none;
      }
    }

    .g-mediums__show-more {
      &:nth-child(3) {
        display: none;
      }
    }
  }

  @container (max-width: 171px) {
    & > .i-medium {
      display: none;

      &:first-child {
        display: flex;
      }
    }
  }

  &.--overflows {
    & > .i-medium {
      &:first-child {
        max-width: calc(100% - 64px);
      }

      &:not(:first-child) {
        display: none;
      }
    }

    .g-mediums__show-more {
      display: flex;
    }

    .g-mediums.--toggle {
      > .i-medium {
        display: flex;

        &::before {
          content: "+";
        }
      }
    }
  }
}

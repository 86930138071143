@use "settings/s-colors" as colors;

.swiper {
  --swiper-pagination-color: #{colors.$primary};

  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  &.--hidden {
    display: none;
  }
}

.swiper-button-disabled.btn {
  opacity: 0;
  pointer-events: none;
}

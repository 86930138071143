@use "settings/s-colors.scss" as colors;

.main-footer-column {
  &:nth-child(4) {
    min-width: 100%;

    @include media-breakpoint-up(md) {
      min-width: 264px;
    }

    @include media-breakpoint-up(xxl) {
      min-width: 448px;
    }
  }
}

.main-footer-column__link {
  overflow-wrap: break-word;
  word-break: break-all;
}

.main-footer-column__delivery {
  .g-methods {
    gap: 16px;
  }
}

@use "settings/s-general.scss" as general;

.u-rounded-full {
  border-radius: 9999px;
}

@for $i from 1 through 5 {
  .u-radius-#{$i} {
    border-radius: $i * general.$spacing;
  }
}

.u-radius-4px {
  border-radius: 4px;
}

@use "settings/s-colors" as colors;

.i-order {
  border: 1px solid colors.$primary-300;
}

.i-order-column {
  --images-col: 160px;
  --status-col: 160px;
  --id-col: 140px;
  --date-col: 140px;
  --price-col: 1fr;

  width: max-content;
  min-width: 100%;
  grid-template-columns: var(--images-col) var(--status-col) var(--id-col) var(--date-col) var(--price-col) 48px;

  @include media-breakpoint-only(xl) {
    --date-col: 100px;
  }
}

.i-order__products-count {
  width: 48px;
  height: 48px;
}

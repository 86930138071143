@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.btn {
  &.--primary {
    --background: #{colors.$primary-600};

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$primary};

        box-shadow: shadows.$button-primary-hover-shadow;
      }
    }
  }

  &.--secondary {
    --background: #{colors.$secondary-500};

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$secondary};

        box-shadow: shadows.$button-secondary-hover-shadow;
      }
    }
  }

  &.--secondary-to-primary {
    --background: #{colors.$secondary-500};

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$primary-600};
      }
    }
  }

  &.--transparent {
    --background: transparent;

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color;
  }

  &.--outline-secondary {
    --font-color: #{colors.$secondary-500};
    --inner-shadow: inset 0 0 0 1px #{colors.$secondary-500};
    --background: #{colors.$white};

    background-color: var(--background);
    box-shadow: shadows.$button-outline-shadow, var(--inner-shadow);
    transition: background-color 0.3s ease;
    transition-property: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        box-shadow: shadows.$button-outline-hover-shadow, var(--inner-shadow);
      }
    }
  }

  &.--outline {
    --font-color: #{colors.$primary-600};
    --inner-shadow: inset 0 0 0 1px #{colors.$primary-300};
    --background: #{colors.$background};

    background-color: var(--background);
    box-shadow: shadows.$button-outline-shadow, var(--inner-shadow);
    transition: background-color 0.3s ease;
    transition-property: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$white};

        box-shadow: shadows.$button-outline-hover-shadow, var(--inner-shadow);
      }
    }
  }

  &.--outline-transparent {
    --font-color: #{colors.$primary-600};
    --inner-shadow: inset 0 0 0 1px #{colors.$primary-300};
    --background: #{rgba(colors.$background, 0.5)};

    background-color: var(--background);
    box-shadow: shadows.$button-outline-shadow, var(--inner-shadow);
    transition: background-color 0.3s ease;
    transition-property: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$white};

        box-shadow: shadows.$button-outline-hover-shadow, var(--inner-shadow);
      }
    }
  }

  &.--outline-transparent-primary-300 {
    --font-color: #{colors.$primary-300};
    --border-color: #{colors.$primary-300};
    --background: transparent;

    background-color: var(--background);
    border: 1px solid var(--border-color);
    transition: background-color 0.3s ease;
    transition-property: background-color, box-shadow;
  }

  &.--ghost {
    --background: transparent;
    --font-color: #{colors.$primary};

    background-color: var(--background);
    transition: background-color 0.3s ease;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$primary-100};
      }
    }
  }

  &.--facebook {
    --background: #{colors.$facebook};
    --radius: 8px;
    --background-hover: rgb(59 89 152 / 80%);

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color;

    &.--icon-only {
      width: 100%;
      flex-shrink: 1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--background-hover);
      }
    }
  }

  &.--google {
    --background: #{colors.$white};
    --radius: 8px;
    --background-hover: rgb(0 0 0 / 5%);

    path {
      fill: revert-layer;
    }

    border: 1px solid colors.$tertiary-400;
    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color;

    &.--icon-only {
      width: 100%;
      flex-shrink: 1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--background-hover);
      }
    }
  }

  &.--apple {
    --background: #{colors.$apple};
    --radius: 8px;
    --background-hover: rgb(17 17 17 / 80%);

    background-color: var(--background);
    transition: background-color 0.3s ease;
    transition-property: background-color;

    &.--icon-only {
      width: 100%;
      flex-shrink: 1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--background-hover);
      }
    }
  }

  &.--link {
    --border-radius: 0;
    --font-size: 14px;
    --font-weight: 400;
    --font-color: #{colors.$primary-600};
    --background: transparent;
    --underline-color: #{colors.$primary-600};
    --min-height: 24px;

    background-color: var(--background);
    color: var(--font-color);
    text-transform: none;
    padding: 0;
    transition: color 0.3s ease;

    .btn__title {
      text-decoration: underline;
      text-decoration-color: var(--underline-color);
      transition: text-decoration-color 0.3s ease;
    }

    path {
      transition: fill 0.3s ease;
      will-change: fill;
    }

    @media (hover: hover) {
      &:hover {
        --font-color: #{colors.$primary};
        --underline-color: transparent;
      }
    }
  }

  &.--disabled {
    opacity: 0.4;
    pointer-events: none;

    &.--outline,
    &.--outline-transparent {
      --font-color: #{colors.$tertiary-400};
      --inner-shadow: inset 0 0 0 1px #{colors.$tertiary-400};
    }
  }

  &.--swiper {
    --font-color: #{colors.$primary-600};
    --inner-shadow: inset 0 0 0 1px #{colors.$primary-300};
    --background: #{rgba(colors.$white, 0.5)};

    background-color: var(--background);
    box-shadow: shadows.$button-outline-shadow, var(--inner-shadow);
    transition: background-color 0.3s ease;
    transition-property: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background: #{colors.$white};

        box-shadow: shadows.$button-outline-hover-shadow, var(--inner-shadow);
      }
    }
  }

  &.--no-style {
    --font-color: #{colors.$black};
    --background: transparent;

    padding: 0;
    min-height: 0;
    background-color: var(--background);
    color: var(--font-color);
    font-size: 14px;

    .btn__body {
      padding: 0;
      min-height: auto;
    }

    path {
      fill: var(--font-color);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--font-color);
      }
    }
  }
}

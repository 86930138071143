@use "settings/s-colors.scss" as colors;

.g-terms__checkbox {
  border-radius: 8px;

  .f-checkbox__title {
    font-weight: 400;
  }
}

.g-terms__text-only {
  line-height: 16px;

  a {
    display: inline-block;
    color: colors.$primary-600;
    font-weight: 600;
  }
}

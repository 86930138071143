.g-interprets {
  @media (width > 359px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-breakpoint-up(xxl) {
    grid-template-columns: repeat(6, 1fr);
  }
}

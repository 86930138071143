@use "settings/s-colors" as colors;

.i-intro-banner__inner {
  --max-width: 524px;

  max-width: var(--max-width);
  margin-left: var(--m-left);

  @include media-breakpoint-down(sm) {
    --max-width: 100%;
  }
}

.i-intro-banner {
  --min-height: 254px;

  min-height: var(--min-height);
  color: var(--font-color);
  text-align: var(--text-align);

  &.--light-text {
    --font-color: #{colors.$white};
    --text-background: rgba(60 64 67 / 70%);
    --blend-mode: multiply;
  }

  &.--dark-text {
    --font-color: #{colors.$primary};
    --text-background: rgba(249 249 249 / 70%);
    --blend-mode: normal;
  }

  &.--left {
    --text-align: left;

    @include media-breakpoint-down(sm) {
      --text-align: center;
    }
  }

  &.--right {
    --text-align: right;
    --m-left: auto;

    @include media-breakpoint-down(sm) {
      --text-align: center;
      --m-left: 0;
    }
  }

  &.--text-background {
    .i-intro-banner__inner:has(.i-intro-banner__inner-background) {
      padding: 16px;
    }

    .i-intro-banner__inner-background {
      width: 100%;
      height: 100%;
      background-color: var(--text-background);
      mix-blend-mode: var(--blend-mode);
      z-index: -1;
      transform: translate(-16px, -16px);
    }
  }

  @include media-breakpoint-down(sm) {
    --min-height: auto;

    padding: 24px;
  }
}

.i-intro-banner__description {
  line-height: 24px;
}

.i-intro-banner__buttons {
  margin-left: var(--m-left);
}

.i-intro-banner__image {
  z-index: -2;
}

@use "settings/s-colors.scss" as colors;

.link__icon {
  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }
}

.link {
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  text-decoration-color: transparent;

  &:hover {
    text-decoration-color: inherit;
  }

  &.--icon-before {
    .link__icon {
      order: -1;
    }
  }

  &.--icon-background {
    .link__icon {
      position: relative;
      width: 24px;
      height: 24px;
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
        border-radius: 56px;
        background-color: colors.$secondary-500;
        z-index: -1;
      }

      svg {
        margin-left: 0.5px;
      }
    }
  }

  &.--underline-none {
    text-decoration: none;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 8px 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1460px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1500px;
  --bs-breakpoint-xxxl: 1920px;
}

.row {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-05,
.gx-05 {
  --bs-gutter-x: 4px;
}

.g-05,
.gy-05 {
  --bs-gutter-y: 4px;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 8px;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 8px;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 16px;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 16px;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 24px;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 24px;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 32px;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 32px;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 40px;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 40px;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 48px;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 48px;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 56px;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 56px;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 64px;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 64px;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 72px;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 72px;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 80px;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 80px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-05,
  .gx-sm-05 {
    --bs-gutter-x: 4px;
  }
  .g-sm-05,
  .gy-sm-05 {
    --bs-gutter-y: 4px;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 8px;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 8px;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 16px;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 16px;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 24px;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 24px;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 32px;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 32px;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 40px;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 40px;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 48px;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 48px;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 56px;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 56px;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 64px;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 64px;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 72px;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 72px;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 80px;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-05,
  .gx-md-05 {
    --bs-gutter-x: 4px;
  }
  .g-md-05,
  .gy-md-05 {
    --bs-gutter-y: 4px;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 8px;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 8px;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 16px;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 16px;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 24px;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 24px;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 32px;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 32px;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 40px;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 40px;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 48px;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 48px;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 56px;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 56px;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 64px;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 64px;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 72px;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 72px;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 80px;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-05,
  .gx-lg-05 {
    --bs-gutter-x: 4px;
  }
  .g-lg-05,
  .gy-lg-05 {
    --bs-gutter-y: 4px;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 8px;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 8px;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 16px;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 16px;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 24px;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 24px;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 32px;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 32px;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 40px;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 40px;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 48px;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 48px;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 56px;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 56px;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 64px;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 64px;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 72px;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 72px;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 80px;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-05,
  .gx-xl-05 {
    --bs-gutter-x: 4px;
  }
  .g-xl-05,
  .gy-xl-05 {
    --bs-gutter-y: 4px;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 8px;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 8px;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 16px;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 16px;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 24px;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 24px;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 32px;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 32px;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 48px;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 48px;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 56px;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 56px;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 64px;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 64px;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 72px;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 72px;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 80px;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 1500px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-05,
  .gx-xxl-05 {
    --bs-gutter-x: 4px;
  }
  .g-xxl-05,
  .gy-xxl-05 {
    --bs-gutter-y: 4px;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 8px;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 8px;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 16px;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 16px;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 24px;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 24px;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 32px;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 32px;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 48px;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 48px;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 56px;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 56px;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 64px;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 64px;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 72px;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 72px;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 80px;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 80px;
  }
}
@media (min-width: 1920px) {
  .col-xxxl {
    flex: 1 0 0%;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxxl-05,
  .gx-xxxl-05 {
    --bs-gutter-x: 4px;
  }
  .g-xxxl-05,
  .gy-xxxl-05 {
    --bs-gutter-y: 4px;
  }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 8px;
  }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 8px;
  }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 16px;
  }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 16px;
  }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 24px;
  }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 24px;
  }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 32px;
  }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 32px;
  }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xxxl-6,
  .gx-xxxl-6 {
    --bs-gutter-x: 48px;
  }
  .g-xxxl-6,
  .gy-xxxl-6 {
    --bs-gutter-y: 48px;
  }
  .g-xxxl-7,
  .gx-xxxl-7 {
    --bs-gutter-x: 56px;
  }
  .g-xxxl-7,
  .gy-xxxl-7 {
    --bs-gutter-y: 56px;
  }
  .g-xxxl-8,
  .gx-xxxl-8 {
    --bs-gutter-x: 64px;
  }
  .g-xxxl-8,
  .gy-xxxl-8 {
    --bs-gutter-y: 64px;
  }
  .g-xxxl-9,
  .gx-xxxl-9 {
    --bs-gutter-x: 72px;
  }
  .g-xxxl-9,
  .gy-xxxl-9 {
    --bs-gutter-y: 72px;
  }
  .g-xxxl-10,
  .gx-xxxl-10 {
    --bs-gutter-x: 80px;
  }
  .g-xxxl-10,
  .gy-xxxl-10 {
    --bs-gutter-y: 80px;
  }
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-fill {
  object-fit: fill;
}

.object-fit-scale {
  object-fit: scale-down;
}

.object-fit-none {
  object-fit: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: var(--bs-box-shadow);
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm);
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg);
}

.shadow-none {
  box-shadow: none;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity));
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity));
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity));
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity));
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity));
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity));
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity));
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity));
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity));
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity));
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle);
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle);
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle);
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle);
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle);
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle);
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle);
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle);
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-05 {
  margin: 4px;
}

.m-1 {
  margin: 8px;
}

.m-2 {
  margin: 16px;
}

.m-3 {
  margin: 24px;
}

.m-4 {
  margin: 32px;
}

.m-5 {
  margin: 40px;
}

.m-6 {
  margin: 48px;
}

.m-7 {
  margin: 56px;
}

.m-8 {
  margin: 64px;
}

.m-9 {
  margin: 72px;
}

.m-10 {
  margin: 80px;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-05 {
  margin-right: 4px;
  margin-left: 4px;
}

.mx-1 {
  margin-right: 8px;
  margin-left: 8px;
}

.mx-2 {
  margin-right: 16px;
  margin-left: 16px;
}

.mx-3 {
  margin-right: 24px;
  margin-left: 24px;
}

.mx-4 {
  margin-right: 32px;
  margin-left: 32px;
}

.mx-5 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-6 {
  margin-right: 48px;
  margin-left: 48px;
}

.mx-7 {
  margin-right: 56px;
  margin-left: 56px;
}

.mx-8 {
  margin-right: 64px;
  margin-left: 64px;
}

.mx-9 {
  margin-right: 72px;
  margin-left: 72px;
}

.mx-10 {
  margin-right: 80px;
  margin-left: 80px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-05 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-4 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-5 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-6 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.my-7 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.my-8 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.my-9 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.my-10 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-05 {
  margin-top: 4px;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mt-6 {
  margin-top: 48px;
}

.mt-7 {
  margin-top: 56px;
}

.mt-8 {
  margin-top: 64px;
}

.mt-9 {
  margin-top: 72px;
}

.mt-10 {
  margin-top: 80px;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-05 {
  margin-right: 4px;
}

.me-1 {
  margin-right: 8px;
}

.me-2 {
  margin-right: 16px;
}

.me-3 {
  margin-right: 24px;
}

.me-4 {
  margin-right: 32px;
}

.me-5 {
  margin-right: 40px;
}

.me-6 {
  margin-right: 48px;
}

.me-7 {
  margin-right: 56px;
}

.me-8 {
  margin-right: 64px;
}

.me-9 {
  margin-right: 72px;
}

.me-10 {
  margin-right: 80px;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-05 {
  margin-bottom: 4px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mb-5 {
  margin-bottom: 40px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mb-7 {
  margin-bottom: 56px;
}

.mb-8 {
  margin-bottom: 64px;
}

.mb-9 {
  margin-bottom: 72px;
}

.mb-10 {
  margin-bottom: 80px;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-05 {
  margin-left: 4px;
}

.ms-1 {
  margin-left: 8px;
}

.ms-2 {
  margin-left: 16px;
}

.ms-3 {
  margin-left: 24px;
}

.ms-4 {
  margin-left: 32px;
}

.ms-5 {
  margin-left: 40px;
}

.ms-6 {
  margin-left: 48px;
}

.ms-7 {
  margin-left: 56px;
}

.ms-8 {
  margin-left: 64px;
}

.ms-9 {
  margin-left: 72px;
}

.ms-10 {
  margin-left: 80px;
}

.ms-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0;
}

.p-05 {
  padding: 4px;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.p-4 {
  padding: 32px;
}

.p-5 {
  padding: 40px;
}

.p-6 {
  padding: 48px;
}

.p-7 {
  padding: 56px;
}

.p-8 {
  padding: 64px;
}

.p-9 {
  padding: 72px;
}

.p-10 {
  padding: 80px;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-05 {
  padding-right: 4px;
  padding-left: 4px;
}

.px-1 {
  padding-right: 8px;
  padding-left: 8px;
}

.px-2 {
  padding-right: 16px;
  padding-left: 16px;
}

.px-3 {
  padding-right: 24px;
  padding-left: 24px;
}

.px-4 {
  padding-right: 32px;
  padding-left: 32px;
}

.px-5 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-6 {
  padding-right: 48px;
  padding-left: 48px;
}

.px-7 {
  padding-right: 56px;
  padding-left: 56px;
}

.px-8 {
  padding-right: 64px;
  padding-left: 64px;
}

.px-9 {
  padding-right: 72px;
  padding-left: 72px;
}

.px-10 {
  padding-right: 80px;
  padding-left: 80px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-05 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-5 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.py-7 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.py-8 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.py-9 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.py-10 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pt-0 {
  padding-top: 0;
}

.pt-05 {
  padding-top: 4px;
}

.pt-1 {
  padding-top: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pt-3 {
  padding-top: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pt-5 {
  padding-top: 40px;
}

.pt-6 {
  padding-top: 48px;
}

.pt-7 {
  padding-top: 56px;
}

.pt-8 {
  padding-top: 64px;
}

.pt-9 {
  padding-top: 72px;
}

.pt-10 {
  padding-top: 80px;
}

.pe-0 {
  padding-right: 0;
}

.pe-05 {
  padding-right: 4px;
}

.pe-1 {
  padding-right: 8px;
}

.pe-2 {
  padding-right: 16px;
}

.pe-3 {
  padding-right: 24px;
}

.pe-4 {
  padding-right: 32px;
}

.pe-5 {
  padding-right: 40px;
}

.pe-6 {
  padding-right: 48px;
}

.pe-7 {
  padding-right: 56px;
}

.pe-8 {
  padding-right: 64px;
}

.pe-9 {
  padding-right: 72px;
}

.pe-10 {
  padding-right: 80px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-05 {
  padding-bottom: 4px;
}

.pb-1 {
  padding-bottom: 8px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pb-4 {
  padding-bottom: 32px;
}

.pb-5 {
  padding-bottom: 40px;
}

.pb-6 {
  padding-bottom: 48px;
}

.pb-7 {
  padding-bottom: 56px;
}

.pb-8 {
  padding-bottom: 64px;
}

.pb-9 {
  padding-bottom: 72px;
}

.pb-10 {
  padding-bottom: 80px;
}

.ps-0 {
  padding-left: 0;
}

.ps-05 {
  padding-left: 4px;
}

.ps-1 {
  padding-left: 8px;
}

.ps-2 {
  padding-left: 16px;
}

.ps-3 {
  padding-left: 24px;
}

.ps-4 {
  padding-left: 32px;
}

.ps-5 {
  padding-left: 40px;
}

.ps-6 {
  padding-left: 48px;
}

.ps-7 {
  padding-left: 56px;
}

.ps-8 {
  padding-left: 64px;
}

.ps-9 {
  padding-left: 72px;
}

.ps-10 {
  padding-left: 80px;
}

.gap-0 {
  gap: 0;
}

.gap-05 {
  gap: 4px;
}

.gap-1 {
  gap: 8px;
}

.gap-2 {
  gap: 16px;
}

.gap-3 {
  gap: 24px;
}

.gap-4 {
  gap: 32px;
}

.gap-5 {
  gap: 40px;
}

.gap-6 {
  gap: 48px;
}

.gap-7 {
  gap: 56px;
}

.gap-8 {
  gap: 64px;
}

.gap-9 {
  gap: 72px;
}

.gap-10 {
  gap: 80px;
}

.row-gap-0 {
  row-gap: 0;
}

.row-gap-05 {
  row-gap: 4px;
}

.row-gap-1 {
  row-gap: 8px;
}

.row-gap-2 {
  row-gap: 16px;
}

.row-gap-3 {
  row-gap: 24px;
}

.row-gap-4 {
  row-gap: 32px;
}

.row-gap-5 {
  row-gap: 40px;
}

.row-gap-6 {
  row-gap: 48px;
}

.row-gap-7 {
  row-gap: 56px;
}

.row-gap-8 {
  row-gap: 64px;
}

.row-gap-9 {
  row-gap: 72px;
}

.row-gap-10 {
  row-gap: 80px;
}

.column-gap-0 {
  column-gap: 0;
}

.column-gap-05 {
  column-gap: 4px;
}

.column-gap-1 {
  column-gap: 8px;
}

.column-gap-2 {
  column-gap: 16px;
}

.column-gap-3 {
  column-gap: 24px;
}

.column-gap-4 {
  column-gap: 32px;
}

.column-gap-5 {
  column-gap: 40px;
}

.column-gap-6 {
  column-gap: 48px;
}

.column-gap-7 {
  column-gap: 56px;
}

.column-gap-8 {
  column-gap: 64px;
}

.column-gap-9 {
  column-gap: 72px;
}

.column-gap-10 {
  column-gap: 80px;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw);
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw);
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw);
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color);
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis);
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis);
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis);
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis);
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis);
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis);
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis);
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis);
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em;
}

.link-offset-2 {
  text-underline-offset: 0.25em;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em;
}

.link-offset-3 {
  text-underline-offset: 0.375em;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity));
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity));
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity));
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity));
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity));
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity));
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1));
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity));
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity));
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle);
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle);
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle);
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle);
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle);
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle);
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle);
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle);
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--bs-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--bs-border-radius);
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill);
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm);
  border-top-right-radius: var(--bs-border-radius-sm);
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg);
  border-top-right-radius: var(--bs-border-radius-lg);
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl);
  border-top-right-radius: var(--bs-border-radius-xl);
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl);
  border-top-right-radius: var(--bs-border-radius-xxl);
}

.rounded-top-circle {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill);
  border-top-right-radius: var(--bs-border-radius-pill);
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm);
  border-bottom-right-radius: var(--bs-border-radius-sm);
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg);
  border-bottom-right-radius: var(--bs-border-radius-lg);
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl);
  border-bottom-right-radius: var(--bs-border-radius-xl);
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl);
  border-bottom-right-radius: var(--bs-border-radius-xxl);
}

.rounded-end-circle {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill);
  border-bottom-right-radius: var(--bs-border-radius-pill);
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm);
  border-bottom-left-radius: var(--bs-border-radius-sm);
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg);
  border-bottom-left-radius: var(--bs-border-radius-lg);
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl);
  border-bottom-left-radius: var(--bs-border-radius-xl);
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl);
  border-bottom-left-radius: var(--bs-border-radius-xxl);
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill);
  border-bottom-left-radius: var(--bs-border-radius-pill);
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-0 {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm);
  border-top-left-radius: var(--bs-border-radius-sm);
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg);
  border-top-left-radius: var(--bs-border-radius-lg);
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl);
  border-top-left-radius: var(--bs-border-radius-xl);
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl);
  border-top-left-radius: var(--bs-border-radius-xxl);
}

.rounded-start-circle {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill);
  border-top-left-radius: var(--bs-border-radius-pill);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.z-n1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .object-fit-sm-contain {
    object-fit: contain;
  }
  .object-fit-sm-cover {
    object-fit: cover;
  }
  .object-fit-sm-fill {
    object-fit: fill;
  }
  .object-fit-sm-scale {
    object-fit: scale-down;
  }
  .object-fit-sm-none {
    object-fit: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-inline-grid {
    display: inline-grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-05 {
    margin: 4px;
  }
  .m-sm-1 {
    margin: 8px;
  }
  .m-sm-2 {
    margin: 16px;
  }
  .m-sm-3 {
    margin: 24px;
  }
  .m-sm-4 {
    margin: 32px;
  }
  .m-sm-5 {
    margin: 40px;
  }
  .m-sm-6 {
    margin: 48px;
  }
  .m-sm-7 {
    margin: 56px;
  }
  .m-sm-8 {
    margin: 64px;
  }
  .m-sm-9 {
    margin: 72px;
  }
  .m-sm-10 {
    margin: 80px;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-05 {
    margin-right: 4px;
    margin-left: 4px;
  }
  .mx-sm-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-sm-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-sm-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-sm-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-sm-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-sm-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-sm-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-sm-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-sm-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-sm-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-05 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-sm-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-sm-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-sm-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-sm-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-sm-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-sm-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-sm-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-sm-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-sm-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-sm-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-05 {
    margin-top: 4px;
  }
  .mt-sm-1 {
    margin-top: 8px;
  }
  .mt-sm-2 {
    margin-top: 16px;
  }
  .mt-sm-3 {
    margin-top: 24px;
  }
  .mt-sm-4 {
    margin-top: 32px;
  }
  .mt-sm-5 {
    margin-top: 40px;
  }
  .mt-sm-6 {
    margin-top: 48px;
  }
  .mt-sm-7 {
    margin-top: 56px;
  }
  .mt-sm-8 {
    margin-top: 64px;
  }
  .mt-sm-9 {
    margin-top: 72px;
  }
  .mt-sm-10 {
    margin-top: 80px;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .me-sm-05 {
    margin-right: 4px;
  }
  .me-sm-1 {
    margin-right: 8px;
  }
  .me-sm-2 {
    margin-right: 16px;
  }
  .me-sm-3 {
    margin-right: 24px;
  }
  .me-sm-4 {
    margin-right: 32px;
  }
  .me-sm-5 {
    margin-right: 40px;
  }
  .me-sm-6 {
    margin-right: 48px;
  }
  .me-sm-7 {
    margin-right: 56px;
  }
  .me-sm-8 {
    margin-right: 64px;
  }
  .me-sm-9 {
    margin-right: 72px;
  }
  .me-sm-10 {
    margin-right: 80px;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-05 {
    margin-bottom: 4px;
  }
  .mb-sm-1 {
    margin-bottom: 8px;
  }
  .mb-sm-2 {
    margin-bottom: 16px;
  }
  .mb-sm-3 {
    margin-bottom: 24px;
  }
  .mb-sm-4 {
    margin-bottom: 32px;
  }
  .mb-sm-5 {
    margin-bottom: 40px;
  }
  .mb-sm-6 {
    margin-bottom: 48px;
  }
  .mb-sm-7 {
    margin-bottom: 56px;
  }
  .mb-sm-8 {
    margin-bottom: 64px;
  }
  .mb-sm-9 {
    margin-bottom: 72px;
  }
  .mb-sm-10 {
    margin-bottom: 80px;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0;
  }
  .ms-sm-05 {
    margin-left: 4px;
  }
  .ms-sm-1 {
    margin-left: 8px;
  }
  .ms-sm-2 {
    margin-left: 16px;
  }
  .ms-sm-3 {
    margin-left: 24px;
  }
  .ms-sm-4 {
    margin-left: 32px;
  }
  .ms-sm-5 {
    margin-left: 40px;
  }
  .ms-sm-6 {
    margin-left: 48px;
  }
  .ms-sm-7 {
    margin-left: 56px;
  }
  .ms-sm-8 {
    margin-left: 64px;
  }
  .ms-sm-9 {
    margin-left: 72px;
  }
  .ms-sm-10 {
    margin-left: 80px;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .p-sm-0 {
    padding: 0;
  }
  .p-sm-05 {
    padding: 4px;
  }
  .p-sm-1 {
    padding: 8px;
  }
  .p-sm-2 {
    padding: 16px;
  }
  .p-sm-3 {
    padding: 24px;
  }
  .p-sm-4 {
    padding: 32px;
  }
  .p-sm-5 {
    padding: 40px;
  }
  .p-sm-6 {
    padding: 48px;
  }
  .p-sm-7 {
    padding: 56px;
  }
  .p-sm-8 {
    padding: 64px;
  }
  .p-sm-9 {
    padding: 72px;
  }
  .p-sm-10 {
    padding: 80px;
  }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-sm-05 {
    padding-right: 4px;
    padding-left: 4px;
  }
  .px-sm-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-sm-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-sm-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-sm-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-sm-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-sm-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-sm-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-sm-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-sm-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-sm-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-sm-05 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-sm-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-sm-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-sm-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-sm-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-sm-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-sm-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-sm-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-sm-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-sm-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-sm-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-05 {
    padding-top: 4px;
  }
  .pt-sm-1 {
    padding-top: 8px;
  }
  .pt-sm-2 {
    padding-top: 16px;
  }
  .pt-sm-3 {
    padding-top: 24px;
  }
  .pt-sm-4 {
    padding-top: 32px;
  }
  .pt-sm-5 {
    padding-top: 40px;
  }
  .pt-sm-6 {
    padding-top: 48px;
  }
  .pt-sm-7 {
    padding-top: 56px;
  }
  .pt-sm-8 {
    padding-top: 64px;
  }
  .pt-sm-9 {
    padding-top: 72px;
  }
  .pt-sm-10 {
    padding-top: 80px;
  }
  .pe-sm-0 {
    padding-right: 0;
  }
  .pe-sm-05 {
    padding-right: 4px;
  }
  .pe-sm-1 {
    padding-right: 8px;
  }
  .pe-sm-2 {
    padding-right: 16px;
  }
  .pe-sm-3 {
    padding-right: 24px;
  }
  .pe-sm-4 {
    padding-right: 32px;
  }
  .pe-sm-5 {
    padding-right: 40px;
  }
  .pe-sm-6 {
    padding-right: 48px;
  }
  .pe-sm-7 {
    padding-right: 56px;
  }
  .pe-sm-8 {
    padding-right: 64px;
  }
  .pe-sm-9 {
    padding-right: 72px;
  }
  .pe-sm-10 {
    padding-right: 80px;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-05 {
    padding-bottom: 4px;
  }
  .pb-sm-1 {
    padding-bottom: 8px;
  }
  .pb-sm-2 {
    padding-bottom: 16px;
  }
  .pb-sm-3 {
    padding-bottom: 24px;
  }
  .pb-sm-4 {
    padding-bottom: 32px;
  }
  .pb-sm-5 {
    padding-bottom: 40px;
  }
  .pb-sm-6 {
    padding-bottom: 48px;
  }
  .pb-sm-7 {
    padding-bottom: 56px;
  }
  .pb-sm-8 {
    padding-bottom: 64px;
  }
  .pb-sm-9 {
    padding-bottom: 72px;
  }
  .pb-sm-10 {
    padding-bottom: 80px;
  }
  .ps-sm-0 {
    padding-left: 0;
  }
  .ps-sm-05 {
    padding-left: 4px;
  }
  .ps-sm-1 {
    padding-left: 8px;
  }
  .ps-sm-2 {
    padding-left: 16px;
  }
  .ps-sm-3 {
    padding-left: 24px;
  }
  .ps-sm-4 {
    padding-left: 32px;
  }
  .ps-sm-5 {
    padding-left: 40px;
  }
  .ps-sm-6 {
    padding-left: 48px;
  }
  .ps-sm-7 {
    padding-left: 56px;
  }
  .ps-sm-8 {
    padding-left: 64px;
  }
  .ps-sm-9 {
    padding-left: 72px;
  }
  .ps-sm-10 {
    padding-left: 80px;
  }
  .gap-sm-0 {
    gap: 0;
  }
  .gap-sm-05 {
    gap: 4px;
  }
  .gap-sm-1 {
    gap: 8px;
  }
  .gap-sm-2 {
    gap: 16px;
  }
  .gap-sm-3 {
    gap: 24px;
  }
  .gap-sm-4 {
    gap: 32px;
  }
  .gap-sm-5 {
    gap: 40px;
  }
  .gap-sm-6 {
    gap: 48px;
  }
  .gap-sm-7 {
    gap: 56px;
  }
  .gap-sm-8 {
    gap: 64px;
  }
  .gap-sm-9 {
    gap: 72px;
  }
  .gap-sm-10 {
    gap: 80px;
  }
  .row-gap-sm-0 {
    row-gap: 0;
  }
  .row-gap-sm-05 {
    row-gap: 4px;
  }
  .row-gap-sm-1 {
    row-gap: 8px;
  }
  .row-gap-sm-2 {
    row-gap: 16px;
  }
  .row-gap-sm-3 {
    row-gap: 24px;
  }
  .row-gap-sm-4 {
    row-gap: 32px;
  }
  .row-gap-sm-5 {
    row-gap: 40px;
  }
  .row-gap-sm-6 {
    row-gap: 48px;
  }
  .row-gap-sm-7 {
    row-gap: 56px;
  }
  .row-gap-sm-8 {
    row-gap: 64px;
  }
  .row-gap-sm-9 {
    row-gap: 72px;
  }
  .row-gap-sm-10 {
    row-gap: 80px;
  }
  .column-gap-sm-0 {
    column-gap: 0;
  }
  .column-gap-sm-05 {
    column-gap: 4px;
  }
  .column-gap-sm-1 {
    column-gap: 8px;
  }
  .column-gap-sm-2 {
    column-gap: 16px;
  }
  .column-gap-sm-3 {
    column-gap: 24px;
  }
  .column-gap-sm-4 {
    column-gap: 32px;
  }
  .column-gap-sm-5 {
    column-gap: 40px;
  }
  .column-gap-sm-6 {
    column-gap: 48px;
  }
  .column-gap-sm-7 {
    column-gap: 56px;
  }
  .column-gap-sm-8 {
    column-gap: 64px;
  }
  .column-gap-sm-9 {
    column-gap: 72px;
  }
  .column-gap-sm-10 {
    column-gap: 80px;
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .object-fit-md-contain {
    object-fit: contain;
  }
  .object-fit-md-cover {
    object-fit: cover;
  }
  .object-fit-md-fill {
    object-fit: fill;
  }
  .object-fit-md-scale {
    object-fit: scale-down;
  }
  .object-fit-md-none {
    object-fit: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-inline-grid {
    display: inline-grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-05 {
    margin: 4px;
  }
  .m-md-1 {
    margin: 8px;
  }
  .m-md-2 {
    margin: 16px;
  }
  .m-md-3 {
    margin: 24px;
  }
  .m-md-4 {
    margin: 32px;
  }
  .m-md-5 {
    margin: 40px;
  }
  .m-md-6 {
    margin: 48px;
  }
  .m-md-7 {
    margin: 56px;
  }
  .m-md-8 {
    margin: 64px;
  }
  .m-md-9 {
    margin: 72px;
  }
  .m-md-10 {
    margin: 80px;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-05 {
    margin-right: 4px;
    margin-left: 4px;
  }
  .mx-md-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-md-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-md-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-md-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-md-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-md-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-md-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-md-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-md-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-md-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-05 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-md-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-md-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-md-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-md-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-md-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-md-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-md-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-md-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-md-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-md-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-05 {
    margin-top: 4px;
  }
  .mt-md-1 {
    margin-top: 8px;
  }
  .mt-md-2 {
    margin-top: 16px;
  }
  .mt-md-3 {
    margin-top: 24px;
  }
  .mt-md-4 {
    margin-top: 32px;
  }
  .mt-md-5 {
    margin-top: 40px;
  }
  .mt-md-6 {
    margin-top: 48px;
  }
  .mt-md-7 {
    margin-top: 56px;
  }
  .mt-md-8 {
    margin-top: 64px;
  }
  .mt-md-9 {
    margin-top: 72px;
  }
  .mt-md-10 {
    margin-top: 80px;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0;
  }
  .me-md-05 {
    margin-right: 4px;
  }
  .me-md-1 {
    margin-right: 8px;
  }
  .me-md-2 {
    margin-right: 16px;
  }
  .me-md-3 {
    margin-right: 24px;
  }
  .me-md-4 {
    margin-right: 32px;
  }
  .me-md-5 {
    margin-right: 40px;
  }
  .me-md-6 {
    margin-right: 48px;
  }
  .me-md-7 {
    margin-right: 56px;
  }
  .me-md-8 {
    margin-right: 64px;
  }
  .me-md-9 {
    margin-right: 72px;
  }
  .me-md-10 {
    margin-right: 80px;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-05 {
    margin-bottom: 4px;
  }
  .mb-md-1 {
    margin-bottom: 8px;
  }
  .mb-md-2 {
    margin-bottom: 16px;
  }
  .mb-md-3 {
    margin-bottom: 24px;
  }
  .mb-md-4 {
    margin-bottom: 32px;
  }
  .mb-md-5 {
    margin-bottom: 40px;
  }
  .mb-md-6 {
    margin-bottom: 48px;
  }
  .mb-md-7 {
    margin-bottom: 56px;
  }
  .mb-md-8 {
    margin-bottom: 64px;
  }
  .mb-md-9 {
    margin-bottom: 72px;
  }
  .mb-md-10 {
    margin-bottom: 80px;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0;
  }
  .ms-md-05 {
    margin-left: 4px;
  }
  .ms-md-1 {
    margin-left: 8px;
  }
  .ms-md-2 {
    margin-left: 16px;
  }
  .ms-md-3 {
    margin-left: 24px;
  }
  .ms-md-4 {
    margin-left: 32px;
  }
  .ms-md-5 {
    margin-left: 40px;
  }
  .ms-md-6 {
    margin-left: 48px;
  }
  .ms-md-7 {
    margin-left: 56px;
  }
  .ms-md-8 {
    margin-left: 64px;
  }
  .ms-md-9 {
    margin-left: 72px;
  }
  .ms-md-10 {
    margin-left: 80px;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .p-md-0 {
    padding: 0;
  }
  .p-md-05 {
    padding: 4px;
  }
  .p-md-1 {
    padding: 8px;
  }
  .p-md-2 {
    padding: 16px;
  }
  .p-md-3 {
    padding: 24px;
  }
  .p-md-4 {
    padding: 32px;
  }
  .p-md-5 {
    padding: 40px;
  }
  .p-md-6 {
    padding: 48px;
  }
  .p-md-7 {
    padding: 56px;
  }
  .p-md-8 {
    padding: 64px;
  }
  .p-md-9 {
    padding: 72px;
  }
  .p-md-10 {
    padding: 80px;
  }
  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-md-05 {
    padding-right: 4px;
    padding-left: 4px;
  }
  .px-md-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-md-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-md-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-md-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-md-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-md-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-md-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-md-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-md-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-md-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-md-05 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-md-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-md-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-md-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-md-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-md-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-md-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-md-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-md-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-md-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-md-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pt-md-05 {
    padding-top: 4px;
  }
  .pt-md-1 {
    padding-top: 8px;
  }
  .pt-md-2 {
    padding-top: 16px;
  }
  .pt-md-3 {
    padding-top: 24px;
  }
  .pt-md-4 {
    padding-top: 32px;
  }
  .pt-md-5 {
    padding-top: 40px;
  }
  .pt-md-6 {
    padding-top: 48px;
  }
  .pt-md-7 {
    padding-top: 56px;
  }
  .pt-md-8 {
    padding-top: 64px;
  }
  .pt-md-9 {
    padding-top: 72px;
  }
  .pt-md-10 {
    padding-top: 80px;
  }
  .pe-md-0 {
    padding-right: 0;
  }
  .pe-md-05 {
    padding-right: 4px;
  }
  .pe-md-1 {
    padding-right: 8px;
  }
  .pe-md-2 {
    padding-right: 16px;
  }
  .pe-md-3 {
    padding-right: 24px;
  }
  .pe-md-4 {
    padding-right: 32px;
  }
  .pe-md-5 {
    padding-right: 40px;
  }
  .pe-md-6 {
    padding-right: 48px;
  }
  .pe-md-7 {
    padding-right: 56px;
  }
  .pe-md-8 {
    padding-right: 64px;
  }
  .pe-md-9 {
    padding-right: 72px;
  }
  .pe-md-10 {
    padding-right: 80px;
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pb-md-05 {
    padding-bottom: 4px;
  }
  .pb-md-1 {
    padding-bottom: 8px;
  }
  .pb-md-2 {
    padding-bottom: 16px;
  }
  .pb-md-3 {
    padding-bottom: 24px;
  }
  .pb-md-4 {
    padding-bottom: 32px;
  }
  .pb-md-5 {
    padding-bottom: 40px;
  }
  .pb-md-6 {
    padding-bottom: 48px;
  }
  .pb-md-7 {
    padding-bottom: 56px;
  }
  .pb-md-8 {
    padding-bottom: 64px;
  }
  .pb-md-9 {
    padding-bottom: 72px;
  }
  .pb-md-10 {
    padding-bottom: 80px;
  }
  .ps-md-0 {
    padding-left: 0;
  }
  .ps-md-05 {
    padding-left: 4px;
  }
  .ps-md-1 {
    padding-left: 8px;
  }
  .ps-md-2 {
    padding-left: 16px;
  }
  .ps-md-3 {
    padding-left: 24px;
  }
  .ps-md-4 {
    padding-left: 32px;
  }
  .ps-md-5 {
    padding-left: 40px;
  }
  .ps-md-6 {
    padding-left: 48px;
  }
  .ps-md-7 {
    padding-left: 56px;
  }
  .ps-md-8 {
    padding-left: 64px;
  }
  .ps-md-9 {
    padding-left: 72px;
  }
  .ps-md-10 {
    padding-left: 80px;
  }
  .gap-md-0 {
    gap: 0;
  }
  .gap-md-05 {
    gap: 4px;
  }
  .gap-md-1 {
    gap: 8px;
  }
  .gap-md-2 {
    gap: 16px;
  }
  .gap-md-3 {
    gap: 24px;
  }
  .gap-md-4 {
    gap: 32px;
  }
  .gap-md-5 {
    gap: 40px;
  }
  .gap-md-6 {
    gap: 48px;
  }
  .gap-md-7 {
    gap: 56px;
  }
  .gap-md-8 {
    gap: 64px;
  }
  .gap-md-9 {
    gap: 72px;
  }
  .gap-md-10 {
    gap: 80px;
  }
  .row-gap-md-0 {
    row-gap: 0;
  }
  .row-gap-md-05 {
    row-gap: 4px;
  }
  .row-gap-md-1 {
    row-gap: 8px;
  }
  .row-gap-md-2 {
    row-gap: 16px;
  }
  .row-gap-md-3 {
    row-gap: 24px;
  }
  .row-gap-md-4 {
    row-gap: 32px;
  }
  .row-gap-md-5 {
    row-gap: 40px;
  }
  .row-gap-md-6 {
    row-gap: 48px;
  }
  .row-gap-md-7 {
    row-gap: 56px;
  }
  .row-gap-md-8 {
    row-gap: 64px;
  }
  .row-gap-md-9 {
    row-gap: 72px;
  }
  .row-gap-md-10 {
    row-gap: 80px;
  }
  .column-gap-md-0 {
    column-gap: 0;
  }
  .column-gap-md-05 {
    column-gap: 4px;
  }
  .column-gap-md-1 {
    column-gap: 8px;
  }
  .column-gap-md-2 {
    column-gap: 16px;
  }
  .column-gap-md-3 {
    column-gap: 24px;
  }
  .column-gap-md-4 {
    column-gap: 32px;
  }
  .column-gap-md-5 {
    column-gap: 40px;
  }
  .column-gap-md-6 {
    column-gap: 48px;
  }
  .column-gap-md-7 {
    column-gap: 56px;
  }
  .column-gap-md-8 {
    column-gap: 64px;
  }
  .column-gap-md-9 {
    column-gap: 72px;
  }
  .column-gap-md-10 {
    column-gap: 80px;
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .object-fit-lg-contain {
    object-fit: contain;
  }
  .object-fit-lg-cover {
    object-fit: cover;
  }
  .object-fit-lg-fill {
    object-fit: fill;
  }
  .object-fit-lg-scale {
    object-fit: scale-down;
  }
  .object-fit-lg-none {
    object-fit: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-inline-grid {
    display: inline-grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0;
  }
  .m-lg-05 {
    margin: 4px;
  }
  .m-lg-1 {
    margin: 8px;
  }
  .m-lg-2 {
    margin: 16px;
  }
  .m-lg-3 {
    margin: 24px;
  }
  .m-lg-4 {
    margin: 32px;
  }
  .m-lg-5 {
    margin: 40px;
  }
  .m-lg-6 {
    margin: 48px;
  }
  .m-lg-7 {
    margin: 56px;
  }
  .m-lg-8 {
    margin: 64px;
  }
  .m-lg-9 {
    margin: 72px;
  }
  .m-lg-10 {
    margin: 80px;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-05 {
    margin-right: 4px;
    margin-left: 4px;
  }
  .mx-lg-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-lg-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-lg-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-lg-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-lg-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-lg-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-lg-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-lg-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-lg-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-lg-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-05 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-lg-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-lg-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-lg-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-lg-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-lg-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-lg-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-lg-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-lg-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-lg-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-lg-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mt-lg-05 {
    margin-top: 4px;
  }
  .mt-lg-1 {
    margin-top: 8px;
  }
  .mt-lg-2 {
    margin-top: 16px;
  }
  .mt-lg-3 {
    margin-top: 24px;
  }
  .mt-lg-4 {
    margin-top: 32px;
  }
  .mt-lg-5 {
    margin-top: 40px;
  }
  .mt-lg-6 {
    margin-top: 48px;
  }
  .mt-lg-7 {
    margin-top: 56px;
  }
  .mt-lg-8 {
    margin-top: 64px;
  }
  .mt-lg-9 {
    margin-top: 72px;
  }
  .mt-lg-10 {
    margin-top: 80px;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0;
  }
  .me-lg-05 {
    margin-right: 4px;
  }
  .me-lg-1 {
    margin-right: 8px;
  }
  .me-lg-2 {
    margin-right: 16px;
  }
  .me-lg-3 {
    margin-right: 24px;
  }
  .me-lg-4 {
    margin-right: 32px;
  }
  .me-lg-5 {
    margin-right: 40px;
  }
  .me-lg-6 {
    margin-right: 48px;
  }
  .me-lg-7 {
    margin-right: 56px;
  }
  .me-lg-8 {
    margin-right: 64px;
  }
  .me-lg-9 {
    margin-right: 72px;
  }
  .me-lg-10 {
    margin-right: 80px;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-05 {
    margin-bottom: 4px;
  }
  .mb-lg-1 {
    margin-bottom: 8px;
  }
  .mb-lg-2 {
    margin-bottom: 16px;
  }
  .mb-lg-3 {
    margin-bottom: 24px;
  }
  .mb-lg-4 {
    margin-bottom: 32px;
  }
  .mb-lg-5 {
    margin-bottom: 40px;
  }
  .mb-lg-6 {
    margin-bottom: 48px;
  }
  .mb-lg-7 {
    margin-bottom: 56px;
  }
  .mb-lg-8 {
    margin-bottom: 64px;
  }
  .mb-lg-9 {
    margin-bottom: 72px;
  }
  .mb-lg-10 {
    margin-bottom: 80px;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0;
  }
  .ms-lg-05 {
    margin-left: 4px;
  }
  .ms-lg-1 {
    margin-left: 8px;
  }
  .ms-lg-2 {
    margin-left: 16px;
  }
  .ms-lg-3 {
    margin-left: 24px;
  }
  .ms-lg-4 {
    margin-left: 32px;
  }
  .ms-lg-5 {
    margin-left: 40px;
  }
  .ms-lg-6 {
    margin-left: 48px;
  }
  .ms-lg-7 {
    margin-left: 56px;
  }
  .ms-lg-8 {
    margin-left: 64px;
  }
  .ms-lg-9 {
    margin-left: 72px;
  }
  .ms-lg-10 {
    margin-left: 80px;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .p-lg-0 {
    padding: 0;
  }
  .p-lg-05 {
    padding: 4px;
  }
  .p-lg-1 {
    padding: 8px;
  }
  .p-lg-2 {
    padding: 16px;
  }
  .p-lg-3 {
    padding: 24px;
  }
  .p-lg-4 {
    padding: 32px;
  }
  .p-lg-5 {
    padding: 40px;
  }
  .p-lg-6 {
    padding: 48px;
  }
  .p-lg-7 {
    padding: 56px;
  }
  .p-lg-8 {
    padding: 64px;
  }
  .p-lg-9 {
    padding: 72px;
  }
  .p-lg-10 {
    padding: 80px;
  }
  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-lg-05 {
    padding-right: 4px;
    padding-left: 4px;
  }
  .px-lg-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-lg-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-lg-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-lg-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-lg-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-lg-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-lg-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-lg-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-lg-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-lg-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-lg-05 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-lg-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-lg-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-lg-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-lg-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-lg-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-lg-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-lg-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-lg-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-lg-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-lg-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pt-lg-05 {
    padding-top: 4px;
  }
  .pt-lg-1 {
    padding-top: 8px;
  }
  .pt-lg-2 {
    padding-top: 16px;
  }
  .pt-lg-3 {
    padding-top: 24px;
  }
  .pt-lg-4 {
    padding-top: 32px;
  }
  .pt-lg-5 {
    padding-top: 40px;
  }
  .pt-lg-6 {
    padding-top: 48px;
  }
  .pt-lg-7 {
    padding-top: 56px;
  }
  .pt-lg-8 {
    padding-top: 64px;
  }
  .pt-lg-9 {
    padding-top: 72px;
  }
  .pt-lg-10 {
    padding-top: 80px;
  }
  .pe-lg-0 {
    padding-right: 0;
  }
  .pe-lg-05 {
    padding-right: 4px;
  }
  .pe-lg-1 {
    padding-right: 8px;
  }
  .pe-lg-2 {
    padding-right: 16px;
  }
  .pe-lg-3 {
    padding-right: 24px;
  }
  .pe-lg-4 {
    padding-right: 32px;
  }
  .pe-lg-5 {
    padding-right: 40px;
  }
  .pe-lg-6 {
    padding-right: 48px;
  }
  .pe-lg-7 {
    padding-right: 56px;
  }
  .pe-lg-8 {
    padding-right: 64px;
  }
  .pe-lg-9 {
    padding-right: 72px;
  }
  .pe-lg-10 {
    padding-right: 80px;
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pb-lg-05 {
    padding-bottom: 4px;
  }
  .pb-lg-1 {
    padding-bottom: 8px;
  }
  .pb-lg-2 {
    padding-bottom: 16px;
  }
  .pb-lg-3 {
    padding-bottom: 24px;
  }
  .pb-lg-4 {
    padding-bottom: 32px;
  }
  .pb-lg-5 {
    padding-bottom: 40px;
  }
  .pb-lg-6 {
    padding-bottom: 48px;
  }
  .pb-lg-7 {
    padding-bottom: 56px;
  }
  .pb-lg-8 {
    padding-bottom: 64px;
  }
  .pb-lg-9 {
    padding-bottom: 72px;
  }
  .pb-lg-10 {
    padding-bottom: 80px;
  }
  .ps-lg-0 {
    padding-left: 0;
  }
  .ps-lg-05 {
    padding-left: 4px;
  }
  .ps-lg-1 {
    padding-left: 8px;
  }
  .ps-lg-2 {
    padding-left: 16px;
  }
  .ps-lg-3 {
    padding-left: 24px;
  }
  .ps-lg-4 {
    padding-left: 32px;
  }
  .ps-lg-5 {
    padding-left: 40px;
  }
  .ps-lg-6 {
    padding-left: 48px;
  }
  .ps-lg-7 {
    padding-left: 56px;
  }
  .ps-lg-8 {
    padding-left: 64px;
  }
  .ps-lg-9 {
    padding-left: 72px;
  }
  .ps-lg-10 {
    padding-left: 80px;
  }
  .gap-lg-0 {
    gap: 0;
  }
  .gap-lg-05 {
    gap: 4px;
  }
  .gap-lg-1 {
    gap: 8px;
  }
  .gap-lg-2 {
    gap: 16px;
  }
  .gap-lg-3 {
    gap: 24px;
  }
  .gap-lg-4 {
    gap: 32px;
  }
  .gap-lg-5 {
    gap: 40px;
  }
  .gap-lg-6 {
    gap: 48px;
  }
  .gap-lg-7 {
    gap: 56px;
  }
  .gap-lg-8 {
    gap: 64px;
  }
  .gap-lg-9 {
    gap: 72px;
  }
  .gap-lg-10 {
    gap: 80px;
  }
  .row-gap-lg-0 {
    row-gap: 0;
  }
  .row-gap-lg-05 {
    row-gap: 4px;
  }
  .row-gap-lg-1 {
    row-gap: 8px;
  }
  .row-gap-lg-2 {
    row-gap: 16px;
  }
  .row-gap-lg-3 {
    row-gap: 24px;
  }
  .row-gap-lg-4 {
    row-gap: 32px;
  }
  .row-gap-lg-5 {
    row-gap: 40px;
  }
  .row-gap-lg-6 {
    row-gap: 48px;
  }
  .row-gap-lg-7 {
    row-gap: 56px;
  }
  .row-gap-lg-8 {
    row-gap: 64px;
  }
  .row-gap-lg-9 {
    row-gap: 72px;
  }
  .row-gap-lg-10 {
    row-gap: 80px;
  }
  .column-gap-lg-0 {
    column-gap: 0;
  }
  .column-gap-lg-05 {
    column-gap: 4px;
  }
  .column-gap-lg-1 {
    column-gap: 8px;
  }
  .column-gap-lg-2 {
    column-gap: 16px;
  }
  .column-gap-lg-3 {
    column-gap: 24px;
  }
  .column-gap-lg-4 {
    column-gap: 32px;
  }
  .column-gap-lg-5 {
    column-gap: 40px;
  }
  .column-gap-lg-6 {
    column-gap: 48px;
  }
  .column-gap-lg-7 {
    column-gap: 56px;
  }
  .column-gap-lg-8 {
    column-gap: 64px;
  }
  .column-gap-lg-9 {
    column-gap: 72px;
  }
  .column-gap-lg-10 {
    column-gap: 80px;
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .object-fit-xl-contain {
    object-fit: contain;
  }
  .object-fit-xl-cover {
    object-fit: cover;
  }
  .object-fit-xl-fill {
    object-fit: fill;
  }
  .object-fit-xl-scale {
    object-fit: scale-down;
  }
  .object-fit-xl-none {
    object-fit: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-inline-grid {
    display: inline-grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0;
  }
  .m-xl-05 {
    margin: 4px;
  }
  .m-xl-1 {
    margin: 8px;
  }
  .m-xl-2 {
    margin: 16px;
  }
  .m-xl-3 {
    margin: 24px;
  }
  .m-xl-4 {
    margin: 32px;
  }
  .m-xl-5 {
    margin: 40px;
  }
  .m-xl-6 {
    margin: 48px;
  }
  .m-xl-7 {
    margin: 56px;
  }
  .m-xl-8 {
    margin: 64px;
  }
  .m-xl-9 {
    margin: 72px;
  }
  .m-xl-10 {
    margin: 80px;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-05 {
    margin-right: 4px;
    margin-left: 4px;
  }
  .mx-xl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xl-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-xl-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-xl-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-xl-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-05 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-xl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xl-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-xl-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xl-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-xl-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mt-xl-05 {
    margin-top: 4px;
  }
  .mt-xl-1 {
    margin-top: 8px;
  }
  .mt-xl-2 {
    margin-top: 16px;
  }
  .mt-xl-3 {
    margin-top: 24px;
  }
  .mt-xl-4 {
    margin-top: 32px;
  }
  .mt-xl-5 {
    margin-top: 40px;
  }
  .mt-xl-6 {
    margin-top: 48px;
  }
  .mt-xl-7 {
    margin-top: 56px;
  }
  .mt-xl-8 {
    margin-top: 64px;
  }
  .mt-xl-9 {
    margin-top: 72px;
  }
  .mt-xl-10 {
    margin-top: 80px;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0;
  }
  .me-xl-05 {
    margin-right: 4px;
  }
  .me-xl-1 {
    margin-right: 8px;
  }
  .me-xl-2 {
    margin-right: 16px;
  }
  .me-xl-3 {
    margin-right: 24px;
  }
  .me-xl-4 {
    margin-right: 32px;
  }
  .me-xl-5 {
    margin-right: 40px;
  }
  .me-xl-6 {
    margin-right: 48px;
  }
  .me-xl-7 {
    margin-right: 56px;
  }
  .me-xl-8 {
    margin-right: 64px;
  }
  .me-xl-9 {
    margin-right: 72px;
  }
  .me-xl-10 {
    margin-right: 80px;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-05 {
    margin-bottom: 4px;
  }
  .mb-xl-1 {
    margin-bottom: 8px;
  }
  .mb-xl-2 {
    margin-bottom: 16px;
  }
  .mb-xl-3 {
    margin-bottom: 24px;
  }
  .mb-xl-4 {
    margin-bottom: 32px;
  }
  .mb-xl-5 {
    margin-bottom: 40px;
  }
  .mb-xl-6 {
    margin-bottom: 48px;
  }
  .mb-xl-7 {
    margin-bottom: 56px;
  }
  .mb-xl-8 {
    margin-bottom: 64px;
  }
  .mb-xl-9 {
    margin-bottom: 72px;
  }
  .mb-xl-10 {
    margin-bottom: 80px;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0;
  }
  .ms-xl-05 {
    margin-left: 4px;
  }
  .ms-xl-1 {
    margin-left: 8px;
  }
  .ms-xl-2 {
    margin-left: 16px;
  }
  .ms-xl-3 {
    margin-left: 24px;
  }
  .ms-xl-4 {
    margin-left: 32px;
  }
  .ms-xl-5 {
    margin-left: 40px;
  }
  .ms-xl-6 {
    margin-left: 48px;
  }
  .ms-xl-7 {
    margin-left: 56px;
  }
  .ms-xl-8 {
    margin-left: 64px;
  }
  .ms-xl-9 {
    margin-left: 72px;
  }
  .ms-xl-10 {
    margin-left: 80px;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .p-xl-0 {
    padding: 0;
  }
  .p-xl-05 {
    padding: 4px;
  }
  .p-xl-1 {
    padding: 8px;
  }
  .p-xl-2 {
    padding: 16px;
  }
  .p-xl-3 {
    padding: 24px;
  }
  .p-xl-4 {
    padding: 32px;
  }
  .p-xl-5 {
    padding: 40px;
  }
  .p-xl-6 {
    padding: 48px;
  }
  .p-xl-7 {
    padding: 56px;
  }
  .p-xl-8 {
    padding: 64px;
  }
  .p-xl-9 {
    padding: 72px;
  }
  .p-xl-10 {
    padding: 80px;
  }
  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xl-05 {
    padding-right: 4px;
    padding-left: 4px;
  }
  .px-xl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-xl-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-xl-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-xl-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-xl-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xl-05 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-xl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xl-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-xl-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-xl-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-xl-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pt-xl-05 {
    padding-top: 4px;
  }
  .pt-xl-1 {
    padding-top: 8px;
  }
  .pt-xl-2 {
    padding-top: 16px;
  }
  .pt-xl-3 {
    padding-top: 24px;
  }
  .pt-xl-4 {
    padding-top: 32px;
  }
  .pt-xl-5 {
    padding-top: 40px;
  }
  .pt-xl-6 {
    padding-top: 48px;
  }
  .pt-xl-7 {
    padding-top: 56px;
  }
  .pt-xl-8 {
    padding-top: 64px;
  }
  .pt-xl-9 {
    padding-top: 72px;
  }
  .pt-xl-10 {
    padding-top: 80px;
  }
  .pe-xl-0 {
    padding-right: 0;
  }
  .pe-xl-05 {
    padding-right: 4px;
  }
  .pe-xl-1 {
    padding-right: 8px;
  }
  .pe-xl-2 {
    padding-right: 16px;
  }
  .pe-xl-3 {
    padding-right: 24px;
  }
  .pe-xl-4 {
    padding-right: 32px;
  }
  .pe-xl-5 {
    padding-right: 40px;
  }
  .pe-xl-6 {
    padding-right: 48px;
  }
  .pe-xl-7 {
    padding-right: 56px;
  }
  .pe-xl-8 {
    padding-right: 64px;
  }
  .pe-xl-9 {
    padding-right: 72px;
  }
  .pe-xl-10 {
    padding-right: 80px;
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pb-xl-05 {
    padding-bottom: 4px;
  }
  .pb-xl-1 {
    padding-bottom: 8px;
  }
  .pb-xl-2 {
    padding-bottom: 16px;
  }
  .pb-xl-3 {
    padding-bottom: 24px;
  }
  .pb-xl-4 {
    padding-bottom: 32px;
  }
  .pb-xl-5 {
    padding-bottom: 40px;
  }
  .pb-xl-6 {
    padding-bottom: 48px;
  }
  .pb-xl-7 {
    padding-bottom: 56px;
  }
  .pb-xl-8 {
    padding-bottom: 64px;
  }
  .pb-xl-9 {
    padding-bottom: 72px;
  }
  .pb-xl-10 {
    padding-bottom: 80px;
  }
  .ps-xl-0 {
    padding-left: 0;
  }
  .ps-xl-05 {
    padding-left: 4px;
  }
  .ps-xl-1 {
    padding-left: 8px;
  }
  .ps-xl-2 {
    padding-left: 16px;
  }
  .ps-xl-3 {
    padding-left: 24px;
  }
  .ps-xl-4 {
    padding-left: 32px;
  }
  .ps-xl-5 {
    padding-left: 40px;
  }
  .ps-xl-6 {
    padding-left: 48px;
  }
  .ps-xl-7 {
    padding-left: 56px;
  }
  .ps-xl-8 {
    padding-left: 64px;
  }
  .ps-xl-9 {
    padding-left: 72px;
  }
  .ps-xl-10 {
    padding-left: 80px;
  }
  .gap-xl-0 {
    gap: 0;
  }
  .gap-xl-05 {
    gap: 4px;
  }
  .gap-xl-1 {
    gap: 8px;
  }
  .gap-xl-2 {
    gap: 16px;
  }
  .gap-xl-3 {
    gap: 24px;
  }
  .gap-xl-4 {
    gap: 32px;
  }
  .gap-xl-5 {
    gap: 40px;
  }
  .gap-xl-6 {
    gap: 48px;
  }
  .gap-xl-7 {
    gap: 56px;
  }
  .gap-xl-8 {
    gap: 64px;
  }
  .gap-xl-9 {
    gap: 72px;
  }
  .gap-xl-10 {
    gap: 80px;
  }
  .row-gap-xl-0 {
    row-gap: 0;
  }
  .row-gap-xl-05 {
    row-gap: 4px;
  }
  .row-gap-xl-1 {
    row-gap: 8px;
  }
  .row-gap-xl-2 {
    row-gap: 16px;
  }
  .row-gap-xl-3 {
    row-gap: 24px;
  }
  .row-gap-xl-4 {
    row-gap: 32px;
  }
  .row-gap-xl-5 {
    row-gap: 40px;
  }
  .row-gap-xl-6 {
    row-gap: 48px;
  }
  .row-gap-xl-7 {
    row-gap: 56px;
  }
  .row-gap-xl-8 {
    row-gap: 64px;
  }
  .row-gap-xl-9 {
    row-gap: 72px;
  }
  .row-gap-xl-10 {
    row-gap: 80px;
  }
  .column-gap-xl-0 {
    column-gap: 0;
  }
  .column-gap-xl-05 {
    column-gap: 4px;
  }
  .column-gap-xl-1 {
    column-gap: 8px;
  }
  .column-gap-xl-2 {
    column-gap: 16px;
  }
  .column-gap-xl-3 {
    column-gap: 24px;
  }
  .column-gap-xl-4 {
    column-gap: 32px;
  }
  .column-gap-xl-5 {
    column-gap: 40px;
  }
  .column-gap-xl-6 {
    column-gap: 48px;
  }
  .column-gap-xl-7 {
    column-gap: 56px;
  }
  .column-gap-xl-8 {
    column-gap: 64px;
  }
  .column-gap-xl-9 {
    column-gap: 72px;
  }
  .column-gap-xl-10 {
    column-gap: 80px;
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1500px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .object-fit-xxl-contain {
    object-fit: contain;
  }
  .object-fit-xxl-cover {
    object-fit: cover;
  }
  .object-fit-xxl-fill {
    object-fit: fill;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down;
  }
  .object-fit-xxl-none {
    object-fit: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-inline-grid {
    display: inline-grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0;
  }
  .m-xxl-05 {
    margin: 4px;
  }
  .m-xxl-1 {
    margin: 8px;
  }
  .m-xxl-2 {
    margin: 16px;
  }
  .m-xxl-3 {
    margin: 24px;
  }
  .m-xxl-4 {
    margin: 32px;
  }
  .m-xxl-5 {
    margin: 40px;
  }
  .m-xxl-6 {
    margin: 48px;
  }
  .m-xxl-7 {
    margin: 56px;
  }
  .m-xxl-8 {
    margin: 64px;
  }
  .m-xxl-9 {
    margin: 72px;
  }
  .m-xxl-10 {
    margin: 80px;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-05 {
    margin-right: 4px;
    margin-left: 4px;
  }
  .mx-xxl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xxl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xxl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xxl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xxl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xxl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xxl-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-xxl-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-xxl-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-xxl-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-05 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-xxl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xxl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xxl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xxl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xxl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xxl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xxl-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-xxl-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xxl-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-xxl-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mt-xxl-05 {
    margin-top: 4px;
  }
  .mt-xxl-1 {
    margin-top: 8px;
  }
  .mt-xxl-2 {
    margin-top: 16px;
  }
  .mt-xxl-3 {
    margin-top: 24px;
  }
  .mt-xxl-4 {
    margin-top: 32px;
  }
  .mt-xxl-5 {
    margin-top: 40px;
  }
  .mt-xxl-6 {
    margin-top: 48px;
  }
  .mt-xxl-7 {
    margin-top: 56px;
  }
  .mt-xxl-8 {
    margin-top: 64px;
  }
  .mt-xxl-9 {
    margin-top: 72px;
  }
  .mt-xxl-10 {
    margin-top: 80px;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0;
  }
  .me-xxl-05 {
    margin-right: 4px;
  }
  .me-xxl-1 {
    margin-right: 8px;
  }
  .me-xxl-2 {
    margin-right: 16px;
  }
  .me-xxl-3 {
    margin-right: 24px;
  }
  .me-xxl-4 {
    margin-right: 32px;
  }
  .me-xxl-5 {
    margin-right: 40px;
  }
  .me-xxl-6 {
    margin-right: 48px;
  }
  .me-xxl-7 {
    margin-right: 56px;
  }
  .me-xxl-8 {
    margin-right: 64px;
  }
  .me-xxl-9 {
    margin-right: 72px;
  }
  .me-xxl-10 {
    margin-right: 80px;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-05 {
    margin-bottom: 4px;
  }
  .mb-xxl-1 {
    margin-bottom: 8px;
  }
  .mb-xxl-2 {
    margin-bottom: 16px;
  }
  .mb-xxl-3 {
    margin-bottom: 24px;
  }
  .mb-xxl-4 {
    margin-bottom: 32px;
  }
  .mb-xxl-5 {
    margin-bottom: 40px;
  }
  .mb-xxl-6 {
    margin-bottom: 48px;
  }
  .mb-xxl-7 {
    margin-bottom: 56px;
  }
  .mb-xxl-8 {
    margin-bottom: 64px;
  }
  .mb-xxl-9 {
    margin-bottom: 72px;
  }
  .mb-xxl-10 {
    margin-bottom: 80px;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0;
  }
  .ms-xxl-05 {
    margin-left: 4px;
  }
  .ms-xxl-1 {
    margin-left: 8px;
  }
  .ms-xxl-2 {
    margin-left: 16px;
  }
  .ms-xxl-3 {
    margin-left: 24px;
  }
  .ms-xxl-4 {
    margin-left: 32px;
  }
  .ms-xxl-5 {
    margin-left: 40px;
  }
  .ms-xxl-6 {
    margin-left: 48px;
  }
  .ms-xxl-7 {
    margin-left: 56px;
  }
  .ms-xxl-8 {
    margin-left: 64px;
  }
  .ms-xxl-9 {
    margin-left: 72px;
  }
  .ms-xxl-10 {
    margin-left: 80px;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .p-xxl-0 {
    padding: 0;
  }
  .p-xxl-05 {
    padding: 4px;
  }
  .p-xxl-1 {
    padding: 8px;
  }
  .p-xxl-2 {
    padding: 16px;
  }
  .p-xxl-3 {
    padding: 24px;
  }
  .p-xxl-4 {
    padding: 32px;
  }
  .p-xxl-5 {
    padding: 40px;
  }
  .p-xxl-6 {
    padding: 48px;
  }
  .p-xxl-7 {
    padding: 56px;
  }
  .p-xxl-8 {
    padding: 64px;
  }
  .p-xxl-9 {
    padding: 72px;
  }
  .p-xxl-10 {
    padding: 80px;
  }
  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxl-05 {
    padding-right: 4px;
    padding-left: 4px;
  }
  .px-xxl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xxl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xxl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xxl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xxl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xxl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-xxl-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-xxl-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-xxl-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-xxl-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxl-05 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-xxl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xxl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xxl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xxl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xxl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xxl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xxl-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-xxl-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-xxl-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-xxl-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pt-xxl-05 {
    padding-top: 4px;
  }
  .pt-xxl-1 {
    padding-top: 8px;
  }
  .pt-xxl-2 {
    padding-top: 16px;
  }
  .pt-xxl-3 {
    padding-top: 24px;
  }
  .pt-xxl-4 {
    padding-top: 32px;
  }
  .pt-xxl-5 {
    padding-top: 40px;
  }
  .pt-xxl-6 {
    padding-top: 48px;
  }
  .pt-xxl-7 {
    padding-top: 56px;
  }
  .pt-xxl-8 {
    padding-top: 64px;
  }
  .pt-xxl-9 {
    padding-top: 72px;
  }
  .pt-xxl-10 {
    padding-top: 80px;
  }
  .pe-xxl-0 {
    padding-right: 0;
  }
  .pe-xxl-05 {
    padding-right: 4px;
  }
  .pe-xxl-1 {
    padding-right: 8px;
  }
  .pe-xxl-2 {
    padding-right: 16px;
  }
  .pe-xxl-3 {
    padding-right: 24px;
  }
  .pe-xxl-4 {
    padding-right: 32px;
  }
  .pe-xxl-5 {
    padding-right: 40px;
  }
  .pe-xxl-6 {
    padding-right: 48px;
  }
  .pe-xxl-7 {
    padding-right: 56px;
  }
  .pe-xxl-8 {
    padding-right: 64px;
  }
  .pe-xxl-9 {
    padding-right: 72px;
  }
  .pe-xxl-10 {
    padding-right: 80px;
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pb-xxl-05 {
    padding-bottom: 4px;
  }
  .pb-xxl-1 {
    padding-bottom: 8px;
  }
  .pb-xxl-2 {
    padding-bottom: 16px;
  }
  .pb-xxl-3 {
    padding-bottom: 24px;
  }
  .pb-xxl-4 {
    padding-bottom: 32px;
  }
  .pb-xxl-5 {
    padding-bottom: 40px;
  }
  .pb-xxl-6 {
    padding-bottom: 48px;
  }
  .pb-xxl-7 {
    padding-bottom: 56px;
  }
  .pb-xxl-8 {
    padding-bottom: 64px;
  }
  .pb-xxl-9 {
    padding-bottom: 72px;
  }
  .pb-xxl-10 {
    padding-bottom: 80px;
  }
  .ps-xxl-0 {
    padding-left: 0;
  }
  .ps-xxl-05 {
    padding-left: 4px;
  }
  .ps-xxl-1 {
    padding-left: 8px;
  }
  .ps-xxl-2 {
    padding-left: 16px;
  }
  .ps-xxl-3 {
    padding-left: 24px;
  }
  .ps-xxl-4 {
    padding-left: 32px;
  }
  .ps-xxl-5 {
    padding-left: 40px;
  }
  .ps-xxl-6 {
    padding-left: 48px;
  }
  .ps-xxl-7 {
    padding-left: 56px;
  }
  .ps-xxl-8 {
    padding-left: 64px;
  }
  .ps-xxl-9 {
    padding-left: 72px;
  }
  .ps-xxl-10 {
    padding-left: 80px;
  }
  .gap-xxl-0 {
    gap: 0;
  }
  .gap-xxl-05 {
    gap: 4px;
  }
  .gap-xxl-1 {
    gap: 8px;
  }
  .gap-xxl-2 {
    gap: 16px;
  }
  .gap-xxl-3 {
    gap: 24px;
  }
  .gap-xxl-4 {
    gap: 32px;
  }
  .gap-xxl-5 {
    gap: 40px;
  }
  .gap-xxl-6 {
    gap: 48px;
  }
  .gap-xxl-7 {
    gap: 56px;
  }
  .gap-xxl-8 {
    gap: 64px;
  }
  .gap-xxl-9 {
    gap: 72px;
  }
  .gap-xxl-10 {
    gap: 80px;
  }
  .row-gap-xxl-0 {
    row-gap: 0;
  }
  .row-gap-xxl-05 {
    row-gap: 4px;
  }
  .row-gap-xxl-1 {
    row-gap: 8px;
  }
  .row-gap-xxl-2 {
    row-gap: 16px;
  }
  .row-gap-xxl-3 {
    row-gap: 24px;
  }
  .row-gap-xxl-4 {
    row-gap: 32px;
  }
  .row-gap-xxl-5 {
    row-gap: 40px;
  }
  .row-gap-xxl-6 {
    row-gap: 48px;
  }
  .row-gap-xxl-7 {
    row-gap: 56px;
  }
  .row-gap-xxl-8 {
    row-gap: 64px;
  }
  .row-gap-xxl-9 {
    row-gap: 72px;
  }
  .row-gap-xxl-10 {
    row-gap: 80px;
  }
  .column-gap-xxl-0 {
    column-gap: 0;
  }
  .column-gap-xxl-05 {
    column-gap: 4px;
  }
  .column-gap-xxl-1 {
    column-gap: 8px;
  }
  .column-gap-xxl-2 {
    column-gap: 16px;
  }
  .column-gap-xxl-3 {
    column-gap: 24px;
  }
  .column-gap-xxl-4 {
    column-gap: 32px;
  }
  .column-gap-xxl-5 {
    column-gap: 40px;
  }
  .column-gap-xxl-6 {
    column-gap: 48px;
  }
  .column-gap-xxl-7 {
    column-gap: 56px;
  }
  .column-gap-xxl-8 {
    column-gap: 64px;
  }
  .column-gap-xxl-9 {
    column-gap: 72px;
  }
  .column-gap-xxl-10 {
    column-gap: 80px;
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
}
@media (min-width: 1920px) {
  .float-xxxl-start {
    float: left;
  }
  .float-xxxl-end {
    float: right;
  }
  .float-xxxl-none {
    float: none;
  }
  .object-fit-xxxl-contain {
    object-fit: contain;
  }
  .object-fit-xxxl-cover {
    object-fit: cover;
  }
  .object-fit-xxxl-fill {
    object-fit: fill;
  }
  .object-fit-xxxl-scale {
    object-fit: scale-down;
  }
  .object-fit-xxxl-none {
    object-fit: none;
  }
  .d-xxxl-inline {
    display: inline;
  }
  .d-xxxl-inline-block {
    display: inline-block;
  }
  .d-xxxl-block {
    display: block;
  }
  .d-xxxl-grid {
    display: grid;
  }
  .d-xxxl-inline-grid {
    display: inline-grid;
  }
  .d-xxxl-table {
    display: table;
  }
  .d-xxxl-table-row {
    display: table-row;
  }
  .d-xxxl-table-cell {
    display: table-cell;
  }
  .d-xxxl-flex {
    display: flex;
  }
  .d-xxxl-inline-flex {
    display: inline-flex;
  }
  .d-xxxl-none {
    display: none;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxxl-row {
    flex-direction: row;
  }
  .flex-xxxl-column {
    flex-direction: column;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxxl-center {
    justify-content: center;
  }
  .justify-content-xxxl-between {
    justify-content: space-between;
  }
  .justify-content-xxxl-around {
    justify-content: space-around;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxxl-start {
    align-items: flex-start;
  }
  .align-items-xxxl-end {
    align-items: flex-end;
  }
  .align-items-xxxl-center {
    align-items: center;
  }
  .align-items-xxxl-baseline {
    align-items: baseline;
  }
  .align-items-xxxl-stretch {
    align-items: stretch;
  }
  .align-content-xxxl-start {
    align-content: flex-start;
  }
  .align-content-xxxl-end {
    align-content: flex-end;
  }
  .align-content-xxxl-center {
    align-content: center;
  }
  .align-content-xxxl-between {
    align-content: space-between;
  }
  .align-content-xxxl-around {
    align-content: space-around;
  }
  .align-content-xxxl-stretch {
    align-content: stretch;
  }
  .align-self-xxxl-auto {
    align-self: auto;
  }
  .align-self-xxxl-start {
    align-self: flex-start;
  }
  .align-self-xxxl-end {
    align-self: flex-end;
  }
  .align-self-xxxl-center {
    align-self: center;
  }
  .align-self-xxxl-baseline {
    align-self: baseline;
  }
  .align-self-xxxl-stretch {
    align-self: stretch;
  }
  .order-xxxl-first {
    order: -1;
  }
  .order-xxxl-0 {
    order: 0;
  }
  .order-xxxl-1 {
    order: 1;
  }
  .order-xxxl-2 {
    order: 2;
  }
  .order-xxxl-3 {
    order: 3;
  }
  .order-xxxl-4 {
    order: 4;
  }
  .order-xxxl-5 {
    order: 5;
  }
  .order-xxxl-last {
    order: 6;
  }
  .m-xxxl-0 {
    margin: 0;
  }
  .m-xxxl-05 {
    margin: 4px;
  }
  .m-xxxl-1 {
    margin: 8px;
  }
  .m-xxxl-2 {
    margin: 16px;
  }
  .m-xxxl-3 {
    margin: 24px;
  }
  .m-xxxl-4 {
    margin: 32px;
  }
  .m-xxxl-5 {
    margin: 40px;
  }
  .m-xxxl-6 {
    margin: 48px;
  }
  .m-xxxl-7 {
    margin: 56px;
  }
  .m-xxxl-8 {
    margin: 64px;
  }
  .m-xxxl-9 {
    margin: 72px;
  }
  .m-xxxl-10 {
    margin: 80px;
  }
  .m-xxxl-auto {
    margin: auto;
  }
  .mx-xxxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxxl-05 {
    margin-right: 4px;
    margin-left: 4px;
  }
  .mx-xxxl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xxxl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xxxl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xxxl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xxxl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xxxl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xxxl-7 {
    margin-right: 56px;
    margin-left: 56px;
  }
  .mx-xxxl-8 {
    margin-right: 64px;
    margin-left: 64px;
  }
  .mx-xxxl-9 {
    margin-right: 72px;
    margin-left: 72px;
  }
  .mx-xxxl-10 {
    margin-right: 80px;
    margin-left: 80px;
  }
  .mx-xxxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxxl-05 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .my-xxxl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xxxl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xxxl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xxxl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xxxl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xxxl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xxxl-7 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .my-xxxl-8 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .my-xxxl-9 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .my-xxxl-10 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .my-xxxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxxl-0 {
    margin-top: 0;
  }
  .mt-xxxl-05 {
    margin-top: 4px;
  }
  .mt-xxxl-1 {
    margin-top: 8px;
  }
  .mt-xxxl-2 {
    margin-top: 16px;
  }
  .mt-xxxl-3 {
    margin-top: 24px;
  }
  .mt-xxxl-4 {
    margin-top: 32px;
  }
  .mt-xxxl-5 {
    margin-top: 40px;
  }
  .mt-xxxl-6 {
    margin-top: 48px;
  }
  .mt-xxxl-7 {
    margin-top: 56px;
  }
  .mt-xxxl-8 {
    margin-top: 64px;
  }
  .mt-xxxl-9 {
    margin-top: 72px;
  }
  .mt-xxxl-10 {
    margin-top: 80px;
  }
  .mt-xxxl-auto {
    margin-top: auto;
  }
  .me-xxxl-0 {
    margin-right: 0;
  }
  .me-xxxl-05 {
    margin-right: 4px;
  }
  .me-xxxl-1 {
    margin-right: 8px;
  }
  .me-xxxl-2 {
    margin-right: 16px;
  }
  .me-xxxl-3 {
    margin-right: 24px;
  }
  .me-xxxl-4 {
    margin-right: 32px;
  }
  .me-xxxl-5 {
    margin-right: 40px;
  }
  .me-xxxl-6 {
    margin-right: 48px;
  }
  .me-xxxl-7 {
    margin-right: 56px;
  }
  .me-xxxl-8 {
    margin-right: 64px;
  }
  .me-xxxl-9 {
    margin-right: 72px;
  }
  .me-xxxl-10 {
    margin-right: 80px;
  }
  .me-xxxl-auto {
    margin-right: auto;
  }
  .mb-xxxl-0 {
    margin-bottom: 0;
  }
  .mb-xxxl-05 {
    margin-bottom: 4px;
  }
  .mb-xxxl-1 {
    margin-bottom: 8px;
  }
  .mb-xxxl-2 {
    margin-bottom: 16px;
  }
  .mb-xxxl-3 {
    margin-bottom: 24px;
  }
  .mb-xxxl-4 {
    margin-bottom: 32px;
  }
  .mb-xxxl-5 {
    margin-bottom: 40px;
  }
  .mb-xxxl-6 {
    margin-bottom: 48px;
  }
  .mb-xxxl-7 {
    margin-bottom: 56px;
  }
  .mb-xxxl-8 {
    margin-bottom: 64px;
  }
  .mb-xxxl-9 {
    margin-bottom: 72px;
  }
  .mb-xxxl-10 {
    margin-bottom: 80px;
  }
  .mb-xxxl-auto {
    margin-bottom: auto;
  }
  .ms-xxxl-0 {
    margin-left: 0;
  }
  .ms-xxxl-05 {
    margin-left: 4px;
  }
  .ms-xxxl-1 {
    margin-left: 8px;
  }
  .ms-xxxl-2 {
    margin-left: 16px;
  }
  .ms-xxxl-3 {
    margin-left: 24px;
  }
  .ms-xxxl-4 {
    margin-left: 32px;
  }
  .ms-xxxl-5 {
    margin-left: 40px;
  }
  .ms-xxxl-6 {
    margin-left: 48px;
  }
  .ms-xxxl-7 {
    margin-left: 56px;
  }
  .ms-xxxl-8 {
    margin-left: 64px;
  }
  .ms-xxxl-9 {
    margin-left: 72px;
  }
  .ms-xxxl-10 {
    margin-left: 80px;
  }
  .ms-xxxl-auto {
    margin-left: auto;
  }
  .p-xxxl-0 {
    padding: 0;
  }
  .p-xxxl-05 {
    padding: 4px;
  }
  .p-xxxl-1 {
    padding: 8px;
  }
  .p-xxxl-2 {
    padding: 16px;
  }
  .p-xxxl-3 {
    padding: 24px;
  }
  .p-xxxl-4 {
    padding: 32px;
  }
  .p-xxxl-5 {
    padding: 40px;
  }
  .p-xxxl-6 {
    padding: 48px;
  }
  .p-xxxl-7 {
    padding: 56px;
  }
  .p-xxxl-8 {
    padding: 64px;
  }
  .p-xxxl-9 {
    padding: 72px;
  }
  .p-xxxl-10 {
    padding: 80px;
  }
  .px-xxxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxxl-05 {
    padding-right: 4px;
    padding-left: 4px;
  }
  .px-xxxl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xxxl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xxxl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xxxl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xxxl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xxxl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .px-xxxl-7 {
    padding-right: 56px;
    padding-left: 56px;
  }
  .px-xxxl-8 {
    padding-right: 64px;
    padding-left: 64px;
  }
  .px-xxxl-9 {
    padding-right: 72px;
    padding-left: 72px;
  }
  .px-xxxl-10 {
    padding-right: 80px;
    padding-left: 80px;
  }
  .py-xxxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxxl-05 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .py-xxxl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xxxl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xxxl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xxxl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xxxl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xxxl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .py-xxxl-7 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .py-xxxl-8 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .py-xxxl-9 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .py-xxxl-10 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pt-xxxl-0 {
    padding-top: 0;
  }
  .pt-xxxl-05 {
    padding-top: 4px;
  }
  .pt-xxxl-1 {
    padding-top: 8px;
  }
  .pt-xxxl-2 {
    padding-top: 16px;
  }
  .pt-xxxl-3 {
    padding-top: 24px;
  }
  .pt-xxxl-4 {
    padding-top: 32px;
  }
  .pt-xxxl-5 {
    padding-top: 40px;
  }
  .pt-xxxl-6 {
    padding-top: 48px;
  }
  .pt-xxxl-7 {
    padding-top: 56px;
  }
  .pt-xxxl-8 {
    padding-top: 64px;
  }
  .pt-xxxl-9 {
    padding-top: 72px;
  }
  .pt-xxxl-10 {
    padding-top: 80px;
  }
  .pe-xxxl-0 {
    padding-right: 0;
  }
  .pe-xxxl-05 {
    padding-right: 4px;
  }
  .pe-xxxl-1 {
    padding-right: 8px;
  }
  .pe-xxxl-2 {
    padding-right: 16px;
  }
  .pe-xxxl-3 {
    padding-right: 24px;
  }
  .pe-xxxl-4 {
    padding-right: 32px;
  }
  .pe-xxxl-5 {
    padding-right: 40px;
  }
  .pe-xxxl-6 {
    padding-right: 48px;
  }
  .pe-xxxl-7 {
    padding-right: 56px;
  }
  .pe-xxxl-8 {
    padding-right: 64px;
  }
  .pe-xxxl-9 {
    padding-right: 72px;
  }
  .pe-xxxl-10 {
    padding-right: 80px;
  }
  .pb-xxxl-0 {
    padding-bottom: 0;
  }
  .pb-xxxl-05 {
    padding-bottom: 4px;
  }
  .pb-xxxl-1 {
    padding-bottom: 8px;
  }
  .pb-xxxl-2 {
    padding-bottom: 16px;
  }
  .pb-xxxl-3 {
    padding-bottom: 24px;
  }
  .pb-xxxl-4 {
    padding-bottom: 32px;
  }
  .pb-xxxl-5 {
    padding-bottom: 40px;
  }
  .pb-xxxl-6 {
    padding-bottom: 48px;
  }
  .pb-xxxl-7 {
    padding-bottom: 56px;
  }
  .pb-xxxl-8 {
    padding-bottom: 64px;
  }
  .pb-xxxl-9 {
    padding-bottom: 72px;
  }
  .pb-xxxl-10 {
    padding-bottom: 80px;
  }
  .ps-xxxl-0 {
    padding-left: 0;
  }
  .ps-xxxl-05 {
    padding-left: 4px;
  }
  .ps-xxxl-1 {
    padding-left: 8px;
  }
  .ps-xxxl-2 {
    padding-left: 16px;
  }
  .ps-xxxl-3 {
    padding-left: 24px;
  }
  .ps-xxxl-4 {
    padding-left: 32px;
  }
  .ps-xxxl-5 {
    padding-left: 40px;
  }
  .ps-xxxl-6 {
    padding-left: 48px;
  }
  .ps-xxxl-7 {
    padding-left: 56px;
  }
  .ps-xxxl-8 {
    padding-left: 64px;
  }
  .ps-xxxl-9 {
    padding-left: 72px;
  }
  .ps-xxxl-10 {
    padding-left: 80px;
  }
  .gap-xxxl-0 {
    gap: 0;
  }
  .gap-xxxl-05 {
    gap: 4px;
  }
  .gap-xxxl-1 {
    gap: 8px;
  }
  .gap-xxxl-2 {
    gap: 16px;
  }
  .gap-xxxl-3 {
    gap: 24px;
  }
  .gap-xxxl-4 {
    gap: 32px;
  }
  .gap-xxxl-5 {
    gap: 40px;
  }
  .gap-xxxl-6 {
    gap: 48px;
  }
  .gap-xxxl-7 {
    gap: 56px;
  }
  .gap-xxxl-8 {
    gap: 64px;
  }
  .gap-xxxl-9 {
    gap: 72px;
  }
  .gap-xxxl-10 {
    gap: 80px;
  }
  .row-gap-xxxl-0 {
    row-gap: 0;
  }
  .row-gap-xxxl-05 {
    row-gap: 4px;
  }
  .row-gap-xxxl-1 {
    row-gap: 8px;
  }
  .row-gap-xxxl-2 {
    row-gap: 16px;
  }
  .row-gap-xxxl-3 {
    row-gap: 24px;
  }
  .row-gap-xxxl-4 {
    row-gap: 32px;
  }
  .row-gap-xxxl-5 {
    row-gap: 40px;
  }
  .row-gap-xxxl-6 {
    row-gap: 48px;
  }
  .row-gap-xxxl-7 {
    row-gap: 56px;
  }
  .row-gap-xxxl-8 {
    row-gap: 64px;
  }
  .row-gap-xxxl-9 {
    row-gap: 72px;
  }
  .row-gap-xxxl-10 {
    row-gap: 80px;
  }
  .column-gap-xxxl-0 {
    column-gap: 0;
  }
  .column-gap-xxxl-05 {
    column-gap: 4px;
  }
  .column-gap-xxxl-1 {
    column-gap: 8px;
  }
  .column-gap-xxxl-2 {
    column-gap: 16px;
  }
  .column-gap-xxxl-3 {
    column-gap: 24px;
  }
  .column-gap-xxxl-4 {
    column-gap: 32px;
  }
  .column-gap-xxxl-5 {
    column-gap: 40px;
  }
  .column-gap-xxxl-6 {
    column-gap: 48px;
  }
  .column-gap-xxxl-7 {
    column-gap: 56px;
  }
  .column-gap-xxxl-8 {
    column-gap: 64px;
  }
  .column-gap-xxxl-9 {
    column-gap: 72px;
  }
  .column-gap-xxxl-10 {
    column-gap: 80px;
  }
  .text-xxxl-start {
    text-align: left;
  }
  .text-xxxl-end {
    text-align: right;
  }
  .text-xxxl-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem;
  }
  .fs-2 {
    font-size: 2rem;
  }
  .fs-3 {
    font-size: 1.75rem;
  }
  .fs-4 {
    font-size: 1.5rem;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-grid {
    display: grid;
  }
  .d-print-inline-grid {
    display: inline-grid;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: flex;
  }
  .d-print-inline-flex {
    display: inline-flex;
  }
  .d-print-none {
    display: none;
  }
}
/*! purgecss start ignore */
.container {
  padding-right: 26px;
  padding-left: 26px;
}
@media (max-width: 575.98px) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (width <= 359px) {
  .container {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.gap-0\.5 {
  gap: 4px;
}

.fancybox__container {
  --fancybox-accent-color: #6248aa;
}
.fancybox__container .fancybox__thumb {
  background-size: contain;
}

.flatpickr-calendar {
  --theme-color: #6248aa;
  --theme-color-hover: #f1ecf6;
  --border-color: #d7cef0;
  --font-color: #484752;
  --font-color-out: #b1afbd;
  /* stylelint-disable-next-line no-descending-specificity */
  /* stylelint-disable-next-line no-descending-specificity */
}
.flatpickr-calendar.open {
  border-radius: 24px;
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
  width: 408px;
  background: #f9f9f9;
}
.flatpickr-calendar.open::before, .flatpickr-calendar.open::after {
  display: none;
}
.flatpickr-calendar .flatpickr-day {
  color: var(--font-color);
  width: 100%;
  max-width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
}
.flatpickr-calendar .flatpickr-day:hover {
  background: var(--theme-color-hover);
  border: 1px solid var(--border-color);
}
.flatpickr-calendar .flatpickr-day.selected {
  background: var(--theme-color);
  border-color: var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.startRange.today, .flatpickr-calendar .flatpickr-day.startRange.selected.today,
.flatpickr-calendar .flatpickr-day.endRange.today,
.flatpickr-calendar .flatpickr-day.endRange.selected.today {
  background: transparent;
  border-color: var(--theme-color);
  color: var(--font-color);
}
.flatpickr-calendar .flatpickr-day.startRange.today:hover, .flatpickr-calendar .flatpickr-day.startRange.selected.today:hover,
.flatpickr-calendar .flatpickr-day.endRange.today:hover,
.flatpickr-calendar .flatpickr-day.endRange.selected.today:hover {
  background: transparent;
  border-color: var(--theme-color);
  color: var(--font-color);
}
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay.selected.today, .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay.selected.today,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay.selected.today,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay.selected.today {
  color: var(--font-color-out);
}
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay.selected.today:hover, .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay.selected.today:hover,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay.selected.today:hover,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay.selected.today:hover {
  color: var(--font-color-out);
}
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.startRange.selected,
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay {
  box-shadow: 5px 0 var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.startRange:hover,
.flatpickr-calendar .flatpickr-day.startRange.selected:hover,
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay:hover {
  box-shadow: 5px 0 var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.endRange.selected,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay {
  box-shadow: -5px 0 var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.endRange:hover,
.flatpickr-calendar .flatpickr-day.endRange.selected:hover,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay:hover {
  box-shadow: -5px 0 var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay:not(.flatpickr-disabled),
.flatpickr-calendar .flatpickr-day.startRange.selected,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:not(.flatpickr-disabled),
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay:not(.flatpickr-disabled),
.flatpickr-calendar .flatpickr-day.endRange.selected {
  background: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--border-color);
}
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay:not(.flatpickr-disabled):hover,
.flatpickr-calendar .flatpickr-day.startRange.selected:hover,
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.inRange.prevMonthDay:not(.flatpickr-disabled):hover,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay:hover,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay:not(.flatpickr-disabled):hover,
.flatpickr-calendar .flatpickr-day.endRange.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
  color: var(--border-color);
}
.flatpickr-calendar .flatpickr-day.inRange {
  box-shadow: -5px 0 var(--theme-color), 5px 0 var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.inRange.today {
  background: transparent;
  border-color: var(--theme-color);
  color: var(--font-color);
}
.flatpickr-calendar .flatpickr-day.inRange.nextMonthDay.today, .flatpickr-calendar .flatpickr-day.inRange.prevMonthDay.today {
  background: transparent;
  border-color: var(--theme-color);
  color: var(--font-color-out);
}
.flatpickr-calendar .flatpickr-day.inRange:hover {
  box-shadow: -5px 0 var(--theme-color), 5px 0 var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.startRange.selected,
.flatpickr-calendar .flatpickr-day.inRange,
.flatpickr-calendar .flatpickr-day.endRange,
.flatpickr-calendar .flatpickr-day.endRange.selected {
  background: var(--theme-color);
  border-color: var(--theme-color);
  color: #fff;
}
.flatpickr-calendar .flatpickr-day.startRange:hover,
.flatpickr-calendar .flatpickr-day.startRange.selected:hover,
.flatpickr-calendar .flatpickr-day.inRange:hover,
.flatpickr-calendar .flatpickr-day.endRange:hover,
.flatpickr-calendar .flatpickr-day.endRange.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
  color: #fff;
}
.flatpickr-calendar .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) .flatpickr-day.selected {
  box-shadow: -10px 0 var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)):hover,
.flatpickr-calendar .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)):hover,
.flatpickr-calendar .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) .flatpickr-day.selected:hover {
  box-shadow: -10px 0 var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.today {
  border: 2px solid var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.today:hover {
  background: transparent;
  border-color: var(--theme-color);
  color: var(--font-color);
}
.flatpickr-calendar .flatpickr-day.nextMonthDay,
.flatpickr-calendar .flatpickr-day.prevMonthDay:not(.flatpickr-disabled) {
  color: var(--font-color-out);
}
.flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.prevMonthDay:not(.flatpickr-disabled):hover {
  background: var(--theme-color-hover);
  border-color: var(--border-color);
}
.flatpickr-calendar .flatpickr-day.nextMonthDay.selected,
.flatpickr-calendar .flatpickr-day.prevMonthDay.selected:not(.flatpickr-disabled) {
  color: var(--border-color);
  background: var(--theme-color);
  border-color: var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.nextMonthDay.today,
.flatpickr-calendar .flatpickr-day.prevMonthDay.today:not(.flatpickr-disabled) {
  color: var(--font-color-out);
  border-color: var(--theme-color);
  background: transparent;
}
.flatpickr-calendar .flatpickr-day.nextMonthDay.today:hover,
.flatpickr-calendar .flatpickr-day.prevMonthDay.today:not(.flatpickr-disabled):hover {
  background: transparent;
  border-color: var(--theme-color);
  color: var(--font-color-out);
}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.flatpickr-calendar .flatpickr-day.prevMonthDay.flatpickr-disabled {
  color: var(--font-color-out);
}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled:hover,
.flatpickr-calendar .flatpickr-day.prevMonthDay.flatpickr-disabled:hover {
  color: var(--font-color-out);
}
.flatpickr-calendar .flatpickr-day.startRange.nextMonthDay.selected,
.flatpickr-calendar .flatpickr-day.startRange.prevMonthDay.selected,
.flatpickr-calendar .flatpickr-day.endRange.nextMonthDay.selected,
.flatpickr-calendar .flatpickr-day.endRange.prevMonthDay.selected {
  color: var(--border-color);
}
.flatpickr-calendar .flatpickr-day.startRange.endRange.selected {
  box-shadow: none;
}
.flatpickr-calendar .flatpickr-months {
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  padding-bottom: 24px;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 4px 16px 1px transparent;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover {
  background: #fff;
  box-shadow: 0 4px 16px 1px rgba(98, 72, 170, 0.2);
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month::before,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center center;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month svg,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month svg {
  display: none;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month::before {
  background-image: url("../images/svg/calendar-left.svg");
}
.flatpickr-calendar .flatpickr-months .flatpickr-next-month::before {
  background-image: url("../images/svg/calendar-right.svg");
}
.flatpickr-calendar .flatpickr-months .flatpickr-month {
  height: 48px;
  width: 100%;
  display: flex;
}
.flatpickr-calendar .flatpickr-months .flatpickr-current-month {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding-top: 0;
  width: 100%;
  height: 48px;
  left: 0;
}
.flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months,
.flatpickr-calendar .flatpickr-months .numInput.cur-year {
  color: var(--theme-color);
  font-weight: 600;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  padding: 0;
  text-align: center;
  appearance: none;
}
.flatpickr-calendar .flatpickr-months .numInput.cur-year {
  appearance: textfield;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper {
  margin-left: -16px;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper span {
  line-height: normal;
  padding: 0;
  width: 16px;
  height: 16px;
  opacity: 1;
  border: none;
  background: transparent;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper span::after {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  border: none;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper .arrowUp {
  top: -4px;
  right: -4px;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper .arrowUp::after {
  background-image: url("../images/svg/arrow-up-num-input.svg");
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.flatpickr-calendar .flatpickr-months .numInputWrapper .arrowDown {
  top: 12px;
  right: -4px;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper .arrowDown::after {
  background-image: url("../images/svg/arrow-down-num-input.svg");
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.flatpickr-calendar .flatpickr-months .numInputWrapper:hover {
  background: transparent;
}
.flatpickr-calendar .flatpickr-months .numInputWrapper:hover .numInput.cur-year {
  text-decoration-color: transparent;
}
.flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months {
  background-image: url("../images/svg/calendar-down.svg");
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 24px;
}
.flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months:hover {
  background: unset;
  background-image: url("../images/svg/calendar-down.svg");
  background-repeat: no-repeat;
  background-position: right center;
  text-decoration-color: transparent;
}
.flatpickr-calendar .flatpickr-days {
  width: 100%;
}
.flatpickr-calendar .dayContainer {
  margin-top: 8px;
  margin-bottom: -48px;
  gap: 8px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  justify-content: center;
}
.flatpickr-calendar .flatpickr-weekdays {
  height: 56px;
  background: var(--theme-color-hover);
  border-radius: 8px;
  padding: 8px;
  justify-content: center;
}
.flatpickr-calendar .flatpickr-weekdaycontainer {
  justify-content: center;
  gap: 8px;
}
.flatpickr-calendar span.flatpickr-weekday {
  color: var(--theme-color);
  font-size: 16px;
  font-weight: 700;
  max-width: 40px;
  width: 100%;
}

.flatpickr-innerContainer {
  padding: 32px;
  padding-top: 0;
}

.ss-content {
  pointer-events: none;
}
.ss-content.ss-open-above, .ss-content.ss-open-below {
  pointer-events: auto;
}

.swiper {
  --swiper-pagination-color: #312960;
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide.--hidden {
  display: none;
}

.swiper-button-disabled.btn {
  opacity: 0;
  pointer-events: none;
}

body {
  font-family: "Inter", "Adjusted Verdana", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  line-height: 1.5;
  background-color: #f9f9f9;
  color: #484752;
  min-height: 100vh;
}
body #product-parameters,
body #product-content,
body #product-reviews {
  scroll-margin-top: var(--scroll-margin-top);
}
@media (max-width: 991.98px) {
  body * {
    --scroll-margin-top: 80px;
  }
  body.--search-open {
    overflow: hidden;
  }
  body.--search-open .i-notification,
  body.--search-open .m-header-top {
    opacity: 0;
    pointer-events: none;
  }
}
body:has(.c-modal.--open) {
  overflow: hidden;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
  display: block;
}

figure {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  text-wrap: balance;
  font-family: "Blinker", "Adjusted Verdana", sans-serif;
}

html {
  height: 100%;
  text-size-adjust: none;
}

iframe {
  display: block;
  max-width: 100%;
}

img {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease;
  height: auto;
  max-width: 100%;
}
img[data-src], img[data-srcset] {
  display: block;
  min-height: 1px;
  min-width: 1px;
}
img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: #312960;
  transition: text-decoration-color 0.3s ease;
  text-decoration: underline;
}
a:hover, a:focus {
  color: #312960;
  text-decoration-color: transparent;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  overflow-x: hidden;
  display: block;
  flex-grow: 1;
}
main:has(.product-navbar) {
  overflow-x: initial;
}
main:has(.f-filtration.--open) {
  overflow-x: initial;
}

p {
  text-wrap: pretty;
}

:root {
  /* Section spacing */
  --section-spacing: 80px;
}
@media (max-width: 575.98px) {
  :root {
    --section-spacing: 40px;
  }
}

@font-face {
  font-family: "Adjusted Verdana";
  size-adjust: 80%;
  src: local(verdana);
}
@font-face {
  font-family: "Adjusted Georgia";
  size-adjust: 78%;
  src: local(georgia);
}
@font-face {
  font-family: Blinker;
  src: url("../fonts/Blinker-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: Blinker;
  src: url("../fonts/Blinker-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: Inter;
  src: url("../fonts/Inter-VariableFont_opsz,wght.woff2") format("woff2");
  font-optical-sizing: auto;
  font-weight: 400 500 700;
  font-style: normal;
  font-display: fallback;
}
strong,
b {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

input[type=number] {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -moz-appearance: textfield;
}

fieldset {
  margin: 0;
  padding: 0;
  min-width: 0;
}

progress {
  vertical-align: baseline;
}

* {
  min-width: 0;
}

/**
 * Clickable labels
 */
label[for] {
  cursor: pointer;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

.entry-content .alignnone {
  margin: 5px 20px 20px 0;
}
.entry-content .alignright {
  float: right;
  margin: 0 0 20px 30px;
}
.entry-content .alignright img {
  margin-block: 0;
}
.entry-content .alignleft {
  float: left;
  margin: 0 30px 20px 0;
}
.entry-content .alignleft img {
  margin-block: 0;
}
.entry-content .aligncenter,
.entry-content div.aligncenter {
  display: block;
  margin: 48px auto;
}
.entry-content .aligncenter img,
.entry-content div.aligncenter img {
  margin-inline: auto;
  margin-block: 0;
}
@media (max-width: 575.98px) {
  .entry-content .alignleft,
  .entry-content .alignright {
    float: none;
    display: block;
    margin: 0;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.u-bg-primary {
  background-color: #312960;
}

.u-bg-primary-100 {
  background-color: #edecf4;
}

.u-bg-primary-300 {
  background-color: #d7cef0;
}

.u-bg-primary-600-o-80 {
  background-color: rgba(98, 72, 170, 0.8);
}

.u-bg-gray {
  background-color: #a9a9b2;
}

.u-bg-white {
  background-color: #fff;
}

.u-bg-background {
  background-color: #f9f9f9;
}

.u-bg-secondary-500 {
  background-color: #ff5c50;
}

.u-bg-gradient {
  background: linear-gradient(to right, #312960, #6248aa);
}

.u-border-top {
  border-top: 1px solid #312960;
}

.u-border-right {
  border-right: 1px solid #312960;
}

.u-border-bottom {
  border-bottom: 1px solid #312960;
}

.u-border-left {
  border-left: 1px solid #312960;
}

.u-border-gray {
  border-color: #d7cef0;
}

.u-clamp {
  --lines: 2;
  display: -webkit-box;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-primary {
  color: #312960;
}

.u-color-primary-100 {
  color: #edecf4;
}

.u-color-primary-300 {
  color: #d7cef0;
}

.u-color-primary-500 {
  color: #7868a3;
}

.u-color-primary-600 {
  color: #6248aa;
}

.u-color-secondary {
  color: #e93d30;
}

.u-color-white {
  color: #fff;
}

.u-color-tertiary-600 {
  color: #73717e;
}

.u-color-tertiary {
  color: #484752;
}

.u-container-inline {
  container-type: inline-size;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-effect-fade > .u-effect-fade__target {
  opacity: 0;
  pointer-events: none;
}
@media (min-width: 992px) {
  .u-effect-fade:hover > .u-effect-fade__target {
    opacity: 1;
    pointer-events: auto;
    transition: 0.3s ease;
  }
}

.u-empty\:none:empty {
  display: none;
}

.u-fz-xxs {
  --size: 10px;
  --line-height: 16px;
  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-xs {
  --size: 12px;
  font-size: var(--size);
}

.u-fz-sm {
  --size: 14px;
  --line-height: 16px;
  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-md {
  --size: 16px;
  font-size: var(--size);
}

.u-fz-lg {
  --size: 18px;
  --line-height: 24px;
  font-size: var(--size);
  line-height: var(--line-height);
}

.u-fz-xl {
  --size: 20px;
  font-size: var(--size);
}

.u-fz-xxl {
  --size: 22px;
  font-size: var(--size);
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-font-primary {
  font-family: "Inter", "Adjusted Verdana", sans-serif;
}

.u-font-secondary {
  font-family: "Blinker", "Adjusted Verdana", sans-serif;
}

.u-h1 {
  --size: 45px;
}
@media (max-width: 767.98px) {
  .u-h1 {
    --size: 32px;
  }
}

.u-h2 {
  --size: 37px;
}
@media (max-width: 767.98px) {
  .u-h2 {
    --size: 28px;
  }
}

.u-h3 {
  --size: 28px;
}
@media (max-width: 767.98px) {
  .u-h3 {
    --size: 26px;
  }
}

.u-h4 {
  --size: 24px;
}
@media (max-width: 767.98px) {
  .u-h4 {
    --size: 20px;
  }
}

.u-h5 {
  --size: 22px;
}
@media (max-width: 767.98px) {
  .u-h5 {
    --size: 18px;
  }
}

.u-h6 {
  --size: 18px;
}
@media (max-width: 767.98px) {
  .u-h6 {
    --size: 16px;
  }
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5,
.u-h6 {
  font-size: var(--size);
}

.u-img-hover {
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.u-img-hover img {
  transition-property: opacity, transform;
  will-change: transform, opacity;
}
.u-img-hover:hover img {
  transform: scale(1.03);
}

.--loading {
  position: relative;
  pointer-events: none;
}
.--loading::after, .--loading::before {
  content: "";
  position: absolute;
  display: block;
}
.--loading::before {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  z-index: 90;
  transition: opacity 0.3s ease;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  animation: opacity-pulse 2s ease-out infinite;
}
.--loading::after {
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: -15px;
  margin-left: -15px;
  border: 4px solid #ff5c50;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s ease infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  z-index: 9001;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opacity-pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.5;
  }
}
.u-scrollbar .os-scrollbar {
  --os-handle-bg: #6248aa;
  --os-handle-bg-hover: #6248aa;
  --os-handle-bg-active: #6248aa;
  --os-handle-border-radius: 8px;
  --os-size: 16px;
  --os-track-bg: #edecf4;
  --os-track-bg-hover: #edecf4;
  --os-track-bg-active: #edecf4;
  --os-padding-perpendicular: 4px;
  --os-padding-axis: 4px;
  cursor: grab;
}
.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}

.u-scrollbar-native {
  --sb-track-color: #f9f9f9;
  --sb-thumb-color: #7868a3;
  --sb-size: 8px;
}
.u-scrollbar-native::-webkit-scrollbar {
  width: var(--sb-size);
}
.u-scrollbar-native::-webkit-scrollbar:horizontal {
  height: var(--sb-size);
}
.u-scrollbar-native::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 8px;
}
.u-scrollbar-native::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 8px;
  border: 1px solid #fff;
}

@supports not selector(::-webkit-scrollbar) {
  .u-scrollbar-native {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}
.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-50-p {
  top: 50%;
}

.u-top-0 {
  top: 0;
}

.u-right-0 {
  right: 0;
}

.u-bottom-0 {
  bottom: 0;
}

.u-inset-0 {
  inset: 0;
}

.u-left-50-p {
  left: 50%;
}

.u-left-0 {
  left: 0;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%) translateY(-50%);
}

.u-rounded-full {
  border-radius: 9999px;
}

.u-radius-1 {
  border-radius: 8px;
}

.u-radius-2 {
  border-radius: 16px;
}

.u-radius-3 {
  border-radius: 24px;
}

.u-radius-4 {
  border-radius: 32px;
}

.u-radius-5 {
  border-radius: 40px;
}

.u-radius-4px {
  border-radius: 4px;
}

.u-shadow-small {
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}

.u-shadow-medium {
  box-shadow: 0 4px 30px 2px rgba(49, 50, 96, 0.3);
}

.u-hover\:shadow-medium:hover {
  box-shadow: 0 4px 30px 2px rgba(49, 50, 96, 0.3);
}

.u-hover\:shadow-small:hover {
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}

.u-z-index-0 {
  z-index: 0;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.u-z-index-101 {
  z-index: 101;
}

.a-user-panel {
  border: 1px solid #d7cef0;
}

.base-header {
  --spacing: 24px;
  margin-inline: auto;
  max-width: 928px;
  margin-bottom: var(--spacing);
}
.base-header.--left {
  align-items: flex-start;
  text-align: left;
  margin-inline: 0;
}
.base-header.--spacing-none {
  --spacing: 0;
}
.base-header.--spacing-lg {
  --spacing: 56px;
}
@media (max-width: 575.98px) {
  .base-header.--spacing-lg {
    --spacing: 40px;
  }
}
@media (min-width: 768px) {
  .base-header {
    --spacing: 48px;
  }
}

.base-header__description p {
  margin-bottom: 0;
}

.base-heading {
  --decoration-width: 7px;
  --decoration-height: 16px;
}
@media (max-width: 575.98px) {
  .base-heading {
    --decoration-width: 5px;
    --decoration-height: 12px;
  }
}
.base-heading.--decoration::before {
  content: "";
  display: inline-block;
  width: var(--decoration-width);
  height: var(--decoration-height);
  border-radius: 0 var(--decoration-height) var(--decoration-height) 0;
  background-color: #ff5c50;
  vertical-align: middle;
}
.base-heading.--decoration-after {
  display: flex;
  gap: 8px;
}
.base-heading.--decoration-line .base-heading__decoration-after::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #d7cef0;
}
.base-heading.u-h3 {
  --decoration-width: 5px;
  --decoration-height: 12px;
}
@media (max-width: 575.98px) {
  .base-heading.u-h3 {
    --decoration-width: 4px;
    --decoration-height: 9px;
  }
}

.base-section {
  --decor-max-height: 560px;
  --decor-radius: 40px;
}
.base-section .base-section__banner {
  max-width: 1686px;
  width: 100%;
  height: 100%;
  max-height: var(--decor-max-height);
  background: linear-gradient(79.93deg, #312960 0%, #6248aa 98.96%);
  border-radius: var(--decor-radius);
  pointer-events: none;
  z-index: -2;
}
.base-section .base-section__banner-inner .base-section__banner-equalizer svg, .base-section .base-section__banner-inner::after {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 1686px;
  width: 100%;
  max-height: var(--decor-max-height);
  mix-blend-mode: multiply;
  border-radius: var(--decor-radius);
  pointer-events: none;
  z-index: -1;
}
.base-section .base-section__banner-inner::after {
  content: "";
  top: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center top;
}
.base-section .base-section__banner-inner .base-section__banner-equalizer svg {
  bottom: 0;
}
.base-section .base-section__banner-img {
  width: 461px;
  height: 461px;
  right: -160px;
  bottom: -160px;
}
.base-section.--big .base-header .base-heading, .base-section.--big-reverse .base-header .base-heading, .base-section.--small .base-header .base-heading, .base-section.--small-reverse .base-header .base-heading {
  color: #fff;
}
.base-section.--big .base-header .base-header__description, .base-section.--big-reverse .base-header .base-header__description, .base-section.--small .base-header .base-header__description, .base-section.--small-reverse .base-header .base-header__description {
  color: #edecf4;
}
.base-section.--big, .base-section.--big-reverse {
  min-height: var(--decor-max-height);
}
.base-section.--big .base-section__banner-inner::after, .base-section.--big-reverse .base-section__banner-inner::after {
  background-image: url("../images/svg/banner_front_big.svg");
}
.base-section.--small, .base-section.--small-reverse {
  --decor-max-height: 360px;
  min-height: var(--decor-max-height);
}
.base-section.--small .base-section__banner-inner::after, .base-section.--small-reverse .base-section__banner-inner::after {
  background-image: url("../images/svg/banner_front_small.svg");
}
.base-section.--big-reverse .base-section__banner-img, .base-section.--small-reverse .base-section__banner-img {
  left: -160px;
  right: auto;
}
.base-section.--equalizer .base-section__banner-inner::after {
  display: none;
}
@media (max-width: 1199.98px) {
  .base-section .base-section__banner-img {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .base-section {
    --decor-radius: 24px;
  }
  .base-section .base-section__banner,
  .base-section .base-section__banner-inner::after {
    border-radius: var(--decor-radius) var(--decor-radius) 0 0;
  }
}

.base-section__banner-equalizer {
  overflow: hidden;
  pointer-events: none;
}
.base-section__banner-equalizer path {
  transform-box: fill-box;
  will-change: transform;
}
.base-section__banner-equalizer path:nth-child(5n) {
  animation: equalizer 4s cubic-bezier(0.54, 2.36, 0.41, -0.33) -1s infinite alternate-reverse;
}
.base-section__banner-equalizer path:nth-child(5n+1) {
  animation: equalizer 3s cubic-bezier(0.89, 1.33, 0.64, 0.78) -2s infinite reverse;
}
.base-section__banner-equalizer path:nth-child(5n+2) {
  animation: equalizer 2s cubic-bezier(0.76, 1.32, 0.37, -0.32) infinite alternate;
}
.base-section__banner-equalizer path:nth-child(5n+3) {
  animation: equalizer 5s cubic-bezier(0.535, 0.33, 0.69, 1.275) infinite;
}
.base-section__banner-equalizer path:nth-child(5n+4) {
  animation: equalizer 4s cubic-bezier(0.61, -0.05, 0.51, 1.41) -3s infinite reverse;
}

@keyframes equalizer {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(45%);
  }
  40% {
    transform: translateY(23%);
  }
  60% {
    transform: translateY(64%);
  }
  80% {
    transform: translateY(32%);
  }
  100% {
    transform: translateY(0);
  }
}
.base-section {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
}
.base-section.--top-0 {
  padding-top: 0;
}
.base-section.--bottom-0 {
  padding-bottom: 0;
}
.base-section.--none {
  --spacing: 0;
}

main:has(.stylebook-grid) {
  overflow-x: visible;
}

.btn {
  --direction-offset: 8px;
}
.btn.--size-xs {
  --direction-offset: 4px;
}
@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}
@media (hover: hover) {
  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}
@media (hover: hover) {
  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }
}
@media (hover: hover) {
  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--sm-base-text {
  --font-size: 16px;
  --padding-y: 10px;
  --padding-x: 16px;
  --min-height: 48px;
}
.btn.--sm {
  --font-size: 14px;
  --padding-y: 12px;
  --padding-x: 24px;
  --min-height: 48px;
}
.btn.--xs {
  --font-size: 12px;
  --padding-y: 12px;
  --padding-x: 24px;
  --min-height: 40px;
  --svg-size: 16px;
}
.btn.--xxs {
  --font-size: 14px;
  --padding-y: 2px;
  --padding-x: 8px;
  --min-height: 32px;
  --svg-size: 24px;
}
.btn.--xxxs {
  --font-size: 12px;
  --padding-y: 0px;
  --padding-x: 2px;
  --min-height: 24px;
  --svg-size: 24px;
}

.btn.--primary {
  --background: #6248aa;
  background-color: var(--background);
  transition: background-color 0.3s ease;
  transition-property: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--primary:hover {
    --background: #312960;
    box-shadow: 0 4px 16px 1px rgba(98, 72, 170, 0.2);
  }
}
.btn.--secondary {
  --background: #ff5c50;
  background-color: var(--background);
  transition: background-color 0.3s ease;
  transition-property: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--secondary:hover {
    --background: #e93d30;
    box-shadow: 0 4px 16px 1px rgba(255, 92, 80, 0.6);
  }
}
.btn.--secondary-to-primary {
  --background: #ff5c50;
  background-color: var(--background);
  transition: background-color 0.3s ease;
  transition-property: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--secondary-to-primary:hover {
    --background: #6248aa;
  }
}
.btn.--transparent {
  --background: transparent;
  background-color: var(--background);
  transition: background-color 0.3s ease;
  transition-property: background-color;
}
.btn.--outline-secondary {
  --font-color: #ff5c50;
  --inner-shadow: inset 0 0 0 1px #ff5c50;
  --background: #fff;
  background-color: var(--background);
  box-shadow: 0 4px 16px 1px transparent, var(--inner-shadow);
  transition: background-color 0.3s ease;
  transition-property: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--outline-secondary:hover {
    box-shadow: 0 4px 16px 1px rgba(98, 72, 170, 0.2), var(--inner-shadow);
  }
}
.btn.--outline {
  --font-color: #6248aa;
  --inner-shadow: inset 0 0 0 1px #d7cef0;
  --background: #f9f9f9;
  background-color: var(--background);
  box-shadow: 0 4px 16px 1px transparent, var(--inner-shadow);
  transition: background-color 0.3s ease;
  transition-property: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--outline:hover {
    --background: #fff;
    box-shadow: 0 4px 16px 1px rgba(98, 72, 170, 0.2), var(--inner-shadow);
  }
}
.btn.--outline-transparent {
  --font-color: #6248aa;
  --inner-shadow: inset 0 0 0 1px #d7cef0;
  --background: rgba(249, 249, 249, 0.5);
  background-color: var(--background);
  box-shadow: 0 4px 16px 1px transparent, var(--inner-shadow);
  transition: background-color 0.3s ease;
  transition-property: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--outline-transparent:hover {
    --background: #fff;
    box-shadow: 0 4px 16px 1px rgba(98, 72, 170, 0.2), var(--inner-shadow);
  }
}
.btn.--outline-transparent-primary-300 {
  --font-color: #d7cef0;
  --border-color: #d7cef0;
  --background: transparent;
  background-color: var(--background);
  border: 1px solid var(--border-color);
  transition: background-color 0.3s ease;
  transition-property: background-color, box-shadow;
}
.btn.--ghost {
  --background: transparent;
  --font-color: #312960;
  background-color: var(--background);
  transition: background-color 0.3s ease;
}
@media (hover: hover) {
  .btn.--ghost:hover {
    --background: #edecf4;
  }
}
.btn.--facebook {
  --background: #3b5998;
  --radius: 8px;
  --background-hover: rgb(59 89 152 / 80%);
  background-color: var(--background);
  transition: background-color 0.3s ease;
  transition-property: background-color;
}
.btn.--facebook.--icon-only {
  width: 100%;
  flex-shrink: 1;
}
@media (hover: hover) {
  .btn.--facebook:hover {
    background-color: var(--background-hover);
  }
}
.btn.--google {
  --background: #fff;
  --radius: 8px;
  --background-hover: rgb(0 0 0 / 5%);
  border: 1px solid #b1afbd;
  background-color: var(--background);
  transition: background-color 0.3s ease;
  transition-property: background-color;
}
.btn.--google path {
  fill: revert-layer;
}
.btn.--google.--icon-only {
  width: 100%;
  flex-shrink: 1;
}
@media (hover: hover) {
  .btn.--google:hover {
    background-color: var(--background-hover);
  }
}
.btn.--apple {
  --background: #111;
  --radius: 8px;
  --background-hover: rgb(17 17 17 / 80%);
  background-color: var(--background);
  transition: background-color 0.3s ease;
  transition-property: background-color;
}
.btn.--apple.--icon-only {
  width: 100%;
  flex-shrink: 1;
}
@media (hover: hover) {
  .btn.--apple:hover {
    background-color: var(--background-hover);
  }
}
.btn.--link {
  --border-radius: 0;
  --font-size: 14px;
  --font-weight: 400;
  --font-color: #6248aa;
  --background: transparent;
  --underline-color: #6248aa;
  --min-height: 24px;
  background-color: var(--background);
  color: var(--font-color);
  text-transform: none;
  padding: 0;
  transition: color 0.3s ease;
}
.btn.--link .btn__title {
  text-decoration: underline;
  text-decoration-color: var(--underline-color);
  transition: text-decoration-color 0.3s ease;
}
.btn.--link path {
  transition: fill 0.3s ease;
  will-change: fill;
}
@media (hover: hover) {
  .btn.--link:hover {
    --font-color: #312960;
    --underline-color: transparent;
  }
}
.btn.--disabled {
  opacity: 0.4;
  pointer-events: none;
}
.btn.--disabled.--outline, .btn.--disabled.--outline-transparent {
  --font-color: #b1afbd;
  --inner-shadow: inset 0 0 0 1px #b1afbd;
}
.btn.--swiper {
  --font-color: #6248aa;
  --inner-shadow: inset 0 0 0 1px #d7cef0;
  --background: rgba(255, 255, 255, 0.5);
  background-color: var(--background);
  box-shadow: 0 4px 16px 1px transparent, var(--inner-shadow);
  transition: background-color 0.3s ease;
  transition-property: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--swiper:hover {
    --background: #fff;
    box-shadow: 0 4px 16px 1px rgba(98, 72, 170, 0.2), var(--inner-shadow);
  }
}
.btn.--no-style {
  --font-color: #081828;
  --background: transparent;
  padding: 0;
  min-height: 0;
  background-color: var(--background);
  color: var(--font-color);
  font-size: 14px;
}
.btn.--no-style .btn__body {
  padding: 0;
  min-height: auto;
}
.btn.--no-style path {
  fill: var(--font-color);
}
@media (hover: hover) {
  .btn.--no-style:hover {
    color: var(--font-color);
  }
}

.btn-copy-to-clipboard__tooltip {
  top: 100%;
  right: 0;
  width: 100%;
  width: max-content;
  opacity: var(--tootlip-opacity);
  transition: opacity 0.3s ease;
}

.btn-copy-to-clipboard {
  --tootlip-opacity: 0;
}
.btn-copy-to-clipboard.--copied {
  --tootlip-opacity: 1;
}

.btn__icon .icon {
  --size: var(--svg-size);
}
.btn__icon svg {
  transition: transform 0.3s ease;
  transition-property: transform, opacity;
}

.btn {
  --border-radius: 8px;
  --font-size: 16px;
  --font-weight: 700;
  --font-color: #fff;
  --font-family: Blinker, Adjusted Verdana, sans-serif;
  --padding-y: 16px;
  --padding-x: 24px;
  --min-height: 56px;
  --svg-size: 20px;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  user-select: none;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;
  text-transform: uppercase;
}
.btn:hover {
  text-decoration: none;
  color: var(--font-color);
}
.btn:focus, .btn:active {
  outline: 0;
  color: var(--font-color);
  text-decoration: none;
}
.btn path {
  fill: var(--font-color);
}
.btn.--icon-before .btn__icon {
  order: -1;
}
.btn.--icon-only {
  height: var(--min-height);
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn.--icon-only:not(.--full-width) {
  width: var(--min-height);
}
.btn.--icon-only .btn__title {
  display: none;
}
@media (max-width: 575.98px) {
  .btn.--full-width-mobile {
    width: 100%;
  }
}
.btn.--rounded {
  --border-radius: calc(var(--min-height) / 2);
}
.btn.--indicator::after {
  content: attr(data-indicator);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #ff5c50;
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  right: -3px;
  border-radius: 50%;
}

.c-compositions-box {
  border: 1px solid #edecf4;
}
.c-compositions-box.entry-content ol {
  column-count: 2;
  column-gap: 32px;
  margin-top: 0;
}
.c-compositions-box.entry-content ol > li {
  padding-left: 24px;
}
.c-compositions-box.entry-content ol > li::before {
  font-size: 16px;
  width: 24px;
  height: 24px;
  color: #6248aa;
  margin-left: -30px;
  justify-content: center;
}
.c-compositions-box.entry-content h6,
.c-compositions-box.entry-content li:last-child {
  margin-bottom: 0;
}

.content-w-aside__content .base-section .container {
  padding-inline: 0;
}
.content-w-aside__content .base-section:first-child {
  padding-top: 0;
}
.content-w-aside__content .base-section:last-child {
  padding-bottom: 0;
}
@media (min-width: 1200px) {
  .content-w-aside__content {
    grid-column: 4/span 8;
  }
}

@media (min-width: 1200px) {
  .content-w-aside__aside {
    grid-column: span 4;
    will-change: min-height;
  }
}
@media (min-width: 1200px) {
  .content-w-aside__aside.--sticky {
    height: max-content;
  }
}
@media (min-width: 1200px) {
  .content-w-aside__aside.--sticky .content-w-aside__aside-inner {
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
  }
}

.content-w-aside {
  --spacing: var(--section-spacing);
  padding-block: var(--spacing);
}
.content-w-aside.--top-0 {
  padding-top: 0;
}
.content-w-aside.--top-3 {
  padding-top: 24px;
}
.content-w-aside.--top-6 {
  padding-top: 48px;
}
.content-w-aside.--bottom-0 {
  padding-bottom: 0;
}
.content-w-aside.--none {
  --padding-y: 0;
}
.content-w-aside.--reverse .content-w-aside__aside, .content-w-aside.--reverse-lg .content-w-aside__aside {
  order: -1;
  grid-column: span 3;
}
.content-w-aside.--reverse .content-w-aside__content, .content-w-aside.--reverse-lg .content-w-aside__content {
  grid-column: 4/span 9;
}
@media (max-width: 991.98px) {
  .content-w-aside.--reverse-lg .content-w-aside__aside {
    order: 0;
  }
}
@media (max-width: 991.98px) {
  .content-w-aside.--default-lg .content-w-aside__content {
    order: -1;
  }
}
@media (min-width: 1200px) {
  .content-w-aside.--columns-4 .content-w-aside__aside {
    grid-column: span 4;
  }
  .content-w-aside.--columns-4 .content-w-aside__content {
    grid-column: 1/span 8;
  }
}
.content-w-aside:not(:has(.content-w-aside__aside)) .content-w-aside__content {
  grid-column: 2/span 10;
}

.content-w-aside__row {
  --gap: 32px;
  gap: var(--gap);
}
@media (max-width: 1199.98px) {
  .content-w-aside__row {
    --gap: 80px;
  }
}
@media (max-width: 575.98px) {
  .content-w-aside__row {
    --gap: 40px;
  }
}
@media (min-width: 1200px) {
  .content-w-aside__row {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 1199.98px) {
  .content-w-aside__row {
    display: flex;
    flex-direction: column;
  }
}

.c-counter {
  --width: 152px;
  --color: #6248aa;
  --border-color: #d7cef0;
  border: 1px solid var(--border-color);
  min-height: 56px;
  min-width: var(--width);
  color: var(--color);
  user-select: none;
  transition: background-color 0.3s ease;
}
.c-counter.--disabled {
  --color: #b1afbd;
  --border-color: #edecf4;
  pointer-events: none;
}

.c-counter__value-visual {
  gap: 4px;
}

.c-counter__button {
  width: 24px;
  height: 24px;
  z-index: 2;
  cursor: pointer;
}
.c-counter__button path {
  fill: var(--color);
}
.c-counter__button > svg {
  z-index: 2;
}
.c-counter__button::before {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #d7cef0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.c-counter__button:hover::before {
  opacity: 1;
}

.c-counter__notice {
  pointer-events: none;
  border-radius: 4px;
  top: calc(100% + 4px);
  padding: 4px 8px;
  box-shadow: 0 4px 30px 2px rgba(49, 50, 96, 0.3);
  z-index: 10;
}
.c-counter__notice::before {
  content: "";
  display: block;
  width: 16px;
  height: 8px;
  background-color: inherit;
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.d-small-circles {
  left: 0;
  column-gap: 3px;
}
.d-small-circles::before, .d-small-circles::after {
  content: "";
  display: block;
}
.d-small-circles::after {
  width: 16px;
  height: 16px;
  background-color: #6248aa;
  border-radius: 16px;
}
.d-small-circles::before {
  width: 8px;
  height: 16px;
  background-color: #ff5c50;
  border-radius: 0 16px 16px 0;
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  margin-right: var(--margin-right);
  box-shadow: inset 0 0 0 var(--border-width) var(--color);
  background-color: #fff;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  border-radius: var(--radius);
}
.f-checkbox__visual svg {
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}
.f-checkbox__visual::after {
  content: "";
  position: absolute;
  display: none;
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  background-color: #fff;
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}

.f-checkbox__body {
  flex-basis: calc(100% - (var(--size) + var(--margin-right)));
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0;
}
.f-checkbox__input:checked ~ .f-checkbox__visual {
  --border-width: 1px;
  background-color: var(--color);
}
.f-checkbox__input:checked ~ .f-checkbox__visual::after,
.f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title a {
  color: var(--color);
  font-weight: 700;
}

.f-checkbox {
  --color: #6248aa;
  --radius: 6px;
  --size: 24px;
  --margin-right: 16px;
  --border-width: 1px;
  --min-height: 48px;
  min-height: var(--min-height);
  cursor: pointer;
}
.f-checkbox:hover {
  --color: #6248aa;
  --border-width: 2px;
}
.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}
.f-checkbox.--disabled {
  --color: #d7cef0;
  pointer-events: none;
}
.f-checkbox.--disabled .f-checkbox__input:not(:checked) ~ .f-checkbox__visual {
  background-color: #f9f9f9;
}
.f-checkbox.--disabled .f-checkbox__title {
  color: var(--color);
}
.f-checkbox .f-error__title {
  font-size: 16px;
}

.f-checkbox-select__header {
  padding: 6px 16px;
  padding-left: 24px;
  min-height: var(--height);
  border: 1px solid var(--border-color);
  border-radius: 24px;
  user-select: none;
  background-color: var(--header-background);
  box-shadow: var(--shadow);
  transition: box-shadow 0.3s ease, border 0.3s ease, border-radius 0.3s ease;
}
.f-checkbox-select__header:hover {
  --border-color: #6248aa;
  --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
}

.f-checkbox-select__clear {
  display: none;
}
.f-checkbox-select__clear.--active {
  display: inline-flex;
}

.f-checkbox-select__toggler {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.f-checkbox-select__toggler svg {
  width: 24px;
  height: 24px;
  transition: scale 0.3s ease;
}

.f-checkbox-select__title {
  --end: 0;
  overflow: hidden;
  flex: 1;
  top: 0;
  left: 0;
  height: 16px;
}
.f-checkbox-select__title > span {
  color: #312960;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: left 2s linear;
}
.f-checkbox-select__title::after {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 16px;
  height: 100%;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box; /* stylelint-disable-line max-line-length */
  z-index: 1;
}
.f-checkbox-select__title:hover > span {
  left: var(--end);
}

.f-checkbox-select__body {
  box-shadow: 0 0 0 1px var(--border-color);
  left: 1px;
  transform: scaleY(0.25);
  transform-origin: top;
  width: calc(100% - 2px);
  background-color: #fff;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  margin-top: -1px;
}
.f-checkbox-select__body .os-scrollbar-track {
  height: calc(100% - 8px);
}

.f-checkbox-select {
  --header-background: #fff;
  --border-color: #b1afbd;
  --shadow: 0px 0px 0px 0px transparent;
  --height: 48px;
  min-width: 0;
}
.f-checkbox-select .f-checkbox {
  --min-height: 40px;
}
.f-checkbox-select.--active {
  --border-color: #6248aa;
}
.f-checkbox-select.--active .f-checkbox-select__header {
  border-radius: 24px 24px 0 0;
}
.f-checkbox-select.--active .f-checkbox-select__body {
  opacity: 1;
  pointer-events: auto;
  transform: scaleY(1);
  border-radius: 0 0 24px 24px;
}
.f-checkbox-select.--active .f-checkbox-select__toggler svg {
  scale: -1;
}
@media (max-width: 575.98px) {
  .f-checkbox-select {
    --height: 40px;
  }
}

.f-checkbox-select__body-inner {
  max-height: 183px;
}
.f-checkbox-select__body-inner .f-checkbox:last-child {
  margin-bottom: 16px;
}
.f-checkbox-select__body-inner .f-checkbox__title {
  font-weight: 500;
  color: #312960;
}

.f-color__input {
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}
.f-color__input::after {
  content: "";
  position: absolute;
  inset: 0;
}

.f-color {
  --size: 32px;
  --radius: 50%;
  width: var(--size);
  height: var(--size);
  border-radius: var(--radius);
  background: transparent;
  padding: 4px;
  transition: 0.2s ease;
  transition-property: box-shadow, background-color;
}
.f-color::after {
  content: "";
  display: block;
  border-radius: var(--radius);
  background: var(--background-color);
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1px #fff;
}
.f-color:hover {
  box-shadow: 0 4px 10px 2px rgba(120, 104, 163, 0.1);
  background: #fff;
}
.f-color:hover::after {
  box-shadow: inset 0 0 0 0 #fff;
}
.f-color:has(input:checked), .f-color.--active {
  background: #6248aa;
}
.f-color:has(input:checked)::after, .f-color.--active::after {
  box-shadow: inset 0 0 0 2px #fff;
}
.f-color.--disabled {
  pointer-events: none;
  opacity: 0.8;
}
.f-color.--link.--checked {
  background: #6248aa;
}
.f-color.--link.--checked::after {
  box-shadow: inset 0 0 0 2px #fff;
}

.f-date__icon {
  display: var(--icon-display);
  padding: 8px;
  position: absolute;
  pointer-events: none;
  padding-right: 16px;
  right: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.f-date__input {
  color: var(--font-color);
  border-radius: var(--radius);
  cursor: var(--cursor);
}
.f-date__input::-webkit-inner-spin-button, .f-date__input::-webkit-clear-button, .f-date__input::-webkit-calendar-picker-indicator {
  display: none;
  appearance: none;
}

.f-date {
  --inset-shadow-color: #b1afbd;
  --radius: 24px;
  --icon-display: block;
  --cursor: pointer;
  --font-color: #312960;
  --shadow: 0px 0px 0px 0px transparent;
}
.f-date:hover {
  --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
}
.f-date:hover .f-base {
  --inset-shadow-color: #6248aa;
}
.f-date:not(.--native) .f-date__input {
  padding-right: 48px;
}
.f-date.--active .f-base {
  --inset-shadow-color: #6248aa;
}
.f-date.--native {
  --icon-display: none;
  --cursor: auto;
}
.f-date.--invalid .f-base {
  --inset-shadow-color: #e21c3d;
}

.f-date__wrap {
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  transition: box-shadow 0.3s ease;
}

.f-error {
  --display: none;
  width: auto;
  top: calc(100% - 8px);
  left: 16px;
  color: #e21c3d;
  padding: 4px;
  border-radius: 8px;
  box-shadow: 0 4px 30px 2px rgba(49, 41, 96, 0.3);
  display: var(--display);
  z-index: 3;
  gap: 4px;
}
.f-error::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 10px;
  transform: translateY(-100%);
  width: 8px;
  height: 7px;
  background-color: #fff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  pointer-events: none;
  z-index: 3;
}
.f-error.--show {
  --display: flex;
}

.f-error__title {
  line-height: 16px;
}

.f-base {
  --inset-shadow-color: #b1afbd;
  display: block;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  padding: 12px 16px;
  padding-left: 24px;
  appearance: none;
  border: none;
  min-height: 48px;
  font-weight: 500;
  box-shadow: inset 0 0 0 1px var(--inset-shadow-color);
  transition: box-shadow 0.3s ease;
}
.f-base:focus {
  border-color: none;
  outline: none;
}
.f-base::placeholder {
  opacity: 1;
  color: #7868a3;
}
@media (max-width: 575.98px) {
  .f-base {
    min-height: 40px;
    padding-block: 10px;
  }
}

textarea.f-base {
  min-height: 176px;
  padding-top: 16px;
  padding-bottom: 12px;
  resize: none;
}

.i-selected-file {
  min-height: 24px;
  cursor: pointer;
  gap: 4px;
}

.i-selected-file__title {
  color: #6248aa;
  text-decoration: underline;
}

.i-selected-file__remove {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file.--empty .f-file__files {
  display: none;
}

.f-file__input {
  display: none;
}

.f-ico .f-base {
  appearance: textfield;
}
.f-ico .f-base::-webkit-outer-spin-button, .f-ico .f-base::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.f-label {
  --top: 50%;
  --translate-y: -50%;
  --color: #7868a3;
  position: absolute;
  top: var(--top);
  left: 20px;
  margin-right: 1px;
  transform: translateY(var(--translate-y));
  transition: transform 0.3s ease, font-size 0.3s ease, top 0.3s ease, left 0.3s ease, color 0.3s ease;
  color: var(--color);
  background-color: #fff;
  font-size: 14px;
  line-height: 16px;
  pointer-events: none;
  font-weight: 400;
  padding-inline: 4px;
  z-index: 2;
}
.f-label.--required::after {
  content: "*";
  color: #7868a3;
  padding-left: 4px;
}
.f-label.--active {
  --color: #6248aa;
  --top: 0;
  font-size: 12px;
}

.f-multicheckbox-search__search .f-base {
  min-height: 40px;
}

.f-multicheckbox-search__body {
  padding-left: 2px;
}
.f-multicheckbox-search__body .os-scrollbar-track {
  height: 100%;
}

.f-multicheckbox-search__body-inner {
  max-height: 192px;
}
.f-multicheckbox-search__body-inner > div {
  padding-block: 4px;
}
.f-multicheckbox-search__body-inner .f-checkbox__title {
  font-weight: 500;
  color: #312960;
}

.f-multicheckbox-search .f-checkbox {
  --min-height: 40px;
  margin-right: 16px;
}

.f-multicheckbox-search__load-more.--hidden {
  display: none;
}

.f-radio-box__visual {
  --size: 24px;
  --border-width: 1px;
  --color: #6248aa;
  --radius: calc(var(--size) / 2);
  --options-col-display: block;
  width: var(--size);
  height: var(--size);
  box-shadow: 0 0 0 var(--border-width) var(--color);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  border-radius: var(--radius);
}
.f-radio-box__visual svg {
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}
.f-radio-box__visual::after {
  content: "";
  position: absolute;
  display: none;
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  background-color: #fff;
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}

.f-radio-box__image-col {
  flex: 0 0 80px;
}

.f-radio-box__options-col {
  display: var(--options-col-display);
}
@media (min-width: 768px) {
  .f-radio-box__options-col > .link {
    margin-left: 24px;
  }
}

.f-radio-box__reload-link.link {
  display: none;
}

.f-radio-box.--disabled .f-radio-box__visual {
  --color: #d7cef0;
}
.f-radio-box.--disabled .f-radio-box__image-col {
  opacity: 0.6;
}
.f-radio-box.--disabled,
.f-radio-box.--disabled .i-price__main,
.f-radio-box.--disabled .i-price__before,
.f-radio-box.--disabled .i-price__base {
  color: #d7cef0;
}
.f-radio-box:has(.f-radio-box__reload-link) {
  --options-col-display: none;
}
.f-radio-box:has(.f-radio-box__input:checked) {
  --options-col-display: flex;
}

.f-radio-box__body {
  --inset-shadow-color: transparent;
  box-shadow: inset 0 0 0 1px var(--inset-shadow-color);
  transition: background-color 0.3s ease;
}
.f-radio-box__body:hover {
  background-color: #ebebeb;
}
.f-radio-box__body:hover .f-radio-box__visual {
  --border-width: 2px;
}
@media (max-width: 991.98px) {
  .f-radio-box__body {
    flex-wrap: wrap;
  }
}

.f-radio-box__content > * {
  flex: 1 1 0;
}
@media (max-width: 991.98px) {
  .f-radio-box__content {
    padding-left: 40px;
  }
}

@media (max-width: 991.98px) {
  .f-radio-box__title {
    width: 100%;
  }
}

.f-radio-box__input {
  display: none;
}
.f-radio-box__input:checked ~ .f-radio-box__body {
  --inset-shadow-color: #d7cef0;
  background-color: #fff;
}
.f-radio-box__input:checked ~ .f-radio-box__body .f-radio-box__visual {
  background-color: #312960;
}
.f-radio-box__input:checked ~ .f-radio-box__body .f-radio-box__visual svg {
  opacity: 1;
  transform: scale(1);
}
.f-radio-box__input:checked ~ .f-radio-box__body .f-radio-box__reload-link.link {
  display: inline-flex;
}
.f-radio-box__input:disabled:checked ~ .f-radio-box__body .f-radio-box__visual {
  background-color: #d7cef0;
}

.f-radio-box__price-col {
  flex: 1 0 auto;
}
@media (min-width: 992px) {
  .f-radio-box__price-col {
    flex-basis: 100px;
    max-width: 100px;
  }
}

.f-range-slider__label.--from,
.f-range-slider__label.--to,
.f-range-slider__label.--from-to {
  bottom: 14px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  white-space: nowrap;
}

.f-range-slider {
  --height: 4px;
  --thumb-size: 16px;
  --thumb-offset: 8px;
  --color: #6248aa;
  --background: #d7cef0;
  --gradient-from: calc(var(--background-from) - var(--thumb-offset));
  --gradient-to: calc(var(--background-to) + var(--thumb-offset));
}
.f-range-slider.--from-to .f-range-slider__label.--from,
.f-range-slider.--from-to .f-range-slider__label.--to {
  display: none;
}
.f-range-slider.--from-to .f-range-slider__label.--from-to {
  display: block;
}

.f-range-slider__control .f-base {
  min-height: 40px;
  padding-block: 10px;
  appearance: textfield;
}
.f-range-slider__control .f-base::-webkit-outer-spin-button, .f-range-slider__control .f-base::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.f-range-slider__ranges {
  height: var(--height);
}

@media (max-width: 575.98px) {
  .f-range-slider__inputs {
    flex-direction: column;
  }
}

.f-range-slider__range {
  appearance: none;
  inset: 0 var(--thumb-offset);
  position: absolute;
  background: linear-gradient(90deg, transparent 0%, transparent var(--gradient-from), var(--color) var(--gradient-from), var(--color) var(--gradient-to), transparent var(--gradient-to), transparent 100%);
  pointer-events: none;
}
.f-range-slider__range::before {
  content: "";
  position: absolute;
  inset: 0 calc(var(--thumb-offset) * -1);
  background-color: var(--background);
  z-index: -1;
  border-radius: var(--height);
}
.f-range-slider__range::-webkit-slider-thumb, .f-range-slider__range::-moz-range-thumb {
  appearance: none;
  pointer-events: all;
  width: var(--thumb-size);
  height: var(--thumb-size);
  background-color: #6248aa;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  transform: translateX(calc(var(--thumb-offset) * var(--side)));
  transition: 0.2s ease background-color;
  z-index: 2;
}
.f-range-slider__range.--from {
  --side: -1;
}
.f-range-slider__range.--to {
  --side: 1;
}

.f-single-select__select.slim-select.ss-main {
  padding-inline: var(--padding-inline);
  padding-left: 24px;
  box-shadow: var(--shadow);
  transition: box-shadow 0.3s ease, border 0.3s ease, border-radius 0.3s ease;
}
.f-single-select__select.slim-select.ss-main::after {
  display: block;
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/svg/arrow-down.svg");
  background-repeat: no-repeat;
  justify-self: end;
  align-self: center;
  grid-area: select;
  pointer-events: none;
  scale: 1;
  transition: scale 0.3s ease;
}
.f-single-select__select.slim-select.ss-main.ss-disabled {
  --ss-font-color: #d7cef0;
}
.f-single-select__select.slim-select.ss-main .ss-values {
  gap: 4px;
}
.f-single-select__select.slim-select.ss-main .ss-values .ss-value {
  min-height: 24px;
}
.f-single-select__select.slim-select.ss-main .ss-values .ss-value .ss-value-text {
  padding-right: 4px;
}
.f-single-select__select.slim-select.ss-main .ss-values .ss-value .ss-value-delete {
  border-left: none;
  padding-left: 4px;
}
.f-single-select__select.slim-select .ss-list .ss-option {
  padding-inline: 16px;
  padding-left: 24px;
}
.f-single-select__select.slim-select .ss-arrow {
  display: none;
}

.f-single-select.--multiple .ss-main .ss-values {
  margin-block: 12px;
}

.f-single-select,
.f-single-select__select.slim-select {
  --radius: 24px;
  --height: 48px;
  --padding-inline: 16px;
  --padding-block: 10px;
  --border-color: #b1afbd;
  --shadow: 0px 0px 0px 0px transparent;
  --ss-primary-color: #312960;
  --ss-bg-color: #fff;
  --ss-font-color: #312960;
  --ss-font-placeholder-color: #7868a3;
  --ss-disabled-color: #f9f9f9;
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 300px;
  --ss-spacing-l: 7px;
  --ss-spacing-m: 12px;
  --ss-spacing-s: 0;
  --ss-animation-timing: 0.2s;
  --ss-border-radius: var(--radius);
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
.f-single-select:hover,
.f-single-select__select.slim-select:hover {
  --border-color: #6248aa;
  --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
}
.f-single-select.--native .f-single-select__wrap,
.f-single-select__select.slim-select.--native .f-single-select__wrap {
  display: grid;
  align-items: center;
  grid-template-areas: "select";
}
.f-single-select.--native .f-single-select__wrap::after,
.f-single-select__select.slim-select.--native .f-single-select__wrap::after {
  display: block;
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/svg/arrow-down.svg");
  background-repeat: no-repeat;
  justify-self: end;
  grid-area: select;
  margin-right: 16px;
  pointer-events: none;
}
.f-single-select.--native .f-single-select__select,
.f-single-select__select.slim-select.--native .f-single-select__select {
  color: #312960;
  font-weight: 500;
  border-radius: var(--radius);
  width: 100%;
  min-height: var(--height);
  border: 1px solid var(--border-color);
  background-color: #fff;
  padding-block: var(--padding-block);
  padding-inline: var(--padding-inline);
  padding-left: 24px;
  padding-right: 40px;
  cursor: pointer;
  outline: none;
  margin: 0;
  appearance: none;
  grid-area: select;
  box-shadow: var(--shadow);
  transition: box-shadow 0.3s ease, border 0.3s ease;
}
.f-single-select .ss-search input,
.f-single-select__select.slim-select .ss-search input {
  padding-inline: 24px;
}
.f-single-select .ss-search input:focus,
.f-single-select__select.slim-select .ss-search input:focus {
  box-shadow: none;
}
.f-single-select.ss-open-below,
.f-single-select__select.slim-select.ss-open-below {
  --border-color: #6248aa;
}
.f-single-select.ss-open-below::after,
.f-single-select__select.slim-select.ss-open-below::after {
  scale: -1;
}
.f-single-select.--invalid,
.f-single-select__select.slim-select.--invalid {
  --border-color: #e21c3d;
}
.f-single-select.--invalid .ss-main,
.f-single-select__select.slim-select.--invalid .ss-main {
  --border-color: #e21c3d;
}
@media (max-width: 575.98px) {
  .f-single-select,
  .f-single-select__select.slim-select {
    --height: 40px;
  }
}

.f-single-select-inline {
  gap: 1px;
}

.f-single-select-inline__select {
  --ss-primary-color: #6248aa;
  --ss-bg-color: #fff;
  --ss-font-color: #6248aa;
  --ss-border-color: transparent;
  --ss-success-color: #48a463;
  --ss-error-color: #e21c3d;
  --ss-main-height: 24px;
  --ss-content-height: auto;
  --ss-spacing-l: 0;
  --ss-spacing-m: 4px;
  --ss-spacing-s: 2px;
  --ss-animation-timing: 0.3s;
  --ss-border-radius: 8px;
  --text-decoration-color: #6248aa;
  --selected-text-color: #6248aa;
  --option-text-color: #6248aa;
  --option-text-decoration-color: #6248aa;
  font-size: 16px;
  border-radius: 8px;
}
.f-single-select-inline__select:hover {
  --selected-text-color: #312960;
  --text-decoration-color: transparent;
}
.f-single-select-inline__select .ss-single,
.f-single-select-inline__select .ss-option {
  color: var(--selected-text-color);
  text-decoration: underline;
  text-decoration-color: var(--text-decoration-color);
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  will-change: text-decoration-color, color;
}
.f-single-select-inline__select.ss-content {
  min-width: 142px;
  border-top: 0;
  font-size: 14px;
  line-height: 16px;
  box-shadow: 0 4px 30px 2px rgba(49, 41, 96, 0.3);
  transform: translateY(24px) !important;
}
.f-single-select-inline__select.ss-content .ss-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  pointer-events: none;
}
.f-single-select-inline__select.ss-content .ss-list .ss-option {
  text-decoration-color: var(--option-text-decoration-color);
}
.f-single-select-inline__select.ss-content .ss-list .ss-option:hover {
  --option-text-decoration-color: transparent;
  color: #312960;
  background-color: transparent;
}
.f-single-select-inline__select.ss-open-above, .f-single-select-inline__select.ss-open-below {
  --ss-bg-color: #fff;
}
.f-single-select-inline__select.ss-open-above.ss-content .ss-list, .f-single-select-inline__select.ss-open-below.ss-content .ss-list {
  pointer-events: auto;
}
.f-single-select-inline__select.ss-open-above.ss-content {
  transform: translateY(-22px) !important;
}
.f-single-select-inline__select.ss-main {
  width: auto;
  font-weight: 400;
  border-color: transparent;
  background-color: transparent;
}
.f-single-select-inline__select.ss-main::after {
  display: block;
  content: "";
  width: 24px;
  height: 24px;
  margin-left: 6px;
  background-image: url("../images/svg/chevron-down-ss-inline.svg");
  background-repeat: no-repeat;
  background-size: 12px 10px;
  background-position: center;
  justify-self: flex-end;
  align-self: center;
  grid-area: select;
  pointer-events: none;
  scale: 1;
  transition: scale 0.3s ease;
}
.f-single-select-inline__select.ss-main:focus {
  box-shadow: none;
}
.f-single-select-inline__select.ss-main.ss-open-above, .f-single-select-inline__select.ss-main.ss-open-below {
  border-color: var(--ss-border-color);
  border-bottom-color: transparent;
}
.f-single-select-inline__select.ss-main .ss-arrow {
  display: none;
}

.f-switch {
  --width: 48px;
  --height: 24px;
  --offset: 2px;
  --background: #fff;
  --background-checked: #fff;
  --transition-timing: 0.2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height) - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #6248aa;
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width) - var(--handle-size) - var(--offset));
}
.f-switch.--disabled {
  --background: #a9a9b2;
  --background-checked: #a9a9b2;
  --handle-background: #ebebeb;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;
  box-shadow: 0 0 0 1px #d7cef0;
}
.f-switch__visual::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  will-change: transform;
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;
}
.f-switch__input:checked ~ .f-switch__visual {
  --background: var(--background-checked);
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-tag-checkbox__input {
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}
.f-tag-checkbox__input::after {
  content: "";
  position: absolute;
  inset: 0;
}

.f-tag-checkbox {
  --border-color: #d7cef0;
  --color: #312960;
  --background: transparent;
  --height: 48px;
  --border-radius: 8px;
  --font-size: 16px;
  --font-weight: 400;
  --line-height: 24px;
  --padding-x: 8px;
  min-height: var(--height);
  min-width: var(--height);
  padding-inline: var(--padding-x);
  box-shadow: inset 0 0 0 1px var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  color: var(--color);
  transition: 0.2s ease;
  transition-property: box-shadow, background-color, color;
}
.f-tag-checkbox::after {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url("../images/svg/check.svg") center center no-repeat, #6248aa;
  background-size: 14px;
  top: 2px;
  left: 2px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.1s ease;
}
.f-tag-checkbox.--disabled {
  --border-color: #b1afbd;
  --color: #b1afbd;
  pointer-events: none;
}
.f-tag-checkbox:hover {
  --background-color: #fff;
  --font-weight: 700;
  box-shadow: 0 4px 10px 2px rgba(120, 104, 163, 0.1), inset 0 0 0 1px var(--border-color);
}
.f-tag-checkbox:has(.f-tag-checkbox__input:checked), .f-tag-checkbox.--active {
  --border-color: #6248aa;
  --font-weight: 700;
  box-shadow: inset 0 0 0 2px var(--border-color);
}
.f-tag-checkbox:has(.f-tag-checkbox__input:checked)::after, .f-tag-checkbox.--active::after {
  opacity: 1;
}
.f-tag-checkbox.--link.--checked {
  --border-color: #6248aa;
  --font-weight: 700;
  box-shadow: inset 0 0 0 2px var(--border-color);
}
.f-tag-checkbox.--link.--checked::after {
  opacity: 1;
}

.f-text__icon {
  position: absolute;
  pointer-events: none;
}
.f-text__icon.--before {
  left: 24px;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.f-text__icon.--after {
  right: 24px;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.f-text__input {
  border-radius: var(--radius);
  color: var(--font-color);
}

.f-text {
  --inset-shadow-color: #b1afbd;
  --font-color: #312960;
  --shadow: 0px 0px 0px 0px transparent;
  --radius: 24px;
}
.f-text:hover {
  --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
}
.f-text:hover .f-base {
  --inset-shadow-color: #6248aa;
}
.f-text.--active .f-base {
  --inset-shadow-color: #6248aa;
}
.f-text.--invalid .f-base {
  --inset-shadow-color: #e21c3d;
}
.f-text.--icon-before .f-text__input {
  padding-left: 56px;
}
.f-text.--icon-before .f-label:not(.--active) {
  left: 56px;
}
.f-text.--icon-after .f-text__input {
  padding-right: 56px;
}
.f-text.--disabled {
  opacity: 0.5;
}
@media (max-width: 575.98px) {
  .f-text .f-text__icon.--before {
    left: 16px;
  }
  .f-text .f-text__icon.--after {
    right: 16px;
  }
  .f-text.--icon-before .f-text__input {
    padding-left: 48px;
  }
  .f-text.--icon-before .f-label:not(.--active) {
    left: 48px;
  }
  .f-text.--icon-after .f-text__input {
    padding-right: 48px;
  }
}

.f-text__wrap {
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  transition: box-shadow 0.3s ease;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 24px;
  right: 16px;
  width: var(--size);
  height: var(--size);
}
.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  grid-column-start: 1;
  grid-row-start: 1;
  width: var(--size);
  height: var(--size);
}
.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}
.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea {
  --inset-shadow-color: #b1afbd;
  --font-color: #312960;
  --shadow: 0px 0px 0px 0px transparent;
  --radius: 24px;
}
.f-textarea:hover {
  --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
}
.f-textarea:hover .f-base {
  --inset-shadow-color: #6248aa;
}
.f-textarea .f-label {
  --top: 24px;
}
.f-textarea.--active .f-base {
  --inset-shadow-color: #6248aa;
}
.f-textarea.--active .f-label {
  --top: 0;
}
.f-textarea.--invalid .f-base {
  --inset-shadow-color: #e21c3d;
}
.f-textarea.--disabled {
  --font-color: #d7cef0;
  cursor: not-allowed;
}
.f-textarea.--disabled .f-base {
  background-color: #f9f9f9;
}
.f-textarea.--disabled .f-base, .f-textarea.--disabled.--active .f-base {
  --inset-shadow-color: #b1afbd;
}

.f-textarea__wrap {
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  transition: box-shadow 0.3s ease;
}

.f-textarea__input {
  border-radius: var(--radius);
  color: var(--font-color);
}

.f-variant__input {
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
}
.f-variant__input::after {
  content: "";
  position: absolute;
  inset: 0;
}

.f-variant__mediums {
  pointer-events: none;
}
.f-variant__mediums .img {
  margin: 0 -10px;
  transform: translate(0);
}

.f-variant__color {
  width: 6px;
  height: 6px;
  background-color: var(--color);
}

.f-variant {
  --border-color: #d7cef0;
  --color: #312960;
  --background: transparent;
  --height: 88px;
  --font-weight: 400;
  --line-height: 24px;
  min-height: var(--height);
  min-width: var(--height);
  box-shadow: inset 0 0 0 1px var(--border-color);
  background-color: var(--background);
  color: var(--color);
  transition: 0.2s ease;
  padding-inline: 12px;
  transition-property: box-shadow, background-color, color;
}
.f-variant::after {
  content: "";
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url("../images/svg/check.svg") center center no-repeat, #6248aa;
  background-size: 14px;
  top: 50%;
  left: 2px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.1s ease;
}
.f-variant:hover {
  --background-color: #fff;
  --font-weight: 700;
  box-shadow: 0 4px 10px 2px rgba(120, 104, 163, 0.1), inset 0 0 0 1px var(--border-color);
}
.f-variant:has(.f-variant__input:checked, .f-variant__input.--link.--checked) {
  --font-weight: 700;
  box-shadow: inset 0 0 0 2px var(--border-color);
}
.f-variant:has(.f-variant__input:checked, .f-variant__input.--link.--checked):not(.--disabled) {
  --border-color: #6248aa;
}
.f-variant:has(.f-variant__input:checked, .f-variant__input.--link.--checked)::after {
  opacity: 1;
}
.f-variant.--disabled {
  --border-color: #b1afbd;
  --color: #b1afbd;
  pointer-events: none;
}
.f-variant.--disabled .f-variant__mediums {
  filter: grayscale(1);
}

.f-contact {
  --padding: 24px;
  --max-width: 100%;
  max-width: var(--max-width);
  padding: var(--padding);
  box-shadow: 0 4px 30px 2px rgba(49, 50, 96, 0.3);
  border-radius: 24px;
  background: #fff;
}
.f-contact .g-terms__text-only {
  color: #312960;
}
.f-contact .g-terms__text-only > a {
  color: #ff5c50;
}
@media (min-width: 768px) {
  .f-contact {
    --padding: 40px;
  }
}
@media (max-width: 575.98px) {
  .f-contact {
    --max-width: 488px;
    margin-inline: auto;
  }
}

@media (min-width: 768px) {
  .f-contact__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.f-contact__submit .btn {
  min-width: 160px;
  gap: 4px;
}
@media (min-width: 576px) {
  .f-contact__submit {
    margin-left: auto;
  }
}
@media (max-width: 575.98px) {
  .f-contact__submit {
    width: 100%;
  }
}

.f-coupon-add {
  background: #fff;
  border-radius: 24px;
  border: 1px solid #7868a3;
}

.f-coupon-add__inputs {
  max-width: 500px;
}

@media (min-width: 576px) {
  .f-coupon-add__input .f-text__input {
    padding-right: 48px;
  }
}

@media (min-width: 576px) {
  .f-coupon-add__apply {
    margin-left: -24px;
  }
}

@media (min-width: 768px) and (max-width: 1199.98px) {
  .f-editorial-calendar-filter__inputs-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 1499.98px) {
  .f-editorial-calendar-filter__inputs-wrapper {
    gap: 24px;
  }
}
@media (max-width: 767.98px) {
  .f-editorial-calendar-filter__inputs-wrapper {
    flex-direction: column;
    gap: 16px;
  }
}

.f-editorial-calendar-filter {
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  column-gap: 80px;
}
@media (max-width: 1199.98px) {
  .f-editorial-calendar-filter {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767.98px) {
  .f-editorial-calendar-filter {
    gap: 16px;
    padding: 16px;
  }
}

.f-editorial-calendar-filter__filter-visual {
  border: 1px solid #edecf4;
  grid-column: 1/-1;
}
@media (max-width: 991.98px) {
  .f-editorial-calendar-filter__filter-visual {
    padding: 16px;
  }
}

.f-filtration__wrapper {
  position: sticky;
  top: 24px;
  transition: top 0.3s ease;
}
@media (max-width: 991.98px) {
  .f-filtration__wrapper {
    position: relative;
  }
}

.f-filtration__header {
  padding: 8px 12px;
}
.f-filtration__header::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #d7cef0;
  left: 0;
  bottom: -16px;
  transform: translateY(-100%);
}

.f-filtration__header-heading {
  margin: 0;
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .f-filtration__header-heading .base-heading {
    --size: 20px;
  }
}

@media (min-width: 992px) {
  .f-filtration__close-filter.btn {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .f-filtration__content {
    height: 100%;
    overflow: auto;
  }
}

.f-filtration__reset-btn {
  display: none;
}

.f-filtration {
  padding: 16px;
  max-height: 100%;
  height: auto;
  display: block;
  border: 1px solid #d7cef0;
  border-radius: 24px;
}
@media (max-width: 991.98px) {
  .f-filtration {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    background: #fff;
    width: min(320px, 100%);
    height: 100%;
    border-radius: 24px 0 0 24px;
    padding-right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease;
  }
}
.f-filtration.--open {
  transform: translateX(0);
  box-shadow: 0 4px 30px 2px rgba(49, 50, 96, 0.3);
}
.f-filtration:has(.i-selected-tag) .f-filtration__reset-btn {
  display: inline-flex;
}

.f-forgotten-password {
  border-radius: 16px;
  box-shadow: 0 4px 30px 2px rgba(49, 41, 96, 0.3);
  border: 1px solid #d5d4da;
}
@media (max-width: 991.98px) {
  .f-forgotten-password {
    justify-self: center;
  }
}

.f-interprets-filter {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}
.f-interprets-filter .f-text {
  --p-offset: 176px;
  width: calc(50% + 16px);
}
.f-interprets-filter .f-text .f-text__input {
  padding-right: var(--p-offset);
}
@media (max-width: 575.98px) {
  .f-interprets-filter .f-text .f-text__input {
    min-height: 48px;
    padding-block: 12px;
  }
}
@media (max-width: 1199.98px) {
  .f-interprets-filter .f-text {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .f-interprets-filter .f-text {
    --p-offset: 16px;
  }
}

.f-interprets-filter__submit {
  --m-offset: calc(-1 * 160px);
  margin-left: var(--m-offset);
}
.f-interprets-filter__submit .btn {
  --font-size: 16px;
  border: 1px solid #6248aa;
  min-width: 160px;
  line-height: 24px;
}
@media (max-width: 767.98px) {
  .f-interprets-filter__submit {
    --m-offset: 0;
    position: static;
  }
}
@media (max-width: 575.98px) {
  .f-interprets-filter__submit {
    width: 100%;
  }
}

.f-interprets-filter__keypad-btn.btn {
  --font-size: 18px;
  --min-height: 48px;
  width: var(--min-height);
  padding: 0;
  line-height: 24px;
}
.f-interprets-filter__keypad-btn.btn:nth-child(1) {
  width: 64px;
}

.f-newsletter {
  background: linear-gradient(to right, #312960, #6248aa);
  border-radius: 24px;
  border: 1px solid #7868a3;
  padding-left: 240px;
}
.f-newsletter .f-text {
  --border-color: #d5d4da;
}
.f-newsletter .f-text:hover {
  --shadow: none;
}
.f-newsletter .f-text .f-text__input {
  --p-offset: 176px;
  padding-right: var(--p-offset);
}
@media (max-width: 1499.98px) {
  .f-newsletter .f-text .f-text__input {
    --p-offset: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .f-newsletter .f-text .f-text__input {
    --p-offset: 176px;
  }
}
@media (max-width: 575.98px) {
  .f-newsletter .f-text .f-text__input {
    min-height: 48px;
    padding-block: 12px;
  }
}
@media (max-width: 991.98px) {
  .f-newsletter {
    padding: 24px;
  }
}

@media (min-width: 992px) {
  .f-newsletter__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}
@media (max-width: 991.98px) {
  .f-newsletter__grid {
    gap: 24px;
  }
}

.f-newsletter__header {
  gap: 16px;
}
@media (max-width: 575.98px) {
  .f-newsletter__header.--left {
    text-align: center;
  }
}

@media (max-width: 1499.98px) {
  .f-newsletter__inputs-col {
    gap: 16px;
  }
}
@media (max-width: 575.98px) {
  .f-newsletter__inputs-col {
    flex-direction: column;
  }
}

@media (max-width: 1499.98px) {
  .f-newsletter__inputs {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .f-newsletter__inputs {
    flex-direction: row;
  }
}

.f-newsletter__submit {
  --m-offset: calc(-1 * 176px);
  margin-left: var(--m-offset);
}
.f-newsletter__submit .btn {
  --font-size: 16px;
  gap: 4px;
  min-width: 160px;
  line-height: 24px;
}
@media (max-width: 1499.98px) {
  .f-newsletter__submit {
    --m-offset: 0;
    position: static;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .f-newsletter__submit {
    --m-offset: calc(-1 * 176px);
    position: relative;
  }
}

.f-newsletter__terms .f-checkbox__title {
  color: #d7cef0;
  font-weight: 400;
}
.f-newsletter__terms .f-checkbox__title a {
  color: #d7cef0;
}

.f-newsletter__img {
  max-width: 269px;
  height: auto;
  left: 0;
  top: 50%;
  transform: translate(-25%, -50%);
}
@media (max-width: 991.98px) {
  .f-newsletter__img {
    display: none;
  }
}

.f-product-review {
  border: 1px solid #b1afbd;
}

.f-product-review__image {
  width: 112px;
}

.f-refund-method {
  border: 1px solid #edecf4;
}

.f-refund-method__disclaimer {
  border: 1px solid #d7cef0;
}

.f-registration {
  max-width: 448px;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 4px 30px 2px rgba(49, 41, 96, 0.3);
  border: 1px solid #d5d4da;
}
@media (max-width: 991.98px) {
  .f-registration {
    justify-self: center;
  }
}

.f-registration__existing-user {
  padding-top: 12px;
}

.f-reset-password {
  border-radius: 16px;
  box-shadow: 0 4px 30px 2px rgba(49, 41, 96, 0.3);
  border: 1px solid #d5d4da;
}

.f-search__close-button.btn {
  top: 5px;
  right: 5px;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
}

.f-search__dropdown {
  top: 100%;
  transition: 0.2s ease;
}
@media (min-width: 992px) {
  .f-search__dropdown {
    max-height: min(722px, 100vh - 176px);
    box-shadow: 0 4px 30px 2px rgba(49, 50, 96, 0.3);
    border: 1px solid #6248aa;
    border-top: none;
    border-radius: 0 0 24px 24px;
    transform: translateY(-4px);
  }
}
@media (max-width: 991.98px) {
  .f-search__dropdown {
    padding-bottom: 24px;
  }
}
.f-search__dropdown .container {
  padding: 0;
}

.f-search__search-field {
  z-index: 15;
}

.f-search__search-typing {
  display: var(--search-typing-display);
  left: 56px;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 991.98px) {
  .f-search__body {
    position: static;
  }
}

.f-search {
  --search-typing-display: block;
  grid-area: search;
}
@media (max-width: 991.98px) {
  .f-search {
    width: 100%;
    order: 10;
  }
}
.f-search .f-text__wrap {
  border: 1px solid #6248aa;
}
.f-search .f-base {
  box-shadow: none;
}
.f-search.--disable-typing {
  --search-typing-display: none;
}
.f-search:has(.i-search-dropdown).--open .f-search__dropdown {
  transform: translateY(0);
  pointer-events: auto;
  display: flex;
  opacity: 1;
}
@media (max-width: 991.98px) {
  .f-search:has(.i-search-dropdown).--open .f-search__dropdown {
    width: 100%;
    top: 0;
    left: 0;
    height: calc(100dvh + 80px);
    padding-top: 162px;
    border-radius: 0;
    box-shadow: none;
    transition: opacity 0.3s ease;
    background: none;
  }
}
@media (max-width: 991.98px) {
  .f-search:has(.i-search-dropdown).--open .f-search__close-button {
    opacity: 1;
    pointer-events: auto;
  }
}
.f-search:has(.i-search-dropdown).--open .f-text__wrap {
  background-color: #fff;
  box-shadow: none;
}
@media (min-width: 992px) {
  .f-search:has(.i-search-dropdown).--open .f-text__wrap {
    border-radius: 24px 24px 0 0;
    border-bottom-color: transparent;
  }
}

.f-user-password {
  width: min(100%, 448px);
}
@media (max-width: 1199.98px) {
  .f-user-password {
    width: 100%;
  }
}

.g-alerts:not(:has(.i-alert)) {
  display: none;
}

.g-breadcrumbs__list.--top-0 {
  padding-top: 0;
}
.g-breadcrumbs__list.--none {
  padding-block: 0;
}

.g-buttons {
  --spacing: 0px;
  margin-top: var(--spacing);
}
.g-buttons:has(.btn) {
  --spacing: 24px;
}
@media (min-width: 768px) {
  .g-buttons:has(.btn) {
    --spacing: 48px;
  }
}
.g-buttons.--spacing-none {
  --spacing: 0;
}

.cart-items-group__header {
  display: grid;
  grid-template-columns: 80px 2fr 1fr 150px 40px 0.5fr 46px;
  grid-template-areas: "content content availability counter discount price action";
}
@media (max-width: 1199.98px) {
  .cart-items-group__header {
    display: none;
  }
}

.cart-items-group__heading.--product {
  grid-area: content;
}
.cart-items-group__heading.--availability {
  grid-area: availability;
}
.cart-items-group__heading.--amount {
  grid-area: counter;
}
.cart-items-group__heading.--sale {
  grid-area: discount;
}
.cart-items-group__heading.--price {
  grid-area: price;
}

.g-category__cell.--hidden {
  display: none;
}

@media (max-width: 575.98px) {
  .g-category {
    --bs-gutter-x: 16px;
    --bs-gutter-y: 16px;
  }
}

@media (max-width: 991.98px) {
  .g-contacts-cards {
    max-width: 480px;
    margin-inline: auto;
  }
}

@media (min-width: 1200px) {
  .g-contacts-pillars {
    padding-inline: 16px;
  }
}

.g-contacts-pillars__cell {
  --max-width: 100%;
  --margin: auto;
  max-width: var(--max-width);
}
@media (min-width: 1200px) {
  .g-contacts-pillars__cell:nth-child(1) .i-contact-pillar {
    margin-left: var(--margin);
    border-radius: 24px 0 0 24px;
  }
}
@media (min-width: 1200px) {
  .g-contacts-pillars__cell:nth-child(3) .i-contact-pillar {
    margin-right: var(--margin);
    border-radius: 0 24px 24px 0;
  }
}
@media (max-width: 1199.98px) {
  .g-contacts-pillars__cell {
    --max-width: 668px;
    --margin: 0;
    margin-inline: auto;
  }
  .g-contacts-pillars__cell:nth-child(1) .i-contact-pillar, .g-contacts-pillars__cell:nth-child(3) .i-contact-pillar {
    border-radius: 24px;
  }
}
@media (min-width: 1500px) {
  .g-contacts-pillars__cell:nth-child(1), .g-contacts-pillars__cell:nth-child(3) {
    --max-width: 407px;
    width: 100%;
  }
  .g-contacts-pillars__cell:nth-child(2) {
    --max-width: 447px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .g-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .g-interprets-category {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .g-interprets-category {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1500px) {
  .g-interprets-category {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-interprets-category__heading,
.g-interprets__load-more-wrapper {
  grid-column: 1/-1;
}

@media (width > 359px) {
  .g-interprets {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .g-interprets {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1200px) {
  .g-interprets {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1500px) {
  .g-interprets {
    grid-template-columns: repeat(6, 1fr);
  }
}

@container (min-width: 264px) {
  .g-mediums:not(.--toggle):not(:has(> :nth-child(5))) .g-mediums__show-more {
    display: none;
  }
  .g-mediums:not(.--toggle):has(> :nth-child(5)) > .i-medium {
    display: none;
  }
  .g-mediums:not(.--toggle):has(> :nth-child(5)) > .i-medium:nth-child(-n+2) {
    display: flex;
  }
  .g-mediums:not(.--toggle):has(> :nth-child(5)) .g-mediums.--toggle > :first-child {
    display: none;
  }
  .g-mediums:not(.--toggle):has(> :nth-child(5)) .g-mediums.--toggle > :nth-child(2)::before {
    content: "";
  }
}
@container (min-width: 172px) and (max-width: 263px) {
  .g-mediums:not(.--toggle) > .i-medium {
    display: none;
  }
  .g-mediums:not(.--toggle) > .i-medium:nth-child(-n+2) {
    display: flex;
  }
  .g-mediums:not(.--toggle) > .i-medium:nth-child(2):not(:nth-last-child(2)) {
    display: none;
  }
  .g-mediums:not(.--toggle) .g-mediums__show-more:nth-child(3) {
    display: none;
  }
}
@container (max-width: 171px) {
  .g-mediums:not(.--toggle) > .i-medium {
    display: none;
  }
  .g-mediums:not(.--toggle) > .i-medium:first-child {
    display: flex;
  }
}
.g-mediums:not(.--toggle).--overflows > .i-medium:first-child {
  max-width: calc(100% - 64px);
}
.g-mediums:not(.--toggle).--overflows > .i-medium:not(:first-child) {
  display: none;
}
.g-mediums:not(.--toggle).--overflows .g-mediums__show-more {
  display: flex;
}
.g-mediums:not(.--toggle).--overflows .g-mediums.--toggle > .i-medium {
  display: flex;
}
.g-mediums:not(.--toggle).--overflows .g-mediums.--toggle > .i-medium::before {
  content: "+";
}

.g-mediums.--toggle {
  border-radius: 16px;
  padding: 10px;
  flex-direction: column;
  background: #fff;
  width: max-content;
  pointer-events: none;
  opacity: 0;
  box-shadow: 0 4px 16px 1px rgba(98, 72, 170, 0.2);
  transform: translate(-1px, -1px);
  transition: 0.1s opacity ease;
}
@media (max-width: 575.98px) {
  .g-mediums.--toggle {
    left: 50%;
    transform: translateX(-50%);
  }
}
.g-mediums.--toggle .i-medium::before {
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
}
.g-mediums .g-mediums__show-more:hover .g-mediums.--toggle {
  opacity: 1;
  pointer-events: auto;
}

.g-mediums {
  gap: 24px;
  z-index: 10;
}
.g-mediums:hover {
  z-index: 15;
}
.g-mediums .i-medium::before,
.g-mediums .g-mediums__show-more::before {
  content: "+";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  left: -18px;
  font-size: 18px;
  line-height: 12px;
  color: #484752;
  top: 50%;
  transform: translateY(-50%);
}
.g-mediums .i-medium:first-child::before {
  display: none;
}

@media (max-width: 575.98px) {
  .g-personal-data__row {
    --bs-gutter-y: 24px;
  }
}

.g-persons {
  display: grid;
}
@media (min-width: 768px) {
  .g-persons {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .g-persons {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-posts-grid {
  display: grid;
  gap: 32px;
  row-gap: 72px;
}
@media (min-width: 992px) {
  .g-posts-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-posts-grid__cell:first-of-type {
  grid-column: 1/-1;
}

.g-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  row-gap: 56px;
}
@media (max-width: 991.98px) {
  .g-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .g-posts {
    display: flex;
    flex-direction: column;
  }
}
.g-posts.--compact {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767.98px) {
  .g-posts.--compact {
    display: flex;
    flex-direction: column;
  }
}
.g-posts.--inline {
  display: flex;
  flex-direction: column;
}

.g-product-overviews {
  padding-bottom: 1px;
}

.g-products__title {
  grid-column: 1/-1;
  margin-bottom: -8px;
}

@media (min-width: 768px) {
  .g-products {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 992px) {
  .g-products {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767.98px) {
  .g-products .i-product {
    margin-inline: auto;
  }
}
@media (min-width: 1200px) {
  .g-products.--lg {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-products-slider__button {
  --offset: calc(50% - 4px);
  pointer-events: auto;
}
.g-products-slider__button.btn.--outline {
  --bg-white: 255 255 255;
  --background: rgb(var(--bg-white) / 50%);
  --background-hover: rgb(var(--bg-white) / 100%);
}
@media (hover: hover) {
  .g-products-slider__button.btn.--outline:hover {
    --background: var(--background-hover);
  }
}
@media (width <= 359px) {
  .g-products-slider__button.btn.--outline {
    --min-height: 32px;
    --offset: calc(50% - 12px);
  }
}
.g-products-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}
.g-products-slider__button.--next {
  transform: translateX(var(--offset));
}
.g-products-slider__button .icon.--lg {
  --size: 20px;
}

.g-products-slider.--loading::before {
  border-radius: 32px;
}
.g-products-slider.swiper {
  display: flex;
  align-items: center;
  overflow: initial;
}
.g-products-slider .swiper-wrapper {
  display: flex;
  align-items: center;
}
.g-products-slider .swiper-slide {
  flex-shrink: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  cursor: pointer;
}
.g-products-slider .swiper-slide-visible {
  opacity: 1;
  pointer-events: auto;
}

.g-products-slider__buttons {
  pointer-events: none;
}
.g-products-slider__buttons .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

.g-products-slider__load-more-img.img.--cover {
  inset: 16px;
  border-radius: 8px;
  background-color: #6248aa;
  width: auto;
  height: auto;
  overflow: hidden;
}
.g-products-slider__load-more-img.img.--cover img {
  opacity: 0.3;
  transition: 0.3s ease;
  transition-property: opacity, scale;
}

.g-products-slider__load-more.swiper-slide {
  border-radius: 16px;
  border: 1px solid #d7cef0;
  height: auto;
  transition: 0.3s ease;
  transition-property: box-shadow, background-color;
}
.g-products-slider__load-more.swiper-slide:hover {
  background-color: #fff;
}
.g-products-slider__load-more.swiper-slide:hover .g-products-slider__load-more-img img {
  opacity: 0.2;
  scale: 1.03;
}

.g-review {
  row-gap: 16px;
  padding: 40px;
  border-radius: 24px;
  border: 1px solid #d7cef0;
}
@media (min-width: 992px) {
  .g-review {
    row-gap: 24px;
  }
}
@media (min-width: 1200px) {
  .g-review {
    row-gap: 40px;
  }
}
@media (min-width: 1500px) {
  .g-review .g-review__cell {
    padding: 8px 16px;
  }
}

.g-review__cell-separator {
  column-gap: 16px;
}

.g-review__cell-separator-line {
  height: 1px;
  width: 100%;
  background-color: #d7cef0;
  margin: 0;
}

@media (min-width: 768px) {
  .g-sales {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .g-signpost .g-signpost__cell {
    max-width: 448px;
    margin-inline: auto;
  }
}

@media (min-width: 576px) {
  .g-similar-interprets {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .g-similar-interprets {
    grid-template-columns: auto;
  }
}

.g-socials.--sm {
  gap: 8px;
}

.g-terms__checkbox {
  border-radius: 8px;
}
.g-terms__checkbox .f-checkbox__title {
  font-weight: 400;
}

.g-terms__text-only {
  line-height: 16px;
}
.g-terms__text-only a {
  display: inline-block;
  color: #6248aa;
  font-weight: 600;
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}
.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}
.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}
@media (max-width: 575.98px) {
  .g-testimonials__button {
    --offset: 8px;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  min-height: 8px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 8px;
}
.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transition: width 0.3s ease;
  transform-origin: center;
}
.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 32px;
}

.g-testimonials .swiper-slide {
  flex-shrink: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  width: fit-content;
  cursor: pointer;
}
.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .g-testimonials {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1500px) {
  .g-testimonials {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-testimonials__last {
  height: 51px;
}

.g-top-products {
  grid-template: repeat(5, 1fr)/repeat(3, auto);
  grid-auto-flow: column;
}
@media (max-width: 1199.98px) {
  .g-top-products {
    gap: 16px;
  }
}
@media (max-width: 991.98px) {
  .g-top-products {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: row;
  }
}

.g-top-products__item::before {
  content: attr(data-top-products-order);
  position: absolute;
  font-weight: 700;
  width: 32px;
  height: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../images/svg/star-filled.svg") no-repeat center;
  background-size: 32px;
  z-index: 1;
  pointer-events: none;
}

@media (max-width: 575.98px) {
  .g-usps {
    --bs-gutter-x: 16px;
    --bs-gutter-y: 16px;
  }
}

.icon {
  --size: 20px;
  color: #081828;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}
.icon svg {
  width: 100%;
  height: 100%;
}
.icon.--colored {
  color: var(--color);
}
.icon.--colored path {
  fill: var(--color);
}
.icon.--primary {
  --color: #312960;
}
.icon.--primary-600 {
  --color: #6248aa;
}
.icon.--secondary-500 {
  --color: #ff5c50;
}
.icon.--white {
  --color: #fff;
}
.icon.--black {
  --color: #081828;
}
.icon.--danger {
  --color: #e21c3d;
}
.icon.--5-xl {
  --size: 80px;
}
.icon.--xxxxl {
  --size: 56px;
}
.icon.--xxxl {
  --size: 48px;
}
.icon.--xxl {
  --size: 40px;
}
.icon.--xl {
  --size: 32px;
}
.icon.--lg {
  --size: 24px;
}
.icon.--md {
  --size: 18px;
}
.icon.--sm {
  --size: 16px;
}
.icon.--xs {
  --size: 12px;
}
.icon.--xxs {
  --size: 10px;
}

.img.--ratio-4-3 {
  aspect-ratio: 4/3;
}
.img.--ratio-16-9 {
  aspect-ratio: 16/9;
}
.img.--ratio-3-2 {
  aspect-ratio: 3/2;
}
.img.--ratio-1-1 {
  aspect-ratio: 1/1;
}
.img.--ratio-3-4 {
  aspect-ratio: 3/4;
}
.img.--contain {
  width: 100%;
  height: 100%;
}
.img.--contain img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.img.--contain picture {
  height: 100%;
  width: 100%;
}
.img.--cover {
  width: 100%;
  height: 100%;
}
.img.--cover img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.img.--cover picture {
  height: 100%;
  width: 100%;
}
.img.--placeholder {
  --opacity: 0.7;
}
.img.--placeholder:has(img.loaded) {
  --opacity: 0;
}
.img.--placeholder::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #edecf4;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
  opacity: var(--opacity);
  transition: opacity 0.3s ease;
}

.i-add-wishlist-button {
  --svg-empty: block;
  --svg-full: none;
}
.i-add-wishlist-button.--wishlisted {
  --svg-empty: none;
  --svg-full: block;
}
.i-add-wishlist-button .s-heart__empty {
  display: var(--svg-empty);
  transition: fill 0.3s ease;
}
.i-add-wishlist-button .s-heart__full {
  display: var(--svg-full);
}
.i-add-wishlist-button:hover .s-heart__empty {
  fill: #ff5c50;
}

.i-alert {
  --background: #fff;
  --border-color: #ebebeb;
  --color: #081828;
  --opacity: 0;
  --translate: -32px;
  --icon-background: #fff;
  background-color: var(--background);
  color: var(--color);
  opacity: var(--opacity);
  border-color: var(--border-color);
  transform: translateY(var(--translate));
  pointer-events: none;
  border-width: 1px;
  border-style: solid;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  border-radius: 4px;
}
.i-alert.--show {
  --opacity: 1;
  --translate: 0;
  pointer-events: auto;
}
.i-alert.--success {
  --border-color: #48a463;
  --background: #e7f4e4;
  --color: #48a463;
  --icon-background: #48a463;
}
.i-alert.--danger {
  --border-color: #e21c3d;
  --background: #f9dede;
  --color: #e21c3d;
  --icon-background: #e21c3d;
}
.i-alert.--warning {
  --border-color: #fdb81e;
  --background: #fff1d2;
  --color: #fdb81e;
  --icon-background: #fdb81e;
}
.i-alert.--primary {
  --border-color: #312960;
  --background: #312960;
  --color: #fff;
  --icon-background: #312960;
}

.i-alert__icon,
.i-alert__close {
  background-color: var(--icon-background);
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author {
  display: flex;
}
.i-author.--compact {
  align-items: center;
}
.i-author.--compact .i-author__description {
  display: none;
}
@media (max-width: 575.98px) {
  .i-author {
    flex-direction: column;
  }
}

.i-author__image {
  --size: 64px;
  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}

.i-availability__description {
  padding: var(--p-description);
  border: var(--description-border);
  background-color: var(--description-background-color);
  color: #312960;
}

.i-availability__title {
  column-gap: var(--title-gap);
  font-size: var(--font-size-title);
}
.i-availability__title::before {
  content: "";
  flex-shrink: 0;
  display: inline-block;
  width: var(--dot-size);
  height: var(--dot-size);
  margin: 2px;
  border-radius: 50%;
  background-color: var(--background-color);
  box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
}

.i-availability.--available {
  --background-color: #57bc3d;
  --shadow-color: rgba(87, 188, 61, 0.5);
  --border-color: #57bc3d;
}
.i-availability.--unavailable {
  --background-color: #ff2616;
  --shadow-color: rgba(255, 38, 22, 0.5);
  --border-color: #ff2616;
}
.i-availability.--preorder {
  --background-color: #0dc5ed;
  --shadow-color: rgba(13, 197, 237, 0.5);
  --border-color: #0dc5ed;
}
.i-availability.--ended {
  --background-color: #484752;
  --shadow-color: rgba(72, 71, 82, 0.5);
  --border-color: #484752;
}
.i-availability.--enroute {
  --background-color: #ff7629;
  --shadow-color: rgba(255, 118, 41, 0.5);
  --border-color: #ff7629;
}
.i-availability.--custom-color {
  --background-color: var(--custom-color);
  --shadow-color: rgba(var(--custom-color), 0.5);
  --border-color: var(--custom-color);
}
.i-availability.--size-xs {
  --dot-size: 6px;
  --title-gap: 4px;
  --font-size: 12px;
  --font-size-title: 12px;
  --description-border: 0 none;
  --description-background-color: #edecf4;
  --line-height: 16px;
  --shadow-blur: 2px;
  --shadow-spread: 1px;
  --p-description: 2px 6px;
}
.i-availability.--size-xs .icon {
  display: none;
}
.i-availability.--size-xs .i-availability__description {
  font-size: var(--font-size-title);
}
.i-availability.--size-sm {
  --dot-size: 8px;
  --title-gap: 4px;
  --font-size: 14px;
  --font-size-title: 14px;
  --description-border: 0 none;
  --description-background-color: #edecf4;
  --line-height: 16px;
  --shadow-blur: 2px;
  --shadow-spread: 1px;
  --p-description: 2px 8px;
}
.i-availability.--size-sm .icon {
  display: none;
}
.i-availability.--size-lg {
  --dot-size: 16px;
  --title-gap: 8px;
  --font-size: 16px;
  --font-size-title: 18px;
  --description-border: 2px solid var(--background-color);
  --description-background-color: #fff;
  --line-height: 16px;
  --shadow-blur: 8px;
  --shadow-spread: 2px;
  --p-description: 6px 12px 6px 10px;
}
.i-availability.--size-lg .i-availability__title {
  font-weight: 700;
  margin-left: 4px;
  color: var(--background-color);
}
.i-availability.--mini .i-availability__quantity,
.i-availability.--mini .i-availability__description {
  display: none;
}

.i-banner {
  background: linear-gradient(to right, #312960, #6248aa);
  border-radius: 24px;
  padding-left: 240px;
}
@media (max-width: 991.98px) {
  .i-banner {
    padding: 24px;
  }
}
@media (max-width: 575.98px) {
  .i-banner {
    text-align: center;
  }
  .i-banner .btn {
    --min-height: 48px;
    --padding-y: 12px;
  }
}

.i-banner__header {
  max-width: 110ch;
}
@media (max-width: 991.98px) {
  .i-banner__header {
    max-width: 100%;
  }
}

.i-banner__description {
  line-height: 24px;
}

.i-banner__img {
  max-width: 269px;
  height: auto;
  transform: translate(-25%, -50%);
}
@media (max-width: 991.98px) {
  .i-banner__img {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .i-banner__buttons {
    width: 100%;
  }
}

.i-breadcrumb__divider {
  min-width: 24px;
  align-items: center;
}
.i-breadcrumb__divider svg {
  width: 6px;
  height: 9px;
}

.i-breadcrumb {
  color: #7868a3;
}
.i-breadcrumb.--homepage .i-breadcrumb__link {
  gap: 0;
}
.i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}

.i-cart-button__button.btn {
  --background: #312960;
}

@media (max-width: 991.98px) {
  .i-cart-button {
    position: static;
  }
}
@media (min-width: 992px) {
  .i-cart-button .container {
    padding: 16px;
  }
}
.i-cart-button:hover .i-cart-button__button .icon {
  --color: #ff5c50;
}
.i-cart-button.--open .i-cart-button__panel {
  opacity: 1;
  pointer-events: auto;
}

.i-cart-dropdown {
  width: min(100vw - 16px, 568px);
  max-height: min(100vh - 160px, 712px);
}
@media (max-width: 991.98px) {
  .i-cart-dropdown {
    width: 100%;
    top: 0;
    height: calc(100vh + 66px);
    max-height: none;
    padding-top: 162px;
    left: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    z-index: auto;
  }
}
.i-cart-dropdown:not(:has(.i-product-overview)) .i-cart-dropdown__cart-button {
  display: none;
}

@media (max-width: 991.98px) {
  .i-cart-dropdown__title {
    position: absolute;
    top: 66px;
    height: 80px;
    align-items: center;
    pointer-events: none;
    color: #fff;
    width: auto;
  }
}
@media (max-width: 991.98px) {
  .i-cart-dropdown__title .base-heading__decoration-after::after {
    display: none;
  }
}

.i-cart-dropdown__cart-list-wrapper {
  overflow: auto;
}

.i-cart {
  --image-size: 80px;
  --grid-columns: var(--image-size) 2fr 1fr 150px 40px 0.5fr 46px;
  --grid-areas: "image content availability counter discount price action";
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  align-items: center;
  min-height: var(--image-size);
  background-color: #fff;
  border: 1px solid #edecf4;
}
.i-cart .c-counter {
  --width: 128px;
  min-height: 40px;
  height: 40px;
}
.i-cart.--voucher {
  --grid-columns: auto 4fr 1fr 46px;
  --grid-areas: "image content price action";
}
@media (max-width: 1199.98px) {
  .i-cart {
    --image-size: 64px;
    grid-template-columns: var(--image-size) 1fr 1fr 46px;
    grid-template-areas: "image content content content" "availability availability discount discount" "counter counter price action";
  }
  .i-cart.--voucher {
    grid-template-columns: auto 1fr 46px;
    grid-template-areas: "image content content" "price price action";
  }
}

.i-cart__image {
  grid-area: image;
  width: var(--image-size);
  height: var(--image-size);
}

.i-cart__content {
  grid-area: content;
}
.i-cart__content .i-sale-voucher {
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  padding-left: 10px;
}
@media (max-width: 1199.98px) {
  .i-cart__content {
    align-self: start;
    margin-top: 8px;
    max-width: 100%;
  }
}

.i-cart__availability {
  grid-area: availability;
}

.i-cart__counter {
  grid-area: counter;
}

.i-cart__price {
  grid-area: price;
}

.i-cart__discount {
  grid-area: discount;
  justify-self: end;
}

.i-cart__action {
  grid-area: action;
  justify-self: end;
}

.i-cart-list__wrapper {
  max-height: 250px;
}

.i-cart-list__products:not(:has(.i-product-overview)) {
  display: none;
}

.i-cart-step {
  --decorator-size: 40px;
  --flow-marker-size: 1px;
  flex: 0 1 100%;
  align-items: center;
  position: relative;
  color: #312960;
  text-align: center;
  font-size: 18px;
}
.i-cart-step::before {
  content: attr(data-step);
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--decorator-size);
  min-height: var(--decorator-size);
  background-color: #312960;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 2 16px rgba(213, 212, 218, 0.3);
}
.i-cart-step::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(var(--decorator-size) / 2 - var(--flow-marker-size) / 2);
  left: 0;
  right: 0;
  height: var(--flow-marker-size);
  z-index: -1;
  background-color: #d7cef0;
}
.i-cart-step.--disabled {
  color: #7868a3;
}
.i-cart-step.--disabled::before {
  background-color: #fff;
  color: #7868a3;
}
.i-cart-step.--active::before {
  background-color: #d7cef0;
  color: #6248aa;
}
.i-cart-step.--completed::before {
  background-image: url("../images/svg/check.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  text-indent: -9999px;
}
.i-cart-step:first-child::after {
  left: 50%;
}
.i-cart-step:last-child::after {
  right: 50%;
}
@media (min-width: 576px) and (max-width: 991.98px) {
  .i-cart-step {
    --decorator-size: 32px;
    font-size: 16px;
  }
  .i-cart-step.--completed::before {
    background-size: 12px;
  }
}
@media (max-width: 575.98px) {
  .i-cart-step {
    --decorator-size: 40px;
    font-size: 14px;
  }
}

a .i-cart-step__title {
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;
}

a.i-cart-step {
  text-decoration: none;
}
a.i-cart-step:hover .i-cart-step__title {
  text-decoration: underline;
}

.i-category__image-link {
  max-height: 140px;
  max-width: 140px;
  transition: transform 0.3s linear;
  transform-style: preserve-3d;
}
@media (min-width: 768px) {
  .i-category__image-link {
    max-height: 160px;
    max-width: 160px;
  }
}
.i-category__image-link .img {
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}

.i-category__image-back-overlay {
  backface-visibility: hidden;
  background-color: rgba(98, 72, 170, 0.8);
  color: #fff;
  transform: rotateY(180deg);
  column-gap: 4px;
}

.i-category__image-wrapper {
  width: min(var(--image-size), 100%);
  height: min(var(--image-size), 100%);
}
.i-category__image-wrapper:hover .i-category__image-link {
  transform: rotateY(180deg);
}

.i-category__image-overlay {
  min-width: 56px;
  height: 56px;
  background-color: #fff;
  color: #6248aa;
  padding-inline: 16px;
  right: -24px;
}

.i-category__decoration {
  left: 0;
  column-gap: 3px;
}
.i-category__decoration::before, .i-category__decoration::after {
  content: "";
  display: block;
}
.i-category__decoration::after {
  width: 16px;
  height: 16px;
  background-color: #6248aa;
  border-radius: 16px;
}
.i-category__decoration::before {
  width: 8px;
  height: 16px;
  background-color: #ff5c50;
  border-radius: 0 16px 16px 0;
}

.i-category {
  --image-size: 140px;
}
.i-category.--last .i-category__decoration {
  transform: scale(-1);
  right: 0;
  left: auto;
}
@media (min-width: 768px) {
  .i-category {
    --image-size: 160px;
  }
}

.i-code {
  line-height: 16px;
  border: 1px dashed #ff5c50;
  border-radius: 8px;
  overflow-x: hidden;
}

.i-contact-card {
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
  padding: 40px;
}

.i-contact-card__decoration-position .d-small-circles {
  left: unset;
  right: 0;
}

.i-contact-card__content {
  color: #484752;
  line-height: 30px;
}

.i-contact-card__image {
  border-radius: 16px;
}

.i-contact-pillar {
  --img-size: 88px;
  --shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
  --min-height: 386px;
  --padding-y: 40px;
  --padding-x: 80px;
  min-height: var(--min-height);
  background-color: #fff;
  border: 1px solid #edecf4;
  border-radius: 24px;
  box-shadow: var(--shadow);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
}
.i-contact-pillar.--colored {
  --img-size: 120px;
  --shadow: 0 4px 30px 2px rgba(49, 50, 96, 0.3);
  --min-height: 464px;
  background: linear-gradient(to right, #312960, #6248aa);
  border: none;
}
@media (max-width: 1499.98px) {
  .i-contact-pillar {
    --padding-y: 32px;
    --padding-x: 24px;
  }
}
@media (max-width: 575.98px) {
  .i-contact-pillar {
    --padding-y: 24px;
    --padding-x: 16px;
  }
}

.i-contact-pillar__img {
  width: var(--img-size);
  height: var(--img-size);
  margin-inline: auto;
  border: 1px solid #d7cef0;
  border-radius: 16px;
  background-color: #fff;
}

.i-contact-pillar__btn .btn {
  --padding-x: 16px;
  gap: 4px;
  min-width: 160px;
}

.i-contact-pillar__contact .link__icon {
  position: relative;
  width: 24px;
  height: 24px;
}
.i-contact-pillar__contact .link__icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 56px;
  background-color: #ff5c50;
}
.i-contact-pillar__contact .link__icon .icon {
  z-index: 1;
}
.i-contact-pillar__contact .link__icon svg {
  margin-left: 0.5px;
}

.i-content-toggle__content {
  display: var(--content-display);
}

.i-content-toggle {
  --content-display: none;
}
.i-content-toggle.--open {
  --content-display: block;
}

.i-countdown__value {
  width: 48px;
  height: 48px;
  border-radius: 6px;
}

.i-countdown__colon {
  margin-top: 12px;
}

.i-countdown__step.--hidden {
  display: none;
}
.i-countdown__step.--hidden + .i-countdown__colon {
  display: none;
}

.i-discount {
  aspect-ratio: 1/1;
  background-color: #f43e80;
  text-align: center;
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
  font-size: var(--font-size);
}
.i-discount.--size-sm {
  --size: 32px;
  --font-size: 12px;
}
.i-discount.--size-md {
  --size: 40px;
  --font-size: 14px;
}
.i-discount.--size-lg {
  --size: 64px;
  --font-size: 18px;
}

.i-document {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  color: #edecf4;
}

.i-document__title {
  color: #312960;
}

.i-document__details {
  color: #312960;
}

.i-faq__body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.i-faq__body-inner {
  overflow: hidden;
}

.i-faq__header {
  cursor: pointer;
}

.i-faq__heading {
  line-height: 24px;
  transition: color 0.3s ease;
}

.i-faq__toggler {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.i-faq {
  --border-radius: 16px;
  --shadow: 0px 0px 0px 0px transparent;
  --bg-color: transparent;
  border: 1px solid #d7cef0;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  background-color: var(--bg-color);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}
.i-faq:hover {
  --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);
  --bg-color: #fff;
}
.i-faq:hover .i-faq__heading {
  color: #6248aa;
}
.i-faq:hover .i-faq__toggler {
  background-color: #edecf4;
}
.i-faq svg {
  transition: transform 0.3s ease;
  will-change: transform;
}
.i-faq.--open {
  --bg-color: transparent;
}
.i-faq.--open .i-faq__toggler svg {
  transform: scale(-1);
}
.i-faq.--open .i-faq__body {
  grid-template-rows: 1fr;
}

.i-filtration__header {
  cursor: pointer;
  min-height: 40px;
  border-radius: 24px;
  background-color: transparent;
  transition: 0.3s ease background-color;
}
@media (hover: hover) {
  .i-filtration__header:hover {
    background-color: #edecf4;
  }
}

.i-filtration__title {
  margin-left: -18px;
  transition: margin-left 0.3s ease;
}

.i-filtration__body {
  height: var(--height);
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity 0s ease;
}

.i-filtration__dropdown {
  scale: var(--dropdown-scale);
  transition: scale 0.3s ease;
}

.i-filtration__decor {
  opacity: var(--opacity);
  transition: opacity 0.3s ease;
}

.i-filtration__range-year .f-base {
  min-height: 40px;
  padding-block: 10px;
  appearance: textfield;
}
.i-filtration__range-year .f-base::-webkit-outer-spin-button, .i-filtration__range-year .f-base::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

@media (min-width: 1200px) and (max-width: 1499.98px) {
  .i-filtration__range-inputs {
    flex-direction: column;
  }
}
@media (max-width: 575.98px) {
  .i-filtration__range-inputs {
    flex-direction: column;
  }
}

.i-filtration {
  --dropdown-scale: 1;
  --opacity: 0;
  --height: 0;
  --pointer-events: none;
  overflow: hidden;
}
.i-filtration .f-range-slider {
  padding-top: 32px;
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .i-filtration .f-range-slider .f-range-slider__inputs {
    flex-direction: column;
  }
}
.i-filtration.--open {
  --dropdown-scale: -1;
  --opacity: 1;
  --height: auto;
  --pointer-events: all;
  overflow: auto;
}
.i-filtration.--open .i-filtration__title {
  margin-left: 0;
}
.i-filtration.--open .i-filtration__body {
  transition: opacity 0.3s ease;
}

@media (max-width: 767.98px) {
  .i-filtration-visual {
    flex-direction: column;
  }
}
.i-filtration-visual:not(:has(.i-selected-tag)) {
  display: none;
}

.i-filtration-visual__reset-btn.btn {
  padding-block: 6px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .i-information__label {
    flex-shrink: 0;
    width: min(100%, 112px);
  }
}

.i-information__item {
  --background-color: #f9f9f9;
  row-gap: 8px;
  border-radius: 8px;
}
@media (max-width: 575.98px) {
  .i-information__item {
    flex-direction: column;
  }
}
.i-information__item:nth-child(even) {
  background-color: var(--background-color);
}

.i-information__content {
  max-width: 80ch;
}

.i-information.--background-inverted .i-information__item {
  --background-color: #fff;
}

.i-interpret-category__img {
  width: 56px;
}
.i-interpret-category__img .img {
  border-radius: 50%;
  z-index: 1;
}

.i-interpret-category {
  --background-color: transparent;
  --color: #484752;
  --transform: scale(1);
  outline: none;
  border: none;
  background-color: var(--background-color);
  color: var(--color);
  padding-block: 0;
  min-height: 56px;
  transition: color 0.3s ease;
  transition-property: color, background-color;
}
.i-interpret-category::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #d7cef0;
  border-radius: 40px;
  z-index: 2;
}
@media (hover: hover) {
  .i-interpret-category:hover {
    --background-color: #fff;
    --color: #6248aa;
    --transform: scale(1.3);
    color: var(--color);
  }
}
.i-interpret-category:has(.i-interpret-category__img) {
  padding-left: 0;
}
.i-interpret-category.--checkbox::before {
  transition: border 0.3s ease;
}
.i-interpret-category:has(.i-interpret-category__input:checked), .i-interpret-category.--active {
  --background-color: transparent;
  --color: #6248aa;
}
.i-interpret-category:has(.i-interpret-category__input:checked)::before, .i-interpret-category.--active::before {
  border: 1px solid #6248aa;
}
.i-interpret-category.--link {
  text-decoration: none;
}
.i-interpret-category.--link:focus {
  color: var(--color);
}
@media (max-width: 991.98px) {
  .i-interpret-category.--lg-hidden {
    display: none;
  }
}

.i-interpret-category__icon {
  width: 40px;
  height: 40px;
}
.i-interpret-category__icon .icon,
.i-interpret-category__icon .img {
  transform: var(--transform);
  transition: transform 0.3s ease;
}
.i-interpret-category__icon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background-image: url("../images/svg/spot.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.i-interpret-detail .base-heading::before {
  display: none;
}

.i-interpret-detail__image {
  width: 88px;
}
.i-interpret-detail__image .img {
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}

.i-interpret-detail__tags {
  border: 1px solid #edecf4;
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .i-interpret-detail__tags {
    align-items: flex-start;
  }
}

@media (max-width: 575.98px) {
  .i-interpret-detail__description {
    text-align: center;
  }
}

.i-interpret-detail__content.entry-content ol,
.i-interpret-detail__content.entry-content ul {
  margin-top: 0;
}

@media (max-width: 575.98px) {
  .i-interpret {
    width: min(100%, 488px);
    margin-inline: auto;
  }
}

.i-interpret__image-link {
  transition: transform 0.3s linear;
  transform-style: preserve-3d;
}
.i-interpret__image-link .img {
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}

.i-interpret__image-back-overlay {
  backface-visibility: hidden;
  background-color: rgba(98, 72, 170, 0.8);
  color: #fff;
  transform: rotateY(180deg);
  column-gap: 4px;
}

.i-interpret__image-wrapper {
  width: min(204px, 100%);
}
@media (max-width: 767.98px) {
  .i-interpret__image-wrapper {
    width: min(128px, 100%);
  }
}
.i-interpret__image-wrapper ~ .base-heading {
  transition: color 0.3s ease;
  transition-property: color, transform;
}
.i-interpret__image-wrapper:hover .i-interpret__image-link {
  transform: rotateY(180deg);
}
.i-interpret__image-wrapper:hover ~ .base-heading {
  color: #6248aa;
  transform: translateY(-8px);
}

.i-interpret__more-btn.btn {
  --font-size: 16px;
}

.i-interpret__products-btn {
  min-width: 160px;
}

.i-intro-banner__inner {
  --max-width: 524px;
  max-width: var(--max-width);
  margin-left: var(--m-left);
}
@media (max-width: 575.98px) {
  .i-intro-banner__inner {
    --max-width: 100%;
  }
}

.i-intro-banner {
  --min-height: 254px;
  min-height: var(--min-height);
  color: var(--font-color);
  text-align: var(--text-align);
}
.i-intro-banner.--light-text {
  --font-color: #fff;
  --text-background: rgba(60 64 67 / 70%);
  --blend-mode: multiply;
}
.i-intro-banner.--dark-text {
  --font-color: #312960;
  --text-background: rgba(249 249 249 / 70%);
  --blend-mode: normal;
}
.i-intro-banner.--left {
  --text-align: left;
}
@media (max-width: 575.98px) {
  .i-intro-banner.--left {
    --text-align: center;
  }
}
.i-intro-banner.--right {
  --text-align: right;
  --m-left: auto;
}
@media (max-width: 575.98px) {
  .i-intro-banner.--right {
    --text-align: center;
    --m-left: 0;
  }
}
.i-intro-banner.--text-background .i-intro-banner__inner:has(.i-intro-banner__inner-background) {
  padding: 16px;
}
.i-intro-banner.--text-background .i-intro-banner__inner-background {
  width: 100%;
  height: 100%;
  background-color: var(--text-background);
  mix-blend-mode: var(--blend-mode);
  z-index: -1;
  transform: translate(-16px, -16px);
}
@media (max-width: 575.98px) {
  .i-intro-banner {
    --min-height: auto;
    padding: 24px;
  }
}

.i-intro-banner__description {
  line-height: 24px;
}

.i-intro-banner__buttons {
  margin-left: var(--m-left);
}

.i-intro-banner__image {
  z-index: -2;
}

.i-media.--video {
  cursor: pointer;
}

.i-medium {
  --height: 32px;
  height: var(--height);
  min-width: var(--height);
  border-radius: 22px;
  box-shadow: 0 0 0 1px #b1afbd;
}
.i-medium.--compact {
  --height: 20px;
}

.i-medium__image {
  margin-left: 3px;
  margin-right: -4px;
}

@media (max-width: 991.98px) {
  .i-menu__submenu-button {
    margin-left: auto;
    padding: 16px;
  }
}
.i-menu__submenu-button::before {
  content: "";
  position: absolute;
  inset: 0;
}
@media (min-width: 992px) {
  .i-menu__submenu-button::before {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .i-menu__submenu-button .img {
    transform: rotate(-90deg);
  }
}

.i-menu__link {
  padding: 22px 24px 26px;
  display: flex;
  gap: 4px;
  align-items: center;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
}
@media (min-width: 992px) {
  .i-menu__link {
    height: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .i-menu__link {
    padding: 16px 12px 18px;
    font-size: 14px;
  }
}
@media (max-width: 991.98px) {
  .i-menu__link {
    padding: 0 0 0 8px;
    min-height: 56px;
  }
}
@media (min-width: 992px) {
  .i-menu__link::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    display: block;
    background-color: #ff5c50;
    height: 4px;
    transition: transform 0.2s ease;
    transform-origin: bottom;
    transform: scaleY(0);
  }
}
@media (max-width: 991.98px) {
  .i-menu__link::before {
    content: "";
    width: 4px;
    height: 9px;
    border-radius: 0 50% 50% 0;
    vertical-align: middle;
    background-color: #ff5c50;
    display: inline-block;
  }
}
.i-menu__link:hover {
  color: #6248aa;
}
.i-menu__link:hover::after {
  transform: scaleY(1);
}
.i-menu__link.--submenu-mobile-only {
  display: none;
}
@media (max-width: 991.98px) {
  .i-menu__link.--submenu-mobile-only {
    display: flex;
    min-height: 72px;
  }
}
.i-menu__link.--submenu-mobile-only .i-menu__submenu-button {
  pointer-events: none;
}

.i-menu__submenu-wrapper {
  position: absolute;
  left: 0;
  width: 100%;
  padding-bottom: 80px;
}
@media (min-width: 992px) {
  .i-menu__submenu-wrapper {
    opacity: 0;
    pointer-events: none;
    top: 100%;
    padding-bottom: 0;
    box-shadow: 0 6px 10px 2px rgba(120, 104, 163, 0.2);
    border-radius: 0 0 24px 24px;
    background: #f9f9f9;
    transition: opacity 0.3s ease;
    max-height: calc(100vh - var(--menu-bottom-offset));
    overflow: auto;
  }
}
@media (max-width: 991.98px) {
  .i-menu__submenu-wrapper {
    top: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
  }
}

@media (min-width: 992px) {
  .i-menu__submenu {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: min-content;
    gap: 16px 32px;
  }
}
@media (max-width: 991.98px) {
  .i-menu__submenu {
    padding: 16px 0 20px;
  }
}

.i-menu__submenu-header {
  position: sticky;
  top: 0;
  z-index: 10;
  width: calc(100% - 64px);
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .i-menu.--top-category .i-menu__submenu {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 992px) {
  .i-menu.hover .i-menu__submenu-wrapper {
    opacity: 1;
    pointer-events: auto;
  }
}
@media (max-width: 991.98px) {
  .i-menu.--open .i-menu__submenu-wrapper {
    overflow: auto;
    opacity: 1;
    pointer-events: auto;
    overscroll-behavior: none;
  }
}
.i-menu.--open ~ .i-menu {
  z-index: -1;
}
.i-menu.--active .i-menu__link {
  color: #ff5c50;
}
@media (min-width: 1500px) {
  .i-menu.--sm .i-menu__link {
    font-size: 16px;
  }
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .i-menu.--sm .i-menu__link {
    padding: 16px 8px 18px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-menu.--sm .i-menu__link {
    font-size: 12px;
    gap: 2px;
  }
  .i-menu.--sm .i-menu__link .i-menu__submenu-button {
    width: 18px;
  }
}

.i-menu__submenu-content {
  grid-template-areas: "lists" "top-category";
}
@media (min-width: 992px) {
  .i-menu__submenu-content {
    grid-template-areas: "lists lists lists top-category";
  }
}

.i-menu__submenu-lists {
  grid-area: lists;
}

.i-menu__submenu-top-category {
  grid-area: top-category;
  min-height: 264px;
  max-height: 480px;
}
.i-menu__submenu-top-category .i-top-category {
  height: 100%;
}

.i-method {
  --min-height: 32px;
  --min-width: 48px;
  --padding-y: 2px;
  --padding-x: 4px;
  border: 1px solid #d5d4da;
  border-radius: 4px;
  background-color: #fff;
  height: var(--min-height);
  width: var(--min-width);
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
}

.i-tab-control {
  min-width: 90px;
  border: 1px solid #d7cef0;
  transition: 0.2s ease;
  transition-property: background-color, border-color, color;
  padding: var(--padding);
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.i-tab-control:hover {
  background-color: #d7cef0;
}
.i-tab-control.--selected {
  background-color: #6248aa;
  border-color: #6248aa;
  color: #fff;
}
.i-tab-control.--size-xs {
  --font-size: 12px;
  --line-height: 16px;
  --padding: 4px 8px;
}
.i-tab-control.--size-sm {
  --font-size: 14px;
  --line-height: 16px;
  --padding: 8px;
}
@media (max-width: 767.98px) {
  .i-tab-control.--size-sm {
    --font-size: 12px;
    --line-height: 16px;
    --padding: 4px 8px;
  }
}
.i-tab-control.--size-md {
  --font-size: 16px;
  --line-height: 24px;
  --padding: 8px 16px;
}
@media (max-width: 991.98px) {
  .i-tab-control.--size-md {
    --font-size: 14px;
    --line-height: 16px;
    --padding: 8px;
    min-width: 48px;
  }
}

.i-new-products {
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}
@media (max-width: 767.98px) {
  .i-new-products {
    padding-inline: 8px;
  }
}

.i-new-products__slider-buttons {
  left: -26px;
  right: -26px;
  top: 106px;
}
@media (max-width: 575.98px) {
  .i-new-products__slider-buttons {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .i-new-products__slider .swiper-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
.i-new-products__slider .swiper-slide {
  height: auto;
  cursor: pointer;
}
.i-new-products__slider.swiper {
  overflow: clip;
  overflow-clip-margin: 8px;
}
.i-new-products__slider.swiper:not(.swiper-initialized) .swiper-wrapper {
  gap: 16px;
}
@media (min-width: 576px) {
  .i-new-products__slider.swiper:not(.swiper-initialized) .swiper-slide {
    width: 192px;
    flex: 0 0 192px;
  }
}

@media (max-width: 991.98px) {
  .i-new-products__header {
    justify-content: center;
  }
}

.i-new-products__tab-controls {
  justify-content: center;
}

.i-new-products__button.--hidden {
  display: none;
}

.i-notification {
  min-height: 40px;
  background-color: #ff5c50;
  color: #fff;
}
@media (max-width: 575.98px) {
  .i-notification {
    text-align: center;
  }
}
@media (max-width: 991.98px) {
  .i-notification:has(~ .m-header.--menu-open, ~ .m-header.--user-open, ~ .m-header.--cart-open) {
    display: none;
  }
}

.i-notification__container {
  width: min(100% - 32px, 832px);
}
@media (max-width: 575.98px) {
  .i-notification__container {
    flex-direction: column;
    gap: 8px;
  }
}

@media (max-width: 575.98px) {
  .i-notification__icon {
    margin-inline: auto;
  }
}

@media (max-width: 575.98px) {
  .i-notification__close {
    order: -1;
  }
}

.i-order-info__icon {
  height: 24px;
}

.i-order {
  border: 1px solid #d7cef0;
}

.i-order-column {
  --images-col: 160px;
  --status-col: 160px;
  --id-col: 140px;
  --date-col: 140px;
  --price-col: 1fr;
  width: max-content;
  min-width: 100%;
  grid-template-columns: var(--images-col) var(--status-col) var(--id-col) var(--date-col) var(--price-col) 48px;
}
@media (min-width: 1200px) and (max-width: 1499.98px) {
  .i-order-column {
    --date-col: 100px;
  }
}

.i-order__products-count {
  width: 48px;
  height: 48px;
}

.i-overview.--default {
  flex-wrap: wrap;
  align-items: center;
}
.i-overview.--default .i-overview__title,
.i-overview.--default .i-overview__subtitle {
  font-size: 12px;
}
.i-overview.--default .i-overview__label {
  width: 100%;
  flex-grow: 1;
}
.i-overview.--default .i-overview__description {
  min-width: 204px;
  max-width: calc(100% - 56px - 72px);
}
.i-overview.--compact {
  justify-content: space-between;
}
.i-overview.--compact .i-overview__title,
.i-overview.--compact .i-overview__subtitle {
  font-size: 14px;
}

.i-overview__image {
  --image-size: 56px;
  flex: 0 0 var(--image-size);
  max-width: var(--image-size);
  height: var(--image-size);
}

.i-overview__price {
  margin-left: auto;
}

@media (min-width: 992px) {
  .i-post.--hero {
    display: grid;
    gap: 32px;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991.98px) {
  .i-post.--hero {
    max-width: 688px;
    margin-inline: auto;
  }
}
.i-post.--hero .i-post__tags {
  margin-top: 0;
}
@media (min-width: 992px) {
  .i-post.--hero .i-post__content-col {
    padding-block: 32px;
  }
}
.i-post.--hero .i-post__content {
  justify-content: center;
}

@media (min-width: 992px) {
  .i-post.--inline {
    display: grid;
    grid-template-columns: minmax(160px, 446px) auto;
    gap: 32px;
  }
  .i-post.--inline .i-post__content {
    max-width: 70ch;
  }
}

@media (max-width: 991.98px) {
  .i-post {
    max-width: 448px;
    margin-inline: auto;
  }
}

.i-price {
  text-align: right;
  column-gap: 2px;
  flex: 0 0 auto;
}
.i-price .i-price__main {
  font-size: var(--font-size-main);
  line-height: var(--line-height-main);
}
.i-price .i-price__base {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}
.i-price .i-price__before {
  font-size: var(--font-size-before);
  line-height: var(--line-height-before);
}
.i-price.--size-lg {
  --font-size-main: 18px;
  --line-height-main: 24px;
  --font-size-base: 12px;
  --line-height-base: 14px;
  --font-size-before: 14px;
  --line-height-before: 16px;
}
.i-price.--size-sm {
  --font-size-main: 14px;
  --line-height-main: 16px;
  --font-size-base: 12px;
  --line-height-base: 16px;
  --font-size-before: 12px;
  --line-height-before: 14px;
}

.i-price__title {
  flex: 1;
  text-align: left;
}
.i-price__title::after {
  content: ":";
}

.i-discount {
  aspect-ratio: 1/1;
  background-color: #f43e80;
  text-align: center;
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
  font-size: var(--font-size);
}
.i-discount.--size-sm {
  --size: 32px;
  --font-size: 12px;
}
.i-discount.--size-md {
  --size: 40px;
  --font-size: 14px;
}
.i-discount.--size-lg {
  --size: 64px;
  --font-size: 18px;
}

.i-price {
  text-align: right;
  column-gap: 2px;
  flex: 0 0 auto;
}
.i-price .i-price__main {
  font-size: var(--font-size-main);
  line-height: var(--line-height-main);
}
.i-price .i-price__base {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}
.i-price .i-price__before {
  font-size: var(--font-size-before);
  line-height: var(--line-height-before);
}
.i-price.--size-lg {
  --font-size-main: 18px;
  --line-height-main: 24px;
  --font-size-base: 12px;
  --line-height-base: 14px;
  --font-size-before: 14px;
  --line-height-before: 16px;
}
.i-price.--size-sm {
  --font-size-main: 14px;
  --line-height-main: 16px;
  --font-size-base: 12px;
  --line-height-base: 16px;
  --font-size-before: 12px;
  --line-height-before: 14px;
}

.i-price__title {
  flex: 1;
  text-align: left;
}
.i-price__title::after {
  content: ":";
}

.i-product-inline__link.link {
  --font-size: 16px;
  --line-height: 24px;
  --lines: 1;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: Inter, Adjusted Verdana, sans-serif;
  text-decoration: none;
}
.i-product-inline__link.link::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 2;
}

.i-product-inline__order {
  width: 32px;
  height: 32px;
  border: 1px solid #edecf4;
  background-color: #fff;
  border-radius: 50%;
}

.i-product-inline__image {
  grid-area: image;
}

.i-product-inline__header {
  grid-area: header;
  margin-bottom: 1px;
}

.i-product-inline__mediums {
  pointer-events: none;
  grid-area: mediums;
}
.i-product-inline__mediums > .i-medium {
  pointer-events: auto;
}
.i-product-inline__mediums .g-mediums__show-more {
  pointer-events: auto;
}
.i-product-inline__mediums:hover .g-mediums {
  pointer-events: auto;
}

.i-product-inline__availability {
  grid-area: availability;
}

.i-product-inline__discount {
  grid-area: discount;
}

.i-product-inline__price {
  grid-area: price;
}

.i-product-inline__cart-add {
  z-index: 10;
  grid-area: button;
  justify-self: flex-end;
}

.i-product-inline__content {
  grid-template-columns: 56px 1fr 64px minmax(96px, auto);
  grid-template-areas: "image header discount price" "image mediums discount price";
}

.i-product-inline {
  border: 1px solid #edecf4;
  transition: background-color 0.2s ease;
  padding-block: 10.5px;
}
.i-product-inline:has(.i-product-inline__link:hover) {
  background: #fff;
}
.i-product-inline:has(.i-product-inline__link:hover) .i-product__image-link img {
  transform: scale(1.03);
}
.i-product-inline.--top {
  padding-left: 24px;
}
.i-product-inline.--top .i-product-inline__content {
  grid-template-columns: 56px 1fr 1fr 64px minmax(96px, auto) auto;
  grid-template-areas: "image header  header       discount price button" "image mediums availability discount price button";
}
@container (max-width: 480px) {
  .i-product-inline .i-product-inline__content {
    grid-template-columns: 56px 1fr auto 32px;
    row-gap: 8px;
    grid-template-areas: "image header header discount" "mediums mediums price price";
  }
  .i-product-inline .i-product-inline__order {
    top: 28px;
  }
  .i-product-inline .i-product-inline__link.link {
    --lines: 3;
    --font-size: 14px;
    --line-height: 16px;
  }
  .i-product-inline .i-product-inline__price.i-price {
    --font-size-main: 14px;
    --line-height-main: 16px;
    --font-size-base: 12px;
    --line-height-base: 16px;
    --font-size-before: 12px;
    --line-height-before: 14px;
  }
  .i-product-inline .i-product-inline__discount.i-discount {
    margin: 0;
    --size: 32px;
    --font-size: 12px;
  }
  .i-product-inline.--top .i-product-inline__content {
    grid-template-columns: 56px 1fr auto 32px;
    grid-template-areas: "image header header discount" "mediums mediums price price" "availability availability button button";
  }
}

.i-product.--horizontal {
  width: 100%;
  border-radius: 16px;
}
.i-product.--horizontal::before {
  top: 8px;
  left: 98px;
}
.i-product.--horizontal .i-product__body {
  display: grid;
  grid-template-columns: 120px 1fr 40px;
  grid-template-areas: "image header header" "image content buttons";
  padding: 8px;
  gap: 16px 8px;
}
.i-product.--horizontal .i-product__content {
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-template-areas: "mediums price" "availability price";
  gap: 8px;
  margin-right: 8px;
}
.i-product.--horizontal .i-product__image {
  align-self: start;
}
.i-product.--horizontal .i-product__buttons {
  align-items: flex-end;
  justify-content: flex-end;
}
.i-product.--horizontal .i-product__price {
  align-self: end;
}
.i-product.--horizontal .i-product__cart-add {
  --min-height: 40px;
  --padding-x: 8px;
  --padding-y: 8px;
}
.i-product.--horizontal .i-product__cart-add .btn__title {
  display: none;
}
.i-product.--horizontal .i-product__wishlist,
.i-product.--horizontal .i-product__discount,
.i-product.--horizontal .i-product__tags {
  display: none;
}
@container (max-width: 400px) {
  .i-product.--horizontal::before {
    top: 8px;
    left: 56px;
  }
  .i-product.--horizontal .i-product__body {
    grid-template-columns: 80px 1fr 40px;
  }
  .i-product.--horizontal .i-product__content {
    grid-template-columns: 64px 1fr;
    grid-template-areas: "mediums mediums" "price price";
    gap: 12px 8px;
    min-height: 80px;
  }
  .i-product.--horizontal .i-product__availability {
    --font-size-title: 12px;
    --font-size: 12px;
    position: absolute;
    bottom: 8px;
    left: 8px;
    max-width: 112px;
  }
}
.i-product.--horizontal.--hidden-buttons .i-product__body {
  grid-template-areas: "image header header" "image content content";
}
.i-product.--horizontal.--hidden-buttons .i-product__price {
  padding-right: 0;
}

.i-discount {
  aspect-ratio: 1/1;
  background-color: #f43e80;
  text-align: center;
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
  font-size: var(--font-size);
}
.i-discount.--size-sm {
  --size: 32px;
  --font-size: 12px;
}
.i-discount.--size-md {
  --size: 40px;
  --font-size: 14px;
}
.i-discount.--size-lg {
  --size: 64px;
  --font-size: 18px;
}

.i-tag {
  --padding-x: 16px;
  --padding-y: 4px;
  --min-height: 32px;
  --font-size: 16px;
  --line-height: 20px;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  min-width: var(--min-width);
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.i-tag.--xs {
  --padding-x: 4px;
  --padding-y: 0;
  --min-height: 16px;
  --font-size: 12px;
  --line-height: 16px;
}
.i-tag.--sm {
  --padding-x: 8px;
  --padding-y: 4px;
  --min-height: 20px;
  --font-size: 14px;
  --line-height: 16px;
}
@media (max-width: 575.98px) {
  .i-tag.--sm {
    --min-height: 24px;
    --min-width: 40px;
    --font-size: 12px;
  }
}
.i-tag.--md {
  --padding-x: 8px;
  --padding-y: 0px;
  --min-height: 32px;
  --min-width: 48px;
  --font-size: 14px;
}
@media (max-width: 575.98px) {
  .i-tag.--md {
    --min-height: 24px;
    --min-width: 40px;
    --font-size: 12px;
  }
}
.i-tag.--lg {
  --padding-x: 24px;
  --padding-y: 4px;
  --min-height: 40px;
}

.i-product.--offer {
  border: none;
}
.i-product.--offer .i-product__body {
  display: grid;
  grid-template-columns: 144px 1fr;
  grid-template-areas: "image header" "content content";
  padding: 8px;
  gap: 8px;
  height: auto;
}
.i-product.--offer .i-product__content {
  align-items: center;
  grid-template-columns: 1fr auto;
  grid-template-areas: "mediums price" "availability price";
  gap: 8px;
}
.i-product.--offer .i-product__discount.i-discount {
  top: auto;
  bottom: 0;
  --size: 32px;
  --font-size: 12px;
}
.i-product.--offer .i-product__buttons {
  display: none;
}
.i-product.--offer .i-product__header {
  align-self: center;
}
.i-product.--offer .i-product__title .link {
  --lines: 6;
  --font-size: 14px;
  --line-height: 16px;
}
.i-product.--offer .i-product__tags {
  width: calc(100% - 24px);
}
.i-product.--offer .i-product__tag.i-tag {
  --padding-x: 4px;
  --padding-y: 0;
  --min-height: 16px;
  --font-size: 12px;
  --line-height: 16px;
}
.i-product.--offer .i-product__price {
  align-self: end;
  --font-size-main: 14px;
  --line-height-main: 16px;
  --font-size-base: 12px;
  --line-height-base: 16px;
  --font-size-before: 12px;
  --line-height-before: 14px;
}

@media (min-width: 576px) {
  .i-product.--vertical .i-product__header {
    flex-grow: 1;
  }
}
@media (min-width: 576px) {
  .i-product.--vertical .i-product__content {
    flex-grow: 0;
  }
}

.i-product.--wishlist {
  border-radius: 16px;
}
.i-product.--wishlist::before {
  top: 8px;
  left: 98px;
}
.i-product.--wishlist .i-product__header {
  align-self: end;
}
.i-product.--wishlist .i-product__subtitle {
  display: block;
}
.i-product.--wishlist .i-product__body {
  display: grid;
  grid-template-columns: 176px 1fr 225px;
  grid-template-areas: "image header buttons" "image content buttons";
}
.i-product.--wishlist .i-product__content {
  align-self: start;
  grid-template-columns: 1fr auto;
  grid-template-areas: "mediums price" "availability price";
  gap: 8px;
  margin-right: 16px;
}
.i-product.--wishlist .i-product__image {
  align-self: start;
}
.i-product.--wishlist .i-discount {
  top: auto;
  bottom: 0;
}
.i-product.--wishlist .i-product__buttons {
  flex-direction: column;
  justify-content: center;
}
.i-product.--wishlist .i-product__price {
  align-self: start;
}
.i-product.--wishlist .i-add-wishlist-button__button {
  width: 100%;
}
.i-product.--wishlist .i-product__cart-add {
  --min-height: 48px;
  --padding-x: 8px;
  --padding-y: 8px;
  flex-grow: 0;
}
@media (min-width: 768px) {
  .i-product.--wishlist {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .i-product.--wishlist .i-product__buttons {
    flex-direction: row;
  }
  .i-product.--wishlist .i-product__buttons > * {
    flex-grow: 1;
  }
}
@media (max-width: 991.98px) {
  .i-product.--wishlist .i-product__body {
    grid-template-columns: 176px 1fr;
    grid-template-areas: "image header" "image content" "buttons buttons";
  }
}
@media (max-width: 767.98px) {
  .i-product.--wishlist::before {
    top: 8px;
    left: 56px;
  }
  .i-product.--wishlist .i-product__body {
    grid-template-columns: 80px 1fr;
    grid-template-areas: "image image" "header header" "content content" "buttons buttons";
  }
  .i-product.--wishlist .i-product__content {
    grid-template-columns: 64px 1fr;
    grid-template-areas: "mediums mediums" "availability price";
    gap: 12px 8px;
  }
}

.i-discount {
  aspect-ratio: 1/1;
  background-color: #f43e80;
  text-align: center;
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
  font-size: var(--font-size);
}
.i-discount.--size-sm {
  --size: 32px;
  --font-size: 12px;
}
.i-discount.--size-md {
  --size: 40px;
  --font-size: 14px;
}
.i-discount.--size-lg {
  --size: 64px;
  --font-size: 18px;
}

.i-price {
  text-align: right;
  column-gap: 2px;
  flex: 0 0 auto;
}
.i-price .i-price__main {
  font-size: var(--font-size-main);
  line-height: var(--line-height-main);
}
.i-price .i-price__base {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}
.i-price .i-price__before {
  font-size: var(--font-size-before);
  line-height: var(--line-height-before);
}
.i-price.--size-lg {
  --font-size-main: 18px;
  --line-height-main: 24px;
  --font-size-base: 12px;
  --line-height-base: 14px;
  --font-size-before: 14px;
  --line-height-before: 16px;
}
.i-price.--size-sm {
  --font-size-main: 14px;
  --line-height-main: 16px;
  --font-size-base: 12px;
  --line-height-base: 16px;
  --font-size-before: 12px;
  --line-height-before: 14px;
}

.i-price__title {
  flex: 1;
  text-align: left;
}
.i-price__title::after {
  content: ":";
}

.i-tag {
  --padding-x: 16px;
  --padding-y: 4px;
  --min-height: 32px;
  --font-size: 16px;
  --line-height: 20px;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  min-width: var(--min-width);
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.i-tag.--xs {
  --padding-x: 4px;
  --padding-y: 0;
  --min-height: 16px;
  --font-size: 12px;
  --line-height: 16px;
}
.i-tag.--sm {
  --padding-x: 8px;
  --padding-y: 4px;
  --min-height: 20px;
  --font-size: 14px;
  --line-height: 16px;
}
@media (max-width: 575.98px) {
  .i-tag.--sm {
    --min-height: 24px;
    --min-width: 40px;
    --font-size: 12px;
  }
}
.i-tag.--md {
  --padding-x: 8px;
  --padding-y: 0px;
  --min-height: 32px;
  --min-width: 48px;
  --font-size: 14px;
}
@media (max-width: 575.98px) {
  .i-tag.--md {
    --min-height: 24px;
    --min-width: 40px;
    --font-size: 12px;
  }
}
.i-tag.--lg {
  --padding-x: 24px;
  --padding-y: 4px;
  --min-height: 40px;
}

.i-availability__description {
  padding: var(--p-description);
  border: var(--description-border);
  background-color: var(--description-background-color);
  color: #312960;
}

.i-availability__title {
  column-gap: var(--title-gap);
  font-size: var(--font-size-title);
}
.i-availability__title::before {
  content: "";
  flex-shrink: 0;
  display: inline-block;
  width: var(--dot-size);
  height: var(--dot-size);
  margin: 2px;
  border-radius: 50%;
  background-color: var(--background-color);
  box-shadow: 0 0 var(--shadow-blur) var(--shadow-spread) var(--shadow-color);
}

.i-availability.--available {
  --background-color: #57bc3d;
  --shadow-color: rgba(87, 188, 61, 0.5);
  --border-color: #57bc3d;
}
.i-availability.--unavailable {
  --background-color: #ff2616;
  --shadow-color: rgba(255, 38, 22, 0.5);
  --border-color: #ff2616;
}
.i-availability.--preorder {
  --background-color: #0dc5ed;
  --shadow-color: rgba(13, 197, 237, 0.5);
  --border-color: #0dc5ed;
}
.i-availability.--ended {
  --background-color: #484752;
  --shadow-color: rgba(72, 71, 82, 0.5);
  --border-color: #484752;
}
.i-availability.--enroute {
  --background-color: #ff7629;
  --shadow-color: rgba(255, 118, 41, 0.5);
  --border-color: #ff7629;
}
.i-availability.--custom-color {
  --background-color: var(--custom-color);
  --shadow-color: rgba(var(--custom-color), 0.5);
  --border-color: var(--custom-color);
}
.i-availability.--size-xs {
  --dot-size: 6px;
  --title-gap: 4px;
  --font-size: 12px;
  --font-size-title: 12px;
  --description-border: 0 none;
  --description-background-color: #edecf4;
  --line-height: 16px;
  --shadow-blur: 2px;
  --shadow-spread: 1px;
  --p-description: 2px 6px;
}
.i-availability.--size-xs .icon {
  display: none;
}
.i-availability.--size-xs .i-availability__description {
  font-size: var(--font-size-title);
}
.i-availability.--size-sm {
  --dot-size: 8px;
  --title-gap: 4px;
  --font-size: 14px;
  --font-size-title: 14px;
  --description-border: 0 none;
  --description-background-color: #edecf4;
  --line-height: 16px;
  --shadow-blur: 2px;
  --shadow-spread: 1px;
  --p-description: 2px 8px;
}
.i-availability.--size-sm .icon {
  display: none;
}
.i-availability.--size-lg {
  --dot-size: 16px;
  --title-gap: 8px;
  --font-size: 16px;
  --font-size-title: 18px;
  --description-border: 2px solid var(--background-color);
  --description-background-color: #fff;
  --line-height: 16px;
  --shadow-blur: 8px;
  --shadow-spread: 2px;
  --p-description: 6px 12px 6px 10px;
}
.i-availability.--size-lg .i-availability__title {
  font-weight: 700;
  margin-left: 4px;
  color: var(--background-color);
}
.i-availability.--mini .i-availability__quantity,
.i-availability.--mini .i-availability__description {
  display: none;
}

.i-product__image {
  grid-area: image;
  border-radius: 16px;
}

.i-product__header,
.i-product__variants {
  grid-column: span 2;
}

.i-product__header {
  grid-area: header;
}

.i-product__link.link {
  --font-size: 16px;
  --line-height: 24px;
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: Inter, Adjusted Verdana, sans-serif;
  text-decoration: none;
}
.i-product__link.link:hover {
  color: #6248aa;
}
.i-product__link.link::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 2;
}

.i-product__content {
  grid-area: content;
  grid-template-columns: 1fr auto;
  grid-template-areas: "mediums mediums" "availability price";
}

.i-product__mediums {
  pointer-events: none;
  grid-area: mediums;
}
.i-product__mediums > .i-medium {
  pointer-events: auto;
}
.i-product__mediums .g-mediums__show-more {
  pointer-events: auto;
}
.i-product__mediums:hover .g-mediums {
  pointer-events: auto;
}

.i-product__price {
  grid-area: price;
}

.i-product__availability {
  grid-area: availability;
}

.i-product__buttons {
  grid-area: buttons;
}

.i-product__image-thumbnail {
  width: 120px;
  height: 120px;
}

.i-product__cart-add,
.i-product__wishlist {
  z-index: 10;
}

.i-product__cart-add {
  flex: 1;
}

.i-product__tags {
  width: calc(100% - 56px);
  pointer-events: none;
}

.i-product {
  width: min(100%, 328px);
  border-radius: 16px;
  border: 1px solid #d7cef0;
  transition: background-color 0.2s ease;
  transition-property: box-shadow, background-color;
}
@media (max-width: 767.98px) {
  .i-product {
    width: 100%;
  }
}
.i-product:hover {
  z-index: 15;
}
.i-product:has(.i-product__link:hover) {
  background: #fff;
}
.i-product:has(.i-product__link:hover) .i-product__image-link img {
  transform: scale(1.03);
}
.i-product.--hidden-buttons .i-product__buttons {
  display: none;
}
@container (max-width: 224px) {
  .i-product .g-mediums.--toggle {
    top: auto;
    left: auto;
    right: 50%;
    bottom: -1px;
    transform: translateX(50%);
  }
  .i-product .i-product__body {
    padding: 8px;
    border-radius: 16px;
  }
  .i-product .i-product__link.link {
    --font-size: 14px;
    --line-height: 16px;
  }
  .i-product .i-product__content {
    grid-template-columns: 1fr;
    grid-template-areas: "mediums" "availability" "price";
  }
  .i-product .i-product__buttons {
    gap: 8px;
  }
  .i-product .i-product__buttons .btn__title {
    display: none;
  }
  .i-product .i-product__price {
    --font-size-main: 14px;
    --line-height-main: 16px;
    --font-size-base: 12px;
    --line-height-base: 16px;
    --font-size-before: 12px;
    --line-height-before: 14px;
  }
  .i-product .i-product__discount {
    --size: 32px;
    --font-size: 12px;
    bottom: 0;
    top: auto;
  }
  .i-product .i-product__tag.i-tag {
    --padding-x: 4px;
    --padding-y: 0;
    --min-height: 16px;
    --font-size: 12px;
    --line-height: 16px;
  }
  .i-product .i-product__availability {
    --dot-size: 6px;
    --title-gap: 4px;
    --font-size: 12px;
    --font-size-title: 12px;
    --description-border: 0 none;
    --description-background-color: #edecf4;
    --line-height: 16px;
    --shadow-blur: 2px;
    --shadow-spread: 1px;
    --p-description: 2px 6px;
  }
}

.i-product-offer__header::after, .i-product-offer__header::before {
  content: "";
  display: block;
  background: url("../images/svg/decor-after.svg") center no-repeat;
  width: 21px;
  height: 9px;
}

.i-product-offer {
  border: 1px solid #d7cef0;
  transition: 0.2s ease;
  transition-property: background-color, opacity, transform;
}
.i-product-offer.--hidden {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-48px);
}
@container (max-width: 312px) {
  .i-product-offer .i-product-offer__body {
    padding: 8px;
  }
}

.i-product-order {
  --image-size: 88px;
  --grid-columns: var(--image-size) 300px 50px 100px auto;
  --grid-areas: "image content count price action";
  display: grid;
  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  align-items: center;
  min-height: var(--image-size);
}
.i-product-order .c-counter {
  --width: 128px;
  min-height: 40px;
  height: 40px;
}
@media (max-width: 1499.98px) {
  .i-product-order {
    --image-size: 64px;
    grid-template-columns: var(--image-size) 3fr 1fr;
    grid-template-areas: "image content count" "price price price" "action action action";
  }
}
@media (max-width: 575.98px) {
  .i-product-order {
    grid-template-areas: "image content content" "count price price" "action action action";
  }
}

.i-product-order__image {
  grid-area: image;
  width: var(--image-size);
  height: var(--image-size);
}

.i-product-order__content {
  grid-area: content;
}
@media (max-width: 1199.98px) {
  .i-product-order__content {
    align-self: start;
    margin-top: 8px;
    max-width: 100%;
  }
}

.i-product-order__count {
  grid-area: count;
  text-align: right;
}
@media (max-width: 575.98px) {
  .i-product-order__count {
    text-align: left;
  }
}

.i-product-order__price {
  grid-area: price;
}

.i-product-order__actions {
  grid-area: action;
  justify-self: end;
  flex-wrap: wrap;
}

.i-product-overview__image {
  width: 56px;
  height: 56px;
}

.i-product-overview__action {
  margin-left: -8px;
}

@media (min-width: 768px) {
  .i-product-overview__count {
    flex: 0 0 50px;
  }
}

.i-product-overview .i-sale-voucher {
  --padding-x: 8px;
  flex-flow: wrap;
  justify-content: start;
}

@media (width <= 395px) {
  .i-product-review .i-product-review__rating {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-product-review:not(.--compact) .i-product-review__rating {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.i-progress {
  --progress-color: linear-gradient(to right, #312960, #6248aa);
}
.i-progress.--complete .i-progress__icon path {
  fill: #e93d30;
}

@media (max-width: 991.98px) {
  .i-progress__header {
    width: min(100%, 256px);
    align-self: center;
  }
}

.i-progress__input {
  width: 100%;
  appearance: none;
  border: none;
  display: block;
  border-radius: 4px;
  height: 8px;
  overflow: hidden;
  background-color: #d7cef0;
}
.i-progress__input::-webkit-progress-bar {
  background: transparent;
}
.i-progress__input::-webkit-progress-value {
  background-image: var(--progress-color);
}
.i-progress__input::-moz-progress-bar {
  background-image: var(--progress-color);
}

.i-progress__icon-decoration {
  margin-right: -8px;
}

.i-promo__button {
  inset: auto 16px 16px;
}
.i-promo__button::after {
  position: absolute;
  display: block;
  bottom: -16px;
  left: -16px;
  width: 100vw;
  height: 100vh;
}

.i-rating .base-heading::before {
  display: none;
}
.i-rating.--vertical {
  flex-direction: row-reverse;
}
@media (min-width: 992px) {
  .i-rating.--vertical {
    flex-direction: column;
  }
}
.i-rating.--horizontal {
  flex-direction: row-reverse;
  gap: 16px;
}
.i-rating.--horizontal .base-heading {
  font-size: 24px;
}

.i-reclamation__collapsible-content {
  --body-inner-overflow: hidden;
  overflow: var(--body-inner-overflow);
}

.i-reclamation {
  --transition-duration: 0.3s;
  --product-tile-height: 120px;
  --border-color: transparent;
  grid-template-rows: var(--product-tile-height) 0fr;
  transition: grid-template-rows var(--transition-duration) ease;
  border: 1px solid var(--border-color);
}
.i-reclamation.--expanded {
  --border-color: #edecf4;
  grid-template-rows: var(--product-tile-height) 1fr;
}
.i-reclamation.--after-expanded .i-reclamation__collapsible-content {
  --body-inner-overflow: visible;
}
@media (max-width: 991.98px) {
  .i-reclamation {
    --product-tile-height: 160px;
  }
}
@media (max-width: 767.98px) {
  .i-reclamation {
    --product-tile-height: 180px;
  }
}
@media (max-width: 575.98px) {
  .i-reclamation {
    --product-tile-height: 220px;
  }
}

.reclamation__product-tile {
  --image-size: 88px;
  --grid-columns: var(--image-size) 3fr 1fr 0.5fr;
  --grid-areas: "image content counter price";
  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  min-height: var(--image-size);
}
.reclamation__product-tile .c-counter {
  width: 150px;
}
@media (max-width: 1199.98px) {
  .reclamation__product-tile {
    --grid-columns: var(--image-size) 3fr 1fr 1fr;
  }
}
@media (max-width: 991.98px) {
  .reclamation__product-tile {
    --image-size: 64px;
    grid-template-columns: var(--image-size) 1fr 1fr;
    grid-template-areas: "image content content" "image counter price";
  }
}
@media (max-width: 575.98px) {
  .reclamation__product-tile {
    grid-template-columns: var(--image-size) 1fr;
    grid-template-areas: "image content" "image counter" "image price";
  }
}

.i-reclamation__image {
  grid-area: image;
  width: var(--image-size);
  height: var(--image-size);
}

.i-reclamation__content {
  grid-area: content;
}

.i-reclamation__counter {
  grid-area: counter;
}

.i-reclamation__price {
  grid-area: price;
}

.i-review {
  row-gap: 4px;
}
@media (min-width: 992px) {
  .i-review {
    column-gap: 24px;
  }
}
@media (min-width: 1200px) {
  .i-review {
    flex-direction: row;
  }
}

.i-review__icon-logo {
  --w: 104px;
  --h: 56px;
  flex: 0 0 var(--w);
  max-width: var(--w);
  height: var(--h);
}

.i-review__stars {
  column-gap: 4px;
}
.i-review__stars .icon {
  --size: 16px;
}
@media (min-width: 768px) {
  .i-review__stars .icon {
    --size: 24px;
    column-gap: 8px;
  }
}

@media (min-width: 1200px) {
  .i-review__rating {
    justify-content: start;
  }
}

.i-review__details .i-review__details-tag {
  font-weight: 700;
  color: #312960;
  max-height: 24px;
  --min-height: none;
  --padding-x: 10px;
}
@media (min-width: 1200px) {
  .i-review__details {
    justify-content: start;
  }
}

.i-sale-voucher {
  --padding-y: 16px;
  --padding-x: 80px;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  overflow: hidden;
}
@media (max-width: 1499.98px) {
  .i-sale-voucher {
    --padding-x: 40px;
  }
}
@media (max-width: 991.98px) {
  .i-sale-voucher {
    --padding-x: 16px;
    flex-direction: column;
    justify-content: center;
  }
}

.i-sale-voucher__title {
  line-height: 16px;
  user-select: none;
}

@media (min-width: 992px) {
  .i-search-dropdown {
    border-top: 1px solid #d7cef0;
  }
}
@media (max-width: 767.98px) {
  .i-search-dropdown {
    max-height: none;
  }
}

.i-search-dropdown__link {
  text-align: right;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .i-search-dropdown__link {
    font-size: 14px;
  }
}

.btn.i-selected-tag {
  --border-radius: 23px;
  --font-weight: 500;
  background-color: #edecf4;
  text-transform: none;
}
@media (hover: hover) {
  .btn.i-selected-tag:hover {
    box-shadow: none;
  }
}

.i-signpost .img {
  border-radius: 24px;
}
.i-signpost .i-signpost__wrapper {
  width: calc(100% - 32px);
  display: flex;
  align-self: center;
  min-height: 171px;
  bottom: 16px;
}
.i-signpost .i-signpost__body-background {
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  background-color: rgba(72, 71, 82, 0.91);
  mix-blend-mode: multiply;
  z-index: 1;
}
.i-signpost .i-signpost__body {
  width: 100%;
  z-index: 4;
}
.i-signpost .base-heading {
  min-height: 71px;
}
@media (hover: hover) {
  .i-signpost:hover img {
    transform: scale(1.03);
  }
}

.i-similar-interprets__title {
  overflow-wrap: break-word;
  word-break: break-all;
  padding-block: 4px;
  transition: color 0.3s ease;
}

.i-similar-interprets {
  --min-height: 56px;
  --radius: 40px;
  user-select: none;
  text-decoration: none;
  border: 1px solid #d7cef0;
  border-radius: var(--radius);
  min-height: var(--min-height);
  min-width: var(--min-height);
  padding-right: 12px;
  transition: box-shadow 0.3s ease;
}
@media (hover: hover) {
  .i-similar-interprets:hover {
    box-shadow: 0 4px 16px 1px rgba(98, 72, 170, 0.2);
  }
  .i-similar-interprets:hover .i-similar-interprets__title {
    color: #6248aa;
  }
}

.i-similar-interprets__img {
  width: var(--min-height);
  height: var(--min-height);
}

.i-social.--lg .btn {
  --border-radius: 16px;
  --min-height: 64px;
}
.i-social.--sm .btn {
  --border-radius: 50%;
  --min-height: 24px;
}

.i-sort-header {
  --arrow-left-display: visible;
  --arrow-right-display: visible;
}
.i-sort-header.--asc {
  --arrow-left-display: hidden;
}
.i-sort-header.--desc {
  --arrow-right-display: hidden;
}
.i-sort-header .s-sort-left {
  visibility: var(--arrow-left-display);
}
.i-sort-header .s-sort-right {
  visibility: var(--arrow-right-display);
}

.i-status {
  --font-color: colors.$black;
  padding: 1px 10px;
  background-color: #c7e9f4;
  color: var(--font-color);
  min-height: 24px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
}
.i-status.--success {
  --font-color: #081828;
  background-color: #cfe9a4;
}
.i-status.--error {
  --font-color: #fff;
  background-color: #ff2616;
}
.i-status.--info {
  --font-color: #081828;
  background-color: #c7e9f4;
}
.i-status.--warning {
  --font-color: #081828;
  background-color: #ffdc61;
}

@media (min-width: 992px) {
  .i-submenu__category-items {
    margin-bottom: 4px;
    padding-left: 36px;
  }
}

.i-submenu__category-item.--hidden {
  display: none;
}

.i-submenu__show-more {
  margin-top: 4px;
}
.i-submenu__show-more.--hidden {
  display: none;
}

.i-submenu__link {
  text-decoration: none;
  transition: color 0.2s ease;
  background-color: var(--background);
}
.i-submenu__link:hover {
  color: #ff5c50;
}

.i-submenu__label {
  padding-block: 2px;
  background-color: #f43e80;
}

.i-submenu {
  grid-column: span 1;
}
.i-submenu.--color-label-1 {
  --background: #f43e80;
}
.i-submenu.--color-label-2 {
  --background: #f9af79;
}
.i-submenu.--color-label-3 {
  --background: #c7e9f4;
}
.i-submenu.--color-label-4 {
  --background: #ffdc61;
}
.i-submenu.--color-label-5 {
  --background: #cfe9a4;
}
.i-submenu.--active .i-submenu__link {
  color: #e93d30;
}
.i-submenu.--has-items {
  grid-row: span 3;
}

.i-support__perex {
  word-wrap: break-word;
}

.i-support__img {
  --max-height: 64px;
  width: var(--max-height);
  height: var(--max-height);
}
.i-support__img::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 21px;
  height: 10px;
  background-image: url("../images/svg/decor-after.svg");
  background-repeat: no-repeat;
  z-index: 1;
}
.i-support__img::after {
  content: "";
  position: absolute;
  inset: 0;
  width: var(--max-height);
  height: var(--max-height);
  border-radius: 9999px;
  border: 2px solid #6248aa;
  z-index: 1;
}
.i-support__img.--placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edecf4;
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}
.i-support__img.--placeholder::before {
  display: none;
}
.i-support__img.--sm {
  --max-height: 48px;
}

.i-support__text {
  gap: 2px;
}

.i-support.--sm::before {
  content: "";
  position: absolute;
  bottom: -16px;
  left: 0;
  transform: translateY(100%);
  width: 100%;
  height: 1px;
  background-color: #d7cef0;
}
.i-support.--sm .i-support__title {
  font-size: 16px;
  line-height: 24px;
}
.i-support.--sm .i-support__perex {
  font-size: 14px;
  line-height: 16px;
}

.i-tab-control {
  min-width: 90px;
  border: 1px solid #d7cef0;
  transition: 0.2s ease;
  transition-property: background-color, border-color, color;
  padding: var(--padding);
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.i-tab-control:hover {
  background-color: #d7cef0;
}
.i-tab-control.--selected {
  background-color: #6248aa;
  border-color: #6248aa;
  color: #fff;
}
.i-tab-control.--size-xs {
  --font-size: 12px;
  --line-height: 16px;
  --padding: 4px 8px;
}
.i-tab-control.--size-sm {
  --font-size: 14px;
  --line-height: 16px;
  --padding: 8px;
}
@media (max-width: 767.98px) {
  .i-tab-control.--size-sm {
    --font-size: 12px;
    --line-height: 16px;
    --padding: 4px 8px;
  }
}
.i-tab-control.--size-md {
  --font-size: 16px;
  --line-height: 24px;
  --padding: 8px 16px;
}
@media (max-width: 991.98px) {
  .i-tab-control.--size-md {
    --font-size: 14px;
    --line-height: 16px;
    --padding: 8px;
    min-width: 48px;
  }
}

.i-tag.--color-label-1 {
  --background-color: #f43e80;
  color: #484752;
}
.i-tag.--color-label-2 {
  --background-color: #f9af79;
  color: #484752;
}
.i-tag.--color-label-3 {
  --background-color: #c7e9f4;
  color: #484752;
}
.i-tag.--color-label-4 {
  --background-color: #ffdc61;
  color: #484752;
}
.i-tag.--color-label-5 {
  --background-color: #cfe9a4;
  color: #484752;
}

a.i-tag.--color-primary-100,
.i-tag.--hoverable.--color-primary-100 {
  --background-color: #edecf4;
}
a.i-tag.--color-primary-100:hover,
.i-tag.--hoverable.--color-primary-100:hover {
  --background-color: #d7cef0;
  border-color: #d7cef0;
}

.i-tag {
  --padding-x: 16px;
  --padding-y: 4px;
  --min-height: 32px;
  --font-size: 16px;
  --line-height: 20px;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  min-width: var(--min-width);
  font-size: var(--font-size);
  line-height: var(--line-height);
}
.i-tag.--xs {
  --padding-x: 4px;
  --padding-y: 0;
  --min-height: 16px;
  --font-size: 12px;
  --line-height: 16px;
}
.i-tag.--sm {
  --padding-x: 8px;
  --padding-y: 4px;
  --min-height: 20px;
  --font-size: 14px;
  --line-height: 16px;
}
@media (max-width: 575.98px) {
  .i-tag.--sm {
    --min-height: 24px;
    --min-width: 40px;
    --font-size: 12px;
  }
}
.i-tag.--md {
  --padding-x: 8px;
  --padding-y: 0px;
  --min-height: 32px;
  --min-width: 48px;
  --font-size: 14px;
}
@media (max-width: 575.98px) {
  .i-tag.--md {
    --min-height: 24px;
    --min-width: 40px;
    --font-size: 12px;
  }
}
.i-tag.--lg {
  --padding-x: 24px;
  --padding-y: 4px;
  --min-height: 40px;
}

.i-tag {
  --font-color: #6248aa;
  --background-color: #fff;
  --font-size: 14px;
  --radius: 24px;
  font-size: var(--font-size);
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: var(--radius);
  border: 1px solid var(--background-color);
  gap: 2px;
}
.i-tag.--outline {
  border-color: #d7cef0;
}
.i-tag.--primary {
  --font-color: #fff;
  --background-color: #6248aa;
}
.i-tag.--blank {
  --background-color: transparent;
  padding: 0;
  min-height: auto;
}
.i-tag.--icon-before .i-tag__icon {
  order: -1;
}
.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}
.i-tag.--icon-only .i-tag__title {
  display: none;
}
.i-tag.--input {
  transition-property: border-color, background-color;
  will-change: border-color, background-color;
}

label.i-tag,
a.i-tag,
.i-tag.--hoverable {
  text-decoration: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
}
label.i-tag:hover,
a.i-tag:hover,
.i-tag.--hoverable:hover {
  border-color: #312960;
}
label.i-tag.--primary:hover,
a.i-tag.--primary:hover,
.i-tag.--hoverable.--primary:hover {
  --font-color: #fff;
  --background-color: #312960;
  color: var(--font-color);
}

a.i-tag.--blank {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;
}
a.i-tag.--blank:hover {
  border-color: transparent;
  text-decoration-color: var(--font-color);
}

.i-tag__input {
  left: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
}

.i-tag:has(.i-tag__input:checked) {
  --font-color: var(--c-white);
  border-color: #312960;
}

.i-testimonial {
  --opacity: 0.8;
  --blend-mode: darken;
  --grayscale: 1;
  --width: 124px;
  --height: 51px;
  width: var(--width);
  max-width: var(--width);
  height: var(--height);
  justify-self: center;
}
.i-testimonial .i-testimonial__image {
  opacity: var(--opacity);
  mix-blend-mode: var(--blend-mode);
  filter: grayscale(var(--grayscale));
  transition: 0.3s ease;
  transition-property: opacity, mix-blend-mode, filter;
  will-change: opacity, mix-blend-mode, filter;
}
.i-testimonial:hover {
  --opacity: 1;
  --blend-mode: darken;
  --grayscale: 0;
}
.i-testimonial.--no-hover {
  --opacity: 1;
  --blend-mode: darken;
  --grayscale: 0;
}
.i-testimonial.--sm {
  --height: 48px;
}

.i-thumbnail {
  border: 1px solid transparent;
  transition: border-color 0.2s ease;
}
.i-thumbnail img {
  border: 1px solid #edecf4;
}

.i-top-category {
  min-height: 264px;
  padding: 60px 8px 8px;
}
@media (hover: hover) {
  .i-top-category:hover img {
    transform: scale(1.03);
  }
}

.i-top-category-background {
  border-radius: 16px;
  background-color: rgba(72, 71, 82, 0.8);
  mix-blend-mode: multiply;
}

.i-top-category__body > .base-heading {
  font-size: 28px;
}
.i-top-category__body > .base-heading::before {
  content: none;
}

.i-total-price {
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  border: 1px solid #d7cef0;
  border-radius: 16px;
  background-color: #f9f9f9;
}
.i-total-price.--size-lg {
  --padding-y: 56px;
  --padding-x: 16px;
}
.i-total-price.--size-md {
  --padding-y: 24px;
  --padding-x: 16px;
}
.i-total-price.--size-sm {
  --padding-y: 16px;
  --padding-x: 8px;
}

.i-transport-indicator {
  flex: 0 1 224px;
}
@media (max-width: 1199.98px) {
  .i-transport-indicator {
    font-size: 14px;
  }
}
.i-transport-indicator.--progress .i-transport-indicator__title.--default-title {
  display: none;
}
.i-transport-indicator.--progress .i-transport-indicator__title.--progress-title {
  display: inline;
}
.i-transport-indicator.--fulfilled .i-transport-indicator__icon {
  --color: #ff5c50;
}
.i-transport-indicator.--fulfilled .i-transport-indicator__title.--fulfilled-title {
  display: inline;
}
.i-transport-indicator.--fulfilled .i-transport-indicator__title.--progress-title, .i-transport-indicator.--fulfilled .i-transport-indicator__title.--default-title {
  display: none;
}

.i-user-button__button.--full {
  display: none;
}

@media (min-width: 992px) {
  .i-user-button__header.base-heading {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .i-user-button__header.base-heading {
    height: 80px;
    top: var(--panel-offset);
    opacity: 0;
    pointer-events: none;
  }
}

.i-user-button__panel {
  width: min(100vw - 16px, 304px);
}
@media (max-width: 991.98px) {
  .i-user-button__panel {
    width: 100%;
    top: 0;
    height: calc(100vh + var(--panel-offset));
    padding-top: var(--panel-padding-top);
    left: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    transition: opacity 0.3s ease;
  }
}

.i-user-button {
  --panel-padding-top: 162px;
  --panel-offset: 66px;
}
@media (max-width: 991.98px) {
  .i-user-button {
    position: static;
  }
}
@media (min-width: 992px) {
  .i-user-button .container {
    padding: 16px;
  }
}
.i-user-button:hover .i-user-button__button .icon {
  --color: #ff5c50;
}
.i-user-button.--filled .i-user-button__button.--full {
  display: flex;
}
.i-user-button.--filled .i-user-button__button.--empty {
  display: none;
}
.i-user-button.--open .i-user-button__header {
  opacity: 1;
}
.i-user-button.--open .i-user-button__panel {
  opacity: 1;
  pointer-events: auto;
}

.i-user-panel__link.link.--primary-600 {
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.i-user-panel__link.link.--primary-600:hover, .i-user-panel__link.link.--primary-600:focus {
  background-color: #edecf4;
}
.i-user-panel__link.link.--primary-600.--active {
  color: #fff;
  background-color: #6248aa;
}
.i-user-panel__link.link.--primary-600.--active:hover, .i-user-panel__link.link.--primary-600.--active:focus {
  color: #fff;
  background-color: #6248aa;
}
.i-user-panel__link.link.--primary-600.--active .icon {
  --color: #fff;
}

.i-usp__icon {
  --size: 80px;
  width: var(--size);
  height: var(--size);
}

.i-variant-row__title {
  width: var(--title-width);
}

.i-variant-row__group-wrapper {
  min-height: 48px;
}

@media (max-width: 575.98px) {
  .i-variant-row {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }
}
@container (max-width: 488px) {
  .i-variant-row {
    flex-direction: column;
    align-items: stretch;
    padding: 0;
  }
}
.i-variant-row.--lg {
  --title-width: 128px;
}
.i-variant-row.--sm {
  --title-width: 80px;
}

.i-wishlist-button__button.--full {
  display: none;
}

.i-wishlist-button:hover .i-wishlist-button__button .icon {
  --color: #ff5c50;
}
.i-wishlist-button.--filled .i-wishlist-button__button.--full {
  display: flex;
}
.i-wishlist-button.--filled .i-wishlist-button__button.--empty {
  display: none;
}

.i-youtube-iframe__wrapper {
  padding-bottom: 60%;
}
.i-youtube-iframe__wrapper iframe {
  width: 100%;
  height: 100%;
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
  width: max-content;
  min-width: 52px;
}
.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
}

.c-language-select__dropdown {
  background-color: #fff;
  transition: opacity 0.3s ease;
  top: 100%;
  left: 0;
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  box-shadow: 0 8px 16px 2px rgba(15, 28, 123, 0.1490196078);
  z-index: 500;
}

.c-language-select__active {
  min-height: 24px;
  gap: 4px;
}

.c-language-select__dropdown-link {
  text-decoration: none;
  transition: background-color 0.3s ease;
}
.c-language-select__dropdown-link:hover {
  background-color: #edecf4;
}

.c-language-select__flag {
  border-radius: 2px;
  margin-right: 4px;
}
.c-language-select__flag.--active {
  background-color: #fff;
  border: 1px solid #fff;
}
.c-language-select__flag.--select {
  background-color: #312960;
  border: 1px solid #312960;
}

.link {
  --cubic-transition: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.link.--animation-left svg, .link.--animation-right svg {
  transition-timing-function: var(--cubic-transition);
}
.link.--animation-left:hover svg {
  transform: translateX(-4px);
}
.link.--animation-right:hover svg {
  transform: translateX(4px);
}
.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  --color: #081828;
}
.link.--white {
  --color: #fff;
}
.link.--primary {
  --color: #312960;
}
.link.--secondary {
  --color: #e93d30;
}
.link.--primary-600 {
  --color: #6248aa;
}
.link.--tertiary {
  --color: #484752;
}
.link.--tertiary-to-primary {
  --color: #484752;
  --color-hover: #312960;
}
.link.--tertiary-to-primary-600 {
  --color: #484752;
  --color-hover: #6248aa;
}
.link.--primary-600-to-primary {
  --color: #6248aa;
  --color-hover: #312960;
}
.link.--primary-to-secondary-500 {
  --color: #312960;
  --color-hover: #ff5c50;
}
.link.--primary-600-to-secondary-500 {
  --color: #6248aa;
  --color-hover: #ff5c50;
}
.link.--colored:not(.--underline-initial) {
  color: var(--color);
  text-decoration-color: transparent;
}
.link.--colored:not(.--underline-initial):hover, .link.--colored:not(.--underline-initial):focus {
  color: var(--color-hover, var(--color));
  text-decoration-color: var(--color-hover, var(--color));
}
.link.--colored.--underline-initial {
  text-decoration-color: inherit;
  color: var(--color);
}
.link.--colored.--underline-initial:hover, .link.--colored.--underline-initial:focus {
  text-decoration-color: transparent;
  color: var(--color-hover, var(--color));
}

.link__icon svg {
  transition: transform 0.3s ease;
  will-change: transform;
}

.link {
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  text-decoration-color: transparent;
}
.link:hover {
  text-decoration-color: inherit;
}
.link.--icon-before .link__icon {
  order: -1;
}
.link.--icon-background .link__icon {
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 1;
}
.link.--icon-background .link__icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  border-radius: 56px;
  background-color: #ff5c50;
  z-index: -1;
}
.link.--icon-background .link__icon svg {
  margin-left: 0.5px;
}
.link.--underline-none {
  text-decoration: none;
}

.main-footer-column:nth-child(4) {
  min-width: 100%;
}
@media (min-width: 768px) {
  .main-footer-column:nth-child(4) {
    min-width: 264px;
  }
}
@media (min-width: 1500px) {
  .main-footer-column:nth-child(4) {
    min-width: 448px;
  }
}

.main-footer-column__link {
  overflow-wrap: break-word;
  word-break: break-all;
}

.main-footer-column__delivery .g-methods {
  gap: 16px;
}

@media (max-width: 575.98px) {
  .main-footer__columns .main-footer-column {
    padding-inline: 16px;
  }
}
@media (width <= 359px) {
  .main-footer__columns .main-footer-column {
    padding-inline: 8px;
  }
}
@media (min-width: 576px) {
  .main-footer__columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .main-footer__columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

.main-footer__bottom-columns::before,
.main-footer__wrap::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -23px);
  width: calc(100% - 52px);
  height: 1px;
  background-color: #d5d4da;
}
@media (min-width: 1200px) {
  .main-footer__bottom-columns::before,
  .main-footer__wrap::before {
    transform: translate(-50%, -15px);
  }
}

.main-footer__bottom-columns .main-footer-column:nth-child(3) {
  margin-inline: auto;
}
@media (max-width: 1199.98px) {
  .main-footer__bottom-columns .main-footer-column:nth-child(3) {
    margin-bottom: 8px;
  }
}
.main-footer__bottom-columns .main-footer-column__link {
  color: #73717e;
  font-size: 14px;
  line-height: 16px;
  overflow-wrap: normal;
  word-break: keep-all;
}
@media (min-width: 1200px) {
  .main-footer__bottom-columns {
    flex-direction: row;
  }
}

.main-footer__wrap {
  gap: 64px;
  padding-top: 56px;
}
.main-footer__wrap::before {
  top: 0;
  transform: translate(-50%, 1px);
}

.main-footer__strip {
  background: linear-gradient(to right, #312960, #6248aa);
  height: 8px;
  width: 100%;
  max-width: 100%;
}

.m-header-bottom {
  position: relative;
}
@media (min-width: 992px) {
  .m-header-bottom {
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: 4px 8px 24px var(--shadow-color);
    transform: translateY(0);
    transition: transform 0.2s ease;
    z-index: -1;
  }
}
@media (max-width: 991.98px) {
  .m-header-bottom {
    background: transparent;
    pointer-events: none;
    height: 0;
    max-height: 100%;
    overflow: auto;
    opacity: 0;
    overscroll-behavior: none;
  }
}

.m-header-bottom__contacts {
  position: fixed;
  bottom: 0;
  border-radius: 24px 24px 0 0;
  transition: transform 0.2s ease;
  transform: translateY(100%);
  z-index: 15;
  pointer-events: auto;
}

@media (max-width: 991.98px) {
  .m-header-logo {
    max-width: 112px;
  }
}
@media (max-width: 575.98px) {
  .m-header-logo {
    max-width: 96px;
  }
}

@media (max-width: 1499.98px) {
  .m-header-middle__contacts {
    font-size: 18px;
  }
}
@media (max-width: 1199.98px) {
  .m-header-middle__contacts {
    font-size: 12px;
  }
}

@media (max-width: 991.98px) {
  .m-header-middle {
    border-radius: 0 0 24px 24px;
    transform: translateY(0);
    transition: transform 0.3s ease;
    z-index: 5;
    pointer-events: auto;
  }
}
@media (max-width: 991.98px) {
  .m-header-middle > .container {
    flex-wrap: wrap;
    gap: 16px;
  }
}
.m-header-middle .c-language-select {
  display: none;
}
@media (max-width: 991.98px) {
  .m-header-middle .i-wishlist-button {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .m-header-middle .f-base {
    min-height: 48px;
  }
}

@media (max-width: 991.98px) {
  .m-header-middle__actions {
    flex-grow: 1;
    gap: 8px;
  }
}

@media (max-width: 991.98px) {
  .m-header-top__contacts {
    justify-content: center;
  }
}

@media (max-width: 991.98px) {
  .m-header-top:has(~ .m-header.--menu-open, ~ .m-header.--user-open, ~ .m-header.--cart-open) {
    display: none;
  }
}
.m-header-top:has(.c-language-select) .m-header-top__socials {
  border-left: 1px solid #fff;
}

.m-header {
  --shadow-color: transparent;
  --header-bottom-offset: 72px;
  --menu-bottom-offset: 248px;
  top: 0;
  left: 0;
  position: sticky;
  z-index: 100;
  transition: box-shadow 0.3s ease;
  transition-property: box-shadow, transform;
}
@media (min-width: 992px) and (max-width: 1499.98px) {
  .m-header {
    --header-bottom-offset: 58px;
  }
}
@media (max-width: 991.98px) {
  .m-header {
    pointer-events: none;
  }
}
@media (max-width: 991.98px) {
  .m-header::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100dvh;
    pointer-events: none;
    background-color: #f9f9f9;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: transform 0.3s ease;
    overflow: hidden;
  }
}
@media (max-width: 991.98px) {
  .m-header.--menu-open .m-header-middle, .m-header.--search-open .m-header-middle, .m-header.--cart-open .m-header-middle, .m-header.--user-open .m-header-middle {
    position: fixed;
    width: 100%;
    top: 0;
    transform: translateY(-66px);
  }
  .m-header.--menu-open .m-header-middle.--layout-simple, .m-header.--search-open .m-header-middle.--layout-simple, .m-header.--cart-open .m-header-middle.--layout-simple, .m-header.--user-open .m-header-middle.--layout-simple {
    transform: translateY(0);
  }
  .m-header.--menu-open .m-header-middle.--layout-simple .i-user-button, .m-header.--search-open .m-header-middle.--layout-simple .i-user-button, .m-header.--cart-open .m-header-middle.--layout-simple .i-user-button, .m-header.--user-open .m-header-middle.--layout-simple .i-user-button {
    --panel-padding-top: 96px;
    --panel-offset: 0;
  }
  .m-header.--menu-open::before, .m-header.--search-open::before, .m-header.--cart-open::before, .m-header.--user-open::before {
    opacity: 1;
  }
  .m-header.--menu-open .f-search, .m-header.--search-open .f-search, .m-header.--cart-open .f-search, .m-header.--user-open .f-search {
    order: -1;
    opacity: 0;
    pointer-events: none;
  }
  .m-header.--menu-open .m-header-logo, .m-header.--search-open .m-header-logo, .m-header.--cart-open .m-header-logo, .m-header.--user-open .m-header-logo {
    display: none;
  }
  .m-header.--menu-open .btn-nav-opener, .m-header.--search-open .btn-nav-opener, .m-header.--cart-open .btn-nav-opener, .m-header.--user-open .btn-nav-opener {
    display: none;
  }
  .m-header.--menu-open .btn-nav-closer, .m-header.--search-open .btn-nav-closer, .m-header.--cart-open .btn-nav-closer, .m-header.--user-open .btn-nav-closer {
    display: block;
  }
}
@media (max-width: 991.98px) {
  .m-header.--user-open .i-user-button__button,
  .m-header.--user-open .i-cart-button__button, .m-header.--cart-open .i-user-button__button,
  .m-header.--cart-open .i-cart-button__button, .m-header.--search-open .i-user-button__button,
  .m-header.--search-open .i-cart-button__button {
    opacity: 0;
    pointer-events: none;
  }
  .m-header.--user-open .btn-nav-closer, .m-header.--cart-open .btn-nav-closer, .m-header.--search-open .btn-nav-closer {
    order: 10;
  }
}
@media (max-width: 991.98px) {
  .m-header.--search-open .f-search {
    order: 5;
    opacity: 1;
    pointer-events: auto;
    width: calc(100% - 64px);
    flex-grow: 0;
  }
  .m-header.--search-open .btn-nav-closer {
    order: 10;
  }
}
@media (max-width: 991.98px) {
  .m-header.--menu-open .m-header-bottom__contacts {
    transform: translateY(0);
  }
  .m-header.--menu-open .c-language-select {
    display: flex;
  }
  .m-header.--menu-open .i-wishlist-button {
    display: flex;
  }
  .m-header.--menu-open .i-cart-button {
    display: none;
  }
  .m-header.--menu-open .m-header-bottom {
    opacity: 1;
    pointer-events: auto;
    height: 100vh;
  }
  .m-header.--menu-open:has(.i-menu.--open) .m-header-middle__actions {
    opacity: 0;
    pointer-events: none;
  }
  .m-header.--menu-open:has(.i-menu.--open) .btn-nav-closer {
    order: 10;
  }
}
.m-header.headroom--not-top {
  --shadow-color: rgba(15, 28, 123, 0.1490196078);
}
.m-header.headroom--not-top.headroom--unpinned .m-header-middle.--layout-simple {
  transform: translateY(0);
}
@media (max-width: 991.98px) {
  .m-header.headroom--not-top.headroom--unpinned .m-header-middle {
    transform: translateY(-66px);
  }
}
@media (min-width: 992px) {
  .m-header.headroom--not-top.headroom--unpinned .m-header-bottom {
    transform: translateY(-100%);
  }
}
.m-header.headroom--not-top.headroom--unpinned .m-header-bottom.--layout-simple {
  transform: translateY(-80px);
}
@media (min-width: 992px) {
  .m-header.headroom--not-top.headroom--pinned ~ main .product-navbar.--top {
    transform: translateY(var(--offset));
  }
}
@media (min-width: 992px) {
  .m-header {
    margin-bottom: var(--header-bottom-offset);
  }
}

.m-header__body {
  min-height: 72px;
  padding-block: 14px;
}

.m-nav {
  --transition-timing: 0;
}
@media (min-width: 992px) {
  .m-nav {
    margin-left: auto;
    border-bottom: 1px solid #d7cef0;
  }
}
@media (max-width: 991.98px) {
  .m-nav {
    padding-block: 80px;
    width: 100vw;
    opacity: 0;
    transition: transform var(--transition-timing) ease;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    pointer-events: auto;
  }
  .m-nav.--open {
    opacity: 1;
  }
  .m-nav.--transition {
    --transition-timing: 0.3s;
  }
  .m-nav:has(.i-menu.--has-submenu.--open) .m-nav__promo-button {
    display: none;
  }
}

@media (min-width: 992px) {
  .m-nav__list {
    flex-direction: row;
  }
}
@media (max-width: 991.98px) {
  .m-nav__list {
    align-self: stretch;
  }
}

.c-modal__dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin-block: 2rem;
  margin-inline: auto;
  cursor: cell;
}

.c-modal__content {
  position: relative;
  width: var(--width);
  margin-inline: auto;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: var(--content-bg-color);
  cursor: auto;
  box-shadow: 4px 4px 24px rgba(15, 38, 57, 0.1019607843);
  padding: 16px 16px;
  border-radius: 16px;
  border: 1px solid #d5d4da;
  transform: translateY(32px);
  transition: transform var(--transtion-length) ease;
}

.c-modal__body {
  position: relative;
  flex: 1 1 auto;
}

.c-modal__footer.--right {
  justify-content: flex-end;
}
.c-modal__footer.--center {
  justify-content: center;
}
.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal {
  --transtion-length: 300ms;
  --width: min(100% - 32px, 880px);
  --content-bg-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
}
.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.c-modal.--open .c-modal__content {
  transform: translateY(0);
}
.c-modal.--hidden {
  visibility: hidden;
  pointer-events: none;
}
.c-modal.--size-xs {
  --width: min(100% - 32px, 368px);
}
.c-modal.--size-sm {
  --width: min(100% - 32px, 568px);
}
.c-modal.--size-xl {
  --width: min(100% - 32px, 1140px);
}
.c-modal.--bg-grey {
  --content-bg-color: #f9f9f9;
}
.c-modal.--height-stretch .c-modal__dialog {
  height: calc(100% - 64px);
}
.c-modal.--height-stretch .c-modal__content {
  height: 100%;
}
.c-modal.--height-stretch .c-modal__inner {
  height: 100%;
}

.m-add-product-to-cart-message.--success {
  background-color: #e7f4e4;
}
.m-add-product-to-cart-message.--error {
  background-color: #f9dede;
}

.m-adult-confirm {
  backdrop-filter: blur(8px);
}

.m-interpret .c-modal__content {
  padding-bottom: 24px;
}

.m-phone-order__grid-top::after {
  content: "";
  position: absolute;
  bottom: -32px;
  left: 0;
  transform: translateY(50%);
  width: 100%;
  height: 1px;
  background-color: #d7cef0;
}
@media (min-width: 576px) {
  .m-phone-order__grid-top {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 768px) {
  .m-phone-order__grid-top {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 768px) {
  .m-phone-order__grid-bottom {
    grid-template-columns: repeat(2, 1fr);
  }
}

.m-phone-order__terms {
  gap: 4px;
}

.m-phone-order__term {
  line-height: 16px;
}
.m-phone-order__term a {
  font-weight: 700;
  color: #6248aa;
}

.m-phone-order__footer {
  grid-template-columns: 1fr 222px;
}
@media (max-width: 991.98px) {
  .m-phone-order__footer {
    grid-template-columns: auto;
  }
}

@media (max-width: 991.98px) {
  .m-phone-order__submit {
    max-width: 206px;
  }
}
@media (max-width: 575.98px) {
  .m-phone-order__submit {
    max-width: 100%;
  }
}

@media (max-width: 575.98px) {
  .m-ppl.c-modal.--size-xl {
    --width: min(100%, 1140px);
  }
  .m-ppl .c-modal__dialog {
    height: calc(100% - 32px);
    margin-block: 16px;
  }
  .m-ppl .c-modal__content {
    height: 100%;
  }
  .m-ppl .c-modal__inner {
    height: 100%;
  }
  .m-ppl #ppl-parcelshop-map {
    height: calc(100% - 32px);
  }
}

.n-orders__pills .n-orders__pill:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.n-orders__pills .n-orders__pill:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.c-order-search__btn {
  min-width: 160px;
}
@media (min-width: 576px) {
  .c-order-search__btn {
    margin-left: -24px;
  }
}

.c-order-search__input {
  width: 100%;
}
@media (min-width: 576px) {
  .c-order-search__input {
    width: min(450px, 100%);
  }
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #081828;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity 0.3s ease;
  position: var(--position);
}
.c-overlay.--show {
  --opacity: 0.75;
  --pointer-events: auto;
}
.c-overlay.--absolute {
  --position: absolute;
  width: 100%;
  height: 100%;
}

.link.c-pagination__link {
  --background: transparent;
  --size: 40px;
  --font-weight: 400;
  gap: 4px;
  font-weight: var(--font-weight);
  padding: 8px 0;
  border-radius: 8px;
  background-color: var(--background);
  min-height: var(--size);
  transition-property: text-decoration-color, color, background-color;
}
.link.c-pagination__link .icon {
  --size: 20px;
}
.link.c-pagination__link.--page-number {
  --font-weight: 500;
  padding: 8px;
  text-decoration: none;
  min-width: var(--size);
}
.link.c-pagination__link.--page-number:hover {
  --background: #edecf4;
}
.link.c-pagination__link.--active {
  --background: transparent;
  --font-weight: 700;
  pointer-events: none;
  color: #ff5c50;
}
.link.c-pagination__link.--blank {
  pointer-events: none;
}

@media (max-width: 767.98px) {
  .c-pagination__items {
    order: -1;
    flex-basis: 100%;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .c-pagination {
    justify-content: center;
  }
}

.product-detail__form {
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "gallery heading" "gallery content";
}
@media (max-width: 991.98px) {
  .product-detail__form {
    grid-template-columns: 1fr;
    grid-template-areas: "heading" "gallery" "content";
  }
}

.product-detail__header,
.product-detail__variants {
  border-bottom: 1px solid #d7cef0;
}

.product-detail__footer {
  grid-template-columns: 56px auto 1fr;
  grid-template-areas: "discount discount price" "wishlist counter cart" "lowest lowest lowest";
}
@container (max-width: 488px) {
  .product-detail__footer {
    gap: 16px;
    grid-template-areas: "discount discount discount" "price price price" "wishlist counter cart" "lowest lowest lowest";
  }
}
@container (max-width: 430px) {
  .product-detail__footer {
    gap: 8px;
    grid-template-areas: "discount discount discount" "price price price" "wishlist counter counter" "cart cart cart" "lowest lowest lowest";
  }
}

.product-detail__gallery {
  grid-area: gallery;
}

.product-detail__heading {
  grid-area: heading;
  margin-bottom: -16px;
}

.product-detail__content {
  grid-area: content;
}

.product-detail__availability {
  min-height: 56px;
}

.product-detail__discount {
  grid-area: discount;
}

.product-detail__price {
  grid-area: price;
}

.product-detail__wishlist {
  grid-area: wishlist;
}

.product-detail__counter {
  grid-area: counter;
}

.product-detail__cart-add {
  grid-area: cart;
}

.product-detail__lowest-price {
  grid-area: lowest;
}

.product-gallery__slider {
  border: 1px solid #edecf4;
  max-width: 568px;
}
@media (min-width: 768px) {
  .product-gallery__slider {
    margin-left: 96px;
  }
}

@media (min-width: 768px) {
  .product-gallery__thumbnails {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: auto;
    width: 88px;
    max-height: 100%;
  }
}
@media (max-width: 767.98px) {
  .product-gallery__thumbnails {
    order: 2;
  }
}

.product-navbar {
  background-color: #f9f9f9;
  z-index: 11;
}
.product-navbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #d7cef0;
}
@media (max-width: 991.98px) {
  .product-navbar {
    position: relative;
  }
}
@media (min-width: 992px) {
  .product-navbar {
    top: var(--top);
    transition: transform 0.2s ease;
  }
}

.product-navbar__item {
  --underline-opacity: 0;
  --color-hover: #ff5c50;
  --min-height: 72px;
  min-height: var(--min-height);
}
@media (hover: hover) {
  .product-navbar__item:hover {
    --underline-opacity: 1;
  }
  .product-navbar__item:hover .product-navbar__link {
    color: var(--color-hover);
  }
}
.product-navbar__item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 4px;
  background-color: var(--color-hover);
  opacity: var(--underline-opacity);
  z-index: 1;
  transition: opacity 0.3s ease;
}
.product-navbar__item.--active {
  --underline-opacity: 1;
}
.product-navbar__item.--active .product-navbar__link {
  color: var(--color-hover);
}
@media (max-width: 767.98px) {
  .product-navbar__item {
    --min-height: 56px;
  }
}

.product-parameters__body {
  border: 1px solid #d7cef0;
}
@media (min-width: 992px) {
  .product-parameters__body {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
}

@container (min-width: 320px) {
  .product-parameters__row {
    grid-template-columns: 1fr 1fr;
  }
}

.product-parameters__value.--open .product-parameters__completed-value {
  display: inline;
}
.product-parameters__value.--open .product-parameters__short {
  display: none;
}
.product-parameters__value.--open .product-parameters__ellipsis {
  display: none;
}
.product-parameters__value.--open .product-parameters__show-more svg {
  transform: rotateX(-180deg);
}

.product-reviews__reviews-group {
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media (min-width: 992px) {
  .product-reviews__header {
    display: grid;
    column-gap: 32px;
    grid-template-columns: 355px 1fr;
    align-items: center;
  }
}

.product-reviews__bottom {
  display: grid;
  grid-template-rows: 0fr;
  margin-top: -88px;
  transition: 0.3s ease;
}

.product-reviews__load-more {
  transition: margin-top 0.3s ease;
}
.product-reviews__load-more::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #d7cef0;
  bottom: 0;
  left: 0;
}

.product-reviews__link {
  opacity: var(--link-opacity);
}
.product-reviews__link.--plus {
  --link-opacity: 1;
}
.product-reviews__link.--minus {
  --link-opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.product-reviews__percent {
  border-radius: 26px;
  padding: 4px 10px;
  min-width: 51px;
  max-height: 24px;
}

.product-reviews.--open .product-reviews__bottom {
  grid-template-rows: 1fr;
  margin-top: 0;
}
.product-reviews.--open .product-reviews__reviews-group {
  opacity: 1;
}
.product-reviews.--open .product-reviews__load-more {
  margin-top: -40px;
}
.product-reviews.--open .product-reviews__link.--plus {
  --link-opacity: 0;
  pointer-events: none;
}
.product-reviews.--open .product-reviews__link.--minus {
  --link-opacity: 1;
  pointer-events: all;
}

.product-thumbnails {
  width: 100%;
  max-height: 100%;
}
.product-thumbnails.swiper {
  position: static;
}
.product-thumbnails .swiper-slide {
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .product-thumbnails .swiper-slide {
    width: 88px;
  }
}
.product-thumbnails .swiper-slide-thumb-active .i-thumbnail {
  border-color: #6248aa;
}
.product-thumbnails .swiper-buttons {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .product-thumbnails .swiper-buttons {
    height: 100%;
    transform: translateX(-50%);
    top: 0;
    left: 50%;
    flex-direction: column;
  }
}
@media (max-width: 767.98px) {
  .product-thumbnails .swiper-buttons {
    width: 100%;
  }
}
.product-thumbnails .swiper-button {
  pointer-events: auto;
  transition: opacity 0.3s ease;
}
@media (min-width: 768px) {
  .product-thumbnails .swiper-button {
    rotate: 90deg;
  }
}
.product-thumbnails .swiper-button.swiper-button-disabled {
  opacity: 0;
}
.product-thumbnails .swiper-button.--hidden {
  opacity: 0;
  pointer-events: none;
}
@media (max-width: 767.98px) {
  .product-thumbnails .swiper-button-prev {
    margin-left: -8px;
  }
}
@media (min-width: 768px) {
  .product-thumbnails .swiper-button-prev {
    margin-top: -8px;
  }
}
@media (min-width: 768px) {
  .product-thumbnails .swiper-button-next {
    margin-bottom: -8px;
  }
}
@media (max-width: 767.98px) {
  .product-thumbnails .swiper-button-next {
    margin-right: -8px;
  }
}

.c-scroll-up {
  --size: 48px;
  --offset-right: 40px;
  --offset-bottom: 40px;
  --radius: 8px;
  --bg-white: 255 255 255;
  --background: rgb(var(--bg-white) / 50%);
  --background-hover: rgb(var(--bg-white) / 100%);
  --icon-size: 24px;
  --icon-color: #6248aa;
  --shadow: 0px 2px 16px 0px rgb(213 212 218 / 30%);
  width: var(--size);
  height: var(--size);
  bottom: var(--offset-bottom);
  right: var(--offset-right);
  border-radius: var(--radius);
  border: 1px solid #6248aa;
  background-color: var(--background);
  box-shadow: var(--shadow);
  z-index: 50;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, opacity 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(0.8);
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .c-scroll-up {
    --offset-right: 26px;
    --offset-bottom: 96px;
  }
}
.c-scroll-up:hover {
  --background: var(--background-hover);
  --shadow: 0px 4px 16px 1px rgb(98 72 170 / 20%);
}
.c-scroll-up::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 8px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-property: opacity, box-shadow;
  box-shadow: 0 0 10px 40px #fff;
}
.c-scroll-up:active::after {
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  opacity: 1;
  transition: 0s;
}
.c-scroll-up svg {
  width: var(--icon-size);
  height: var(--icon-size);
}
.c-scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0) scale(1);
}

body:has(#supportBox-iframe) .c-scroll-up {
  --offset-bottom: 24px;
  --offset-right: 92px;
}

.s-breadcrumbs__homepage {
  display: block;
}

.s-cart__price-calculation {
  --grid-template-columns: 1fr;
  --grid-template-areas: "benefits" "aside";
  grid-template-columns: var(--grid-template-columns);
  grid-template-areas: var(--grid-template-areas);
}
@media (min-width: 992px) {
  .s-cart__price-calculation {
    --grid-template-columns: 1fr 1fr;
    --grid-template-areas: "benefits aside";
  }
}
@media (min-width: 1200px) {
  .s-cart__price-calculation {
    --grid-template-columns: 2fr 1fr;
  }
}
.s-cart__price-calculation .s-cart__benefits {
  grid-area: benefits;
  align-self: start;
}
.s-cart__price-calculation .s-cart__aside {
  grid-area: aside;
}

.s-cart {
  --empty-display: none;
  --content-display: flex;
}
.s-cart.--empty {
  --empty-display: block;
  --content-display: none;
}

.s-cart__content {
  display: var(--content-display);
}

.s-cart__empty {
  display: var(--empty-display);
}

.s-content {
  --padding-y: 48px;
  margin-block: var(--padding-y);
  border-top: 1px solid #d7cef0;
}
.s-content .base-header {
  --spacing: 24px;
  gap: 16px;
}
.s-content .base-header__description {
  font-weight: 400;
}
.s-content .entry-content h6 {
  margin-top: 0;
}
.s-content:has(+ .product-reviews) {
  border-bottom: 1px solid #d7cef0;
}

.s-editorial-calendar {
  margin-top: 24px;
}

@media (min-width: 992px) {
  .s-editorial-calendar__products {
    gap: 80px;
    margin-top: 80px;
  }
}

.s-error-404__heading {
  --font-size: 176px;
  font-size: var(--font-size);
}
@media (max-width: 767.98px) {
  .s-error-404__heading {
    --font-size: 136px;
  }
}
@media (max-width: 575.98px) {
  .s-error-404__heading.--decoration::before {
    display: none;
  }
}

.s-error-404__sub-heading {
  margin-inline: auto;
  margin-bottom: 24px;
  max-width: 720px;
}

.s-error-404__perex {
  max-width: 720px;
  text-align: center;
  margin-inline: auto;
  margin-bottom: 0;
}

.s-faq-list > .base-section__container > .base-header {
  --spacing: 6px;
}

.s-faq__group-col.--box {
  background-color: #fff;
  padding: 16px;
  border-radius: 24px;
}

@media (max-width: 767.98px) {
  .s-goods-reclamation__heading.base-heading {
    display: inline;
  }
}
@media (max-width: 767.98px) {
  .s-goods-reclamation__heading.base-heading .base-heading__decoration-after {
    margin: -6px 0 0 6px;
    vertical-align: middle;
  }
}

.s-hero-signpost {
  margin-top: 40px;
}
@media (max-width: 991.98px) {
  .s-hero-signpost {
    margin-top: 24px;
  }
}

.s-hero-signpost__body:has(.i-product-offer) {
  grid-template-columns: 328px 1fr;
  grid-template-areas: "offer products";
}
@media (max-width: 991.98px) {
  .s-hero-signpost__body:has(.i-product-offer) {
    grid-template-columns: 272px 1fr;
    column-gap: 16px;
  }
}
@media (max-width: 767.98px) {
  .s-hero-signpost__body {
    display: flex;
    flex-direction: column;
  }
}

.s-hero-signpost__products {
  grid-area: products;
}

.s-login__grid {
  max-width: 928px;
  width: 100%;
  margin-inline: auto;
  align-items: center;
}
@media (min-width: 992px) {
  .s-login__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .s-login__text-side {
    padding: 24px;
  }
}
@media (max-width: 575.98px) {
  .s-login__text-side {
    padding: 16px;
  }
}

.s-login__content.entry-content ul {
  margin-top: 32px;
}
.s-login__content.entry-content li {
  margin-bottom: 32px;
}
.s-login__content.entry-content li:last-child {
  margin-bottom: 0;
}

.s-login__form-box {
  box-shadow: 0 4px 30px 2px rgba(49, 41, 96, 0.3);
  border: 1px solid #d5d4da;
  border-radius: 16px;
  max-width: 448px;
  width: 100%;
}

@media (min-width: 992px) {
  .s-order-confirmation__info-item {
    grid-column: span 2;
    grid-template-columns: subgrid;
  }
}

@media (max-width: 575.98px) {
  .s-post-detail__meta {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 575.98px) {
  .s-post-detail__share-links {
    flex-direction: column;
    align-items: normal;
    margin-left: 0;
  }
}

@media (max-width: 575.98px) {
  .s-post-detail__share {
    flex-direction: column;
  }
}

.s-products-list .base-header {
  --spacing: 0;
}
.s-products-list .base-heading::before {
  display: none;
}
.s-products-list .g-buttons .btn {
  min-width: 160px;
}
@media (min-width: 768px) {
  .s-products-list .g-buttons {
    --spacing: 40px;
  }
}

@media (max-width: 767.98px) {
  .s-products-list__header {
    flex-direction: column;
    align-items: flex-start;
  }
  .s-products-list__header .f-single-select-inline {
    margin-left: auto;
  }
}

.s-products-list__load-more.--hidden {
  display: none;
}

.s-products-slider {
  overflow: hidden;
}

@media (max-width: 767.98px) {
  .s-products-slider__slider {
    width: min(100%, 328px);
    margin-inline: auto;
  }
}

@media (min-width: 768px) {
  .s-reccommended-products .g-buttons {
    --spacing: 40px;
  }
}

.s-registration__grid {
  max-width: 928px;
  width: 100%;
  margin-inline: auto;
  align-items: center;
}
@media (min-width: 992px) {
  .s-registration__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .s-registration__text-side {
    padding: 24px;
  }
}
@media (max-width: 575.98px) {
  .s-registration__text-side {
    padding: 16px;
  }
}

.s-registration__content.entry-content ul {
  margin-top: 32px;
}
.s-registration__content.entry-content li {
  margin-bottom: 32px;
}
.s-registration__content.entry-content li:last-child {
  margin-bottom: 0;
}

@media (max-width: 575.98px) {
  .s-signpost .g-buttons {
    max-width: 416px;
    margin-inline: auto;
  }
}

.s-socials__wrap {
  gap: 64px;
  max-width: 922px;
  width: 100%;
  margin-inline: auto;
}
.s-socials__wrap .base-header {
  margin-inline: unset;
}
@media (max-width: 1199.98px) {
  .s-socials__wrap {
    gap: 32px;
  }
}

.s-text-media__grid {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .s-text-media__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-text-media__header-in {
  gap: 8px;
  margin-bottom: 24px;
}

.s-text-media.--reverse .s-text-media__col.--content-col {
  order: -1;
}

.s-top-products__body {
  box-shadow: 0 2px 16px 0 rgba(213, 212, 218, 0.3);
}

.s-top-products__products.--hidden {
  display: none;
}

.s-top-products__button.--hidden {
  display: none;
}

.s-user-password .base-header {
  --spacing: 56px;
}
@media (max-width: 575.98px) {
  .s-user-password .base-header {
    --spacing: 40px;
  }
}

.c-svg {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.c-svg.entered {
  opacity: 1;
}
.c-svg.--colored path {
  fill: var(--color);
  transition: fill 0.3s ease;
}
.c-svg.--primary {
  --color: #312960;
}
.c-svg.--white {
  --color: #fff;
}
.c-svg.--text {
  --color: #081828;
}

.w-cart-overview__more-products {
  --body-inner-overflow: hidden;
  display: grid;
  grid-template-rows: 0fr;
  overflow: var(--body-inner-overflow);
  transition: grid-template-rows 0.3s ease;
}

.w-cart-overview__more-products-body {
  min-height: 0;
}

.w-cart-overview__products.--opened .w-cart-overview__more-products {
  grid-template-rows: 1fr;
}
.w-cart-overview__products.--opened .w-cart-overview__products-button svg {
  transform: rotateX(180deg);
}
.w-cart-overview__products.--after-open .w-cart-overview__more-products {
  --body-inner-overflow: visible;
}

.w-cart-overview__shipment,
.w-cart-overview__payment,
.w-cart-overview__country {
  display: none;
}
.w-cart-overview__shipment:has(.i-overview),
.w-cart-overview__payment:has(.i-overview),
.w-cart-overview__country:has(.i-overview) {
  display: block;
}

@media (min-width: 768px) {
  .w-similar-interprets .g-buttons {
    --spacing: 32px;
  }
}
.w-similar-interprets .w-similar-interprets__header {
  --spacing: 16px;
  padding-block: 8px;
}

@media (min-width: 768px) {
  .w-top-products .g-buttons {
    --spacing: 32px;
  }
}
.w-top-products .w-top-products__header {
  --spacing: 16px;
  padding-block: 8px;
}

.entry-content > * + * {
  margin-block-start: 16px;
}
.entry-content strong:empty,
.entry-content b:empty,
.entry-content p:empty {
  display: none;
}
.entry-content hr {
  border: none;
  background: #ebebeb;
  height: 1px;
  margin-block: 48px;
}
.entry-content .wp-caption {
  border: 0;
  padding: 0;
  text-align: center;
  max-width: 100%;
}
.entry-content .wp-caption-text {
  font-size: 16px;
  margin-left: 0;
  transform: none;
  text-align: center;
  padding: 4px 10px;
  margin-bottom: 0;
}

.entry-content blockquote {
  font-weight: 400;
  padding: 16px;
  margin-block: 32px;
  position: relative;
  font-style: italic;
  background-color: #edecf4;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.entry-content blockquote p {
  font-style: italic;
  margin-bottom: 0;
}

.entry-content .column {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 24px;
}
.entry-content .column img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  float: none !important;
}
@media (max-width: 991.98px) {
  .entry-content .column h2:first-of-type,
  .entry-content .column h3:first-of-type,
  .entry-content .column h4:first-of-type,
  .entry-content .column h5:first-of-type,
  .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}
.entry-content .columns-1 {
  display: block;
}
.entry-content .columns-1 .column {
  display: block;
}
.entry-content .columns-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-top: 48px;
}
@media (max-width: 991.98px) {
  .entry-content .columns-2 {
    display: flex;
    flex-direction: column;
  }
  .entry-content .columns-2 img {
    order: -1;
  }
}
.entry-content .columns-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 991.98px) {
  .entry-content .columns-3 {
    display: flex;
    flex-direction: column;
  }
}
.entry-content .columns-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
@media (max-width: 1199.98px) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991.98px) {
  .entry-content .columns-4 {
    display: flex;
    flex-direction: column;
  }
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin-top: 40px;
  font-weight: bold;
  color: #312960;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.entry-content h1 {
  margin-top: 0;
  position: relative;
}
.entry-content h2 {
  position: relative;
}
.entry-content h6 {
  font-size: 18px;
  line-height: 24px;
}
.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > a {
  display: block;
}
.entry-content img {
  --radius: 40px;
  max-width: 100%;
  border-radius: var(--radius);
}
@media (max-width: 575.98px) {
  .entry-content img {
    --radius: 20px;
  }
}

/* stylelint-disable no-descending-specificity */
.entry-content ol > li {
  position: relative;
  counter-increment: item;
  padding-left: 24px;
}
.entry-content ol > li::before {
  content: counter(item) ".";
  font-weight: 700;
  font-size: 18px;
  top: 1px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  margin-right: 8px;
  color: #312960;
  margin-left: -35px;
  padding: 1px 4px;
  padding-left: 6px;
}
.entry-content ol > li ol > li {
  counter-increment: sub-item;
}
.entry-content ol > li ol > li::before {
  content: counter(sub-item) ".";
}
.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}
.entry-content ol > li ol > li ol > li::before {
  content: counter(sub-sub-item) ".";
}
.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: lower-alpha"] li::before {
  content: counter(item, lower-alpha) ")";
}
.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: upper-alpha"] li::before {
  content: counter(item, upper-alpha) ")";
}
.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: lower-latin"] li::before {
  content: counter(item, lower-latin) ")";
}
.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: upper-latin"] li::before {
  content: counter(item, upper-latin) ")";
}
.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: lower-greek"] li::before {
  content: counter(item, lower-greek) ")";
}
.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: upper-greek"] li::before {
  content: counter(item, upper-greek) ")";
}
.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: lower-roman"] li::before {
  content: counter(item, lower-roman) ")";
}
.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: upper-roman"] li::before {
  content: counter(item, upper-roman) ")";
}
.entry-content ul {
  list-style-type: none;
}
.entry-content ul > li {
  padding-left: 24px;
  position: relative;
  color: #484752;
}
.entry-content ul > li::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 16px;
  height: 24px;
  background-image: url("../images/svg/list-decor.svg");
  background-repeat: no-repeat;
  border-radius: 50%;
}
.entry-content ul,
.entry-content ol {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 16px;
}
.entry-content ul ul,
.entry-content ul ol,
.entry-content ol ul,
.entry-content ol ol {
  margin-bottom: 0;
  margin-top: 8px;
}
.entry-content ul li,
.entry-content ol li {
  margin-bottom: 8px;
}

/* stylelint-enable no-descending-specificity */
p {
  margin-bottom: 0;
}

/* stylelint-disable no-descending-specificity */
.entry-content .table-responsive {
  margin: 16px 0;
  border: 16px solid #fff;
  box-shadow: 0 0 0 1px #ebebeb;
  overflow: auto;
}
.entry-content th,
.entry-content td,
.entry-content tr {
  border: none !important;
}
.entry-content td,
.entry-content th {
  padding: 16px;
  text-align: center;
  position: relative;
}
.entry-content td:first-child,
.entry-content th:first-child {
  border-radius: 8px 0 0 8px;
}
.entry-content td:last-child,
.entry-content th:last-child {
  border-radius: 0 8px 8px 0;
}
.entry-content table {
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 100%;
}
.entry-content table p {
  margin-bottom: 0 !important;
  font-size: 16px;
  transform: none;
}
.entry-content thead {
  background-color: #7868a3;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.entry-content tbody {
  background-color: #fff;
  color: #1d1d1b;
  font-size: 14px;
}
.entry-content tbody tr:nth-child(2n) {
  background-color: #f9f9f9;
}
.entry-content tbody td,
.entry-content tbody th {
  padding: 8px;
}

/* stylelint-enable no-descending-specificity */
/*! purgecss end ignore */
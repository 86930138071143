@use "settings/s-colors" as colors;

@mixin discount-item-size-sm {
  --size: 32px;
  --font-size: 12px;
}

@mixin discount-item-size-md {
  --size: 40px;
  --font-size: 14px;
}

@mixin discount-item-size-lg {
  --size: 64px;
  --font-size: 18px;
}

.i-discount {
  aspect-ratio: 1/1;
  background-color: colors.$label-1;
  text-align: center;
  min-width: var(--size);
  min-height: var(--size);
  border-radius: 50%;
  font-size: var(--font-size);

  &.--size-sm {
    @include discount-item-size-sm;
  }

  &.--size-md {
    @include discount-item-size-md;
  }

  &.--size-lg {
    @include discount-item-size-lg;
  }
}

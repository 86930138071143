@use "settings/s-colors" as colors;

.i-user-button__button.--full {
  display: none;
}

.i-user-button__header.base-heading {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    height: 80px;
    top: var(--panel-offset);
    opacity: 0;
    pointer-events: none;
  }
}

.i-user-button__panel {
  width: min(calc(100vw - 16px), 304px);

  @include media-breakpoint-down(lg) {
    width: 100%;
    top: 0;
    height: calc(100vh + var(--panel-offset));
    padding-top: var(--panel-padding-top);
    left: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    transition: opacity 0.3s ease;
  }
}

.i-user-button {
  --panel-padding-top: 162px;
  --panel-offset: 66px;

  @include media-breakpoint-down(lg) {
    position: static;
  }

  .container {
    @include media-breakpoint-up(lg) {
      padding: 16px;
    }
  }

  &:hover {
    .i-user-button__button {
      .icon {
        --color: #{colors.$secondary-500};
      }
    }
  }

  &.--filled {
    .i-user-button__button {
      &.--full {
        display: flex;
      }

      &.--empty {
        display: none;
      }
    }
  }

  &.--open {
    .i-user-button__header {
      opacity: 1;
    }

    .i-user-button__panel {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.u-effect-fade {
  > .u-effect-fade__target {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    > .u-effect-fade__target {
      @include media-breakpoint-up(lg) {
        opacity: 1;
        pointer-events: auto;
        transition: 0.3s ease;
      }
    }
  }
}

@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-category__image-link {
  max-height: 140px;
  max-width: 140px;
  transition: transform 0.3s linear;
  transform-style: preserve-3d;

  @include media-breakpoint-up(md) {
    max-height: 160px;
    max-width: 160px;
  }

  .img {
    border-radius: 50%;
    border: 4px solid colors.$white;
    box-shadow: shadows.$small-shadow;
  }
}

.i-category__image-back-overlay {
  backface-visibility: hidden;
  background-color: rgba(colors.$primary-600, 0.8);
  color: colors.$white;
  transform: rotateY(180deg);
  column-gap: 4px;
}

.i-category__image-wrapper {
  width: min(var(--image-size), 100%);
  height: min(var(--image-size), 100%);

  &:hover {
    .i-category__image-link {
      transform: rotateY(180deg);
    }
  }
}

.i-category__image-overlay {
  min-width: 56px;
  height: 56px;
  background-color: colors.$white;
  color: colors.$primary-600;
  padding-inline: 16px;

  @include media-breakpoint-down(up) {
    right: -24px;
  }
}

.i-category__decoration {
  left: 0;
  column-gap: 3px;

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::after {
    width: 16px;
    height: 16px;
    background-color: colors.$primary-600;
    border-radius: 16px;
  }

  &::before {
    width: 8px;
    height: 16px;
    background-color: colors.$secondary-500;
    border-radius: 0 16px 16px 0;
  }
}

.i-category {
  --image-size: 140px;

  &.--last {
    .i-category__decoration {
      transform: scale(-1);
      right: 0;
      left: auto;
    }
  }

  @include media-breakpoint-up(md) {
    --image-size: 160px;
  }
}

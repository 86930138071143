@use "settings/s-colors.scss" as colors;
@use "settings/s-shadows.scss" as shadows;

.g-mediums {
  &.--toggle {
    border-radius: 16px;
    padding: 10px;
    flex-direction: column;
    background: colors.$white;
    width: max-content;
    pointer-events: none;
    opacity: 0;
    box-shadow: shadows.$button-primary-hover-shadow;
    transform: translate(-1px, -1px);
    transition: 0.1s opacity ease;

    @include media-breakpoint-down(sm) {
      left: 50%;
      transform: translateX(-50%);
    }

    .i-medium {
      &::before {
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .g-mediums__show-more {
    &:hover {
      .g-mediums {
        &.--toggle {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}

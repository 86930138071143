.entry-content {
  .column {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 24px;

    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
      float: none !important;
    }

    @include media-breakpoint-down(lg) {
      h2,
      h3,
      h4,
      h5,
      h6 {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  .columns-1 {
    display: block;

    .column {
      display: block;
    }
  }

  .columns-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-top: 48px;

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;

      img {
        order: -1;
      }
    }
  }

  .columns-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
    }
  }

  .columns-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
    }
  }
}

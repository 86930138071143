@use "settings/s-colors" as colors;

.f-textarea {
  --inset-shadow-color: #{colors.$tertiary-400};
  --font-color: #{colors.$primary};
  --shadow: 0px 0px 0px 0px transparent;
  --radius: 24px;

  &:hover {
    --shadow: 0px 4px 10px 2px rgb(120 104 163 / 10%);

    .f-base {
      --inset-shadow-color: #{colors.$primary-600};
    }
  }

  .f-label {
    --top: 24px;
  }

  &.--active {
    .f-base {
      --inset-shadow-color: #{colors.$primary-600};
    }

    .f-label {
      --top: 0;
    }
  }

  &.--invalid {
    .f-base {
      --inset-shadow-color: #{colors.$danger};
    }
  }

  &.--disabled {
    --font-color: #{colors.$primary-300};

    cursor: not-allowed;

    .f-base {
      background-color: colors.$background;
    }

    &,
    &.--active {
      .f-base {
        --inset-shadow-color: #{colors.$tertiary-400};
      }
    }
  }
}

.f-textarea__wrap {
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  transition: box-shadow 0.3s ease;
}

.f-textarea__input {
  border-radius: var(--radius);
  color: var(--font-color);
}

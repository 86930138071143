@use "settings/s-colors" as colors;

.i-cart-dropdown {
  width: min(calc(100vw - 16px), 568px);
  max-height: min(calc(100vh - 160px), 712px);

  @include media-breakpoint-down(lg) {
    width: 100%;
    top: 0;
    height: calc(100vh + 66px);
    max-height: none;
    padding-top: 162px;
    left: 0;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
    z-index: auto;
  }

  &:not(:has(.i-product-overview)) .i-cart-dropdown__cart-button {
    display: none;
  }
}

.i-cart-dropdown__title {
  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 66px;
    height: 80px;
    align-items: center;
    pointer-events: none;
    color: colors.$white;
    width: auto;
  }

  .base-heading__decoration-after {
    &::after {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }
}

.i-cart-dropdown__cart-list-wrapper {
  overflow: auto;
}

@use "settings/s-colors" as colors;

.f-radio-box__visual {
  --size: 24px;
  --border-width: 1px;
  --color: #{colors.$primary-600};
  --radius: calc(var(--size) / 2);
  --options-col-display: block;

  width: var(--size);
  height: var(--size);
  box-shadow: 0 0 0 var(--border-width) var(--color);
  transition:
    box-shadow 0.3s ease,
    background-color 0.3s ease;
  border-radius: var(--radius);

  svg {
    opacity: 0;
    transform: scale(0.2);
    transition: opacity 0.3s ease;
    transition-property: opacity, transform;
  }

  &::after {
    content: "";
    position: absolute;
    display: none;
    width: calc(var(--size) / 2);
    height: calc(var(--size) / 2);
    background-color: colors.$white;
    border-radius: calc(var(--size) / 2);
    opacity: 0;
    transform: scale(0.2);
    transition: opacity 0.3s ease;
    transition-property: opacity, transform;
  }
}

.f-radio-box__image-col {
  flex: 0 0 80px;
}

.f-radio-box__options-col {
  display: var(--options-col-display);

  > .link {
    @include media-breakpoint-up(md) {
      margin-left: 24px;
    }
  }
}

.f-radio-box__reload-link.link {
  display: none;
}

.f-radio-box {
  &.--disabled {
    .f-radio-box__visual {
      --color: #{colors.$primary-300};
    }

    .f-radio-box__image-col {
      opacity: 0.6;
    }

    &,
    .i-price__main,
    .i-price__before,
    .i-price__base {
      color: colors.$primary-300;
    }
  }

  &:has(.f-radio-box__reload-link) {
    --options-col-display: none;
  }

  &:has(.f-radio-box__input:checked) {
    --options-col-display: flex;
  }
}

.f-radio-box__body {
  --inset-shadow-color: transparent;

  box-shadow: inset 0 0 0 1px var(--inset-shadow-color);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: colors.$gray-200;

    .f-radio-box__visual {
      --border-width: 2px;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }
}

.f-radio-box__content {
  & > * {
    flex: 1 1 0;
  }

  @include media-breakpoint-down(lg) {
    padding-left: 40px;
  }
}

.f-radio-box__title {
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.f-radio-box__input {
  display: none;

  &:checked ~ .f-radio-box__body {
    --inset-shadow-color: #{colors.$primary-300};

    background-color: colors.$white;

    .f-radio-box__visual {
      background-color: colors.$primary;

      svg {
        opacity: 1;
        transform: scale(1);
      }
    }

    .f-radio-box__reload-link.link {
      display: inline-flex;
    }
  }

  &:disabled:checked ~ .f-radio-box__body {
    .f-radio-box__visual {
      background-color: colors.$primary-300;
    }
  }
}

.f-radio-box__price-col {
  flex: 1 0 auto;

  @include media-breakpoint-up(lg) {
    flex-basis: 100px;
    max-width: 100px;
  }
}

@use "settings/s-colors" as colors;

.i-reclamation__collapsible-content {
  --body-inner-overflow: hidden;

  overflow: var(--body-inner-overflow);
}

.i-reclamation {
  --transition-duration: 0.3s;
  --product-tile-height: 120px;
  --border-color: transparent;

  grid-template-rows: var(--product-tile-height) 0fr;
  transition: grid-template-rows var(--transition-duration) ease;
  border: 1px solid var(--border-color);

  &.--expanded {
    --border-color: #{colors.$primary-100};

    grid-template-rows: var(--product-tile-height) 1fr;
  }

  &.--after-expanded {
    .i-reclamation__collapsible-content {
      --body-inner-overflow: visible;
    }
  }

  @include media-breakpoint-down(lg) {
    --product-tile-height: 160px;
  }

  @include media-breakpoint-down(md) {
    --product-tile-height: 180px;
  }

  @include media-breakpoint-down(sm) {
    --product-tile-height: 220px;
  }
}

.reclamation__product-tile {
  --image-size: 88px;
  --grid-columns: var(--image-size) 3fr 1fr 0.5fr;
  --grid-areas: "image content counter price";

  grid-template-columns: var(--grid-columns);
  grid-template-areas: var(--grid-areas);
  min-height: var(--image-size);

  .c-counter {
    width: 150px;
  }

  @include media-breakpoint-down(xl) {
    --grid-columns: var(--image-size) 3fr 1fr 1fr;
  }

  @include media-breakpoint-down(lg) {
    --image-size: 64px;

    grid-template-columns: var(--image-size) 1fr 1fr;
    grid-template-areas:
      "image content content"
      "image counter price";
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: var(--image-size) 1fr;
    grid-template-areas:
      "image content"
      "image counter"
      "image price";
  }
}

.i-reclamation__image {
  grid-area: image;
  width: var(--image-size);
  height: var(--image-size);
}

.i-reclamation__content {
  grid-area: content;
}

.i-reclamation__counter {
  grid-area: counter;
}

.i-reclamation__price {
  grid-area: price;
}

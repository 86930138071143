@use "settings/s-colors.scss" as colors;

a {
  color: colors.$primary;
  transition: text-decoration-color 0.3s ease;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: colors.$primary;
    text-decoration-color: transparent;
  }
}

.u-h1 {
  --size: 45px;

  @include media-breakpoint-down(md) {
    --size: 32px;
  }
}

.u-h2 {
  --size: 37px;

  @include media-breakpoint-down(md) {
    --size: 28px;
  }
}

.u-h3 {
  --size: 28px;

  @include media-breakpoint-down(md) {
    --size: 26px;
  }
}

.u-h4 {
  --size: 24px;

  @include media-breakpoint-down(md) {
    --size: 20px;
  }
}

.u-h5 {
  --size: 22px;

  @include media-breakpoint-down(md) {
    --size: 18px;
  }
}

.u-h6 {
  --size: 18px;

  @include media-breakpoint-down(md) {
    --size: 16px;
  }
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5,
.u-h6 {
  font-size: var(--size);
}

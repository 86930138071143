.g-products__title {
  grid-column: 1/-1;
  margin-bottom: -8px;
}

.g-products {
  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-down(md) {
    .i-product {
      margin-inline: auto;
    }
  }

  &.--lg {
    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

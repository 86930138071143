@use "settings/s-colors" as colors;

.c-compositions-box {
  border: 1px solid colors.$primary-100;

  &.entry-content {
    ol {
      column-count: 2;
      column-gap: 32px;
      margin-top: 0;
    }

    ol > li {
      padding-left: 24px;

      &::before {
        font-size: 16px;
        width: 24px;
        height: 24px;
        color: colors.$primary-600;
        margin-left: -30px;
        justify-content: center;
      }
    }

    h6,
    li:last-child {
      margin-bottom: 0;
    }
  }
}
